import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  FormGroup,
  Input,
  Label,
  Button,
} from 'reactstrap';

import Loading from './Loading';

import { closeAllModals } from '../actions/modalActions';

import { numToWords, callApi } from '../utils';
import { showError, showInfo } from '../actions/notificationActions';

export default connect(({ modals, user }) => ({
  open: modals.merchant,
}))(
  class CreditMerchant extends Component {
    state = {
      phone: '',
      amount: 0,
      operation: 'credit',
    };

    setInput = (e) => {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    };

    submitRequest = (e) => {
      e.preventDefault();
      const { phone, amount, loading, ref, operation } = this.state;
      const userId = JSON.parse(localStorage.getItem('auth')).id;

      if (loading) return;

      const prompt = `
Are you sure you want to ${operation} merchant with: 

Phone: ${phone}
Amount: ₦ ${amount}

${numToWords(amount).toUpperCase()} NAIRA
    `;

      if (!window.confirm(prompt)) return; // Prompt Agent to confirm wallet crediting

      this.setState({ loading: true });

      callApi(
        '/wallet/update',
        {
          operation,
          type: operation === 'credit' ? 'topup' : 'reversal',
          channel: 'bank',
          phone,
          amount,
          userId,
          ref,
        },
        'post'
      )
        .then(({ data }) => {
          this.setState({ loading: false, phone: '', amount: 0 });
          this.props.dispatch(showInfo(JSON.stringify(data)));
          this.close();
        })
        .catch((err) => {
          this.setState({ loading: false, phone: '', amount: 0 });
          this.props.dispatch(showError(err));
        });
    };

    close = () => {
      this.props.dispatch(closeAllModals());
    };

    render() {
      const { phone, amount, loading, operation } = this.state;
      const { open } = this.props;

      return (
        <div>
          <Modal size="lg" isOpen={open} toggle={this.close} backdrop="static">
            <ModalHeader toggle={this.close}>Credit Merchant</ModalHeader>
            <ModalBody>
              <form onSubmit={this.submitRequest}>
                <FormGroup>
                  <Label>Phone</Label>
                  <Input
                    onChange={this.setInput}
                    value={phone}
                    name="phone"
                    placeholder="Merchant's Phone Number"
                    type="text"
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Reference</Label>
                  <Input
                    onChange={this.setInput}
                    name="ref"
                    placeholder="Reference"
                    type="text"
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Amount</Label>
                  <Input
                    onChange={this.setInput}
                    value={amount}
                    name="amount"
                    placeholder="Amount"
                    type="text"
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Operation</Label>
                  <Input
                    type="select"
                    name="operation"
                    id="operation"
                    onChange={this.setInput}
                    value={operation}
                  >
                    <option value="credit">Credit</option>
                    <option value="debit">Debit</option>
                  </Input>
                </FormGroup>
                <hr />

                {loading && (
                  <Row className="justify-content-center">
                    <Loading />
                  </Row>
                )}

                <Row className="justify-content-center">
                  <Button size="lg" color="primary" type="submit">
                    {loading ? 'PLEASE WAIT...' : 'CONFIRM'}
                  </Button>
                </Row>
              </form>
            </ModalBody>
          </Modal>
        </div>
      );
    }
  }
);
