import React, { Component } from 'react';

import { Badge } from 'reactstrap';

import Loading from './Loading';
import { callApi } from '../utils';

import { Collapse, Button, Row, Card } from 'reactstrap';
import SuccessTick from './SuccessTick';
import ErrorTick from './ErrorTick';
import { showInfo, showError } from '../actions/notificationActions';
import BuyPowerTimeline from './BuyPowerTimeline';

const getTimelineColor = (type) => {
  switch (type) {
    case 'action':
      return 'info';

    case 'open':
      return 'secondary';

    case 'input':
      return 'primary';

    case 'error':
      return 'danger';

    case 'success':
      return 'success';

    case 'close':
      return 'light';

    case 'auth':
      return 'warning';

    default:
      return 'primary';
  }
};

const getRaveTimelineColor = (action) => {
  switch (action) {
    case 'loaded':
      return 'info';

    case 'switched':
      return 'secondary';

    case 'click':
      return 'primary';

    case 'validate':
      return 'primary';

    case 'request':
      return 'danger';

    case 'typing':
      return 'light';

    case 'selected':
      return 'success';

    case 'completion':
      return 'success';

    case 'submitting':
      return 'light';

    case 'submitted':
      return 'warning';

    case 'submiting':
      return 'light';

    case 'submited':
      return 'warning';

    case 'charge':
      return 'danger';

    default:
      return 'light';
  }
};

export const Timeline = ({ timeline = [] }) => {
  return timeline ? (
    <Row className="justify-content-center text-center">
      <ul className="timeline-list">
        <li>
          Time: <strong>{timeline.time_spent} Seconds</strong>
        </li>
        <li>
          Mobile:
          <strong>
            {timeline.mobile ? (
              <span className="text-success">YES</span>
            ) : (
              <span className="text-danger">NO</span>
            )}
          </strong>
        </li>
        <li>Attempts: {timeline.attempts}</li>
        {timeline.channel && <li>Channel: {timeline.channel.toUpperCase()}</li>}

        <li>
          Successful:{' '}
          <strong>
            {timeline.success ? (
              <span className="text-success">YES</span>
            ) : (
              <span className="text-danger">NO</span>
            )}
          </strong>
        </li>
        {timeline.risk_action ? (
          <span>Risk Action: {timeline.risk_action}</span>
        ) : (
          <i />
        )}
        {timeline.errors ? (
          <li className="text-danger">
            {timeline.errors} Error
            {timeline.errors > 1 ? 's' : ''}
          </li>
        ) : null}
        {timeline.history != null &&
          timeline.history.map((t, i) => (
            <li key={i}>
              <Badge color={getTimelineColor(t.type)}>{t.type}</Badge> &rarr;{' '}
              {t.message}
            </li>
          ))}
      </ul>
    </Row>
  ) : (
    <span>No Timeline</span>
  );
};

export const CardDetails = ({ details }) => {
  let { channel = '', bank = '', brand = '' } = details;
  return (
    <Row className="justify-content-center text-center">
      <ul className="timeline-list">
        <li>
          Bank: <strong>{bank.toUpperCase()}</strong>
        </li>
        {channel === 'card' && (
          <li>
            Card: <strong>{brand.toUpperCase()}</strong>
          </li>
        )}
      </ul>
    </Row>
  );
};

export class WhitelistPaystack extends Component {
  state = { loading: false };

  whitelistAction = (flag) => {
    if (
      !window.confirm(
        flag ? 'Whitelist Customer?' : 'Remove Customer Whitelist?'
      )
    )
      return;

    this.setState({ loading: true });
    callApi(
      `/paystack/whitelist?action=${flag ? 'allow' : 'default'}&id=${
        this.props.order.order_id
      }&reference=${this.props.reference}`
    )
      .then((res) => {
        this.setState({ loading: false });
        this.props.dispatch(
          showInfo(res.message || 'Whitelist Action Successful')
        );
        this.props.done();
      })
      .catch((err) => {
        this.setState({ loading: false });
        this.props.dispatch(showError(err));
      });
  };

  render() {
    return (
      <Row className="justify-content-center text-center">
        {this.state.loading ? (
          <Loading />
        ) : (
          <div>
            <span>
              Whitelisted:{' '}
              {this.props.whitelisted ? <SuccessTick /> : <ErrorTick />}{' '}
            </span>
            <br />
            {this.props.whitelisted ? (
              <Button
                onClick={() => this.whitelistAction(false)}
                color="danger"
                size="sm"
              >
                REMOVE WHITELIST
              </Button>
            ) : (
              <Button
                onClick={() => this.whitelistAction(true)}
                color="success"
                size="sm"
              >
                WHITELIST USER
              </Button>
            )}
          </div>
        )}
      </Row>
    );
  }
}

export class UpdateStatus extends Component {
  state = { loading: false };

  update = () => {
    this.setState({ loading: true });
    callApi(`${this.props.endpoint}?id=${this.props.order.order_id}`)
      .then((res) => {
        this.props.dispatch(
          showInfo(res.message || 'Paystack Status updated...')
        );
        this.setState({ loading: false });
        this.props.done();
      })
      .catch((err) => {
        this.props.dispatch(showError(err));
        this.setState({ loading: false });
      });
  };

  render() {
    return (
      <Row className="justify-content-center text-center">
        {this.state.loading ? (
          <Loading />
        ) : (
          <div>
            <Button size="sm" color="secondary" onClick={this.update}>
              UPDATE STATUS
            </Button>
          </div>
        )}
      </Row>
    );
  }
}

export const RaveTimeline = ({ timeline = [] }) => {
  return timeline ? (
    <Row className="justify-content-center text-center p-2">
      <ul className="timeline-list">
        {timeline.map((t, i) => (
          <li key={i}>
            <Badge color={getRaveTimelineColor(t.action)}>{t.action}</Badge>{' '}
            &rarr; {t.note}
          </li>
        ))}
      </ul>
    </Row>
  ) : (
    <span>No Rave Timeline</span>
  );
};

export class WhitelistRave extends Component {
  state = { loading: false };

  whitelistAction = () => {
    if (!window.confirm('Whitelist Customer?')) return;
    this.setState({ loading: true });
    callApi(`/rave/whitelist?action=whitelist&id=${this.props.reference}`)
      .then((res) => {
        this.setState({ loading: false });
        this.props.dispatch(
          showInfo(res.message || 'Whitelist Action Successful')
        );
        this.props.done();
      })
      .catch((err) => {
        this.setState({ loading: false });
        this.props.dispatch(showError(err));
      });
  };

  render() {
    return (
      <Row className="justify-content-center text-center">
        {this.state.loading ? (
          <Loading />
        ) : (
          <div>
            <Button onClick={this.whitelistAction} color="success" size="sm">
              WHITELIST USER
            </Button>
          </div>
        )}
      </Row>
    );
  }
}

export default class TransactionTimeline extends Component {
  state = {
    order: null,
    data: null,
    id: null,
    type: '',
    timeline: null,
    buypower: [], // BuyPower Transaction Timeline
    customer: null,
    details: null,
    loading: false,
    open: false,
    error: '',
  };

  loadDetails = () => {
    this.setState({ loading: true });
    callApi('/transaction/timeline?id=' + this.props.order.order_id)
      .then((data) => {
        let type = data.type;
        if (type === 'PAYSTACK') {
          data = data.data;
          this.setState({
            data,
            id: data.reference,
            type,
            timeline: data.log,
            customer: data.customer || {},
            details: data.authorization,
            loading: false,
            error: '',
          });
        }

        if (type === 'RAVE') {
          data = data.data;
          this.setState({
            data,
            timeline: data,
            type,
            loading: false,
            error: '',
          });
        }

        if (type === 'BUYPOWER' && data.buypower.length) {
          this.setState({ data, buypower: data.buypower, loading: false });
        }
      })
      .catch((err) => {
        this.setState({ loading: false, error: err.message });
      });
  };

  toggle = () => {
    this.setState(({ open }) => {
      if (!open) {
        this.loadDetails();
      }
      return { open: !open };
    });
  };

  render() {
    const {
      data,
      type,
      timeline,
      buypower,
      customer,
      details,
      loading,
      open,
      error,
    } = this.state;
    return (
      <div>
        <Button block color="secondary" onClick={this.toggle}>
          {open ? 'Close' : 'Open'} Transaction Timeline
        </Button>
        {loading ? (
          <Loading />
        ) : (
          open && (
            <Collapse isOpen={open}>
              <Card>
                {error ? (
                  <h5>{error}</h5>
                ) : (
                  <div>
                    <BuyPowerTimeline
                      done={this.loadDetails}
                      data={buypower}
                      meta={data.data}
                    />
                    {type === 'RAVE' ? (
                      <div>
                        <RaveTimeline timeline={timeline} />
                        <UpdateStatus
                          id={this.props.order.order_id}
                          dispatch={this.props.dispatch}
                          done={this.loadDetails}
                          endpoint="/rave/updatestatus"
                        />
                        <WhitelistRave
                          reference={this.props.order.order_id} // Transaction reference
                          done={this.loadDetails}
                          dispatch={this.props.dispatch}
                        />
                      </div>
                    ) : type === 'PAYSTACK' ? (
                      <div>
                        <CardDetails details={details} />
                        <Timeline timeline={timeline || undefined} />
                        <WhitelistPaystack
                          id={customer.customer_code} // User ID/code
                          reference={data.reference} // Transaction reference
                          whitelisted={customer.risk_action === 'allow'}
                          done={this.loadDetails}
                          dispatch={this.props.dispatch}
                        />
                        <UpdateStatus
                          id={this.props.order.order_id}
                          dispatch={this.props.dispatch}
                          done={this.loadDetails}
                          endpoint="/paystack/updatestatus"
                        />
                      </div>
                    ) : null}
                  </div>
                )}
              </Card>
            </Collapse>
          )
        )}
      </div>
    );
  }
}
