import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Table,
} from 'reactstrap';

import { callApi, formatAmount, formatDateTime } from '../../utils';
import { showError } from '../../actions/notificationActions';

import Loading from '.././Loading';
import Pagination from '.././Pagination';
import SearchForm from '.././SearchForm';

class List extends Component {
  state = {
    rows: [],
    query: null,
    page: 1,
    limit: 30,
    loading: false,
    pages: 1,
  };

  componentWillMount() {
    this.load();
  }

  startLoad = () => this.setState({ loading: true });
  stopLoad = () => this.setState({ loading: false });

  scrollToTop = () =>
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);

  load = () => {
    const { query } = this.state;

    this.startLoad();

    callApi(`/payments`, { q: query })
      .then(({ data = [], meta = {} }) => {
        this.setState({ loading: false, rows: data, pages: meta.pages });
        this.scrollToTop();
      })
      .catch((err) => {
        this.stopLoad();
        this.props.dispatch(showError(err));
      });
  };

  search = (e) => {
    e.preventDefault();
    const { query } = this.state;
    if(!query) {
      return;
    }
    this.load();
  }

  setPage = (page) => {
    if (page) {
      this.setState({ page }, () => {
        this.load();
      });
    }
  };

  setQuery = (e) => {
    this.setState({ query: e.target.value });
  };

  view = (id) => {
    this.props.history.push(`/all-payments/${id}`);
  };

  render() {
    const { query, rows, page, loading, limit, pages } = this.state;
    return (
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardHeader className="ml-2">
                <CardTitle>Payments</CardTitle>
                <p className="category">Payments - Page {page}</p>
              </CardHeader>
              <SearchForm
                value={query}
                onChange={this.setQuery}
                onSearch={this.search}
                loading={loading}
              />
              <CardBody>
                <Table responsive hover striped>
                  <thead className="text-primary">
                    <tr>
                      <th>No.</th>
                      <th>Due</th>
                      <th>Paid</th>
                      <th>Order ID</th>
                      <th>Method</th>
                      <th>Reference</th>
                      <th>Status</th>
                      <th>Date</th>
                      {/* <th>Email</th> */}
                    </tr>
                  </thead>

                  <tbody>
                    {rows.map((t, i) => (
                      <tr onClick={() => this.view(t.id)} key={t.id}>
                        <td>{loading ? 'x' : i + 1 + (page - 1) * limit}</td>
                        <td>{formatAmount(t.amount_due)}</td>
                        <td>{formatAmount(t.amount_paid)}</td>
                        <td>{t.order_id}</td>
                        <td>{t.method}</td>
                        <td>{t.reference}</td>
                        <td>{t.status}</td>
                        <td>{formatDateTime(t.created_at)}</td>
                        {/* <td>{t.email}</td> */}
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Pagination total={pages} page={page} onChange={this.setPage} />
                {loading && <Loading />}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect()(List);
