import React, { Component } from 'react';

import { Row, Col, Card, CardHeader, CardTitle, CardBody } from 'reactstrap';

export default class AdminDashboards extends Component {
  render() {
    return (
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardHeader className="ml-2">
                <CardTitle>Admin Dashboards</CardTitle>
              </CardHeader>

              <CardBody>
                <Row>
                  <Col md={6}>
                    <h4>KALLAK</h4>
                    <a
                      href="https://retailer.kvg.com.ng/Home/Login"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://retailer.kvg.com.ng/Home/Login
                    </a>
                  </Col>
                  <Col md={6}>
                    <h4>JED</h4>
                    <a
                      href="https://jedbills.com.ng/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://jedbills.com.ng/
                    </a>
                  </Col>
                  <Col md={6}>
                    <h4>PAYVICE</h4>
                    <a
                      href="https://www.payvice.com/dashboard/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://www.payvice.com/dashboard/
                    </a>
                  </Col>
                  <Col md={6}>
                    <h4>NIBSS</h4>
                    <a
                      href="https://apps.nibss-plc.com.ng/TQS/merchantpay"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://apps.nibss-plc.com.ng/TQS/merchantpay
                    </a>
                  </Col>
                  <Col md={6}>
                    <h4>PAYSTACK</h4>
                    <a
                      href="http://dashboard.paystack.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      http://dashboard.paystack.com
                    </a>
                  </Col>
                  <Col md={6}>
                    <h4>RAVE</h4>
                    <a
                      href="https://rave.flutterwave.com/dashboard"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://rave.flutterwave.com/dashboard
                    </a>
                  </Col>
                  <Col md={6}>
                    <h4>MONNIFY</h4>
                    <a
                      href="https://app.monnify.com/dashboard"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://app.monnify.com/dashboard
                    </a>
                  </Col>
                  <Col md={6}>
                    <h4>BAXI</h4>
                    <a
                      href="https://baxi.capricorndigi.com/app"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://baxi.capricorndigi.com/app
                    </a>
                  </Col>
                  <Col md={6}>
                    <h4>PAYU</h4>
                    <a
                      href="https://mca-portal.herokuapp.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://mca-portal.herokuapp.com/
                    </a>
                  </Col>
                  <Col md={6}>
                    <h4>GLOBAL ACCELEREX</h4>
                    <a
                      href="https://db.globalaccelerex.com/posmgmt/#/login"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://db.globalaccelerex.com/posmgmt/#/login
                    </a>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
