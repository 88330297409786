import React from 'react';

import { Row, Col, Card, CardHeader, CardTitle, CardBody } from 'reactstrap';

import { formatAmount } from '../../utils';

import ClickToCopy from '../ClickToCopy';

function InfoTab({ supervisor }) {
  return (
    <Row>
      <Col sm="6">
        <Card className="card-chart">
          <CardHeader>
            <CardTitle className="mt-0">Supervisor</CardTitle>
          </CardHeader>
          <CardBody>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Supervisor ID</span>
              </div>
              <div> {supervisor.id} </div>
            </div>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Name</span>
              </div>
              <div> {supervisor.name} </div>
            </div>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Phone</span>
              </div>
              <span>
                <span className="cursor-pointer blue-text" onClick={() => {}}>
                  <strong>{supervisor.phone}</strong>{' '}
                </span>
                <ClickToCopy value={supervisor.phone} />
              </span>
            </div>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">DisCo</span>
              </div>
              <div> {supervisor.disco} </div>
            </div>
            {/* <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Payment Method</span>
              </div>
              <div> {supervisor.payment_type} </div>
            </div> */}
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Balance</span>
              </div>
              {supervisor.wallet && (
                <div> {formatAmount(supervisor.wallet)} </div>
              )}
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default InfoTab;
