import { UPDATE_USER, CLEAR_USER } from '../actions/userActions';

const initUserState = {
  phone: '',
  email: '',
  name: '',
  role: null,
  id: null,
};

export default (state = initUserState, action) => {
  switch (action.type) {
    case UPDATE_USER:
      return action.data ? { ...state, ...action.data } : initUserState;

    case CLEAR_USER:
      return initUserState;

    default:
      return state;
  }
};
