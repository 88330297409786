import React, { Component } from 'react';

import { Row, Col, Card } from 'reactstrap';

import {
  callApi,
  formatDate,
  _toDash,
  formatAmount,
  VERTICAL_TYPES,
} from '../utils';
import { showError, showInfo } from '../actions/notificationActions';

import Loading from './Loading';
import ErrorTick from './ErrorTick';
import SuccessTick from './SuccessTick';
import ClickToCopy from './ClickToCopy';
import TreatTransactionAndSendKCT from './TreatTransaction';

import { openUserModal } from '../actions/modalActions';

export default class TransactionDetails extends Component {
  state = {
    lodaing: false,
    transaction: {},
    meta: {},
  };

  componentWillMount() {
    if (this.props.order && this.props.order.order_id) {
      this.loadTransaction();
    }
  }

  loadTransaction = () => {
    this.setState({ loading: true });
    callApi(`/orders/${this.props.order.id}`)
      .then(({ data, meta = {} }) => {
        this.setState({ loading: false, transaction: data, meta });
      })
      .catch((err) => {
        this.setState({ loading: false });
        this.props.dispatch(showError(err));
      });
  };

  openUser = (id) => {
    this.props.dispatch(openUserModal(id));
  };

  refundPaystack = () => {
    // Root user type stuff
    const t = this.state.transaction;
    const allow = window.confirm(
      'REFUND TRANSACTION??? YOU BETTER BE SURE ABOUT THIS...'
    );

    if (!allow) return;

    this.setState({ loading: true });
    callApi(
      '/paystack/refund?id=' +
        this.props.order.order_id +
        '&disco=' +
        t.disco_code
    )
      .then((res) => {
        this.setState({ loading: false });
        this.props.dispatch(
          showInfo(
            res.message ||
              'Refund Processed. Check Paystack Dashboard for more info'
          )
        );
      })
      .catch((err) => {
        this.props.dispatch(showError(err));
        this.setState({ loading: false });
      });
  };

  render() {
    const { loading } = this.state;
    const { order } = this.props;

    return loading ? (
      <Loading />
    ) : (
      <Card>
        <h5 className="text-center">
          {order.order_id} <ClickToCopy value={order.order_id} />
        </h5>
        <Row>
          <Col md={6} xs={12}>
            <ul>
              <li>Time: {formatDate(order.created_at)} </li>
              <li>Name: {order.name} </li>
              <li>
                Phone:&nbsp;
                <span
                  className="cursor-pointer blue-text"
                  onClick={() => this.openUser(order.phone)}
                >
                  <strong>{order.phone}</strong>
                </span>
                &nbsp; <ClickToCopy value={order.phone} />
              </li>
              <li>Order Status: {_toDash(order.order_status)} </li>
            </ul>
          </Col>
          <Col md={6} xs={12}>
            <ul>
              <li>
                {`${
                  order.vertical_type === VERTICAL_TYPES.ELECTRICITY
                    ? 'Meter No: '
                    : order.vertical_type === VERTICAL_TYPES.TV
                    ? 'IUC Number: '
                    : 'Recipient: '
                } ${order.meter_no}`}{' '}
                <ClickToCopy value={order.meter_no} />
              </li>
              <li>Amount: {formatAmount(order.amount)} </li>
              <li>
                {`${
                  order.vertical_type === VERTICAL_TYPES.ELECTRICITY
                    ? 'Disco: '
                    : order.vertical_type === VERTICAL_TYPES.TV
                    ? 'Service Provider: '
                    : 'Network: '
                } ${order.disco_code}`}{' '}
              </li>
              {order.agent ? <li>Generated By: {order.agent.name}</li> : null}
              <li>
                Treated:{' '}
                <span onContextMenu={this.refundPaystack}>
                  {order.treated_on ? <SuccessTick /> : <ErrorTick />}
                </span>
              </li>
            </ul>
          </Col>
        </Row>
        <TreatTransactionAndSendKCT
          order={order}
          treated={order.treated_on ? true : false}
          done={this.loadTransaction}
          dispatch={this.props.dispatch}
          meta={this.state.meta}
        />
      </Card>
    );
  }
}
