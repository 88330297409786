export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';

export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';

const notifObj = {
  title: null,
  message: null,
  // Show notifications on top left for mobile and top right for desktop to avoid blocking mobile navbar
  position: 'tr',
  autoDismiss: 0,
  dismissible: 'both',
};

const successObj = {
  title: '✔',
  level: 'success',
};

const errObj = {
  title: 'Error!',
  level: 'error',
};

const showNotification = (message, type, dispatch) => {
  const uid = Date.now();

  // Remove Notification after timeout
  let timeout = setTimeout(() => {
    dispatch(removeNotification(uid));
  }, 10000);

  const typeObj = type === 'success' ? successObj : errObj;

  // Compose notification object from default + type + message
  const newObj = {
    ...notifObj,
    ...typeObj,
    onRemove: () => {
      clearTimeout(timeout);
      timeout = null;
      dispatch(removeNotification(uid));
    },
    type: ADD_NOTIFICATION,
    uid,
  };

  const final =
    typeof message === 'string'
      ? {
          ...newObj,
          message,
        }
      : {
          ...newObj,
          ...message,
        };

  return dispatch(final);
};

// Show success Notification
export const showInfo = (message = '👍🏾') => (dispatch) => {
  showNotification(message, 'success', dispatch);
};

// Show Error Notification
export const showError = (message = '❌') => (dispatch) => {
  showNotification(message, 'error', dispatch);
};

// Remove Notification
export const removeNotification = (uid) => ({
  type: REMOVE_NOTIFICATION,
  uid,
});

// Clear all Notifications
export const clearNotifications = () => ({
  type: CLEAR_NOTIFICATIONS,
});
