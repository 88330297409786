import React from 'react';
import { connect } from 'react-redux';

import { copyToClipboard } from '../utils';
import { showInfo } from '../actions/notificationActions';

const ClickToCopy = ({ value, children, dispatch }) =>
  value ? (
    children ? (
      children
    ) : (
      <i
        onClick={() => {
          copyToClipboard(value);
          dispatch(showInfo('Copied! ' + value));
        }}
        className="now-ui-icons files_single-copy-04 hover-blue"
      />
    )
  ) : null;

export default connect()(ClickToCopy);
