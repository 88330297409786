import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Button,
  Nav,
  NavLink,
  NavItem,
  Table,
} from 'reactstrap';
import { Route, Switch } from 'react-router-dom';
import classnames from 'classnames';

import { callApi, formatDate } from '../../utils';
import { showError } from '../../actions/notificationActions';

import { onOpenWatchlistModal } from '../../actions/modalActions';
import Loading from '.././Loading';
import Pagination from '.././Pagination';

import WatchlistModal from '../WatchlistModal';
import InfoTab from './InfoTab';

class Detail extends Component {
  state = {
    entity: {},
    meta: {},
    loading: true,
    activeTab: '1',
    toggle: null,
    rows: [],
    page: 1,
    pages: 1,
    limit: 30,
    total: '',
    query: '',
    watchlistUsers: [],
    currentWatchlist: [],
  };

  componentWillMount() {
    this.loadView();
    this.getUserWatchlists();
  }

  startLoad = () => this.setState({ loading: true });
  stopLoad = () => this.setState({ loading: false });

  scrollToTop = () =>
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);

  loadView = (e) => {
    const id = this.props.match.params.id;

    this.startLoad();
    const { query, page, limit } = this.state;
    //Get Current Watchlist
    callApi(`/watchlists/${id}q=${query}&page=${page}&limit=${limit}`)
      .then(({ data = [], meta = {} }) => {
        this.setState({ currentWatchlist: data });
        this.scrollToTop();
      })
      .catch((err) => {
        this.props.dispatch(showError(err));
      })
      .finally(() => {
        this.stopLoad();
      });
  };

  setPage = (page) => {
    if (page) {
      this.setState({ page }, () => {
        this.getUserWatchlists();
      });
    }
  };

  setInput = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  getUserWatchlists = (e) => {
    // Event may be passed in from search form
    if (e) e.preventDefault();
    const { query, page, limit } = this.state;
    callApi(
      `/watchlists/${this.props.match.params.id}/users?q=${query}&page=${page}&limit=${limit}`
    )
      .then(({ data = [], meta }) => {
        this.setState({
          watchlistUsers: data,
          pages: meta.pages,
          total: meta.total,
        });
      })
      .catch((err) => {
        console.log(err);
        // this.stopLoad();
        // this.props.dispatch(showError(err));
      });
  };

  render() {
    let { currentWatchlist } = this.state;
    const { history, match, location } = this.props;

    return (
      <div className="content">
        <WatchlistModal
          watchlist_id={currentWatchlist.id}
          refresh={() => this.getUserWatchlists()}
        />
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardHeader className="ml-2">
                <div className="d-flex justify-content-between align-contents-center">
                  <div>
                    <CardTitle>
                      {currentWatchlist.name || 'Loading...'}
                    </CardTitle>
                    <p className="category">Watchlist</p>
                  </div>
                </div>
              </CardHeader>

              <CardBody>
                <div>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        style={{ color: 'black' }}
                        href="#"
                        className={classnames({
                          active: location.pathname === `${match.url}`,
                        })}
                        onClick={() => {
                          history.push(`${match.url}`);
                        }}
                      >
                        General
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ color: 'black' }}
                        href="#"
                        className={classnames({
                          active: location.pathname === `${match.url}/users`,
                        })}
                        onClick={() => {
                          history.push(`${match.url}/users`);
                        }}
                      >
                        Users
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <br />
                  <div>
                    <Switch>
                      <Route exact path={`${match.path}`}>
                        <InfoTab
                          watchlist={currentWatchlist}
                          totalRecords={this.state.watchlistUsers.length}
                        />
                      </Route>
                      <Route exact path={`${match.path}/users`}>
                        <UsersTab
                          onNew={() =>
                            this.props.dispatch(onOpenWatchlistModal())
                          }
                          watchlistUsers={this.state.watchlistUsers}
                          onRemove={() => {
                            this.getUserWatchlists();
                          }}
                          setPage={(e) => this.setPage(e)}
                          pages={this.state.pages}
                          page={this.state.page}
                          total={this.state.total}
                        />
                      </Route>
                    </Switch>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

function UsersTab({ watchlistUsers, onNew, onRemove, pages, setPage, page }) {
  const [loading] = useState(false);

  let limit = 30;

  const removeFromWatchlist = (watchlistUser) => {
    const confirmed = window.confirm('Click "OK" if you are double sure.');

    if (confirmed) {
      // logic here
      callApi(
        `/watchlists/${watchlistUser.watchlist_id}/users/${watchlistUser.reference}`,
        { reference: watchlistUser.reference },
        'delete'
      )
        .then(() => {
          // Update user watchlist
          onRemove();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <Row>
      <Col>
        <Button
          size="sm"
          color={'primary'}
          className="ml-auto"
          onClick={() => {
            onNew();
          }}
        >
          Add Reference
        </Button>
        <Table responsive hover striped>
          <thead className="text-primary">
            <tr>
              <th>No.</th>
              <th>Reference</th>
              <th>Added By</th>
              <th>CreatedAt</th>
              <th>Actions</th>
            </tr>
          </thead>

          <tbody>
            {/* Debugging */}
            {watchlistUsers &&
              watchlistUsers.map((t, i) => {
                return (
                  <tr key={t.id}>
                    <td>{loading ? 'x' : i + 1 + (page - 1) * limit}</td>
                    <td>{t.reference}</td>
                    <td>{t.createdBy.name}</td>
                    <td>{formatDate(t.created_at)}</td>
                    <td>
                      <RemoveButton onClick={() => removeFromWatchlist(t)} />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
        <Pagination page={page} onChange={setPage} total={pages} />
        {loading && <Loading />}
      </Col>
    </Row>
  );
}

export default connect()(Detail);

const RemoveButton = ({ onClick }) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <Button
      size="sm"
      color="danger"
      onClick={(e) => {
        setIsLoading(true);
        onClick(e);
      }}
      disabled={isLoading}
    >
      {isLoading ? (
        <div>
          Removing...
          <span className="fas fa-spinner fa-spin ml-2"></span>
        </div>
      ) : (
        'Remove'
      )}
    </Button>
  );
};
