import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
} from 'reactstrap';
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  confirmAction,
  openCampaignModal,
  toggleModalLoading,
} from '../../actions/modalActions';
import { showError, showInfo } from '../../actions/notificationActions';

import ConfirmActionModal from '../ConfirmActionModal';
import InfoTab from './InfoTab';
import SupportMsgModal from '../SupportMsgModal';
import { callApi } from '../../utils';
import campaignService from '../../services/campaign.service';
import classnames from 'classnames';
import { connect } from 'react-redux';

class Detail extends Component {
  state = {
    campaign: null,
    meta: {},
    loading: true,
    broadcast: false,
    loadingModal: false,
    supportMsgLoading: false,
    activeTab: '1',
    toggle: null,
    rows: [],
    page: 1,
    pages: 1,
    limit: 10,
  };

  componentDidMount() {
    this.loadCampaign();
  }

  startLoad = () => this.setState({ loading: true });
  stopLoad = () => this.setState({ loading: false });

  scrollToTop = () =>
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);

  loadCampaign = () => {
    this.startLoad();
    campaignService
      .getSingleCampaign(this.props.match.params.id)
      .then((res) => {
        this.setState({
          loading: false,
          campaign: res.data,
        });
      })
      .catch((err) => {
        this.stopLoad();
        this.props.dispatch(showError(err.message));
      });
  };

  editCampaign = () => {
    this.props.dispatch(openCampaignModal(this.props.match.params.id));
  };

  deleteCampaign = () => {
    this.props.dispatch(
      confirmAction({
        title: 'Delete Campaign',
        confirmText: 'Are you sure you want to delete this campaign?',
        confirmCallback: () => {
          this.toggleModalLoading();
          campaignService
            .deleteCampaign(this.props.match.params.id)
            .then((res) => {
              this.toggleModalLoading();
              this.props.dispatch(
                showInfo('Campaign deleted successfully', 'Success')
              );
              this.props.history.push('/campaigns');
            })
            .catch((err) => {
              this.toggleModalLoading();
              this.props.dispatch(showError(err.message));
            });
        },
      })
    );
  };

  broadCastNotification = async () => {
    this.setState({ broadcast: true });
    try {
      const res = await callApi(
        `/campaigns/${this.props.match.params.id}/broadcast`,
        {},
        'post'
      );
      this.setState({ broadcast: false });
      this.props.dispatch(showInfo(res.message || 'Notification broadcasted'));
    } catch (error) {
      this.props.dispatch(showError(error));
      this.setState({ broadcast: false });
    }
  };

  setPage = (page) => {
    if (page) {
      this.setState({ page }, () => {
        this.loadView();
      });
    }
  };

  toggleModalLoading = () => {
    this.props.dispatch(toggleModalLoading(this.state.loadingModal));
  };

  setInput = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    let { campaign, loading, broadcast } = this.state;

    const { history, match, location } = this.props;

    if (!campaign) return <React.Fragment />;

    return (
      <div className="content">
        <ConfirmActionModal />
        <SupportMsgModal customer_id={this.props.match.params.id} />
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardHeader className="ml-2">
                <div className="d-flex justify-content-between align-contents-start">
                  <div>
                    <CardTitle>{campaign.title || 'Loading...'}</CardTitle>
                    <p className="category">Single Campaign</p>
                  </div>
                  <div>
                    <Button
                      // size="sm"
                      disabled={loading}
                      color={'success'}
                      onClick={this.editCampaign}
                    >
                      Edit
                    </Button>
                    <Button
                      // size="sm"
                      disabled={loading}
                      color={'danger'}
                      onClick={this.deleteCampaign}
                    >
                      Delete
                    </Button>
                    <Button
                      // size="sm"
                      disabled={loading}
                      color={'primary'}
                      onClick={this.broadCastNotification}
                    >
                      {broadcast ? 'Broadcasting...' : 'Broadcast Notification'}
                    </Button>
                  </div>
                </div>
              </CardHeader>

              <CardBody>
                <div>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        style={{ color: 'black' }}
                        href="#"
                        className={classnames({
                          active: location.pathname === `${match.url}`,
                        })}
                        onClick={() => {
                          history.push(`${match.url}`);
                        }}
                      >
                        General
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <br />
                  {campaign && (
                    <Switch>
                      <Route exact path={`${match.path}`}>
                        <InfoTab campaign={campaign} />
                      </Route>
                    </Switch>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect()(Detail);
