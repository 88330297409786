import React, { Component } from 'react';
import { connect } from 'react-redux';
import { callApi } from '../utils';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
  Button,
} from 'reactstrap';

import { closeAllModals } from '../actions/modalActions';

export default connect((state) => ({
  open: state.modals.createCustomer,
}))(
  class CreateCustomerModal extends Component {
    state = {
      loading: false,
      name: '',
      email: '',
      phone: '',
      password: '',
      confirmPassword: '',
      error: '',
    };

    setInput = (e) => {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    };

    close = () => {
      this.props.dispatch(closeAllModals());
    };

    submit = (e) => {
      e.preventDefault();

      if (
        !this.state.name ||
        !this.state.email ||
        !this.state.phone ||
        !this.state.password ||
        !this.state.confirmPassword
      )
        return this.setState({ error: 'Please fill in all fields!' });

      if (this.state.password !== this.state.confirmPassword)
        return this.setState({
          error: 'Password and Confirm Password do not match!',
        });

      this.setState({ error: '' });

      let bodyObj = {
        name: this.state.name,
        email: this.state.email,
        phone: this.state.phone,
        password: this.state.password,
      };
      console.log(bodyObj);

      this.setState({ loading: true });

      // Request to create a Customer
      callApi(`/customers`, bodyObj, 'post')
        .then((res) => {
          this.setState({ loading: false });
          console.log('Response ', res);

          alert(res.message);
          this.props.refresh();
          this.close();
        })
        .catch((err) => {
          this.setState({ loading: false });
          if ((err.status = 'unprocessable')) alert(err.message);
          console.log(err);
        });
    };

    render() {
      const {
        name,
        email,
        phone,
        password,
        confirmPassword,
        loading,
      } = this.state;

      const open = this.props.open;
      return (
        <div>
          <Modal size="lg" isOpen={open} toggle={this.close}>
            <ModalHeader toggle={this.close}>Create Customer</ModalHeader>
            <ModalBody>
              <form>
                <div>
                  <FormGroup>
                    <Label className="mt-3">Name:</Label>
                    <Input
                      name="name"
                      type="text"
                      onChange={this.setInput}
                      value={name}
                      disabled={loading}
                    />

                    <Label className="mt-3">Email:</Label>
                    <Input
                      name="email"
                      type="email"
                      onChange={this.setInput}
                      value={email}
                      disabled={loading}
                    />
                  </FormGroup>

                  <Label className="mt-3">Phone:</Label>
                  <Input
                    name="phone"
                    type="number"
                    onChange={this.setInput}
                    value={phone}
                    disabled={loading}
                  />

                  <Label className="mt-3">Password:</Label>
                  <Input
                    name="password"
                    type="password"
                    onChange={this.setInput}
                    value={password}
                    disabled={loading}
                  />

                  <Label className="mt-3">Confirm Password:</Label>
                  <Input
                    name="confirmPassword"
                    type="password"
                    onChange={this.setInput}
                    value={confirmPassword}
                    disabled={loading}
                  />
                </div>
                <div />
              </form>
              {this.state.error ? (
                <span style={{ color: 'red' }}>{this.state.error}</span>
              ) : (
                ''
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="success" disabled={loading} onClick={this.submit}>
                Create Account{' '}
                {loading ? (
                  <span className="fas fa-spinner fa-spin"></span>
                ) : (
                  ''
                )}
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      );
    }
  }
);
