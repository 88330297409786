import React, { useContext } from 'react';
import { useEffect, useState } from 'react';
import { Bar, Doughnut } from 'react-chartjs-2';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from 'reactstrap';
import moment from 'moment';

import { callApi } from '../../../utils';
import CardCategory from '../../CardElements/CardCategory';
import Stats from '../../Stats/Stats';

import JsonViewer from '../../JsonViewer';
import ResourceInfoModal from './ResourceInfoModal';
import { ServiceContext } from './';

const getTimeline = function (service) {
  return callApi(`/services/${service.id}/timeline`);
};

const getPerformance = function (service) {
  return callApi(`/services/${service.id}/performance`);
};

const getPerformanceChartConfig = (response) => {
  return {
    data: (canvas) => {
      var ctx = canvas.getContext('2d');
      var gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
      gradientStroke.addColorStop(0, '#80b6f4');
      gradientStroke.addColorStop(1, '#FFFFFF');

      var gradientFill = ctx.createLinearGradient(0, 170, 0, 50);
      gradientFill.addColorStop(0, 'rgba(128, 182, 244, 0)');
      gradientFill.addColorStop(1, 'rgba(249, 99, 59, 0.40)');

      return {
        labels: ['Successful', 'Failed', 'Pending'],
        datasets: [
          {
            fill: true,
            backgroundColor: ['#18ce0f', '#f96332', '#2CA8FF'],
            // borderWidth: 2,
            data: response &&
              response.data && [
                response.data.successful || 0,
                response.data.failed || 0,
                response.data.pending || 0,
              ],
          },
        ],
      };
    },
    options: {
      title: {
        display: false,
      },
      layout: {
        padding: {
          left: 20,
          right: 20,
          top: 0,
          bottom: 0,
        },
      },
      legend: {
        position: 'bottom',
        fillStyle: '#FFF',
        display: false,
      },
    },
  };
};

const getTimelineChartConfig = (timeline, labels) => {
  return {
    data: () => {
      return {
        labels,
        datasets: [
          {
            label: 'Successful',
            borderColor: '#18ce0f',
            pointBorderColor: '#FFF',
            pointBackgroundColor: '#18ce0f',
            pointBorderWidth: 2,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 1,
            pointRadius: 4,
            fill: true,
            backgroundColor: '#18ce0f',
            borderWidth: 2,
            data: timeline.successful,
          },
          {
            label: 'Pending',
            borderColor: '#2CA8FF',
            pointBorderColor: '#FFF',
            pointBackgroundColor: '#2CA8FF',
            pointBorderWidth: 2,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 1,
            pointRadius: 4,
            fill: true,
            backgroundColor: '#2CA8FF',
            borderWidth: 2,
            data: timeline.pending,
          },
          {
            label: 'Failed',
            borderColor: '#f96332',
            pointBorderColor: '#FFF',
            pointBackgroundColor: '#f96332',
            pointBorderWidth: 2,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 1,
            pointRadius: 4,
            fill: true,
            backgroundColor: '#f96332',
            borderWidth: 2,
            data: timeline.failed,
          },
        ],
      };
    },
    options: {
      maintainAspectRatio: false,
      tooltips: {
        backgroundColor: '#fff',
        titleFontColor: '#333',
        bodyFontColor: '#666',
        bodySpacing: 4,
        xPadding: 12,
        mode: 'nearest',
        intersect: 0,
        position: 'nearest',
      },
      legend: {
        position: 'top',
        // fillStyle: "#FFF",
        display: true,
      },
      scales: {
        yAxes: [
          {
            gridLines: {
              drawTicks: true,
              drawBorder: true,
              display: true,
              color: 'rgba(255,255,255,0.1)',
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              display: true,
              color: 'rgba(255,255,255,0.1)',
            },
          },
        ],
      },
    },
  };
};

function Insight({ onCreateOrder }) {
  const [resourceInfoOpen, setResourceInfoOpen] = useState(false);

  const { service } = useContext(ServiceContext);

  if (!service) {
    return <React.Fragment />;
  }

  const toggleResourceInfo = () => {
    setResourceInfoOpen(!resourceInfoOpen);
  };

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <Row>
            <Col>
              <JsonViewer src={service} />
            </Col>
            <Col>
              <Button onClick={() => toggleResourceInfo()}>
                Get Resource Info
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Row>
        <Col xs={12} md={8}>
          <Timeline service={service} />
        </Col>
        <Col xs={12} md={4}>
          <PerformanceChart service={service} />
        </Col>
      </Row>
      <ResourceInfoModal
        isOpen={resourceInfoOpen}
        service={service}
        toggle={() => toggleResourceInfo()}
        onCreateOrder={onCreateOrder}
      />
    </React.Fragment>
  );
}

const Timeline = React.memo((props) => {
  const { service } = props;

  const [config, setConfig] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(null);

  useEffect(() => {
    (async () => {
      const response = await getTimeline(service);

      const labels = [];

      const loader = (row) => {
        const m = moment(row.head);
        const label = m.format('hh:mm A');
        if (!labels.includes(label)) {
          labels.push(label);
        }

        return row.occurence;
      };

      const successful = response.data.success.map(loader);
      const failed = response.data.failed.map(loader);
      const pending = response.data.pending.map(loader);

      const config = getTimelineChartConfig(
        { successful, pending, failed },
        labels
      );

      setLastUpdated(new Date(response.meta.lastUpdated || Date.now()));
      setConfig(config);
    })();
  }, []);

  return (
    <Card className="card-chart">
      <CardHeader>
        <CardCategory>Timeline</CardCategory>
      </CardHeader>
      <CardBody>
        <Row style={{ height: '250px' }}>
          <Col key={Math.random()} xs={12} md={12}>
            {config ? <Bar {...config} /> : <div>Loading...</div>}
          </Col>
        </Row>
      </CardBody>
      <CardFooter>
        <Stats>
          {[
            {
              i: 'now-ui-icons arrows-1_refresh-69',
              t: moment(lastUpdated).fromNow(),
            },
          ]}
        </Stats>
      </CardFooter>
    </Card>
  );
});

const PerformanceChart = React.memo((props) => {
  const { service } = props;

  const [config, setConfig] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(null);

  useEffect(() => {
    (async () => {
      const response = await getPerformance(service);
      const config = getPerformanceChartConfig(response);

      setLastUpdated(Date.now());
      setConfig(config);
    })();
  }, [service]);

  return (
    <Card className="card-chart">
      <CardHeader>
        <CardCategory>Performance</CardCategory>
      </CardHeader>
      <CardBody>
        <Row style={{ height: '250px' }}>
          <Col key={Math.random()} xs={12} md={12}>
            {config ? <Doughnut {...config} /> : <div>Loading...</div>}
          </Col>
        </Row>
      </CardBody>
      <CardFooter>
        <Stats>
          {[
            {
              i: 'now-ui-icons arrows-1_refresh-69',
              t: moment(lastUpdated).fromNow(),
            },
          ]}
        </Stats>
      </CardFooter>
    </Card>
  );
});

export default React.memo(Insight);
