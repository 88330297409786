import axios from 'axios';
const environment = process.env.NODE_ENV || 'production';

const axiosInstance = axios.create({
  baseURL: environment === 'production' && '/api',
  timeout: 120000, // 1 Minute
  headers: {
    'Content-Type': 'application/json',
  },
});

export default axiosInstance;
