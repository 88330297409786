import React from 'react';
import { Route, Switch } from 'react-router-dom';

import List from './VirtualAccounts/List';
import Detail from './VirtualAccounts/Detail';

function Refunds() {
  return (
    <Switch>
      <Route exact path="/virtual-accounts" component={List} />
      <Route path="/virtual-accounts/:id" component={Detail} />
    </Switch>
  );
}

export default Refunds;
