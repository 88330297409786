import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  Button,
} from 'reactstrap';

import { closeAllModals } from '../../../actions/modalActions';
import { DISCOS, PAYMENT_METHODS, callApi } from '../../../utils';
import { showError, showInfo } from '../../../actions/notificationActions';

export default connect(({ modals }) => ({
  open: modals.createChannelOrder,
  data: modals.data || {},
}))(
  class OrderModal extends Component {
    state = this.getStateFromProps();

    getStateFromProps() {
      const {
        phone,
        amount,
        name,
        email,
        disco_code = 'ABUJA',
        reference,
        meter_no,
        payment_type,
      } = this.props.data;

      return {
        phone,
        amount,
        name,
        email,
        disco_code,
        reference,
        meter_no,
        payment_type,
        loading: false,
      };
    }

    setInput = (e) => {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    };

    componentDidUpdate(prevProps) {
      if (prevProps.open !== this.props.open) {
        this.setState(this.getStateFromProps());
      }
    }

    close = () => {
      this.props.dispatch(closeAllModals());
      this.props.onCreate();
    };

    submitRequest = (e) => {
      e.preventDefault();
      const {
        amount,
        phone,
        meter,
        name,
        email,
        disco_code,
        reference,
        meter_no,
        payment_type,
        loading,
      } = this.state;

      if (loading) return;

      const prompt = `Are you sure you want to create an order?`;

      if (!window.confirm(prompt)) return; // Prompt Agent to confirm Token Generation

      this.setState({ loading: true });

      // Custom API link can be passed to component instead of default vend API link (eg. MCASH)
      callApi(
        `/channels/${this.props.data.id}/transactions`,
        {
          amount,
          phone,
          meter,
          email,
          name,
          disco_code,
          reference,
          payment_type,
          meter_no,
        },
        'post'
      )
        .then((res) => {
          this.setState({ loading: false });
          this.close();
          this.props.dispatch(showInfo(res.message));
        })
        .catch((err) => {
          this.setState({ loading: false });
          this.props.dispatch(showError(err));
        });
    };

    render() {
      const {
        phone,
        name,
        email,
        amount,
        disco_code,
        reference,
        meter_no,
        payment_type,
        loading,
      } = this.state;

      return (
        <Modal
          size="lg"
          isOpen={this.props.open}
          toggle={this.close}
          backdrop="static"
        >
          <ModalHeader toggle={this.close}>Create Order</ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <FormGroup>
                  <Label>Order Reference</Label>
                  <Input
                    onChange={this.setInput}
                    value={reference}
                    name="reference"
                    placeholder="Order Reference"
                    type="text"
                    required
                  />
                  <small className="form-text text-muted">
                    This is a reference from the integrators database.
                  </small>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label>Facility Reference</Label>
                  <Input
                    onChange={this.setInput}
                    value={meter_no}
                    name="meter_no"
                    placeholder="Order Reference"
                    type="text"
                    required
                  />
                  <small className="form-text text-muted">
                    Eg. Meter Number
                  </small>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>Service Provider</Label>
                  <Input
                    onChange={this.setInput}
                    defaultValue={disco_code}
                    name="disco_code"
                    type="select"
                    required
                  >
                    <option
                      disabled={true}
                      value=""
                    >{`Select a Service Provider`}</option>
                    {DISCOS.map((s) => (
                      <option key={s.code} value={s.name}>
                        {s.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label>Amount</Label>
                  <Input
                    onChange={this.setInput}
                    value={amount}
                    name="amount"
                    placeholder="Amount"
                    type="number"
                    required
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>Payment Method</Label>
                  <Input
                    onChange={this.setInput}
                    defaultValue={payment_type}
                    name="payment_type"
                    type="select"
                    required
                  >
                    <option disabled={true} value="">
                      Please Select
                    </option>
                    {PAYMENT_METHODS.map((s) => (
                      <option key={s.value} value={s.value}>
                        {s.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <FormGroup>
                  <Label>Phone Number</Label>
                  <Input
                    onChange={this.setInput}
                    value={phone}
                    name="phone"
                    placeholder="Customer's Phone Number"
                    type="text"
                    required
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup>
                  <Label>Email Address</Label>
                  <Input
                    onChange={this.setInput}
                    value={email}
                    name="email"
                    placeholder="Customer's Email Address"
                    type="text"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <FormGroup>
                  <Label>Customer Name</Label>
                  <Input
                    onChange={this.setInput}
                    value={name}
                    name="name"
                    placeholder="Customer's Name"
                    type="text"
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              type="submit"
              onClick={this.submitRequest}
              disabled={loading}
            >
              {loading ? 'Submitting...' : 'Submit'}
            </Button>
          </ModalFooter>
        </Modal>
      );
    }
  }
);
