import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
} from 'reactstrap';
import React, { Component } from 'react';

import ConfirmActionModal from '../ConfirmActionModal';
import { GeneralTab } from './GeneralTab';
import Loading from '../Loading';
import MerchantCommission from './MerchantCommission';
import MerchantOrders from './MerchantOrders';
import MerchantWallet from './MerchantWallet';
import { callApi } from '../../utils';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { openUserModal } from '../../actions/modalActions';
import { showError } from '../../actions/notificationActions';

class Detail extends Component {
  state = {
    merchant: {},
    meta: {},
    vendResponse: {},
    paymentResponse: {},
    loading: true,
    currentTabIndex: 1,
    toggle: null,
    transactions: [],
    page: 1,
    limit: 10,
  };

  componentWillMount() {
    this.loadView();
  }

  startLoad = () => this.setState({ loading: true });
  stopLoad = () => this.setState({ loading: false });

  scrollToTop = () =>
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);

  loadView = (e) => {
    const id = this.props.match.params.id;

    this.startLoad();
    callApi(`/merchants/${id}`)
      .then(({ data = {}, meta = {} }) => {
        this.setState({ merchant: data, meta });
        this.scrollToTop();
      })
      .catch((err) => {
        this.props.dispatch(showError(err));
      })
      .finally(() => {
        this.stopLoad();
      });
  };

  setTabIndex = (index) => {
    this.setState({ currentTabIndex: index });
  };

  setPage = (page) => {
    if (page) {
      this.setState({ page }, () => {
        this.loadView();
      });
    }
  };

  opentUser = (id) => {
    this.props.dispatch(openUserModal(id));
  };

  render() {
    let { merchant, loading, currentTabIndex } = this.state;

    return (
      <div className="content" style={{ padding: 0 }}>
        <ConfirmActionModal />
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardHeader className="ml-2">
                <div className="d-flex justify-content-between align-contents-center">
                  <div>
                    <CardTitle>{merchant.name || 'Loading...'}</CardTitle>
                    <p className="category">{merchant.id}</p>
                  </div>
                </div>
              </CardHeader>

              <CardBody>
                <div>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        style={{ color: 'black' }}
                        href="#"
                        className={classnames({
                          active: currentTabIndex === 1,
                        })}
                        onClick={() => {
                          this.setTabIndex(1);
                        }}
                      >
                        Details
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ color: 'black' }}
                        href="#"
                        className={classnames({
                          active: currentTabIndex === 2,
                        })}
                        onClick={() => {
                          this.setTabIndex(2);
                        }}
                      >
                        Wallet
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ color: 'black' }}
                        href="#"
                        className={classnames({
                          active: currentTabIndex === 3,
                        })}
                        onClick={() => {
                          this.setTabIndex(3);
                        }}
                      >
                        Orders
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ color: 'black' }}
                        href="#"
                        className={classnames({
                          active: currentTabIndex === 4,
                        })}
                        onClick={() => {
                          this.setTabIndex(4);
                        }}
                      >
                        Commissions
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <br />
                  <div>
                    {loading ? (
                      <Loading />
                    ) : (
                      <React.Fragment>
                        {currentTabIndex === 1 && (
                          <GeneralTab merchant={merchant} />
                        )}
                        {currentTabIndex === 2 && (
                          <MerchantWallet
                            merchant={merchant}
                            id={merchant.id}
                          />
                        )}
                        {currentTabIndex === 3 && (
                          <MerchantOrders
                            merchant={merchant}
                            id={merchant.id}
                          />
                        )}
                        {currentTabIndex === 4 && (
                          <MerchantCommission merchant={merchant} />
                        )}
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect()(Detail);
