import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Button,
} from 'reactstrap';

import { closeAllModals } from '../actions/modalActions';

export default connect((state) => ({
  open: state.modals.geoCodeAddress,
  data: state.modals.data,
}))(
  class GeoAddressModal extends Component {
    state = {
      loading: false,
    };

    close = () => {
      this.props.dispatch(closeAllModals());
    };

    update = () => {
      try {
        this.setState({ loading: true });

        this.props.updateFacility(
          this.props.data.formatted_address,
          this.closeLoading
        );
      } catch (error) {}
    };

    closeLoading = () => {
      this.setState({ loading: false });
    };

    render() {
      const { loading } = this.state;

      const open = this.props.open;
      const data = this.props.data;
      return (
        <div>
          <Modal size="lg" isOpen={open} toggle={this.close}>
            <ModalHeader toggle={this.close}>Customer Address</ModalHeader>
            <ModalBody>
              <form>
                <div>
                  <Label className="mt-3">Address:</Label>
                  <div>{data && data.formatted_address}</div>
                </div>
                <div />
              </form>
            </ModalBody>
            <ModalFooter>
              <Button color="success" disabled={loading} onClick={this.update}>
                Update Meter Address
                {loading ? (
                  <span className="fas fa-spinner fa-spin ml-3"></span>
                ) : (
                  ''
                )}
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      );
    }
  }
);
