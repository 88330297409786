import React, { Component } from 'react';

import { connect } from 'react-redux';
import { Input, Button, Card, CardHeader, CardBody } from 'reactstrap';
import Loading from './Loading';
import JsonViewer from './JsonViewer';

import '../assets/css/slider.css';

import { callApi, isSuperAdmin } from '../utils';
import { showError, showInfo } from '../actions/notificationActions';

const marginDiv = {
  marginTop: '40px',
};

const ToggleConfig = (props) => (
  <label className="switch">
    <input
      type="checkbox"
      checked={props.value}
      onChange={(e) => {
        props.change(props.name, !props.value);
      }}
    />
    <span className="slider round" />
  </label>
);

const InputConfig = (props) => (
  <Input
    name={props.name}
    placeholder={props.name}
    type="textarea"
    value={props.value}
    onChange={(e) => {
      props.change(props.name, e.target.value);
    }}
    style={{ maxWidth: '500px' }}
  />
);

const JsonConfig = (props) => (
  <JsonViewer
    src={props.value}
    name={props.name}
    displayDataTypes={false}
    displayObjectSize={false}
    shouldCollapse={({ type }) => type === 'array'} // collapse root array but not internal objects
    enableClipboard={false}
    iconStyle={'circle'}
    onEdit={({ updated_src }) => props.change(props.name, updated_src)}
    onAdd={({ updated_src }) => props.change(props.name, updated_src)}
    onDelete={({ updated_src }) => props.change(props.name, updated_src)}
  />
);

const ConfigItem = (props) => (
  <div style={{ marginTop: 25 }}>
    <h6>{props.name}</h6>
    {(function (val) {
      switch (val) {
        case 'boolean':
          return <ToggleConfig {...props} />;

        case 'string':
          return <InputConfig {...props} />;

        case 'object':
          return <JsonConfig {...props} />;

        default:
          return <InputConfig {...props} />;
      }
    })(typeof props.value)}
    <hr />
  </div>
);

class Config extends Component {
  state = {
    config: [],
    changed: [],
    loading: false,
    _isSuperAdmin: false,
  };

  changeConfig = (key, value) => {
    this.setState((state) => {
      const changed = state.changed
        .filter((i) => i.key !== key)
        .concat({ key, value });

      const config = state.config.map((c, i) =>
        c.key === key ? { key, value } : c
      );
      return { config, changed };
    });
  };

  componentDidMount() {
    this.loadState();
    this.getConfig();
  }

  saveConfig(e) {
    e.preventDefault();

    this.setState({ loading: true });

    const { changed } = this.state;

    callApi(
      '/config',
      {
        data: changed,
      },
      'post'
    )
      .then((res) => {
        this.setState({ loading: false, changed: [] });
        this.props.dispatch(showInfo(res.message));
        this.loadState();
      })
      .catch((err) => {
        this.setState({ loading: false });
        this.props.dispatch(showError(err));
      });
  }

  loadState() {
    this.setState({ loading: true });
    callApi('/config')
      .then((res) => {
        this.setState({ loading: false, config: res.data });
      })
      .catch((err) => {
        this.setState({ loading: false });
        this.props.dispatch(showError(err));
      });
  }

  formatConfigForSupport() {
    const { config } = this.state;
    return config.filter((c) => {
      if (
        typeof c.value === 'boolean' &&
        !c.key.startsWith('TEST:') &&
        ![
          'METER_CACHE_LOOKUP',
          'REDIRECT_RAVE',
          'VEND_QUEUE',
          'REDIRECT_PAYSTACK',
          'undefined',
        ].includes(c.key)
      )
        return true;
      return false;
    });
  }

  getConfig = async () => {
    const superAdmin = await isSuperAdmin();
    this.setState({
      _isSuperAdmin: superAdmin,
    });
  };

  render() {
    const { config, loading, _isSuperAdmin } = this.state;

    const finalConfig = _isSuperAdmin ? config : this.formatConfigForSupport();

    return (
      <div className="content">
        <div>
          <Card>
            <CardHeader className="ml-2" />
            <CardBody style={{ minHeight: '60vh' }}>
              <form onSubmit={this.saveConfig.bind(this)}>
                {finalConfig.map((item) => {
                  return (
                    <ConfigItem
                      {...item}
                      name={item.key}
                      change={this.changeConfig}
                    />
                  );
                })}
                {loading && (
                  <div className="justify-content-center">
                    <Loading />
                  </div>
                )}
                <div style={{ marginDiv, textAlign: 'center' }}>
                  <Button size="lg" color="primary">
                    {loading ? 'PLEASE WAIT...' : 'SAVE'}
                  </Button>
                </div>
              </form>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}

export default connect()(Config);
