import React from 'react';
import { Route, Switch } from 'react-router-dom';

import List from './VendRequests/List';
import Detail from './VendRequests/Detail';

function AllVendRequests() {
  return (
    <Switch>
      <Route exact path="/requests" component={List} />
      <Route path="/requests/:id" component={Detail} />
    </Switch>
  );
}

export default AllVendRequests;
