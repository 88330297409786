import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  Modal,
  ModalHeader,
  ModalBody,
  // ModalFooter,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  Button,
} from 'reactstrap';

import Loading from './Loading';

import { closeAllModals } from '../actions/modalActions';
import { DISCOS, callApi } from '../utils';
import { showError } from '../actions/notificationActions';

const initState = {
  phone: '',
  meter: '',
  disco: '',
  loading: false,
  data: null,
  isMerchant: false,
};

export default connect(({ modals }) => ({ open: modals.genAccount }))(
  class GenAccountModal extends Component {
    state = initState;

    setInput = (e) => {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    };

    close = () => {
      this.props.dispatch(closeAllModals());
      this.setState(initState);
    };

    submitRequest = (e) => {
      e.preventDefault();
      this.setState({ loading: true });
      const { disco, phone, meter, isMerchant } = this.state;
      callApi('/monnify/generate', { disco, phone, meter, isMerchant }, 'POST')
        .then((account) => this.setState({ loading: false, data: account }))
        .catch((e) => {
          this.setState({ loading: false });
          this.props.dispatch(showError(e.message));
        });
    };

    toggleMerchant = () => {
      this.setState(({ isMerchant }) => ({ isMerchant: !isMerchant }));
    };

    render() {
      const { phone, meter, disco, loading, data, isMerchant } = this.state;

      return (
        <div>
          <Modal size="lg" isOpen={this.props.open} toggle={this.close}>
            <ModalHeader toggle={this.close}>Generate Account</ModalHeader>
            <ModalBody>
              <form onSubmit={this.submitRequest}>
                <Row>
                  <Col xs={12} md={12}>
                    <FormGroup>
                      <Label>Phone</Label>
                      <Input
                        onChange={this.setInput}
                        value={phone}
                        name="phone"
                        placeholder="User's Phone Number"
                        type="text"
                        required
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label>Meter No.</Label>
                      <Input
                        onChange={this.setInput}
                        value={meter}
                        name="meter"
                        placeholder="Enter Meter Number"
                        type="text"
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label>DisCo</Label>
                      <Input
                        onChange={this.setInput}
                        value={disco}
                        name="disco"
                        type="select"
                        required
                      >
                        <option value="">Select a DisCo</option>
                        {DISCOS.map((s) => (
                          <option key={s.code} value={s.name}>
                            {s.name}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={isMerchant}
                          onChange={this.toggleMerchant}
                        />
                        <span className="slider round" />
                      </label>
                      <br />
                      Merchant Account?
                    </FormGroup>
                  </Col>
                </Row>
                <hr />
                {data ? (
                  <div>
                    <h5>Bank: {data.bank}</h5>
                    <h5>Account Number: {data.acc_num}</h5>
                    <h5>Account Name: {data.acc_name}</h5>
                    <hr />
                  </div>
                ) : null}
                {loading && (
                  <Row className="justify-content-center">
                    <Loading />
                  </Row>
                )}

                <Row className="justify-content-center">
                  <Button size="lg" color="secondary" type="submit">
                    {loading ? 'PLEASE WAIT...' : 'GENERATE'}
                  </Button>
                </Row>
              </form>
            </ModalBody>
          </Modal>
        </div>
      );
    }
  }
);
