import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Row,
  Table,
} from 'reactstrap';
import React, { Component } from 'react';

import CreateSurveyModal from '../CreateSurveyModal';
import Loading from '../Loading';
import Pagination from '../Pagination';
import SearchForm from '../SearchForm';
import { connect } from 'react-redux';
import { formatDate } from '../../utils';
import { openCreateSurveyModal } from '../../actions/modalActions';
import { showError } from '../../actions/notificationActions';
import surveyService from '../../services/survey.service';

class Surveys extends Component {
  state = {
    surveys: [],
    status: 'all',
    channel: '',
    query: '',
    page: 1,
    limit: 30,
    loading: false,
    queryStatus: ['draft', 'published', 'archived'],
    queryChannels: ['web', 'mobile'],
  };

  componentWillMount() {
    this.loadCampaigns();
  }

  startLoad = () => this.setState({ loading: true });
  stopLoad = () => this.setState({ loading: false });

  scrollToTop = () =>
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);

  loadCampaigns = (e, statusFilter) => {
    // Event may be passed in from search form
    if (e) e.preventDefault();

    const { status, page, limit, query } = this.state;
    this.startLoad();
    surveyService
      .getSurveys({
        status: statusFilter || status,
        page,
        limit,
        query,
      })
      .then((res) => {
        this.setState({ loading: false, surveys: res.data });
        this.scrollToTop();
      })
      .catch((err) => {
        this.stopLoad();
        this.props.dispatch(showError(err.message));
      });
  };

  setPage = (page) => {
    if (page) {
      this.setState({ page }, () => {
        this.loadCampaigns();
      });
    }
  };

  setQuery = (e) => {
    this.setState({ query: e.target.value });
  };

  filterByStatus = (e) => {
    this.loadCampaigns(null, e.target.value !== 'all' ? e.target.value : '');
  };

  openCampaign = (campaign) => {
    // this.props.dispatch(openCampaignModal(id));
    this.props.history.push(`/surveys/${campaign.id}`);
    // this.props.dispatch(setCustomerDetails(campaign));
  };

  refresh = () => {
    window.location.reload();
  };

  render() {
    const { query, surveys, page, loading, limit } = this.state;
    return (
      <div className="content">
        <CreateSurveyModal refresh={this.refresh} />
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardHeader className="ml-2">
                <div className="d-flex justify-content-between align-contents-center">
                  <div>
                    <CardTitle>SURVEYS</CardTitle>
                    <p className="category">All SURVEYS - Page {page}</p>
                  </div>
                  <div>
                    <Button
                      // size="sm"
                      // disabled={}
                      color={'primary'}
                      onClick={() => {
                        this.props.dispatch(openCreateSurveyModal());
                      }}
                    >
                      Create Survey
                    </Button>
                  </div>
                </div>
              </CardHeader>

              <CardHeader className="ml-2">
                <div className="d-flex justify-content-between align-contents-center">
                  <div>
                    <SearchForm
                      value={query}
                      onChange={this.setQuery}
                      onSearch={this.loadCampaigns}
                      loading={loading}
                    />
                  </div>

                  <div>
                    <FormGroup>
                      <Input
                        id="status"
                        name="status"
                        type="select"
                        onChange={this.filterByStatus}
                      >
                        <option value={'all'}>Get All</option>
                        {this.state.queryStatus.map((status, index) => (
                          <option
                            key={index}
                            value={status.toLocaleLowerCase()}
                          >
                            {status.toUpperCase()}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </div>
                </div>
              </CardHeader>

              <CardBody>
                <Table responsive hover striped>
                  <thead className="text-primary">
                    <tr>
                      <th>No.</th>
                      <th>Title</th>
                      <th>Status</th>
                      <th>CreatedAt</th>
                    </tr>
                  </thead>

                  <tbody>
                    {surveys.map((survey, i) => (
                      <tr
                        onClick={() => this.openCampaign(survey)}
                        key={survey.id}
                      >
                        <td>{loading ? 'x' : i + 1 + (page - 1) * limit}</td>
                        <td>{survey.title}</td>
                        <td>{survey.status}</td>
                        <td>{formatDate(survey.created_at)}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Pagination page={page} onChange={this.setPage} />
                {loading && <Loading />}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect()(Surveys);
