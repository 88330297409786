import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button, Table } from 'reactstrap';

import { callApi } from '../../utils';
import { showError, showInfo } from '../../actions/notificationActions';
import Pagination from '../Pagination';

import Loading from '../Loading';

class UserCards extends React.Component {
  state = {
    queryParams: {
      from: '',
      to: '',
      disco: '',
      page: 1,
      limit: '50',
      subAccount: '',
      amount: '',
      generatedBy: '1',
      operation: 'credit',
    },
    pages: 1,
    meta: { pages: 1 },
    loading: false,
    rows: [],
    users: [],
  };

  setInput = (e) => {
    const { name, value } = e.target;
    this.setState({
      queryParams: { ...this.state.queryParams, [name]: value },
    });
    // setQueryParams({[name]: value})
  };

  setPage = (page) => {
    if (page) {
      this.setState(
        { queryParams: { ...this.state.queryParams, page } },
        () => {
          this.loadTimeline();
        }
      );
    }
  };

  startLoad = () => this.setState({ loading: true });
  stopLoad = () => this.setState({ loading: false });

  loadTimeline = () => {
    const {
      from,
      to,
      operation,
      amount,
      page,
      generatedBy,
      limit,
    } = this.state.queryParams;
    const id = this.props.id;
    this.setState({ loading: true });
    callApi(`/agentsupervisors/timeline`, {
      id,
      from,
      to,
      operation,
      amount,
      page,
      generatedBy,
      limit,
    })
      .then(({ data = [], pages }) => {
        this.setState({ loading: false });
        this.setState({ rows: data, meta: { pages } });
      })
      .catch((err) => {
        this.setState({ loading: false });
        this.props.dispatch(showError(err));
      });
  };

  loadUser = () => {
    this.startLoad();
    callApi('/user?id=' + this.props.user.id)
      .then(({ user = {}, cards = [] }) => {
        this.setState({ loading: false });
        this.setState({ entity: user, rows: [...cards] });
      })
      .catch((err) => {
        this.stopLoad();
        this.props.dispatch(showError(err));
      });
  };

  componentWillMount() {
    this.loadUser();
  }

  deactivateCard = (card) => {
    if (!window.confirm("Deactivate user's card?")) return;
    this.setState({ loading: true });
    callApi('/card/deactivate', { signature: card.signature }, 'post')
      .then((res) => {
        this.setState({ loading: false });
        this.props.dispatch(showInfo(res.message));
        this.loadUser();
      })
      .catch((err) => {
        this.setState({ loading: false });
        this.props.dispatch(showError(err));
      });
  };

  render() {
    const { rows, queryParams, loading, meta } = this.state;

    return (
      <Row>
        <Col md="12" xs={12}>
          <div className="mt-10">
            {loading ? (
              <Loading />
            ) : (
              <Table responsive hover striped>
                <thead className="text-primary">
                  <tr>
                    <th>S/N</th>
                    <th>Type</th>
                    <th>Last</th>
                    <th>Disco</th>
                    <th>Authorized</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {rows.map((t, i) => {
                    return (
                      <tr key={t.id}>
                        <td>
                          {(queryParams.page - 1) * queryParams.limit + (i + 1)}
                        </td>
                        <td>
                          <img
                            src={`/images/cards/${t.type}.png`}
                            alt={t.type}
                          />
                        </td>
                        <td>
                          ****<b>{t.last_digits}</b>
                        </td>
                        <td>{t.disco_code}</td>
                        <td>{Boolean(t.authorized).toString()}</td>
                        <td>
                          <Button
                            onClick={() => this.deactivateCard(t)}
                            color="danger"
                          >
                            Remove
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            )}
            <Pagination
              total={meta.pages}
              page={queryParams.page}
              onChange={this.setPage}
            />
          </div>
          {/* </Card> */}
        </Col>
      </Row>
    );
  }
}

export default connect()(UserCards);
