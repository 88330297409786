import React, { Component } from 'react';
import { connect } from 'react-redux';
import ConfirmActionModal from '../ConfirmActionModal';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Button,
  Nav,
  NavLink,
  NavItem,
} from 'reactstrap';
import classnames from 'classnames';

import { callApi } from '../../utils';
import { showError, showInfo } from '../../actions/notificationActions';

import { openUserModal, openSupervisorModal } from '../../actions/modalActions';

import { Route, Switch } from 'react-router-dom';
import InfoTab from './InfoTab';
import SupervisorTransactions from './SupervisorTransactions';

class Detail extends Component {
  state = {
    supervisor: {},
    meta: {},

    loading: true,
    activeTab: '1',
    toggle: null,
    rows: [],
    page: 1,
    pages: 1,
    limit: 10,
  };

  componentWillMount() {
    this.loadView();
  }

  startLoad = () => this.setState({ loading: true });
  stopLoad = () => this.setState({ loading: false });

  scrollToTop = () =>
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);

  loadView = (e) => {
    const id = this.props.match.params.id;

    this.startLoad();
    callApi(`/agent-supervisors/${id}/wallet`)
      .then(({ data = {}, meta = {} }) => {
        this.setState({ supervisor: data });
        this.scrollToTop();
      })
      .catch((err) => {
        this.props.dispatch(showError(err));
      })
      .finally(() => {
        this.stopLoad();
      });
  };

  getSettlements = (params) => {
    const id = this.props.match.params.id;

    this.startLoad();
    callApi(`/settlement/${id}`, params)
      .then(({ data = {}, meta = {} }) => {
        this.setState({ rows: data, meta });
        this.scrollToTop();
      })
      .catch((err) => {
        this.props.dispatch(showError(err));
      })
      .finally(() => {
        this.stopLoad();
      });
  };

  setPage = (page) => {
    if (page) {
      this.setState({ page }, () => {
        this.loadView();
      });
    }
  };

  blockSupervisor = (id, newStatus, e) => {
    e.stopPropagation();
    if (
      !window.confirm(`${Number(newStatus) ? 'Block' : 'Unblock'} supervisor?`)
    )
      return;
    this.setState({ loading: true });
    callApi('/agent-supervisors/status', { id, status: newStatus }, 'post')
      .then((res) => {
        // this.setState({ loading: false });
        this.props.dispatch(showInfo(res.message));
        this.loadView();
      })
      .catch((err) => {
        this.setState({ loading: false });
        this.props.dispatch(showError(err));
      });
  };

  opentUser = (id) => {
    this.props.dispatch(openUserModal(id));
  };

  openWallet = (data) => {
    this.props.dispatch(openSupervisorModal(data));
  };

  render() {
    let { supervisor, loading } = this.state;

    // const toggle = (tab) => {
    //   this.setState({ activeTab: tab });
    // };

    const { history, match, location } = this.props;

    const id = match.params.id;

    return (
      <div className="content">
        <ConfirmActionModal />
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardHeader className="ml-2">
                <div className="d-flex justify-content-between align-contents-center">
                  <div>
                    <CardTitle>{supervisor.name || 'Loading...'}</CardTitle>
                    <p className="category">Single Supervisor</p>
                  </div>
                  <div>
                    <Button
                      color="primary"
                      onClick={() => this.openWallet(supervisor)}
                    >
                      Fund Wallet
                    </Button>
                    <div
                      style={{ width: '10px', display: 'inline-block' }}
                    ></div>
                    <Button
                      // size="sm"
                      disabled={loading}
                      color={`${
                        Number(supervisor.status) ? 'danger' : 'success'
                      }`}
                      onClick={(e) =>
                        this.blockSupervisor(
                          supervisor.id,
                          Number(supervisor.status) ? '0' : '1',
                          e
                        )
                      }
                    >
                      {loading
                        ? 'Loading...'
                        : Number(supervisor.status)
                        ? 'Block'
                        : 'Unblock'}
                    </Button>
                  </div>
                </div>
              </CardHeader>

              <CardBody>
                <div>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        style={{ color: 'black' }}
                        href="#"
                        className={classnames({
                          active: location.pathname === `${match.url}`,
                        })}
                        onClick={() => {
                          history.push(`${match.url}`);
                        }}
                      >
                        General
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ color: 'black' }}
                        href="#"
                        className={classnames({
                          active: location.pathname === `${match.url}/timeline`,
                        })}
                        onClick={() => {
                          history.push(`${match.url}/timeline`);
                        }}
                      >
                        Transactions
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <br />
                  <div>
                    <Switch>
                      <Route exact path={`${match.path}`}>
                        <InfoTab supervisor={supervisor} />
                      </Route>
                      <Route path={`${match.path}/timeline`}>
                        <SupervisorTransactions id={id} />
                      </Route>
                    </Switch>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

// function GeneralTab({ supervisor }) {
//   return (
//     <Row>
//       <Col sm="6">
//         <Card className="card-chart">
//           <CardHeader>
//             <CardTitle className="mt-0">Gateway</CardTitle>
//           </CardHeader>
//           <CardBody>
//             <div className="d-flex mb-3 justify-content-between">
//               <div>
//                 <span className="text-secondary">Order ID</span>
//               </div>
//               <div> {supervisor.order_id} </div>
//             </div>
//             <div className="d-flex mb-3 justify-content-between">
//               <div>
//                 <span className="text-secondary">Name</span>
//               </div>
//               <div> {supervisor.name} </div>
//             </div>
//             <div className="d-flex mb-3 justify-content-between">
//               <div>
//                 <span className="text-secondary">Phone</span>
//               </div>
//               <span>
//                 <span className="cursor-pointer blue-text" onClick={() => {}}>
//                   <strong>{supervisor.phone}</strong>{' '}
//                 </span>
//                 <ClickToCopy value={supervisor.phone} />
//               </span>
//             </div>
//             <div className="d-flex mb-3 justify-content-between">
//               <div>
//                 <span className="text-secondary">DisCo</span>
//               </div>
//               <div> {supervisor.disco_code} </div>
//             </div>
//             <div className="d-flex mb-3 justify-content-between">
//               <div>
//                 <span className="text-secondary">Payment Method</span>
//               </div>
//               <div> {supervisor.payment_type} </div>
//             </div>
//             <div className="d-flex mb-3 justify-content-between">
//               <div>
//                 <span className="text-secondary">Amount</span>
//               </div>
//               {supervisor.amount && <div> {formatAmount(supervisor.amount)} </div>}
//             </div>
//           </CardBody>
//         </Card>
//       </Col>
//     </Row>
//   );
// }

export default connect()(Detail);
