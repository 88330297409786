import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Table,
} from 'reactstrap';

import { callApi } from '../../utils';
import { showError } from '../../actions/notificationActions';

import Loading from '.././Loading';
import Pagination from '.././Pagination';
import SearchForm from '.././SearchForm';

class VirtualAccountsList extends Component {
  state = {
    rows: [],
    query: '',
    page: 1,
    limit: 30,
    loading: false,
    pages: 1,
  };

  componentWillMount() {
    this.load();
  }

  startLoad = () => this.setState({ loading: true });
  stopLoad = () => this.setState({ loading: false });

  scrollToTop = () =>
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);

  load = (e) => {
    // Event may be passed in from search form
    if (e) e.preventDefault();

    const { query, page, limit } = this.state;

    this.startLoad();

    callApi(`/virtual-accounts?q=${query}&page=${page}&limit=${limit}`)
      .then(({ data = [], meta = {} }) => {
        this.setState({ loading: false, rows: data, pages: meta.pages });
        this.scrollToTop();
      })
      .catch((err) => {
        this.stopLoad();
        this.props.dispatch(showError(err));
      });
  };

  setPage = (page) => {
    if (page) {
      this.setState({ page }, () => {
        this.load();
      });
    }
  };

  setQuery = (e) => {
    this.setState({ query: e.target.value });
  };

  view = (id) => {
    this.props.history.push(`/virtual-accounts/${id}`);
  };

  render() {
    const { query, rows, page, loading, limit, pages } = this.state;
    return (
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardHeader className="ml-2">
                <CardTitle>Virtual Accounts</CardTitle>
                <p className="category">Virtual Accounts - Page {page}</p>
              </CardHeader>
              <SearchForm
                value={query}
                onChange={this.setQuery}
                onSearch={this.load}
                loading={loading}
              />
              <CardBody>
                <Table responsive hover striped>
                  <thead className="text-primary">
                    <tr>
                      <th>No.</th>
                      <th>Acc. Number</th>
                      <th>Acc Name</th>
                      <th>Acc. Type</th>
                      <th>Bank</th>
                      <th>Disco</th>
                      <th>Email</th>
                      <th>Active</th>
                    </tr>
                  </thead>

                  <tbody>
                    {rows.map((t, i) => (
                      <tr onClick={() => this.view(t.id)} key={t.id}>
                        <td>{loading ? 'x' : i + 1 + (page - 1) * limit}</td>
                        <td>{t.acc_num}</td>
                        <td>{t.acc_name}</td>
                        <td>{t.account_type}</td>
                        <td>{t.bank}</td>
                        <td>{t.disco}</td>
                        <td>{t.email}</td>
                        <td>{t.active ? 'YES' : 'NO'}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Pagination total={pages} page={page} onChange={this.setPage} />
                {loading && <Loading />}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect()(VirtualAccountsList);
