import { Col, Row, Table } from 'reactstrap';
import { callApi, formatAmount } from '../../../utils';

import EditCommissionModal from './EditCommisionModal';
import Loading from '../../Loading';
import React from 'react';
import { connect } from 'react-redux';
import { openEditCommissionModal } from '../../../actions/modalActions';
import { showError } from '../../../actions/notificationActions';

class Commission extends React.Component {
  state = {
    loading: true,
    rows: [],
  };

  componentWillMount() {
    this.loadView();
  }

  startLoad = () => this.setState({ loading: true });
  stopLoad = () => this.setState({ loading: false });

  scrollToTop = () =>
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);

  loadView = (e) => {
    e && e.preventDefault();

    const { channel } = this.props;

    this.startLoad();

    callApi(`/channels/${channel.id}/commissions`)
      .then(({ data = [] }) => {
        this.setState({ rows: data });
        this.scrollToTop();
      })
      .catch((err) => {
        this.props.dispatch(showError(err));
      })
      .finally(() => {
        this.stopLoad();
      });
  };

  editCommission = (commission) => {
    this.props.dispatch(
      openEditCommissionModal({
        ...commission,
        channelId: this.props.channel.id,
      })
    );
  };

  setQuery = (e) => {
    this.setState({ q: e.target.value });
  };

  setPage = (page) => {
    let { count, limit } = this.state;

    const canLoad = count % ((page - 1) * limit) < count;

    if (canLoad) {
      this.startLoad();
      this.setState({ page }, () => {
        this.loadView();
      });
    }
  };

  render() {
    const { loading, rows } = this.state;
    return (
      <div>
        <Row>
          <Col sm="12">
            {loading ? (
              <Loading />
            ) : (
              <Table responsive hover striped>
                <thead>
                  <tr>
                    <th>Service</th>
                    <th>Commission Earned</th>
                    <th>Transaction Cap</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {rows.map((r, i) => (
                    <tr key={r.key}>
                      <td>
                        <span style={{ fontWeight: 'bold' }}>{r.key}</span>
                      </td>
                      <td>
                        <span style={{ fontWeight: 'bold' }}>
                          {r.rate * 100}%
                        </span>
                      </td>
                      <td>
                        <span style={{ fontWeight: 'bold' }}>
                          {formatAmount(r.cap)}
                        </span>
                      </td>
                      <td>
                        <div
                          style={{
                            marginBlock: '2px',
                            background: '#e95e38',
                            display: 'inline-block',
                            color: '#fff',
                            borderRadius: '30px',
                            padding: '10px 15px',
                          }}
                          onClick={() => {
                            this.editCommission(r);
                          }}
                        >
                          edit
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </Col>
        </Row>
        <EditCommissionModal loadView={this.loadView} />
      </div>
    );
  }
}

export default connect()(Commission);
