import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Table,
  Button,
} from 'reactstrap';

import { callApi, formatDate } from '../utils';
import { showInfo, showError } from '../actions/notificationActions';

import SearchForm from './SearchForm';
import Loading from './Loading';
import Pagination from './Pagination';
import { openUserModal } from '../actions/modalActions';

class FailedLoginAttempts extends Component {
  state = {
    query: '',
    failedLogins: [],
    page: 1,
    limit: 15,
    loading: false,
  };

  componentWillMount() {
    this.loadFailedLoginAttempts();
  }

  startLoad = () => this.setState({ loading: true });
  stopLoad = () => this.setState({ loading: false });

  scrollToTop = () =>
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);

  loadFailedLoginAttempts = (e) => {
    // Event may be passed in from search form
    if (e) e.preventDefault();

    const { query, page, limit } = this.state;
    this.setState({ loading: true });
    callApi(`/failedlogins/list?q=${query}&page=${page}&limit=${limit}`)
      .then((res) => {
        this.setState({ loading: false, failedLogins: [] }, () =>
          this.setState({ failedLogins: res.failedLogins })
        );
        this.scrollToTop();
      })
      .catch((err) => {
        this.setState({ loading: false });
        this.props.dispatch(showError(err));
      });
  };

  setPage = (page) => {
    if (page) {
      this.setState({ page }, () => {
        this.loadFailedLoginAttempts();
      });
    }
  };

  setQuery = (e) => {
    this.setState({ query: e.target.value });
  };

  markCalled = (id, e) => {
    callApi(`/failedlogins/markcalled/${id}`, {}, 'post')
      .then((res) => {
        this.loadFailedLoginAttempts();
        this.props.dispatch(showInfo(res.message));
      })
      .catch((err) => {
        this.setState({ loading: false });
        this.props.dispatch(showError(err));
      });

    // if (e) e.stopPropagation();
  };

  openUser = (id) => {
    if (id) this.props.dispatch(openUserModal(id));
  };

  render() {
    const { query, page, loading, limit } = this.state;
    const failedLogins = this.state.failedLogins || [];
    return (
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardHeader className="ml-2">
                <CardTitle>Failed Login Attempts</CardTitle>
                <p className="category">Failed Login Attempts - Page {page}</p>
              </CardHeader>

              <SearchForm
                value={query}
                onChange={this.setQuery}
                onSearch={this.loadFailedLoginAttempts}
                loading={loading}
              />

              <CardBody>
                <Table responsive hover striped>
                  <thead className="text-primary">
                    <tr>
                      <th>No.</th>
                      <th>Date</th>
                      <th>Name</th>
                      <th>Phone</th>
                      <th>User ID</th>
                      <th>Attempts</th>
                      <th>Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {failedLogins.map((t, i) => (
                      <tr
                        onClick={() => this.openUser(t.userId)}
                        key={t.order_id}
                      >
                        <td>{loading ? 'x' : i + 1 + (page - 1) * limit}</td>
                        <td>{formatDate(t.created_at)}</td>
                        <td>{t.name}</td>
                        <td>{t.phone}</td>
                        <td>{t.userId}</td>
                        <td>{t.attempts}</td>
                        <td>
                          {!t.called ? (
                            <Button
                              size="sm"
                              color="danger"
                              onClick={(e) => this.markCalled(t._id, e)}
                            >
                              Mark Called
                            </Button>
                          ) : (
                            <span>Called</span>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Pagination page={page} onChange={this.setPage} />
                {loading && <Loading />}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect()(FailedLoginAttempts);
