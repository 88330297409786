import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button, Table } from 'reactstrap';

import { callApi } from '../../utils';
import { showError, showInfo } from '../../actions/notificationActions';
import Pagination from '../Pagination';

import Loading from '../Loading';

class UserVirtualAccounts extends React.Component {
  state = {
    queryParams: {
      page: 1,
      limit: '30',
    },
    pages: 1,
    meta: { pages: 1 },
    loading: false,
    rows: [],
    users: [],
    revokeLoading: false,
  };

  setInput = (e) => {
    const { name, value } = e.target;
    this.setState({
      queryParams: { ...this.state.queryParams, [name]: value },
    });
  };

  setPage = (page) => {
    if (page) {
      this.setState(
        { queryParams: { ...this.state.queryParams, page } },
        () => {
          this.loadGrants();
        }
      );
    }
  };

  startLoad = () => this.setState({ loading: true });
  stopLoad = () => this.setState({ loading: false });

  loadGrants = () => {
    const { page, limit } = this.state.queryParams;
    const id = this.props.user.id;
    this.setState({ loading: true });
    callApi(`/customers/${id}/facility-grants?limit=${limit}&page=${page}`)
      .then(({ data = [], meta = {} }) => {
        console.log('Data', data);
        this.setState({ loading: false });
        this.setState({ rows: data, meta });
      })
      .catch((err) => {
        this.setState({ loading: false });
        this.props.dispatch(showError(err));
      });
  };

  refreshTable = (data) => {
    this.setState({ rows: [...this.state.rows, data] });
  };

  componentWillMount() {
    this.loadGrants();
  }

  view = (id) => {
    // Implement a display modal
    // this.props.dispatch()
  };

  revoke = (user_id, facility_id) => {
    this.setState({ revokeLoading: true });

    callApi(
      `/customers/${user_id}/facility-grants/facility/${facility_id}`,
      {},
      'delete'
    )
      .then((response) => {
        this.setState({ revokeLoading: false });
        this.props.dispatch(showInfo(response.message));
        this.loadGrants();
      })
      .catch((err) => {
        this.setState({ revokeLoading: false });
        this.props.dispatch(showError(err));
      });
  };

  render() {
    const { rows, queryParams, loading, meta } = this.state;

    return (
      <div>
        {/* <CreateVirtualAcctModal refresh={this.refreshTable} /> */}
        <Row>
          <Col md="12" xs={12}>
            <div className="mt-10">
              {loading ? (
                <Loading />
              ) : (
                <div>
                  <Table responsive hover striped>
                    <thead className="text-primary">
                      <tr>
                        <th>No.</th>
                        {/* <th>Acc. Number</th> */}
                        <th>Meter</th>
                        <th>Acc Name</th>
                        <th>Asset Provider</th>
                        <th>Service Provider</th>
                        <th>Type</th>
                        <th>Status</th>
                        <th>Revoke</th>
                      </tr>
                    </thead>

                    <tbody>
                      {rows.map((t, i) => {
                        // const user = users.find((u) => u.id == t.generatedBy) || {};
                        return (
                          <tr onClick={() => this.view(t.id)} key={t.id}>
                            <td>
                              {loading
                                ? 'x'
                                : i +
                                  1 +
                                  (queryParams.page - 1) * queryParams.limit}
                            </td>
                            {/* <td>{t.acc_num}</td> */}
                            <td>{t.facility.reference}</td>
                            <td>{t.facility.owner}</td>
                            <td>{t.facility.asset_provider}</td>
                            <td>{t.facility.service_provider}</td>
                            <td>{t.facility.type}</td>
                            <td>{t.status}</td>
                            <td>
                              {' '}
                              <Button
                                size="sm"
                                disabled={
                                  t.status === 'APPROVED' ? false : true
                                }
                                color={'primary'}
                                onClick={() =>
                                  this.revoke(t.user_id, t.facility_id)
                                }
                              >
                                {'Revoke'}
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              )}
              <Pagination
                total={meta.pages || 1}
                page={queryParams.page}
                onChange={this.setPage}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect()(UserVirtualAccounts);
