import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Table,
  Button,
} from 'reactstrap';

import { callApi, formatDate, formatAmount } from '../utils';
import { showError } from '../actions/notificationActions';

import Loading from './Loading';
import SuccessTick from './SuccessTick';
import ErrorTick from './ErrorTick';
import Pagination from './Pagination';
import SearchForm from './SearchForm';
import { openGenerateModal, openQmcashModal } from '../actions/modalActions';

class QMcash extends Component {
  state = {
    transactions: [],
    query: '',
    page: 1,
    limit: 15,
    loading: false,
  };

  componentWillMount() {
    this.loadTransactions();
  }

  startLoad = () => this.setState({ loading: true });
  stopLoad = () => this.setState({ loading: false });

  scrollToTop = () =>
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);

  loadTransactions = (e) => {
    // Event may be passed in from search form
    if (e) e.preventDefault();

    const { query, page, limit } = this.state;
    this.startLoad();
    callApi(`/transactions/qmcash?q=${query}&page=${page}&limit=${limit}`)
      .then(({ transactions = [] }) => {
        this.setState({ loading: false, transactions });
        this.scrollToTop();
      })
      .catch((err) => {
        this.stopLoad();
        this.props.dispatch(showError(err));
      });
  };

  setPage = (page) => {
    if (page) {
      this.setState({ page }, () => {
        this.loadTransactions();
      });
    }
  };

  setQuery = (e) => {
    this.setState({ query: e.target.value });
  };

  openTransaction = (id) => {
    this.props.dispatch(openQmcashModal(id));
  };

  openGenModal = (t, e) => {
    this.props.dispatch(
      openGenerateModal({
        phone: t.phone,
        amount: Number(t.vend_amount || 0),
        disco: t.disco_code,
        meter: t.meter_no,
        mode: 'QMCASH',
        id: t.order_id,
        link: '/token/vendqmcash',
      })
    );
    if (e) e.stopPropagation();
  };

  render() {
    const { query, transactions, page, loading, limit } = this.state;
    return (
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardHeader className="ml-2">
                <CardTitle>QMCASH</CardTitle>
                <p className="category">QMCASH Transactions - Page {page}</p>
              </CardHeader>

              <SearchForm
                value={query}
                onChange={this.setQuery}
                onSearch={this.loadTransactions}
                loading={loading}
              />

              <CardBody>
                <Table responsive hover striped>
                  <thead className="text-primary">
                    <tr>
                      <th>No.</th>
                      <th>Time</th>
                      <th>Vend Response</th>
                      <th>Action</th>
                      <th>Completed</th>
                      <th>DisCo</th>
                      <th>Amount</th>
                      <th>Meter</th>
                      <th>Phone</th>
                      <th>Name</th>
                      <th>Attempts</th>
                      <th>Order ID</th>
                      <th>Ref ID</th>
                    </tr>
                  </thead>

                  <tbody>
                    {transactions.map((t, i) => (
                      <tr
                        onClick={() => this.openTransaction(t.order_id)}
                        key={t.id}
                      >
                        <td>{loading ? 'x' : i + 1 + (page - 1) * limit}</td>
                        <td>{formatDate(t.created_at)}</td>
                        <td>
                          {t.vend_response
                            ? t.vend_response.slice(0, 15) + ' ...'
                            : ''}
                        </td>
                        <td className="text-center">
                          {Number(t.completed) ? (
                            <span className="text-success">[Generated]</span>
                          ) : t.phone ? (
                            <Button
                              color="info"
                              onClick={(e) => this.openGenModal(t, e)}
                            >
                              Regenerate
                            </Button>
                          ) : (
                            <span className="text-danger">[Pending]</span>
                          )}
                        </td>
                        <td className="text-center">
                          {Number(t.completed) ? (
                            <SuccessTick />
                          ) : (
                            <ErrorTick />
                          )}
                        </td>
                        <td>{t.disco_code}</td>
                        <td>{t.amount ? formatAmount(t.amount) : ''}</td>
                        <td>{t.meter_no}</td>
                        <td>{t.phone}</td>
                        <td>{t.customer_name}</td>
                        <td>{t.attempts}</td>
                        <td>{t.order_id}</td>
                        <td>{t.ref_id}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Pagination page={page} onChange={this.setPage} />
                {loading && <Loading />}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect()(QMcash);
