import React, { Component } from 'react';
import { connect } from 'react-redux';

import ClickToCopy from './ClickToCopy';
import { Badge, Row, Col } from 'reactstrap';

import {
  WhitelistPaystack,
  // WhitelistRave,
  UpdateStatus,
} from './TransactionTimeline';

import { getDuration, formatDate, getDurationSeconds } from '../utils';
import { openUserModal } from '../actions/modalActions';

export default connect()(
  class BuyPowerTimeline extends Component {
    state = {};

    openUser = (id) => {
      this.props.dispatch(openUserModal(id));
    };

    render() {
      const { data = [], meta = {} } = this.props;

      return (
        <div>
          {data.map((d) => (
            <Row key={d._id} className="justify-content-center text-center">
              <Col sm={12}>
                <ul className="timeline-list border-bottom">
                  <li>Name: {d.name}</li>
                  <li>
                    Phone:&nbsp;
                    <span
                      className="cursor-pointer blue-text"
                      onClick={() => this.openUser(d.phone)}
                    >
                      <strong>{d.phone}</strong>
                    </span>
                    &nbsp; <ClickToCopy value={d.phone} />
                  </li>
                  <li>
                    Duration:{' '}
                    <strong className="mark">
                      {getDuration(d.created_at, d.updated_at)}
                    </strong>
                  </li>
                  <li>Time: {formatDate(d.created_at)}</li>
                  <li>
                    Device: <strong className="mark">{d.device}</strong>
                  </li>
                  <li>Refs: {d.refs.join(', ')}</li>
                </ul>
                <ul className="timeline-list">
                  {d.data.map((l) => (
                    <li key={l._id} className="border-bottom py-1">
                      <Badge
                        color={
                          l.success ? 'success' : l.error ? 'danger' : 'primary'
                        }
                      >
                        {l.success ? '✓✓✓' : l.error ? '<XX>' : '-'}
                      </Badge>{' '}
                      &rarr; {l.value}
                      &nbsp;&nbsp; | &nbsp;&nbsp;{' '}
                      <span className="blue-text">
                        {getDurationSeconds(l.time, d.created_at)}s
                      </span>
                    </li>
                  ))}
                </ul>
                {meta.customer &&
                meta.customer.risk_action &&
                meta.reference ? (
                  <div>
                    <WhitelistPaystack
                      id={meta.customer.customer_code}
                      reference={meta.reference}
                      whitelisted={meta.customer.risk_action === 'allow'}
                      dispatch={this.props.dispatch}
                      done={this.props.done}
                    />
                    {/* <WhitelistRave/> */}
                    <UpdateStatus
                      id={meta.reference}
                      dispatch={this.props.dispatch}
                      endpoint="/paystack/updatestatus"
                      done={this.props.done}
                    />
                  </div>
                ) : null}
              </Col>
            </Row>
          ))}
        </div>
      );
    }
  }
);
