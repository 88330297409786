import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Row, Col, Card, CardHeader, CardTitle, CardBody } from 'reactstrap';

import { callApi } from '../../utils';
import { showError } from '../../actions/notificationActions';

import Loading from '.././Loading';
import Pagination from '.././Pagination';
import SearchForm from '.././SearchForm';
import RequestTable from './Table';

class List extends Component {
  state = {
    rows: [],
    query: '',
    page: 1,
    limit: 30,
    loading: false,
    pages: 1,
  };

  componentWillMount() {
    this.load();
  }

  startLoad = () => this.setState({ loading: true });
  stopLoad = () => this.setState({ loading: false });

  scrollToTop = () =>
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);

  load = (e) => {
    // Event may be passed in from search form
    if (e) e.preventDefault();

    const { query, page } = this.state;

    this.startLoad();

    callApi(`/requests?q=${query}&page=${page}`)
      .then(({ data = [], meta = {} }) => {
        this.setState({ loading: false, rows: data, pages: meta.pages });
        this.scrollToTop();
      })
      .catch((err) => {
        this.stopLoad();
        this.props.dispatch(showError(err));
      });
  };

  setPage = (page) => {
    if (page) {
      this.setState({ page }, () => {
        this.load();
      });
    }
  };

  setQuery = (e) => {
    this.setState({ query: e.target.value });
  };

  view = (id) => {
    this.props.history.push(`/requests/${id}`);
  };

  render() {
    const { query, rows, page, loading, limit, pages } = this.state;
    return (
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardHeader className="ml-2">
                <CardTitle>Vend Requests</CardTitle>
                <p className="category">Vend Requests - Page {page}</p>
              </CardHeader>
              <SearchForm
                value={query}
                onChange={this.setQuery}
                onSearch={this.load}
                loading={loading}
              />
              <CardBody>
                <RequestTable
                  rows={rows}
                  page={page}
                  loading={loading}
                  limit={limit}
                  onRowClick={(row) => this.view(row.id)}
                />
                <Pagination total={pages} page={page} onChange={this.setPage} />
                {loading && <Loading />}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect()(List);
