import { clearUser } from './userActions';
import { callApi } from '../utils';

export const AUTHENTICATE = 'AUTHENTICATE';
export const UNAUTHENTICATE = 'UNAUTHENTICATE';

export const authenticate = () => ({ type: AUTHENTICATE });

export const unAuthenticate = () => ({ type: UNAUTHENTICATE });

export const logout = (api = true) => (dispatch) => {
  dispatch(unAuthenticate());
  dispatch(clearUser());

  // Call Logout API
  api && callApi('/logout').catch();
};
