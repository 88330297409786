import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import React, { Component } from 'react';
import { callApi, formatAmount, formatDate } from '../../utils';
import {
  closeAllModals,
  confirmAction,
  toggleModalLoading,
} from '../../actions/modalActions';
import { showError, showInfo } from '../../actions/notificationActions';

import ConfirmActionModal from '../ConfirmActionModal';
import Loading from '../Loading';
import classnames from 'classnames';
import { connect } from 'react-redux';

class Detail extends Component {
  state = {
    refund: {},
    meta: { permissions: {} },
    loading: true,
    activeTab: '1',
    toggle: null,
    transactions: [],
    page: 1,
    limit: 10,
  };

  componentWillMount() {
    this.loadView();
  }

  startLoad = () => this.setState({ loading: true });
  stopLoad = () => this.setState({ loading: false });

  scrollToTop = () =>
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);

  loadView = (e) => {
    const id = this.props.match.params.id;

    this.startLoad();
    callApi(`/refunds/${id}`, {})
      .then(({ data = {}, meta = {} }) => {
        this.setState({ refund: data, meta });
        this.scrollToTop();
      })
      .catch((err) => {
        this.props.dispatch(showError(err));
      })
      .finally(() => {
        this.stopLoad();
      });
  };

  completeRefund = () => {
    const { id } = this.props.match.params;
    this.props.dispatch(toggleModalLoading(true));
    callApi(`/refunds/${id}/process`, {}, 'post')
      .then((res) => {
        this.scrollToTop();

        this.props.dispatch(showInfo(res.message || 'SUCCESS'));
        this.props.dispatch(closeAllModals());
        const { meta = {} } = this.state;
        const { permissions = {} } = meta;
        this.setState({
          meta: {
            ...meta,
            permissions: { ...permissions, canComplete: false },
          },
        });
      })
      .catch((err) => {
        this.props.dispatch(showError(err));
      })
      .finally(() => {
        this.stopLoad();
        this.props.dispatch(toggleModalLoading(false));
      });
  };

  confirmCompletion = (id) => {
    this.props.dispatch(
      confirmAction({
        confirmCallback: this.completeRefund,
        cancel: () => this.props.dispatch(closeAllModals()),
        confirmText: '',
        title: 'Complete Refund?',
      })
    );

    // this.props.dispatch(
    //   openRefundCompletionModal({
    //     id,
    //     manifest: this.state.meta.manifest,
    //     selectedOption: this.state.refund.selectedOption,
    //     onSuccess: (meta) =>
    //       this.setState({
    //         ...this.state,
    //         meta,
    //       }),
    //   })
    // );
  };

  setPage = (page) => {
    if (page) {
      this.setState({ page }, () => {
        this.loadView();
      });
    }
  };

  render() {
    let { refund, meta, loading, activeTab } = this.state;

    const toggle = (tab) => {
      this.setState({ activeTab: tab });
    };

    return (
      <div className="content">
        <ConfirmActionModal />
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardHeader className="ml-2">
                <div className="d-flex justify-content-between align-contents-center">
                  <div>
                    <CardTitle>Single Refund</CardTitle>
                    <p className="category">{refund.id || 'Loading...'}</p>
                  </div>
                  <div>
                    <Button
                      onClick={() => this.confirmCompletion(refund.id)}
                      disabled={loading || !meta.permissions.canComplete}
                      color="primary"
                    >
                      Refund
                    </Button>
                  </div>
                </div>
              </CardHeader>

              <CardBody>
                <div>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => {
                          toggle('1');
                        }}
                      >
                        Details
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <br />
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      {loading ? (
                        <Loading />
                      ) : (
                        <Row>
                          <Col sm="6">
                            <Card className="card-chart">
                              <CardHeader>
                                <CardTitle className="mt-0">Refund</CardTitle>
                              </CardHeader>
                              <CardBody>
                                <div className="d-flex mb-3 justify-content-between">
                                  <div>
                                    <span className="text-secondary">
                                      Refund ID
                                    </span>
                                  </div>
                                  <div> {refund.id} </div>
                                </div>
                                <div className="d-flex mb-3 justify-content-between">
                                  <div>
                                    <span className="text-secondary">
                                      Order ID
                                    </span>
                                  </div>
                                  <div> {refund.order_id} </div>
                                </div>
                                <div className="d-flex mb-3 justify-content-between">
                                  <div>
                                    <span className="text-secondary">
                                      Status
                                    </span>
                                  </div>
                                  <div>{refund.status}</div>
                                </div>
                                <div className="d-flex mb-3 justify-content-between">
                                  <div>
                                    <span className="text-secondary">Time</span>
                                  </div>
                                  <div> {formatDate(refund.created_at)} </div>
                                </div>
                                <div className="d-flex mb-3 justify-content-between">
                                  <div>
                                    <span className="text-secondary">
                                      Comment
                                    </span>
                                  </div>
                                  <div> {refund.comment} </div>
                                </div>
                                <div className="d-flex mb-3 justify-content-between">
                                  <div>
                                    <span className="text-secondary">
                                      Method
                                    </span>
                                  </div>
                                  <div> {refund.method} </div>
                                </div>
                                <div className="d-flex mb-3 justify-content-between">
                                  <div>
                                    <span className="text-secondary">
                                      Amount
                                    </span>
                                  </div>
                                  {refund.amount && (
                                    <div> {formatAmount(refund.amount)} </div>
                                  )}
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      )}
                    </TabPane>
                  </TabContent>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect()(Detail);
