import React, { Component } from 'react';

import { Row, Col, Card, Button } from 'reactstrap';

import { callApi, formatAmount, formatToken } from '../utils';
import { showError, showInfo } from '../actions/notificationActions';
import VendResponseModal from './VendResponseModal';

import Loading from './Loading';
import ClickToCopy from './ClickToCopy';

export default class TransactionDetails extends Component {
  state = {
    lodaing: false,
    transaction: {},
    requestLoading: false,
    requering: false,
  };

  componentWillMount() {
    if (this.props.order && this.props.order.order_id) {
      this.loadTransaction();
    }
  }

  loadTransaction = () => {
    this.setState({ loading: true });
    let id = this.props.order.order_id;

    callApi(`/transactions/vendresponse?id=${id}`)
      .then(({ transaction = {} }) => {
        this.setState({ loading: false, transaction });
      })
      .catch((err) => {
        this.setState({ loading: false });
        this.props.dispatch(showError(err));
      });
  };

  requeryToken = () => {
    this.setState({ loading: true });
    callApi(`/request/${this.props.order.order_id}/response`)
      .then(this.loadTransaction)
      .catch((err) => {
        this.setState({ loading: false });
        this.props.dispatch(showError(err));
      });
  };

  makeNewRequest = async (params) => {
    try {
      this.setState({ requestLoading: true });
      // const payload = { gateway: this.props.evg_gateway };
      const order_id = this.props.order.id;
      const res = await callApi(`/orders/${order_id}/requests`, {}, 'post');

      this.props.dispatch(showInfo(res.message));
      this.loadTransaction();
    } catch (error) {
      this.props.dispatch(showError(error.message));
      this.setState({ requestLoading: false });
    } finally {
      this.setState({ requestLoading: false });
    }
  };

  render() {
    const { loading, requestLoading } = this.state;
    const t = this.state.transaction;
    t.token = formatToken(t.token);

    return loading ? (
      <Loading />
    ) : t.id ? (
      <Card>
        <VendResponseModal />
        <h5 className="text-center">Vend Details</h5>
        {
          <Row>
            <Col md={6} xs={12}>
              <ul>
                {t.vend_type === 'POSTPAID' ? (
                  <li>
                    Receipt:{' '}
                    <b>
                      {t.rct_num} <ClickToCopy value={t.rct_num} />{' '}
                    </b>
                  </li>
                ) : (
                  <li>
                    Token:{' '}
                    <b>
                      {t.token} <ClickToCopy value={t.token} />{' '}
                    </b>
                  </li>
                )}
                <li>Amount: {formatAmount(t.amt)} </li>
                <li>Vend Amount: {formatAmount(t.amt_electricity)} </li>

                <li>VAT: {formatAmount(t.tax)} </li>

                <li>
                  Units: <b>{t.units}</b> kWh
                </li>

                <li>Debt: {formatAmount(t.debt_amt)} </li>
                <li>
                  Gateway: <b>{t.evg_gateway}</b>{' '}
                </li>
                {/* SMS Response */}
                <li>SMS Status: {t.status_name ? t.status_name : 'PENDING'}</li>
              </ul>
            </Col>

            <Col md={6} xs={12}>
              <ul>
                <li>Trans Ref: {t.trans_ref} </li>
                <li>Receipt No.: {t.rct_num} </li>
                <li>Response: {t.response_message} </li>
                <li>Category: {t.meter_category} </li>
                <li>Tariff: {t.tariff_rate} </li>
                <li>Free Units: {t.free_units} </li>
                <li>Vend Type: {t.vend_type} </li>
              </ul>
            </Col>
          </Row>
        }
      </Card>
    ) : (
      <Row className="justify-content-center">
        <Button
          onClick={() => this.makeNewRequest()}
          size="md"
          color="danger"
          disabled={requestLoading}
        >
          NO TOKEN. RETRY
          {requestLoading ? (
            <i className="fas fa-spin fa-spinner ml-3"></i>
          ) : (
            ''
          )}
        </Button>
      </Row>
    );
  }
}
