import React, { Component, Fragment } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react';

import initStore from './store';

import 'bootstrap/dist/css/bootstrap.css';
import './assets/scss/now-ui-dashboard.css';
import './assets/css/demo.css';
import './assets/css/custom.css';

import Loading from './components/Loading';
import Notifications from './components/Notifications';
import UtilsComponent from './components/UtilsComponent';

import Main from './layouts/Main';

// Initialize Redux Store
const { store, persistor } = initStore();

export default class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <PersistGate persistor={persistor} loading={<Loading />}>
          <Notifications />
          <BrowserRouter>
            <Fragment>
              <UtilsComponent />
              <Notifications />
              <Switch>
                <Route path="/" component={Main} />
              </Switch>
            </Fragment>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    );
  }
}
