import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Table,
  Button,
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
} from 'reactstrap';
import { showError } from '../../actions/notificationActions';
import { callApi, formatDate } from '../../utils';
import Loading from '../Loading';
import Pagination from '../Pagination';

function Parcels(props) {
  const { order, dispatch } = props;
  let [loading, setLoading] = useState(false);

  const [queryParams, setQueryParams] = useState({ limit: 10, page: 1 });
  const [response, setResponse] = useState({ data: [], meta: { pages: 1 } });

  const fetchParcels = async () => {
    setLoading(true);
    return callApi(`/orders/${order.id}/parcels`, queryParams)
      .then((res) => {
        // setTransactions(res.data);
        setLoading(false);
        console.log(res);
        // setMeta(res.meta);
        const { data = [], meta = {} } = res;
        setResponse({ data, meta });
      })
      .catch((err) => {
        dispatch(showError(err.message));
        setLoading(false);
      });
  };

  useEffect(() => {
    if (order.id) {
      setLoading(true);
      fetchParcels().finally(() => {
        setLoading(false);
      });
    }
  }, [queryParams.page, order.id]);

  const { data, meta } = response;
  const { page, limit } = queryParams;

  const setPage = (page = 1) => {
    setQueryParams({ ...queryParams, page });
  };

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col xs={12} md={6}>
            <CardTitle tag="h5">Parcels</CardTitle>
          </Col>
          <Col xs={12} md={6} className="text-md-right text-xs-center">
            <Button disabled={loading} onClick={fetchParcels}>
              Refresh
            </Button>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        {loading ? (
          <Loading />
        ) : (
          <Table responsive hover striped>
            <thead className="text-primary">
              <tr>
                <th>No.</th>
                <th>Content</th>
                <th>Type</th>
                <th>Transport</th>
                <th>Date</th>
                <th>Actions</th>
              </tr>
            </thead>

            <tbody>
              {data.length ? (
                data.map((row, index) => (
                  <tr key={row.id}>
                    <td>{loading ? 'x' : index + 1 + (page - 1) * limit}</td>
                    <td>{row.content}</td>
                    <td>{row.type}</td>
                    <td>{row.transport}</td>
                    <td>{formatDate(row.created_at)}</td>
                    <td>
                      <Button size="sm" color="primary" disabled={true}>
                        Send
                      </Button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="11" className="text-center">
                    No parcels for this order.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        )}
        <Pagination page={page} total={meta.pages} onChange={setPage} />
      </CardBody>
    </Card>
  );
}

export default connect()(Parcels);
