import React from 'react';
// react plugin used to create google maps
import {
    withScriptjs, withGoogleMap, GoogleMap, Marker,
} from "react-google-maps";

const MapWrapper = withScriptjs(withGoogleMap(props => {
  console.log(props);
  // { lat: 40.748817, lng: -73.985428 }
    return <GoogleMap
        defaultZoom={13}
        defaultCenter={{ lat: Number(props.latValue), lng: Number(props.lngValue) }}
        defaultOptions={{
            scrollwheel: false,
            styles: [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}] }}
    >
        <Marker
            position={{ lat: Number(props.latValue), lng: Number(props.lngValue) }}
        />
    </GoogleMap>
}
));

class FullScreenMap extends React.Component{
    render(){
      // console.log(this.props);
        return (
          <div id="map" className="map" style={{position: "relative", overflow: "hidden"}}>
              <MapWrapper
                  googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`}
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={<div style={{ height: `100%` }} />}
                  mapElement={<div style={{ height: `100%` }} />}
                  latValue={this.props.latValue}
                  lngValue={this.props.lngValue}
              />
          </div>
        );
    }
}

export default FullScreenMap;
