import React, { Component } from 'react';
import { connect } from 'react-redux';

import NotificationsComponent from 'react-notification-system-redux';
import { clearNotifications } from '../actions/notificationActions';

export default connect((state) => ({ notifications: state.notifications }))(
  class Notifications extends Component {
    componentDidMount() {
      // I'm making this Notifications component stateful to have access to this lifecycle
      // Clear all Notifications from last reload
      this.props.dispatch(clearNotifications());
    }

    render() {
      return (
        <NotificationsComponent notifications={this.props.notifications} />
      );
    }
  }
);
