import React from 'react';
import { connect } from 'react-redux';

import {
  Row,
  Col,
  Table,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  Button,
} from 'reactstrap';

import Pagination from '../../Pagination';

import { callApi, formatAmount, formatDate, _toDash } from '../../../utils';
import { showError } from '../../../actions/notificationActions';

import Loading from '../../Loading';
import SearchForm from '../../SearchForm';
import PreviewEntityModal from '../../PreviewEntityModal';
import {
  closeAllModals,
  openChannelCreateOrder,
  openTransactionModal,
} from '../../../actions/modalActions';
import OrderModal from './OrderModal';

class MerchantTransactions extends React.Component {
  state = {
    count: 0,
    loading: true,
    transactions: [],
    page: 1,
    q: '',
    limit: 30,
    meta: {},
    currentOrder: {},
  };

  componentWillMount() {
    this.loadView();
  }

  startLoad = () => this.setState({ loading: true });
  stopLoad = () => this.setState({ loading: false });

  scrollToTop = () =>
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);

  loadView = (e) => {
    e && e.preventDefault();
    const { id } = this.props;
    const { page, limit, q } = this.state;

    this.startLoad();

    callApi(`/channels/${id}/transactions`, { page, limit, q })
      .then(({ data = [], meta }) => {
        this.setState({ transactions: data, count: meta.total, meta });
        this.scrollToTop();
      })
      .catch((err) => {
        this.props.dispatch(showError(err));
      })
      .finally(() => {
        this.stopLoad();
      });
  };

  setQuery = (e) => {
    this.setState({ q: e.target.value });
  };

  // openTransaction = (t) => {
  //   // this.props.history.push(`/orders/${t.id}`);
  //   this.setState({ currentOrder: t }, () => {
  //     this.props.dispatch(
  //       previewEntity({
  //         entity: t,
  //         btnText: 'Open Order',
  //         close: () => {
  //           this.setState({ currentOrder: {} });
  //         },
  //         view: () => {
  //           this.props.dispatch(closeAllModals());
  //           this.props.history.push(`/orders/${t.id}`);
  //         },
  //       })
  //     );
  //   });
  // };
  openTransaction = (t) => {
    this.props.dispatch(openTransactionModal(t));
  };

  setPage = (page) => {
    let { count, limit } = this.state;

    const canLoad = count % ((page - 1) * limit) < count;

    if (canLoad) {
      this.startLoad();
      this.setState({ page }, () => {
        this.loadView();
      });
    }
  };

  tableInfo() {
    const { loading, page, count = 0, limit } = this.state;

    let start = 0;
    let end = 0;

    if (!loading) {
      start = page * limit - limit + 1;
      end = Math.min(start + limit - 1, count);
    }

    return `Showing ${start} to ${end} of ${count}`;
  }

  rowNumber(index) {
    const { loading, page, limit } = this.state;
    return loading ? 'x' : index + 1 + (page - 1) * limit;
  }

  onCreateOrder() {
    const { id } = this.props;
    this.props.dispatch(
      openChannelCreateOrder({
        id,
        close: () => {
          this.setState({ currentOrder: {} }, () => {
            this.loadView();
          });
        },
        view: () => {
          this.props.dispatch(closeAllModals());
        },
      })
    );
  }

  render() {
    const { loading, transactions, page, q, meta, currentOrder } = this.state;
    return (
      <React.Fragment>
        <Row>
          <Col sm="12">
            {loading ? (
              <Loading />
            ) : (
              <div>
                <div className="d-flex justify-content-between mr-3">
                  <div>
                    <Button size="sm" onClick={() => this.onCreateOrder()}>
                      Create Order
                    </Button>
                  </div>
                </div>
                <div className="d-flex justify-content-between mr-3">
                  <div>{this.tableInfo(this.state)}</div>
                  <div></div>
                  <SearchForm
                    value={q}
                    size="sm"
                    onChange={this.setQuery}
                    onSearch={this.loadView}
                    loading={loading}
                  />
                </div>

                <Row>
                  <PreviewEntityModal>
                    <OrderDisplay order={currentOrder} />
                  </PreviewEntityModal>
                  <Col sm="12">
                    <Table responsive hover striped>
                      <thead className="text-primary">
                        <tr>
                          <th>No.</th>
                          <th>Order Ref</th>
                          <th>Name</th>
                          <th>Phone</th>
                          <th>Date</th>
                          <th>Account</th>
                          <th>Amount</th>
                          <th>DisCo</th>
                          <th>Status</th>
                        </tr>
                      </thead>

                      <tbody>
                        {transactions.map((t, i) => (
                          <tr
                            onClick={() => this.openTransaction(t)}
                            key={t.id}
                          >
                            <td>{this.rowNumber(i, this.state)}</td>
                            <td>{t.order_id}</td>
                            <td>{t.name}</td>
                            <td>{t.phone}</td>
                            <td>{formatDate(t.created_at)}</td>
                            <td>{t.meter_no}</td>
                            <td>{formatAmount(t.amount)}</td>
                            <td>{t.disco_code}</td>
                            <td>{_toDash(t.order_status)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <Pagination
                      total={meta.pages}
                      page={page}
                      onChange={this.setPage}
                    />
                    {loading && <Loading />}
                  </Col>
                </Row>
              </div>
            )}
          </Col>
        </Row>
        <OrderModal onCreate={this.loadView} />
      </React.Fragment>
    );
  }
}

function OrderDisplay({ order = {} }) {
  console.log(order);
  return (
    <div>
      <Row>
        <Col sm="12">
          <Card className="card-chart">
            <CardHeader>
              <CardTitle className="mt-0">Transaction</CardTitle>
            </CardHeader>
            <CardBody>
              <div className="d-flex mb-3 justify-content-between">
                <div>
                  <span className="text-secondary">Order ID</span>
                </div>
                <div> {order.id} </div>
              </div>
              <div className="d-flex mb-3 justify-content-between">
                <div>
                  <span className="text-secondary">Meter No.</span>
                </div>
                <div> {order.meter_no} </div>
              </div>
              <div className="d-flex mb-3 justify-content-between">
                <div>
                  <span className="text-secondary">Disco</span>
                </div>
                <div> {order.disco_code} </div>
              </div>
              <div className="d-flex mb-3 justify-content-between">
                <div>
                  <span className="text-secondary">Order Status</span>
                </div>
                <div>{order.order_status}</div>
              </div>
              <div className="d-flex mb-3 justify-content-between">
                <div>
                  <span className="text-secondary">Order Ref</span>
                </div>
                <div>{order.order_id}</div>
              </div>

              <div className="d-flex mb-3 justify-content-between">
                <div>
                  <span className="text-secondary">Payment Type</span>
                </div>
                <div> {order.payment_type} </div>
              </div>
              <div className="d-flex mb-3 justify-content-between">
                <div>
                  <span className="text-secondary">Amount</span>
                </div>
                {order.amount && <div> {formatAmount(order.amount)} </div>}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default connect()(MerchantTransactions);
