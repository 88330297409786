import React from 'react';
import { connect } from 'react-redux';
import { saveAs } from 'file-saver';
import { Row, Col, Label, Button, Table, FormGroup, Input } from 'reactstrap';

import { callApi, formatAmount, formatDateTime } from '../../utils';
import { showError } from '../../actions/notificationActions';
import Pagination from '../Pagination';

import Loading from '../Loading';

class UserTransactions extends React.Component {
  state = {
    queryParams: {
      from: '',
      to: '',
      disco: '',
      page: 1,
      limit: '30',
      subAccount: '',
      amount: '',
      generatedBy: '1',
      operation: 'credit',
    },
    pages: 1,
    meta: { pages: 1 },
    loading: false,
    rows: [],
    users: [],
  };

  setInput = (e) => {
    const { name, value } = e.target;
    this.setState({
      queryParams: { ...this.state.queryParams, [name]: value },
    });
    // setQueryParams({[name]: value})
  };

  setPage = (page) => {
    if (page) {
      this.setState(
        { queryParams: { ...this.state.queryParams, page } },
        () => {
          this.loadTimeline();
        }
      );
    }
  };

  startLoad = () => this.setState({ loading: true });
  stopLoad = () => this.setState({ loading: false });

  loadTimeline = () => {
    const { from, to, amount, page, limit } = this.state.queryParams;
    const id = this.props.user.id;
    this.setState({ loading: true });
    callApi(`/users/${id}/transactions`, {
      // id,
      from,
      to,
      // operation,
      amount,
      page,
      // generatedBy,
      limit,
    })
      .then(({ data = [], meta = {} }) => {
        this.setState({ loading: false });
        this.setState({ rows: data, meta });
      })
      .catch((err) => {
        this.setState({ loading: false });
        this.props.dispatch(showError(err));
      });

    // this.getAllAdminUsers();
  };
  componentWillMount() {
    // this.getSettlements();
    this.loadTimeline();
  }

  downloadTransactions = async () => {
    const { from, to, operation, amount, generatedBy } = this.state.queryParams;
    const { users } = this.state;
    const id = this.props.id;
    console.log(users, generatedBy);
    const res = await fetch(
      `/agentsupervisors/timeline?${new URLSearchParams({
        from,
        to,
        operation,
        amount,
        id,
        download: true,
        supervisor: users.find((u) => u.id === generatedBy).name || 'Agent',
      })}`,
      {
        method: 'GET',
      }
    );

    let fileName = 'test.xlsx';
    fileName = res.headers.get('content-disposition').split('=')[1];

    const blob = await res.blob();

    saveAs(blob, fileName);
  };

  getAllAdminUsers = () => {
    this.setState({ loading: true });
    callApi('/adminusers/all')
      .then(({ data }) => {
        this.setState({ users: data, loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
        this.props.dispatch(showError(err));
      });
  };

  render() {
    const { rows, queryParams, loading, meta } = this.state;

    const { from, to } = queryParams;

    return (
      <Row>
        <Col md="12" xs={12}>
          <div className="mt-10">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                this.getSettlements();
              }}
              className="mb-20"
            >
              <Row colSpan={2} style={{ padding: '0 25px' }}>
                <Col xs="6">
                  <FormGroup>
                    <Label>From</Label>
                    <Input
                      onChange={this.setInput}
                      value={from}
                      name="from"
                      placeholder="From"
                      type="date"
                    />
                  </FormGroup>
                </Col>
                <Col xs="6">
                  <FormGroup>
                    <Label>To</Label>
                    <Input
                      onChange={this.setInput}
                      value={to}
                      name="to"
                      placeholder="To"
                      type="date"
                    />
                  </FormGroup>
                </Col>

                {/* <Col>
                  <FormGroup>
                    <Label>Amount</Label>
                    <Input
                      onChange={this.setInput}
                      value={amount}
                      name="amount"
                      placeholder="Amount"
                      type="number"
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>Operation</Label>
                    <Input
                      onChange={this.setInput}
                      value={operation}
                      name="operation"
                      placeholder="Operation"
                      type="select"
                    >
                      <option value="credit">CREDIT</option>
                      <option value="debit">DEBIT</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>Generated By</Label>
                    <Input
                      onChange={this.setInput}
                      value={generatedBy}
                      name="generatedBy"
                      placeholder=""
                      type="select"
                    >
                      {users.map((u) => (
                        <option key={u.id} value={u.id}>
                          {u.name}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col> */}
              </Row>
              <Row className="justify-content-center">
                <Button size="lg" color="primary" onClick={this.loadTimeline}>
                  {loading ? 'PLEASE WAIT...' : 'Query'}
                </Button>
              </Row>
            </form>

            {loading ? (
              <Loading />
            ) : (
              <Table responsive hover striped>
                <thead className="text-primary">
                  <tr>
                    <th>S/N</th>
                    <th>Date</th>
                    {/* <th>Domain</th> */}
                    {/* <th>Processed</th> */}
                    <th>Amount</th>
                    <th>Disco</th>
                    <th>MeterNo</th>
                    <th>Order ID</th>
                    <th>Status</th>
                    <th>Phone</th>
                    <th>Vend Status</th>
                  </tr>
                </thead>

                <tbody>
                  {rows.map((t, i) => {
                    return (
                      <tr key={t.order_id}>
                        <td>
                          {(queryParams.page - 1) * queryParams.limit + (i + 1)}
                        </td>
                        <td>{formatDateTime(t.date)}</td>
                        <td>{formatAmount(t.amount)}</td>
                        <td>{t.disco}</td>
                        <td>{t.meter}</td>
                        <td>{t.order_id}</td>
                        <td>{t.status}</td>
                        <td>{t.phone}</td>
                        <td>{t.vend_status}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            )}
            {/* {rows.length > 0 && (
              <div className="d-flex justify-content-end">
                <Button
                  size="sm"
                  color="link"
                  onClick={() => this.downloadTransactions()}
                >
                  Download
                </Button>
              </div>
            )} */}
            <Pagination
              total={meta.pages || 1}
              page={queryParams.page}
              onChange={this.setPage}
            />
          </div>
          {/* </Card> */}
        </Col>
      </Row>
    );
  }
}

export default connect()(UserTransactions);
