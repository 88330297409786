import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Table,
  Button,
} from 'reactstrap';

import { callApi, formatDate, formatAmount } from '../utils';
import { showError } from '../actions/notificationActions';

import SearchForm from './SearchForm';
import Loading from './Loading';
import SuccessTick from './SuccessTick';
import ErrorTick from './ErrorTick';
import Pagination from './Pagination';
import {
  openTransactionModal,
  openGenerateModal,
} from '../actions/modalActions';

class MonnifyTransactions extends Component {
  state = {
    query: '',
    transactions: [],
    page: 1,
    limit: 30,
    treated: false,
    loading: false,
  };

  componentWillMount() {
    this.loadTransactions();
  }

  startLoad = () => this.setState({ loading: true });
  stopLoad = () => this.setState({ loading: false });

  scrollToTop = () =>
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);

  loadTransactions = (e) => {
    // Event may be passed in from search form
    if (e) e.preventDefault();

    const { query, page, limit, treated } = this.state;
    this.setState({ loading: true });
    callApi(
      `/monnify/transactions?q=${query}&page=${page}&limit=${limit}&treated=${treated}`
    )
      .then((res) => {
        this.setState({ loading: false, transactions: [] }, () =>
          this.setState({ transactions: res.transactions })
        );
        this.scrollToTop();
      })
      .catch((err) => {
        this.setState({ loading: false });
        this.props.dispatch(showError(err));
      });
  };

  setPage = (page) => {
    if (page) {
      this.setState({ page }, () => {
        this.loadTransactions();
      });
    }
  };

  openTransaction = (id) => {
    this.props.dispatch(openTransactionModal({ id }));
  };

  setQuery = (e) => {
    this.setState({ query: e.target.value });
  };

  toggleTreated = () => {
    this.setState(({ treated }) => ({ treated: !treated }));
  };

  openRegenerateModal = (t, e) => {
    this.props.dispatch(
      openGenerateModal({
        phone: t.phone,
        amount: Number(t.amount || 0) - 100,
        disco: t.disco,
        meter: t.meter,
        mode: 'INSTANT_TRANSFER',
        id: t.order_id,
      })
    );
    if (e) e.stopPropagation();
  };

  openRegenerateSameModal = (t, e) => {
    this.props.dispatch(
      openGenerateModal({
        phone: t.phone,
        amount: Number(t.amount || 0) - 100,
        disco: t.disco,
        meter: t.meter,
        mode: 'INSTANT_TRANSFER',
        id: t.order_id,
        link: '/token/vend',
      })
    );
    if (e) e.stopPropagation();
  };

  render() {
    const { query, page, loading, limit, treated } = this.state;
    const { transactions = [] } = this.state;

    return (
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardHeader className="ml-2">
                <CardTitle>Monnify Transactions</CardTitle>
                <p className="category">Monnify Transactions - Page {page}</p>
              </CardHeader>

              <SearchForm
                value={query}
                onChange={this.setQuery}
                onSearch={this.loadTransactions}
                loading={loading}
              />

              <div className="p-2">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={treated}
                    onChange={this.toggleTreated}
                  />
                  <span className="slider round" />
                </label>
              </div>

              <CardBody>
                <Table responsive hover striped>
                  <thead className="text-primary">
                    <tr>
                      <th>No.</th>
                      <th>Date</th>
                      <th>Treated</th>
                      <th>Phone</th>
                      <th>Meter</th>
                      <th>Amount</th>
                      <th>Order ID</th>
                      <th>Action</th>
                      <th>DisCo</th>
                    </tr>
                  </thead>

                  <tbody>
                    {transactions.map((t, i) => (
                      <tr
                        onClick={() => this.openTransaction(t.order_id)}
                        key={t.order_id}
                      >
                        <td>{loading ? 'x' : i + 1 + (page - 1) * limit}</td>
                        <td>{formatDate(t.created_at)}</td>
                        <td className="text-center">
                          {Number(t.treated) ? <SuccessTick /> : <ErrorTick />}
                        </td>
                        <td>{t.phone}</td>
                        <td>{t.meter}</td>
                        <td>{formatAmount(t.amount)}</td>
                        <td>{t.meter}</td>
                        <td>
                          {!Number(t.treated) ? (
                            <Button
                              size="sm"
                              color="danger"
                              onClick={(e) => this.openRegenerateModal(t, e)}
                            >
                              Generate
                            </Button>
                          ) : (
                            <span>Treated</span>
                          )}
                        </td>
                        <td>{t.disco}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Pagination page={page} onChange={this.setPage} />
                {loading && <Loading />}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect()(MonnifyTransactions);
