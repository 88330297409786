import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Table,
} from 'reactstrap';

import {
  callApi,
  formatDate,
  formatAmount,
  _toDash,
  VERTICAL_TYPES,
} from '../utils';
import { showError } from '../actions/notificationActions';

import SearchForm from './SearchForm';
import Loading from './Loading';
import SuccessTick from './SuccessTick';
import ErrorTick from './ErrorTick';
import Pagination from './Pagination';
import {
  openGenerateModal,
  openTransactionModal,
} from '../actions/modalActions';
import OptionButtons from './OptionButtons';
import PreviewEntityModal from './PreviewEntityModal';
import ClickToCopy from './ClickToCopy';
import SwitchRadio from './SwitchRadio';

class ApprovedTransactions extends Component {
  state = {
    query: '',
    transactions: [],
    discoStatus: [],
    page: 1,
    limit: 15,
    loading: false,
    vertical: 'ALL',
    selectedOrder: {},
    refundable: false,
  };

  componentWillMount() {
    this.loadTransactions();
  }

  startLoad = () => this.setState({ loading: true });
  stopLoad = () => this.setState({ loading: false });

  scrollToTop = () =>
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);

  setInput = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  onOptionsSelect = (o) => {
    this.setState({ vertical: o, loading: true }, this.loadTransactions);
  };

  loadTransactions = (e) => {
    // Event may be passed in from search form
    if (e) e.preventDefault();

    const { query, page, limit, vertical, refundable } = this.state;
    this.setState({ loading: true });
    callApi(
      `/transactions/approved?q=${query}&page=${page}&limit=${limit}&vertical=${vertical}&refundable=${refundable}`
    )
      .then((res) => {
        this.setState(
          { loading: false, transactions: [], discoStatus: [] },
          () =>
            this.setState({
              transactions: res.transactions,
              discoStatus: res.discoStatus,
            })
        );
        this.scrollToTop();
      })
      .catch((err) => {
        //Toggle refundable off if request is not fulfilled
        if (refundable) {
          this.setState({ refundable: false });
        }
        this.setState({ loading: false });
        this.props.dispatch(showError(err));
      });
  };

  setPage = (page) => {
    if (page) {
      this.setState({ page }, () => {
        this.loadTransactions();
      });
    }
  };

  setQuery = (e) => {
    this.setState({ query: e.target.value });
  };

  openRegenerateModal = (t, e) => {
    this.props.dispatch(
      openGenerateModal({
        phone: t.phone,
        amount: Number(t.vend_amount || 0),
        disco: t.disco_code,
        meter: t.meter_no,
        mode: t.payment_type,
        id: t.order_id,
        type: t.vertical_type,
        link:
          t.vertical_type !== VERTICAL_TYPES.ELECTRICITY
            ? '/verticals/regenerate'
            : null,
      })
    );
    if (e) e.stopPropagation();
  };

  openRegenerateSameModal = (t, e) => {
    this.props.dispatch(
      openGenerateModal({
        phone: t.phone,
        amount: Number(t.vend_amount || 0),
        disco: t.disco_code,
        meter: t.meter_no,
        mode: t.payment_type,
        id: t.order_id,
        link: '/token/regenerate',
      })
    );
    if (e) e.stopPropagation();
  };

  toggleRefundable = () => {
    this.setState(
      { refundable: !this.state.refundable, loading: true },
      this.loadTransactions
    );
  };

  openApproveTransactionModal = (t) => {
    this.props.dispatch(openTransactionModal(t));
  };

  render() {
    const { query, page, loading, limit, vertical, refundable } = this.state;
    const { transactions = [], discoStatus = [], selectedOrder } = this.state;

    return (
      <div className="content">
        <PreviewEntityModal>
          <OrderPreview order={selectedOrder} />
        </PreviewEntityModal>
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardHeader className="ml-2">
                <CardTitle>Approved Transactions</CardTitle>
                <p className="category">Approved Transactions - Page {page}</p>
              </CardHeader>
              <SearchForm
                value={query}
                onChange={this.setQuery}
                onSearch={this.loadTransactions}
                size="sm"
                loading={loading}
              />

              <div style={{ marginLeft: '8px' }}>
                <OptionButtons
                  onSelect={this.onOptionsSelect}
                  selected={vertical}
                  options={['ALL', ...Object.values(VERTICAL_TYPES)]}
                />
              </div>

              <div className="ml-3">
                <SwitchRadio
                  checked={refundable}
                  onChange={this.toggleRefundable}
                  size="sm"
                  isToggling={!loading ? true : false}
                  name="Refundable"
                />
              </div>

              <CardBody>
                {loading ? (
                  <Loading />
                ) : (
                  <div>
                    <Table responsive hover striped>
                      <thead className="text-primary">
                        <tr>
                          <th>No.</th>
                          <th>Date</th>
                          <th>Treated</th>
                          <th>Name/Phone</th>
                          <th>Meter</th>
                          <th>Amount</th>
                          <th>Vertical</th>
                          <th>DisCo</th>
                          <th>Channel</th>
                          <th>Status</th>
                          <th>Order ID</th>
                          <th>Generated by</th>
                        </tr>
                      </thead>
                      <tbody>
                        {transactions.map((t, i) => (
                          <tr
                            onClick={() => {
                              console.log(t);
                              this.openApproveTransactionModal(t);
                            }}
                            key={t.id}
                            className={`${
                              t.priority ? 'font-weight-bold' : ''
                            }`}
                          >
                            <td>
                              {loading ? 'x' : i + 1 + (page - 1) * limit}
                            </td>
                            <td>{formatDate(t.created_at)}</td>
                            <td className="text-center">
                              {t.treated_on ? <SuccessTick /> : <ErrorTick />}
                            </td>
                            <td
                              data-toggle="tooltip"
                              data-placement="top"
                              title={t.name}
                            >
                              {t.name}
                              <br />
                              {t.phone}
                            </td>
                            <td
                              data-toggle="tooltip"
                              data-placement="top"
                              title={t.vend_type}
                            >
                              {t.meter_no}
                            </td>
                            <td>{formatAmount(t.amount)}</td>
                            <td>{t.vertical_type || 'ELECTRICITY'}</td>
                            <td>{t.disco_code}</td>
                            <td>{_toDash(t.channel)}</td>
                            <td>{t.order_status}</td>
                            <td>{t.order_id}</td>
                            <td>{t.generator || t.name || 'Unknown'}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>

                    <Pagination page={page} onChange={this.setPage} />
                  </div>
                )}
              </CardBody>

              <Card className="settlement-dash">
                <Row>
                  {discoStatus.map((d) => (
                    <Col xs={6} md={3} key={d.key}>
                      <h5>
                        <strong>{d.key}</strong>:{' '}
                        <span>{d.value ? 'ON' : 'OFF'}</span>
                      </h5>
                    </Col>
                  ))}
                </Row>
              </Card>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect()(ApprovedTransactions);

function OrderPreview({ order }) {
  return (
    <Row>
      <Col sm="6">
        <Card className="card-chart">
          <CardHeader>
            <CardTitle className="mt-0">Order</CardTitle>
          </CardHeader>
          <CardBody>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Order ID</span>
              </div>
              <div> {order.order_id} </div>
            </div>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Name</span>
              </div>
              <div> {order.name} </div>
            </div>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Phone</span>
              </div>
              <span>
                <span className="cursor-pointer blue-text">
                  <strong>{order.phone}</strong>
                </span>
                <ClickToCopy value={order.phone} />
              </span>
            </div>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">DisCo</span>
              </div>
              <div> {order.disco_code} </div>
            </div>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Payment Method</span>
              </div>
              <div> {order.payment_type} </div>
            </div>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Amount</span>
              </div>
              {order.amount && <div> {formatAmount(order.amount)} </div>}
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col sm="6">
        <Card className="card-chart">
          <CardHeader>
            <CardTitle className="mt-0">Other Information</CardTitle>
          </CardHeader>
          <CardBody>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Time</span>
              </div>
              {order.created_at && <div> {formatDate(order.created_at)} </div>}
            </div>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Treated</span>
              </div>
              <div>{order.treated_on ? <SuccessTick /> : <ErrorTick />}</div>
            </div>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Meter No.</span>
              </div>
              <div> {order.meter_no} </div>
            </div>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Status</span>
              </div>
              <div> {order.payment_status} </div>
            </div>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Order Status</span>
              </div>
              <div> {order.order_status} </div>
            </div>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Vend Status</span>
              </div>
              {<div>{order.vend_status}</div>}
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}
