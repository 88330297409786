export const provider = ['paystack', 'monnify', 'payant'];

export const disco = [
  'abuja',
  'kaduna',
  'jos',
  'ph',
  'ikeja',
  'eko',
  'ibadan',
  'enugu',
  'kano',
  'mtn',
  'bh',
];
