import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  Button,
  CardSubtitle,
} from 'reactstrap';

import { closeAllModals } from '../actions/modalActions';
import { showError, showInfo } from '../actions/notificationActions';

import { callApi, formatAmount } from '../utils';

export default connect((state) => ({
  open: state.modals.supervisor,
  data: state.modals.data,
}))(
  class UpdateWalletModal extends Component {
    state = {
      supervisor: {
        name: this.props.data && this.props.data.name,
        phone: this.props.data && this.props.data.phone,
        email: '',
        operation: 'credit',
        amount: '',
        status: 0,
        id: this.props.data && this.props.data.id,
      },
      cards: [],
      loading: false,
    };

    componentDidUpdate(prevProps) {
      if (prevProps.open !== this.props.open && this.props.open) {
        this.setState({
          supervisor: {
            name: this.props.data.name,
            phone: this.props.data.phone,
            email: '',
            operation: 'credit',
            amount: '',
            status: 0,
            id: this.props.data && this.props.data.id,
          },
          cards: [],
        });
        // this.loadSupervisor(this.props.data.id);
      }
    }

    setInput = (e) => {
      const { name, value } = e.target;

      this.setState((state) => {
        return { ...state, supervisor: { ...state.supervisor, [name]: value } };
      });
    };

    close = () => {
      this.props.dispatch(closeAllModals());
    };

    loadSupervisor = (id) => {
      this.setState({ loading: true });
      callApi('/agentsupervisors/wallet')
        .then(({ supervisor = {}, cards = [] }) => {
          this.setState({ loading: false });
          this.setState({ supervisor, cards });
        })
        .catch((err) => {
          this.setState({ loading: false });
          this.props.dispatch(showError(err));
        });
    };

    updateWallet = (e) => {
      e.preventDefault();
      const { operation, amount, id } = this.state.supervisor;
      this.setState({ loading: true });
      callApi(
        '/agentsupervisors/wallet',
        { operation, amount: Number(amount), id, type: 'topup' },
        'post'
      )
        .then(() => {
          this.setState({ loading: false });
          this.props.dispatch(
            showInfo(
              `Wallet ${
                operation === 'credit' ? 'credited' : 'debited'
              } with ${formatAmount(amount)}`
            )
          );
        })
        .catch((err) => {
          this.setState({ loading: false });
          this.props.dispatch(showError(err));
        });
    };

    blockSupervisor = (id, status) => {
      if (!window.confirm('Block supervisor?')) return;
      this.setState({ loading: true });
      callApi('/agentsupervisors/status', { id, status }, 'post')
        .then((res) => {
          this.setState({ loading: false });
          this.props.dispatch(showInfo(res.message));
        })
        .catch((err) => {
          this.setState({ loading: false });
          this.props.dispatch(showError(err));
        });
    };

    render() {
      const { name, phone, operation, amount } = this.state.supervisor;
      const loading = this.state.loading;
      const open = this.props.open;
      return (
        <div>
          <Modal size="lg" isOpen={open} toggle={this.close}>
            <ModalHeader toggle={this.close}>Update Wallet</ModalHeader>
            <ModalBody>
              <form onSubmit={this.updateWallet}>
                <Row>
                  <Col xs={12} md={12}>
                    <Label>Name</Label>
                    <CardSubtitle>{name}</CardSubtitle>
                    <h1> </h1>
                    <Label>Phone</Label>
                    <CardSubtitle>{phone}</CardSubtitle>
                    <h1> </h1>

                    <FormGroup>
                      <Label>Wallet Operation</Label>
                      <Input
                        name="operation"
                        type="select"
                        onChange={this.setInput}
                        value={operation}
                      >
                        <option value="credit">CREDIT</option>
                        <option value="debit">DEBIT</option>
                        {/* <option value="PAYSTACK">PAYSTACK</option>
                        <option value="RAVE">RAVE</option> */}
                      </Input>
                    </FormGroup>

                    <FormGroup>
                      <Label>Amount</Label>
                      <Input
                        onChange={this.setInput}
                        value={amount}
                        name="amount"
                        placeholder="Amount"
                        type="number"
                      />
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          paddingRight: '4px',
                        }}
                      >
                        <Label>{`${
                          this.state.supervisor.amount &&
                          formatAmount(this.state.supervisor.amount)
                        }`}</Label>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Button
                    size="lg"
                    color="primary"
                    disabled={loading}
                    type="submit"
                  >
                    {loading
                      ? 'PLEASE WAIT...'
                      : operation === 'credit'
                      ? 'CREDIT ACCOUNT'
                      : 'DEBIT ACCOUNT'}
                  </Button>
                </Row>
              </form>
            </ModalBody>
            <ModalFooter />
          </Modal>
        </div>
      );
    }
  }
);
