import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import React, { Component } from 'react';
import { showError, showInfo } from '../../../actions/notificationActions';

import { callApi } from '../../../utils';
import { closeAllModals } from '../../../actions/modalActions';
import { connect } from 'react-redux';

export default connect(({ modals }) => ({
  open: modals.openEditCommissionModal,
  data: modals.data || {},
}))(
  class EditCommissionModal extends Component {
    state = this.getStateFromProps();

    getStateFromProps() {
      const { key, rate, flat, cap, channelId } = this.props.data;

      return {
        key,
        rate,
        flat,
        cap,
        channelId,
        loading: false,
      };
    }

    setInput = (e) => {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    };

    componentDidUpdate(prevProps) {
      if (prevProps.open !== this.props.open) {
        this.setState(this.getStateFromProps());
      }
    }

    close = () => {
      this.props.dispatch(closeAllModals());
    };

    submitRequest = (e) => {
      e.preventDefault();
      const { flat, cap, rate, loading, channelId } = this.state;

      if (loading) return;

      const prompt = `Are you sure you want to submit?`;

      if (!window.confirm(prompt)) return;

      this.setState({ loading: true });

      callApi(
        `/channels/${channelId}/commissions/${this.props.data.id}`,
        {
          rate,
          cap,
          flat,
          channelId,
        },
        'put'
      )
        .then((res) => {
          this.setState({ loading: false });
          this.props.loadView();
          this.close();
          this.props.dispatch(showInfo(res.message));
        })
        .catch((err) => {
          this.props.dispatch(showError(err));
          this.setState({ loading: false });
        });
    };

    render() {
      const { key, cap, rate, flat, loading } = this.state;

      return (
        <Modal
          size="lg"
          isOpen={this.props.open}
          toggle={this.close}
          backdrop="static"
        >
          <ModalHeader toggle={this.close}>{key}</ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <FormGroup>
                  <Label>Rate</Label>
                  <Input
                    onChange={this.setInput}
                    value={rate}
                    name="rate"
                    placeholder="Commission Rate"
                    type="number"
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label>Cap</Label>
                  <Input
                    onChange={this.setInput}
                    value={cap}
                    name="cap"
                    placeholder="Cap"
                    type="number"
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label>Flat</Label>
                  <Input
                    onChange={this.setInput}
                    value={flat}
                    name="flat"
                    placeholder="Flat"
                    type="number"
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              type="submit"
              onClick={this.submitRequest}
              disabled={loading}
            >
              {loading ? 'Submitting...' : 'Submit'}
            </Button>
          </ModalFooter>
        </Modal>
      );
    }
  }
);
