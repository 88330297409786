import React, { Component } from 'react';

import { Row, Col, Card } from 'reactstrap';

import { callApi, formatDate, _toDash, formatAmount } from '../utils';
import { showError } from '../actions/notificationActions';

import Loading from './Loading';
import ErrorTick from './ErrorTick';
import SuccessTick from './SuccessTick';
import ClickToCopy from './ClickToCopy';
import TreatTransaction from './TreatTransaction';

import { openUserModal } from '../actions/modalActions';

export default class QMcashDetails extends Component {
  state = {
    lodaing: false,
    transaction: {},
  };

  componentWillMount() {
    if (this.props.id) {
      this.loadTransaction();
    }
  }

  loadTransaction = () => {
    this.setState({ loading: true });
    callApi('/transaction/qmcash?id=' + this.props.id)
      .then(({ transaction = {} }) => {
        this.setState({ loading: false, transaction });
      })
      .catch((err) => {
        this.setState({ loading: false });
        this.props.dispatch(showError(err));
      });
  };

  openUser = (id) => {
    this.props.dispatch(openUserModal(id));
  };

  render() {
    const { loading } = this.state;
    const t = this.state.transaction;
    const id = this.props.id;
    return loading ? (
      <Loading />
    ) : (
      <Card>
        <h5 className="text-center">
          {id} <ClickToCopy value={id} />
        </h5>
        <Row>
          <Col md={6} xs={12}>
            <ul>
              <li>Time: {formatDate(t.created_at)} </li>
              <li>Name: {t.customer_name} </li>
              <li>
                Phone:&nbsp;
                <span
                  className="cursor-pointer blue-text"
                  onClick={() => this.openUser(t.phone)}
                >
                  <strong>{t.phone}</strong>
                </span>
                &nbsp; <ClickToCopy value={t.phone} />
              </li>
              <li>Vend Response: {_toDash(t.vend_response)} </li>
            </ul>
          </Col>
          <Col md={6} xs={12}>
            <ul>
              <li>
                Meter: {t.meter_no} <ClickToCopy value={t.meter_no} />
              </li>
              <li>Amount: {formatAmount(t.amount)} </li>
              <li>DisCo: {t.disco_code} </li>
              <li>Attempts: {t.attempts} </li>
              <li>
                Completed:{' '}
                {Number(t.completed) ? <SuccessTick /> : <ErrorTick />}
              </li>
              {t.treatedBy ? <li>Treated By: {t.treatedBy} </li> : null}
            </ul>
          </Col>
        </Row>
        <TreatTransaction
          id={id}
          link="/transaction/treatqmcash"
          treated={Number(t.completed) ? true : false}
          done={this.loadTransaction}
          dispatch={this.props.dispatch}
        />
      </Card>
    );
  }
}
