import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Table } from 'reactstrap';

import { callApi, formatAmount, formatDate } from '../../utils';
import { showError, showInfo } from '../../actions/notificationActions';
import Pagination from '../Pagination';

import Loading from '../Loading';
import { openTransactionModal } from '../../actions/modalActions';
import SearchForm from '../SearchForm';

class MerchantOrders extends React.Component {
  state = {
    queryParams: {
      page: 1,
      limit: '50',
      amount: '',
      q: '',
    },
    pages: 1,
    meta: { pages: 1 },
    loading: false,
    rows: [],
    users: [],
  };

  setInput = (e) => {
    const { name, value } = e.target;
    this.setState({
      queryParams: { ...this.state.queryParams, [name]: value },
    });
    // setQueryParams({[name]: value})
  };

  setQuery = (e) => {
    e.target.name = 'q';
    this.setInput(e);
  };

  setPage = (page) => {
    if (page) {
      this.setState(
        { queryParams: { ...this.state.queryParams, page } },
        () => {
          this.loadTimeline();
        }
      );
    }
  };

  startLoad = () => this.setState({ loading: true });
  stopLoad = () => this.setState({ loading: false });

  loadTimeline = (e) => {
    if (e) e.preventDefault();
    const { amount, page, q, limit } = this.state.queryParams;
    const id = this.props.id;
    this.setState({ loading: true });
    callApi(`/merchants/${id}/orders`, {
      amount,
      page,
      q,
      limit,
    })
      .then(({ data = [], pages }) => {
        this.setState({ loading: false });
        this.setState({ rows: data, meta: { pages } });
      })
      .catch((err) => {
        this.setState({ loading: false });
        this.props.dispatch(showError(err));
      });
  };

  openTransaction = (id) => {
    this.props.dispatch(openTransactionModal({ id }));
  };

  loadUser = () => {
    this.startLoad();
    callApi('/user?id=' + this.props.id)
      .then(({ user = {}, cards = [] }) => {
        this.setState({ loading: false });
        this.setState({ entity: user, rows: [...cards] });
      })
      .catch((err) => {
        this.stopLoad();
        this.props.dispatch(showError(err));
      });
  };

  componentDidMount() {
    this.loadTimeline();
  }

  deactivateCard = (card) => {
    if (!window.confirm("Deactivate user's card?")) return;
    this.setState({ loading: true });
    callApi('/card/deactivate', { signature: card.signature }, 'post')
      .then((res) => {
        this.setState({ loading: false });
        this.props.dispatch(showInfo(res.message));
        this.loadUser();
      })
      .catch((err) => {
        this.setState({ loading: false });
        this.props.dispatch(showError(err));
      });
  };

  render() {
    const { rows, queryParams, loading, meta } = this.state;

    const { query } = queryParams;

    return (
      <Row>
        <Col md="12" xs={12}>
          <div className="mt-10">
            <SearchForm
              value={query}
              onChange={this.setQuery}
              onSearch={this.loadTimeline}
              loading={loading}
            />
            {loading ? (
              <Loading />
            ) : (
              <Table responsive hover striped>
                <thead className="text-primary">
                  <tr>
                    <th>S/N</th>
                    <th>Meter No.</th>
                    {/* <th>Name</th> */}
                    {/* <th>Order Ref</th> */}
                    <th>Amount</th>
                    {/* <th>Domain</th> */}
                    {/* <th>Processed</th> */}
                    <th>Order ID</th>
                    <th>Disco</th>
                    <th>Gateway</th>
                    <th>Type</th>
                    <th>Payment Status</th>
                    <th>Vend Status</th>
                    <th>Date</th>
                  </tr>
                </thead>

                <tbody>
                  {rows.map((t, i) => {
                    return (
                      <tr
                        onClick={() => this.openTransaction(t.order_id)}
                        key={t.id}
                      >
                        <td>
                          {(queryParams.page - 1) * queryParams.limit + (i + 1)}
                        </td>
                        <td>{t.meter_no}</td>
                        {/* <td>{t.name}</td> */}
                        {/* <td>{t.order_ref}</td> */}
                        <td>{formatAmount(t.amount)}</td>
                        <td>{t.order_id}</td>
                        <td>{t.disco_code}</td>
                        <td>{t.evg_gateway}</td>
                        <td>{t.vend_type}</td>
                        <td>{t.payment_status}</td>
                        <td>{t.vend_status}</td>
                        <td>{formatDate(t.created_at)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            )}
            <Pagination
              total={meta.pages}
              page={queryParams.page}
              onChange={this.setPage}
            />
          </div>
          {/* </Card> */}
        </Col>
      </Row>
    );
  }
}

export default connect()(MerchantOrders);
