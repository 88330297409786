import React from 'react';
import { connect } from 'react-redux';

import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Button,
} from 'reactstrap';
import { openGenAccountModal } from '../actions/modalActions';

const GenerateAccount = ({ dispatch }) => (
  <div className="content">
    <Row>
      <Col xs={12} md={12}>
        <Card>
          <CardHeader className="ml-2">
            <CardTitle>Generate Bank Account</CardTitle>
          </CardHeader>

          <CardBody>
            <Row
              style={{ minHeight: '70vh' }}
              className="justify-content-center align-items-center"
            >
              <Button
                onClick={() => dispatch(openGenAccountModal({}))}
                size="lg"
                color="secondary"
              >
                CLICK HERE TO GENERATE MONNIFY ACCOUNT
              </Button>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  </div>
);

export default connect()(GenerateAccount);
