import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  Row,
  Col,
  Card,
  Input,
  CardHeader,
  CardTitle,
  CardBody,
  Table,
  Button,
} from 'reactstrap';

import { callApi, formatDate, formatAmount } from '../utils';
import { showError, showInfo } from '../actions/notificationActions';

import Loading from './Loading';
import Pagination from './Pagination';
import { openRefundModal } from '../actions/modalActions';

class AgentSettlements extends Component {
  state = {
    supervisor: 'ALL',
    approved: 'ALL',
    breakdown: 'NO',
    settlements: [],
    supervisors: [],
    outstanding: 0,
    agentOutstanding: 0,
    sum: 0,
    count: 0,
    page: 1,
    limit: 20,
    start: '',
    end: '',
  };

  componentWillMount() {
    this.loadSettlements();
    this.loadSupervisors();
  }

  startLoad = () => this.setState({ loading: true });
  stopLoad = () => this.setState({ loading: false });

  scrollToTop = () =>
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);

  loadSupervisors = () => {
    this.setState({ loading: true });
    callApi(`/agentsupervisors`)
      .then((res) => {
        this.setState({ loading: false, supervisors: [] }, () =>
          this.setState({ supervisors: res.data })
        );
        this.scrollToTop();
      })
      .catch((err) => {
        this.setState({ loading: false });
        this.props.dispatch(showError(err));
      });
  };

  loadSettlements = () => {
    const {
      supervisor,
      approved,
      breakdown,
      page,
      limit,
      start,
      end,
    } = this.state;
    this.setState({ loading: true });
    callApi(`/agentsettlements`, {
      supervisor,
      approved,
      breakdown,
      page,
      limit,
      start,
      end,
    })
      .then((res) => {
        this.setState({ loading: false, settlements: [] }, () =>
          this.setState({
            settlements: res.data,
            outstanding: res.outstanding,
            agentOutstanding: res.agentOutstanding,
            sum: res.sum,
            count: res.count,
          })
        );
        this.scrollToTop();
      })
      .catch((err) => {
        this.setState({ loading: false });
        this.props.dispatch(showError(err));
      });
  };

  setPage = (page) => {
    if (page) {
      this.setState({ page }, () => {
        this.loadSettlements();
      });
    }
  };

  approveSettlement = (s) => {
    s.refresh = this.loadSupervisors;
    this.props.dispatch(openRefundModal(s));
  };

  deleteSettlement = (s) => {
    if (!window.confirm('Delete Settlement? ')) return;
    this.setState({ loading: true });
    callApi(`/agentsettlements/delete`, { id: s._id }, 'post')
      .then(({ message }) => {
        this.setState({ loading: false });
        this.props.dispatch(showInfo(message));
        this.loadSettlements();
      })
      .catch(({ message }) => {
        this.setState({ loading: false });
        this.props.dispatch(showError(message));
      });
  };

  setQuery = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const {
      approved,
      supervisor,
      outstanding,
      breakdown,
      agentOutstanding,
      sum,
      count,
      page,
      loading,
      limit,
      start,
      end,
    } = this.state;
    const settlements = this.state.settlements || [];
    const supervisors = this.state.supervisors || [];
    return (
      <Col className="content">
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardHeader className="ml-2">
                <CardTitle>Agent Settlements</CardTitle>
                <p className="category">Agent Settlements - Page {page}</p>
              </CardHeader>

              <Card className="settlement-dash">
                <Row>
                  <Col md={4}>
                    <h5>Outstanding: {formatAmount(outstanding)}</h5>
                  </Col>
                  <Col md={4}>
                    <h5>
                      Agents Outstanding: {formatAmount(agentOutstanding)}
                    </h5>
                  </Col>
                  <Col md={4}>
                    <h5>Sum: {formatAmount(sum)}</h5>
                  </Col>
                  <Col md={4}>
                    <h5>Count: {count}</h5>
                  </Col>
                </Row>
              </Card>

              <Row style={{ padding: '10px 20px' }}>
                <Col md={6} xs={12}>
                  <h6>Start Date</h6>
                  <Input
                    name="start"
                    placeholder="Pick start date"
                    type="date"
                    onChange={this.setQuery.bind(this)}
                    value={start}
                    style={{ maxWidth: '500px' }}
                  />
                </Col>
                <Col md={6} xs={12}>
                  <h6>End Date</h6>
                  <Input
                    name="end"
                    placeholder="Pick end date"
                    type="date"
                    onChange={this.setQuery.bind(this)}
                    value={end}
                    style={{ maxWidth: '500px' }}
                  />
                </Col>
              </Row>
              <Row style={{ padding: '10px 20px' }}>
                <Col md={6} xs={12}>
                  <h6>Status</h6>
                  <Input
                    name="approved"
                    type="select"
                    onChange={this.setQuery.bind(this)}
                    value={approved}
                    style={{ maxWidth: '500px' }}
                  >
                    <option value="ALL">ALL</option>
                    <option value="true">Approved</option>
                    <option value="false">New</option>
                  </Input>
                </Col>
                <Col md={6} xs={12}>
                  <h6>Select Supervisor</h6>
                  <Input
                    name="supervisor"
                    type="select"
                    onChange={this.setQuery.bind(this)}
                    value={supervisor}
                    style={{ maxWidth: '500px' }}
                  >
                    <option value="ALL">ALL</option>
                    {supervisors.map((s) => (
                      <option key={s.id} value={s.id}>
                        {s.name}
                      </option>
                    ))}
                  </Input>
                </Col>
                <Col md={6} xs={12}>
                  <h6>Show Breakdown</h6>
                  <Input
                    name="breakdown"
                    type="select"
                    onChange={this.setQuery.bind(this)}
                    value={breakdown}
                    style={{ maxWidth: '500px' }}
                  >
                    <option value="NO">NO</option>
                    <option value="YES">YES</option>
                  </Input>
                </Col>
              </Row>

              {loading && (
                <div className="justify-content-center">
                  <Loading />
                </div>
              )}
              <div style={{ paddingLeft: '20px', maxWidth: '500px' }}>
                <Button
                  size="lg"
                  color="primary"
                  onClick={() => this.loadSettlements()}
                >
                  {loading ? 'PLEASE WAIT...' : 'SEARCH'}
                </Button>
              </div>

              <CardBody>
                <Table responsive hover striped>
                  <thead className="text-primary">
                    <tr>
                      <th>No.</th>
                      <th>Date</th>
                      <th>Supervisor</th>
                      <th>Amount</th>
                      <th>Bank</th>
                      <th>Payment Type</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {settlements.map((s, i) => (
                      <tr key={s._id}>
                        <td>{loading ? 'x' : i + 1 + (page - 1) * limit}</td>
                        <td>{formatDate(s.createdAt)}</td>
                        <td>{s.name}</td>

                        <td>{formatAmount(s.amount)}</td>
                        <td>{s.bank}</td>
                        <td>{s.channel}</td>
                        <td>
                          {s.approved
                            ? `APPROVED ${formatDate(s.updatedAt)}`
                            : 'NEW'}
                        </td>
                        <td>
                          {s.proofImage ? (
                            <Button
                              size="sm"
                              color="primary"
                              href={s.proofImage}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Download Proof
                            </Button>
                          ) : null}
                          {'   '}
                          {!s.approved ? (
                            <span>
                              <Button
                                size="sm"
                                color="danger"
                                disabled={loading}
                                onClick={() => this.approveSettlement(s)}
                              >
                                Approve
                              </Button>{' '}
                              <Button
                                size="sm"
                                color="danger"
                                disabled={loading}
                                onClick={() => this.deleteSettlement(s)}
                              >
                                Delete
                              </Button>
                            </span>
                          ) : (
                            <span>Treated</span>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Pagination page={page} onChange={this.setPage} />
                {loading && <Loading />}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Col>
    );
  }
}

export default connect()(AgentSettlements);
