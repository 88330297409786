import React from 'react';
import { Route, Switch } from 'react-router-dom';

import List from './Orders/List';
import Detail from './Orders/Detail';

function Orders() {
  return (
    <Switch>
      <Route exact path="/orders" component={List} />
      <Route path="/orders/:id" component={Detail} />
    </Switch>
  );
}

export default Orders;
