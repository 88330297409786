import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  Modal,
  ModalHeader,
  ModalBody,
  // ModalFooter,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  Button,
} from 'reactstrap';

import Loading from './Loading';

import { closeAllModals } from '../actions/modalActions';
import { callApi, numToWords } from '../utils';
import { showError, showInfo } from '../actions/notificationActions';

const initState = {
  reason: 'refund',
  api_id: '',
  amount: 0,
  commission: 0,
  apiUsers: [],
  loading: false,
};

export default connect(({ modals }) => ({ open: modals.apiUser }))(
  class ApiUsersRefundModal extends Component {
    state = initState;

    setInput = (e) => {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    };

    close = () => {
      this.props.dispatch(closeAllModals());
      this.setState(initState);
    };

    submitRequest = (e) => {
      e.preventDefault();
      const { reason, api_id, amount, commission, apiUsers } = this.state;
      const [{ name }] = apiUsers.filter(({ id }) => id === Number(api_id));
      const prompt = `
      Are you sure you want to credit ${name.toUpperCase()} with: 
    
      Amount: ₦ ${amount}
      
      ${numToWords(amount).toUpperCase()} NAIRA
          `;

      if (!window.confirm(prompt)) return;
      this.setState({ loading: true });

      callApi(
        '/apiusers/credit',
        { reason, api_id, amount, commission },
        'POST'
      )
        .then(({ message }) => {
          this.setState({ loading: false });
          this.props.dispatch(showInfo(message));
        })
        .catch((e) => {
          this.setState({ loading: false });
          this.props.dispatch(showError(e.message));
        });
    };

    fetchApiUsers = () => {
      callApi('/apiusers')
        .then(({ data }) => this.setState({ apiUsers: data }))
        .catch((e) => {
          this.setState({ loading: false });
          this.props.dispatch(showError(e.message));
        });
    };

    componentDidMount() {
      this.fetchApiUsers();
    }

    render() {
      const {
        reason,
        api_id,
        amount,
        commission,
        loading,
        apiUsers,
      } = this.state;

      return (
        <div>
          <Modal size="lg" isOpen={this.props.open} toggle={this.close}>
            <ModalHeader toggle={this.close}>Credit API User</ModalHeader>
            <ModalBody>
              <form onSubmit={this.submitRequest}>
                <Row>
                  <Col xs={12} md={12}>
                    <FormGroup>
                      <Label>API USER</Label>
                      <Input
                        onChange={this.setInput}
                        value={api_id}
                        name="api_id"
                        type="select"
                        required
                      >
                        <option value="">Select an API user</option>
                        {apiUsers.map((a) => (
                          <option key={a.id} value={a.id}>
                            {a.name}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <Label>Reason</Label>
                      <Input
                        onChange={this.setInput}
                        value={reason}
                        name="reason"
                        placeholder=""
                        type="text"
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label>Amount</Label>
                      <Input
                        onChange={this.setInput}
                        value={amount}
                        name="amount"
                        placeholder="Enter Credit Amount"
                        type="number"
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label>Commission</Label>
                      <Input
                        onChange={this.setInput}
                        value={commission}
                        name="commission"
                        placeholder="Enter Commission"
                        type="number"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <hr />

                {loading && (
                  <Row className="justify-content-center">
                    <Loading />
                  </Row>
                )}

                <Row className="justify-content-center">
                  <Button
                    disabled={loading}
                    size="lg"
                    color="secondary"
                    type="submit"
                  >
                    {loading ? 'PLEASE WAIT...' : 'SUBMIT'}
                  </Button>
                </Row>
              </form>
            </ModalBody>
          </Modal>
        </div>
      );
    }
  }
);
