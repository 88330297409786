import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button, Table } from 'reactstrap';

import { callApi } from '../../utils';
import { showError, showInfo } from '../../actions/notificationActions';
import Pagination from '../Pagination';

import Loading from '../Loading';
import CreateVirtualAcctModal from '../CreateVirtualAcctModal';
import { openVirtualAccountModal } from '../../actions/modalActions';

class UserVirtualAccounts extends React.Component {
  state = {
    queryParams: {
      page: 1,
      limit: '30',
    },
    pages: 1,
    meta: { pages: 1 },
    loading: false,
    rows: [],
    users: [],
  };

  setInput = (e) => {
    const { name, value } = e.target;
    this.setState({
      queryParams: { ...this.state.queryParams, [name]: value },
    });
  };

  setPage = (page) => {
    if (page) {
      this.setState(
        { queryParams: { ...this.state.queryParams, page } },
        () => {
          this.loadTimeline();
        }
      );
    }
  };

  startLoad = () => this.setState({ loading: true });
  stopLoad = () => this.setState({ loading: false });

  loadTimeline = () => {
    const { page, limit } = this.state.queryParams;
    const id = this.props.user.id;
    this.setState({ loading: true });
    callApi(`/customers/${id}/virtual-accounts`, {
      page,
      limit,
    })
      .then(({ data = [], meta = {} }) => {
        console.log('Data', data);
        this.setState({ loading: false });
        this.setState({ rows: data, meta });
      })
      .catch((err) => {
        this.setState({ loading: false });
        this.props.dispatch(showError(err));
      });
  };

  refreshTable = (data) => {
    this.setState({ rows: [...this.state.rows, data] });
  };

  componentWillMount() {
    this.loadTimeline();
  }

  view = (id) => {
    this.props.history.push(`/virtual-accounts/${id}`);
  };

  sendInstructions = (account, event) => {
    event.stopPropagation();
    callApi(
      `/customers/${account.user_id}/virtual-accounts/${account.id}/instructions`
    )
      .then(({ data }) => {
        this.props.dispatch(showInfo(data));
      })
      .catch((err) => {
        this.props.dispatch(showError(err));
      });
  };

  render() {
    const { rows, queryParams, loading, meta } = this.state;

    console.log(this.props);

    return (
      <div>
        <CreateVirtualAcctModal refresh={this.refreshTable} />
        <Row>
          <Col md="12" xs={12}>
            <div className="mt-10">
              {loading ? (
                <Loading />
              ) : (
                <div>
                  <Button
                    size="sm"
                    disabled={loading}
                    color={'primary'}
                    onClick={() => {
                      this.props.dispatch(openVirtualAccountModal());
                    }}
                  >
                    Create Virtual Account
                  </Button>
                  <Table responsive hover striped>
                    <thead className="text-primary">
                      <tr>
                        <th>No.</th>
                        <th>Acc. Number</th>
                        <th>Meter</th>
                        <th>Acc Name</th>
                        <th>Bank</th>
                        <th>Disco</th>
                        <th>Provider</th>
                        <th>Active</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>

                    <tbody>
                      {rows.map((t, i) => {
                        return (
                          <tr onClick={() => this.view(t.id)} key={t.id}>
                            <td>
                              {loading
                                ? 'x'
                                : i +
                                  1 +
                                  (queryParams.page - 1) * queryParams.limit}
                            </td>
                            <td>{t.acc_num}</td>
                            <td>{t.meter}</td>
                            <td>{t.acc_name}</td>
                            <td>{t.bank}</td>
                            <td>{t.disco.toUpperCase()}</td>
                            <td>{t.provider}</td>
                            <td>{t.active ? 'YES' : 'NO'}</td>
                            <td>
                              <Button
                                size="sm"
                                onClick={(e) => this.sendInstructions(t, e)}
                              >
                                Send Instructions
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              )}
              <Pagination
                total={meta.pages || 1}
                page={queryParams.page}
                onChange={this.setPage}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect()(UserVirtualAccounts);
