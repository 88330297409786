import React from 'react';
import { Route, Switch } from 'react-router-dom';

import List from './Refunds/List';
import Detail from './Refunds/Detail';

function Refunds() {
  return (
    <Switch>
      <Route exact path="/refunds" component={List} />
      <Route exact path="/refunds/:id" component={Detail} />
    </Switch>
  );
}

export default Refunds;
