import AdminDashboards from '../components/AdminDashboards';
import AgentCancellations from '../components/AgentCancellations';
import AgentSettlements from '../components/AgentSettlements';
import AllPayments from '../components/AllPayments';
import AllTransactions from '../components/AllTransactions';
import AllVendRequests from '../components/AllVendRequests';
import ApprovedTransactions from '../components/ApprovedTransactions';
import Businesses from '../components/Businesses';
import Campaigns from '../components/Campaigns';
import CheckMeter from '../components/CheckMeter';
// import Unviewed from '../components/Unviewed';
import Config from '../components/Config';
import CreditMerchant from '../components/CreditMerchant';
import Dashboard from '../components/Dashboard';
import Facilities from '../components/Facilities';
import FacilityVerifications from '../components/FacilityVerification';
// import UntreatedTransactions from '../components/UntreatedTransactions';
import FailedLoginAttempts from '../components/FailedLoginAttempts';
import Gateways from '../components/Gateways';
import GenerateAccount from '../components/GenerateAccount';
// import GenerateToken from '../components/GenerateToken';
import Mcash from '../components/Mcash';
import Merchants from '../components/Merchants';
import MonnifyTransactions from '../components/MonnifyTransactions';
import Orders from '../components/Orders';
import PaymentGateways from '../components/PaymentGateways';
import Payments from '../components/Payments';
import Promotions from '../components/Promotions';
import QMcash from '../components/QMcash';
import Refunds from '../components/Refunds';
import SendPushNotifications from '../components/SendPushNotifications';
import Services from '../components/Services';
import SetSupportSupervisor from '../components/SetSupportSupervisor';
// import Reports from '../components/Reports';
// import AdroitCheques from '../components/AdroitCheques';
// import ApiUsersRefund from '../components/ApiUsersRefund';
import Supervisors from '../components/Supervisors';
import Surveys from '../components/Survery';
import UserSessions from '../components/UserSessions';
import Users from '../components/Users';
import VirtualAccounts from '../components/VirtualAccounts';
import Watchlist from '../components/Watchlist';

const routes = [
  {
    path: '/payments',
    name: 'All Payments',
    icon: 'shopping_credit-card',
    component: AllPayments,
  },
  {
    path: '/transactions',
    name: 'All Transactions',
    icon: 'business_briefcase-24',
    component: AllTransactions,
  },
  {
    path: '/checkmeter',
    name: 'Check Meter',
    icon: 'location_map-big',
    component: CheckMeter,
  },
  {
    path: '/monnify',
    name: 'MONNIFY',
    icon: 'travel_info',
    component: MonnifyTransactions,
  },
  {
    path: '/mcash',
    name: 'MCASH',
    icon: 'business_money-coins',
    component: Mcash,
  },
  { path: '/qmcash', name: 'QMCASH', icon: 'files_box', component: QMcash },
  {
    path: '/genaccount',
    name: 'GENERATE ACCOUNT',
    icon: 'business_money-coins',
    component: GenerateAccount,
  },
  {
    path: '/approved',
    name: 'Approved Transactions',
    icon: 'ui-2_time-alarm',
    component: ApprovedTransactions,
  },
  // {
  //   path: '/disputes',
  //   name: 'Disputes',
  //   icon: 'ui-2_time-alarm',
  //   component: Disputes,
  // },
  // {
  //   path: '/refundables',
  //   name: 'Refundables',
  //   icon: 'ui-2_time-alarm',
  //   component: Refundables,
  // },
  {
    path: '/refunds',
    name: 'Refunds',
    icon: 'ui-2_time-alarm',
    component: Refunds,
  },
  // {
  //   path: '/untreated',
  //   name: 'Untreated Transactions',
  //   icon: 'ui-2_time-alarm',
  //   component: UntreatedTransactions,
  // },
  {
    path: '/users',
    name: 'All Users',
    icon: 'users_single-02',
    component: Users,
  },
  {
    path: '/supervisors',
    name: 'All Supervisors',
    icon: 'users_single-02',
    component: Supervisors,
  },
  {
    path: '/businesses',
    name: 'Businesses',
    icon: 'users_single-02',
    // isFinanceComponent: true,
    // isRestrictedComponent: true,
    component: Businesses,
  },
  {
    path: '/failedlogins',
    name: 'Failed Login Attempts',
    icon: 'users_single-02',
    component: FailedLoginAttempts,
  },
  {
    path: '/sessions',
    name: 'User Sessions',
    icon: 'gestures_tap-01',
    component: UserSessions,
  },
  {
    path: '/payment-gateways',
    name: 'Payment Gateways',
    icon: 'gestures_tap-01',
    isFinanceComponent: true,
    component: PaymentGateways,
  },
  {
    path: '/services',
    name: 'Services',
    icon: 'gestures_tap-01',
    isRestrictedComponent: true,
    component: Services,
  },
  {
    path: '/gateways',
    name: 'Gateways',
    icon: 'gestures_tap-01',
    isRestrictedComponent: true,
    component: Gateways,
  },
  {
    path: '/orders',
    name: 'Orders',
    icon: 'shopping_credit-card',
    component: Orders,
  },
  {
    path: '/requests',
    name: 'Vend Requests',
    icon: 'shopping_credit-card',
    component: AllVendRequests,
  },
  {
    path: '/all-payments',
    name: 'Payments',
    icon: 'shopping_credit-card',
    component: Payments,
  },
  {
    path: '/promotions',
    name: 'Promotions',
    icon: 'business_money-coins',
    resource: 'promotions',
    checkACL: true,
    component: Promotions,
  },
  {
    path: '/virtual-accounts',
    name: 'Virtual Accounts',
    icon: 'gestures_tap-01',
    component: VirtualAccounts,
  },
  {
    path: '/campaigns',
    name: 'Campaigns',
    icon: 'gestures_tap-01',
    component: Campaigns,
  },
  {
    path: '/surveys',
    name: 'Surveys',
    icon: 'gestures_tap-01',
    component: Surveys,
  },
  {
    path: '/watchlists',
    name: 'Watchlists',
    icon: 'gestures_tap-01',
    component: Watchlist,
  },
  // {
  //   path: '/unviewed',
  //   name: 'Unviewed Tokens',
  //   icon: 'business_bulb-63',
  //   component: Unviewed,
  // },
  // {
  //   path: '/generate',
  //   name: 'Generate Token',
  //   icon: 'business_briefcase-24',
  //   component: GenerateToken,
  // },
  {
    path: '/merchant-credit',
    name: 'Merchant Credit',
    checkACL: false,
    resource: 'merchant-credit',
    isFinanceComponent: true,
    icon: 'business_briefcase-24',
    component: CreditMerchant,
  },
  {
    path: '/send-notification',
    name: 'Send Push Notification',
    checkACL: false,
    icon: 'business_briefcase-24',
    component: SendPushNotifications,
  },
  {
    path: '/settlements',
    name: 'Agent Settlements',
    isFinanceComponent: true,
    icon: 'travel_info',
    component: AgentSettlements,
  },
  {
    path: '/cancellations',
    name: 'Agent Cancellations',
    isFinanceComponent: true,
    icon: 'travel_info',
    component: AgentCancellations,
  },
  {
    path: '/supervisor',
    name: 'Set Supervisor',
    isSupportComponent: true,
    icon: 'travel_info',
    component: SetSupportSupervisor,
  },
  {
    path: '/merchants',
    name: 'Merchants',
    isRestrictedComponent: true,
    icon: 'users_single-02',
    component: Merchants,
  },
  // {
  //   path: '/adroitcheque',
  //   name: 'Adroit Cheques',
  //   isFinanceComponent: true,
  //   icon: 'ui-2_time-alarm',
  //   component: AdroitCheques,
  // },
  // {
  //   path: '/creditapiuser',
  //   name: 'Credit API User',
  //   isRestrictedComponent: true,
  //   icon: 'travel_info',
  //   component: ApiUsersRefund,
  // },
  // {
  //   path: '/reports',
  //   name: 'Generate Reports',
  //   icon: 'travel_info',
  //   component: Reports,
  // },
  {
    path: '/config',
    name: 'Config',
    isRestrictedSupportComponent: true,
    icon: 'files_box',
    component: Config,
  },
  {
    path: '/dashboards',
    name: 'Admin Dashboards',
    isRestrictedComponent: true,
    icon: 'files_box',
    component: AdminDashboards,
  },
  {
    path: '/facility-verifications',
    name: 'Facility Verifications',
    icon: 'files_box',
    component: FacilityVerifications,
  },
  {
    path: '/facilities',
    name: 'Facilities',
    icon: 'files_box',
    component: Facilities,
  },
  {
    path: '/',
    name: 'Insights',
    icon: 'files_box',
    component: Dashboard,
  },
];

export default routes;
