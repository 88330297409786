import axiosInstance from './axios.instance';

export default {
  async createPromotion(payload) {
    const response = await axiosInstance
      .post('/promotions', payload)
      .then(({ data }) => {
        return data;
      });
    return response;
  },

  async getPromotionById(id) {
    const response = await axiosInstance
      .get(`/promotions/${id}`)
      .then(({ data }) => {
        return data;
      });

    return response;
  },

  async updatePromotion(id, payload) {
    const response = await axiosInstance
      .patch(`/promotions/${id}`, payload)
      .then(({ data }) => {
        return data;
      });

    return response;
  },

  async updatePromotionPublication(id, payload) {
    const response = await axiosInstance
      .patch(`/promotions/${id}/publication`, payload)
      .then(({ data }) => {
        return data;
      });

    return response;
  },
};
