import React, { useContext } from 'react';

import { Row } from 'reactstrap';
import { ServiceContext } from './';

function Settings() {
  const { service } = useContext(ServiceContext);

  return <Row>{JSON.stringify(service)}</Row>;
}

export default Settings;
