import React from 'react';
import { Row, Col, Card, CardHeader, CardTitle, CardBody } from 'reactstrap';
import Loading from '../Loading';

export function Insight(props) {
  const { facility = {}, loading } = props;

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <Row>
          <Col sm="6">
            {/* <Col> */}
            <Card className="card-chart">
              <CardHeader>
                <CardTitle className="mt-0">Facility</CardTitle>
              </CardHeader>
              <CardBody>
                <div className="d-flex mb-3 justify-content-between">
                  <div>
                    <span className="text-secondary">Meter No.</span>
                  </div>
                  <span>
                    <span>
                      <strong>{facility.reference}</strong>
                    </span>
                  </span>
                </div>
                <div className="d-flex mb-3 justify-content-between">
                  <div>
                    <span className="text-secondary">Owner</span>
                  </div>
                  <div> {facility.owner} </div>
                </div>
                <div className="d-flex mb-3 justify-content-between">
                  <div>
                    <span className="text-secondary">Address</span>
                  </div>
                  <div> {facility.address} </div>
                </div>
                <div className="d-flex mb-3 justify-content-between">
                  <div>
                    <span className="text-secondary">Asset Provider</span>
                  </div>
                  <div> {facility.asset_provider} </div>
                </div>
                <div className="d-flex mb-3 justify-content-between">
                  <div>
                    <span className="text-secondary">Service Provider</span>
                  </div>
                  <div> {facility.service_provider} </div>
                </div>
                <div className="d-flex mb-3 justify-content-between">
                  <div>
                    <span className="text-secondary">Type</span>
                  </div>
                  <div> {facility.type} </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
}
