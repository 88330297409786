import React from 'react';
import { Route, Switch } from 'react-router-dom';

import List from './PaymentGateways/List';
import Detail from './PaymentGateways/Detail';

function Refunds() {
  return (
    <Switch>
      <Route exact path="/payment-gateways" component={List} />
      <Route path="/payment-gateways/:id" component={Detail} />
    </Switch>
  );
}

export default Refunds;
