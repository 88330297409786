import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Table,
  Button,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
} from 'reactstrap';
import {
  previewEntity,
  openGeoCodeAddressModal,
} from '../../actions/modalActions';

import { showError, showInfo } from '../../actions/notificationActions';

import PreviewEntityModal from '../PreviewEntityModal';
import GeoAddressModal from '../GeoAddressModal';
import Maps from '../../views/Maps/Maps';
import { callApi } from '../../utils';

function VerificationAsset(props) {
  const { assets = [], facility } = props;
  const [asset, setAsset] = useState({});
  const [addressLoading, setAddressLoading] = useState(false);

  useEffect(() => {
    if (asset.id) {
      props.dispatch(
        previewEntity({
          entity: asset,
          // btnText: 'Open Vend Request',
          close: () => {
            setAsset({});
          },
          // view: () => {
          //   dispatch(closeAllModals());
          //   props.history.push(`/vend-requests/${currentVend.id}`);
          // },
        })
      );
    }
  }, [asset.id]);
  // const showTokenResponse = (ref) => {
  //   dispatch(showTokenResponseModal({ id: ref }));
  // };

  const getAddressFromGeoCode = async (latlngValue) => {
    const lng = latlngValue.split(',')[0];
    const lat = latlngValue.split(',')[1];

    // console.log(lat, lng);
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyAZjpak5H9kKTl0NXlU-QtHjADW_OQ7Dv0`;

    try {
      const res = await callApi(url);
      // console.log('Geo: => ', res.results);

      return res.results;
    } catch (error) {
      props.dispatch(showError(error));
      console.log(error);
    }
  };

  const updateFacilityAddress = (address, closeLoading) => {
    const { id } = facility;

    callApi(`/facility/${id}/address`, { address }, 'put')
      .then((res) => {
        props.dispatch(showInfo(res.message));
        closeLoading();
      })
      .catch((err) => {
        props.dispatch(showError(err));
        closeLoading();
      });
  };

  const processAddress = async (latlngValue) => {
    try {
      setAddressLoading(true);
      const address = await getAddressFromGeoCode(latlngValue);

      props.dispatch(openGeoCodeAddressModal(address[0]));
    } catch (error) {
      setAddressLoading(false);
      props.dispatch(showError(error));
    }
  };

  return (
    <Card className="card-chart">
      <CardHeader className="d-flex align-items-center justify-content-between">
        <CardTitle className="mt-0">Facility Verification Assets</CardTitle>
      </CardHeader>
      <PreviewEntityModal>
        <AssetDisplay asset={asset} />
      </PreviewEntityModal>
      <GeoAddressModal updateFacilityAddress={updateFacilityAddress} />
      <CardBody>
        {
          <Table responsive hover striped>
            <thead className="text-primary">
              <tr>
                <th>Type</th>
                <th>Value</th>
                {/* <th>Update Address</th> */}
              </tr>
            </thead>

            <tbody>
              {assets.length ? (
                assets.map((a, i) => (
                  <tr key={a.id}>
                    {/* <td>{loading ? 'x' : i + 1 + (page - 1) * limit}</td> */}
                    <td>{a.type}</td>
                    <td>
                      <span style={{ fontSize: '13px' }}>{a.value}</span>
                    </td>
                    <td>
                      {a.type === 'GEO' ? (
                        <Button
                          size="sm"
                          disabled={addressLoading}
                          onClick={() => processAddress(a.value)}
                          color="primary"
                        >
                          Update Address
                        </Button>
                      ) : (
                        ''
                      )}
                    </td>
                    <td>
                      <Button
                        size="sm"
                        color="success"
                        onClick={() => setAsset(a)}
                      >
                        Preview
                      </Button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="11" className="text-center">
                    No assets for this verification.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        }
      </CardBody>
    </Card>
  );
}

function AssetDisplay({ asset = {} }) {
  return (
    <React.Fragment>
      {asset.type === 'IMAGE' ? (
        <div
          className="image"
          style={{
            background: `url(${asset.value})`,
            backgroundSize: '700px, 900px cover',
            backgroundRepeat: 'no-repeat',
            minWidth: '700px',
          }}
        />
      ) : (
        asset.value && (
          <Maps
            latValue={asset.value ? asset.value.split(',')[0] : null}
            lngValue={asset.value ? asset.value.split(',')[1] : null}
          />
        )
      )}
    </React.Fragment>
  );
}

export default connect()(VerificationAsset);
