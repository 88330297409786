import React from 'react';
import * as moment from 'moment';

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
} from 'reactstrap';
// react plugin used to create charts

import { Bar, Doughnut } from 'react-chartjs-2';
// function that returns a color based on an interval of numbers

import { PanelHeader, Stats, CardCategory } from 'components';

import {
  servicePerformanceChart,
  dashboardOrderTimelineChart,
} from '../variables/charts.jsx';

import { callApi } from '../utils.js';
import { showError } from '../actions/notificationActions.js';
import { connect } from 'react-redux';

class Dashboard extends React.Component {
  state = {
    timeline: null,
    performances: [],
    loading: true,
  };

  componentDidMount() {
    callApi('/timeline/order')
      .then((res) => {
        const labels = [];

        const loader = (row) => {
          const m = moment(row.head, 'YYYY-MM-DD');

          if (!labels.includes(m.format('Do MM'))) {
            labels.push(m.format('Do MM'));
          }
          return row.occurence;
        };

        const successful = res.data.success.map(loader);
        const failed = res.data.failed.map(loader);
        const pending = res.data.pending.map(loader);

        const timeline = dashboardOrderTimelineChart(
          { successful, pending, failed },
          labels
        );

        this.setState({ loading: false, timeline });
      })
      .catch((err) => {
        this.setState({ loading: false });
        this.props.dispatch(showError(err));
      });

    callApi('/performance/service')
      .then((res) => {
        const performances = [];

        for (const key in res.data) {
          performances.push(servicePerformanceChart(res.data[key]));
        }

        this.setState({ loading: false, performances });
      })
      .catch((err) => {
        console.debug(err);

        this.setState({ loading: false });
        this.props.dispatch(showError(err));
      });
  }

  render() {
    const { performances, timeline } = this.state;
    return (
      <div>
        <PanelHeader
          size="lg"
          content={
            timeline ? (
              <Bar data={timeline.data} options={timeline.options} />
            ) : (
              ''
            )
          }
        />
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <Card className="card-chart">
                <CardHeader>
                  <CardCategory>Performance</CardCategory>
                  <CardTitle>All Services</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    {!!performances.length &&
                      performances.map((performance) => {
                        return (
                          <Col key={Math.random()} xs={12} md={4}>
                            <Doughnut
                              data={performance.data}
                              options={performance.options}
                            />
                          </Col>
                        );
                      })}
                  </Row>
                </CardBody>
                <CardFooter>
                  <Stats>
                    {[
                      {
                        i: 'now-ui-icons arrows-1_refresh-69',
                        t: 'Just Updated',
                      },
                    ]}
                  </Stats>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default connect()(Dashboard);
