import {
  CLEAR_NOTIFICATIONS,
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
} from '../actions/notificationActions';

const initNotificationState = [];

export default (state = initNotificationState, action) => {
  switch (action.type) {
    case CLEAR_NOTIFICATIONS:
      return initNotificationState;

    case ADD_NOTIFICATION:
      return state.concat(action).slice(0, 3);

    case REMOVE_NOTIFICATION:
      return state.filter((n) => n.uid !== action.uid);

    default:
      return state;
  }
};
