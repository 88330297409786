import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
// import { callApi } from '../utils';

// import A from '../analytics';
// import { logout } from '../actions/authActions';

// This component renders nothing and is just a utility with access to Router events and Redux state
class UtilsComponent extends React.Component {
  componentWillMount() {
    // this.checkAuth();
  }

  componentDidMount() {}

  shouldComponentUpdate(nextProps) {
    return false;
  }

  // checkAuth() {
  //   callApi('/me')
  //     .then(res => res)
  //     .catch(err => {
  //       if (err.status === 401 && this.props.auth.authenticated) {
  //         this.props.dispatch(logout(false));
  //       }
  //     });
  // }

  render() {
    return null;
  }
}

export default withRouter(connect((state) => ({ ...state }))(UtilsComponent));
