import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Input,
  ModalFooter,
  Button,
} from 'reactstrap';

export function IncidentModal(props) {
  const defaultParams = {
    message: '',
    notify: true,
    templateKey: '',
    event: '',
  };

  const {
    onDone,
    toggle,
    isOpen,
    title,
    primaryActionLabel,
    templateInputLabel,
    onSubmit,
    messageTemplates = [],
    events = [],
    ...rest
  } = props;

  const [params, setParams] = useState(defaultParams);
  const [error, setError] = useState(null);
  const [busy, setBusy] = useState(false);

  const handleInputChange = (e) => {
    const input = e.target;

    const value = input.type === 'checkbox' ? input.checked : input.value;

    setParams({
      ...params,
      [input.name]: value,
    });
  };

  const submit = async (params) => {
    if (error) setError(null);
    if (!busy) setBusy(true);

    try {
      await onSubmit(params);
      setParams(defaultParams);
    } catch (err) {
      setError(err);
    } finally {
      setBusy(false);
    }
  };

  useEffect(() => {
    const template = messageTemplates.find(
      (item) => item.key === params.templateKey
    );

    if (template) {
      setParams({
        ...params,
        message: template.message,
      });
    }
  }, [params.templateKey]);

  return (
    <Modal {...rest} isOpen={isOpen}>
      <ModalHeader tag="h6" toggle={toggle}>
        {title}
      </ModalHeader>
      <ModalBody>
        {!!messageTemplates.length && (
          <FormGroup>
            <Label for="templateKey">
              {templateInputLabel || 'Select message template'}
            </Label>
            <Input
              id="templateKey"
              name="templateKey"
              type="select"
              value={params.templateKey}
              onChange={(e) => handleInputChange(e)}
              disabled={busy}
            >
              <option value={''}>Unknown</option>
              {messageTemplates.map((item) => (
                <option key={item.key} value={item.key}>
                  {item.label}
                </option>
              ))}
            </Input>
          </FormGroup>
        )}
        {!!events.length && (
          <FormGroup>
            <Label for="event">
              {templateInputLabel || 'Select message template'}
            </Label>
            <Input
              id="event"
              name="event"
              type="select"
              value={params.event}
              onChange={(e) => handleInputChange(e)}
              disabled={busy}
            >
              <option value={''}>Select an event</option>
              {events.map((item) => (
                <option key={item.key} value={item.key}>
                  {item.label}
                </option>
              ))}
            </Input>
          </FormGroup>
        )}
        <FormGroup>
          <Label for="message">Message</Label>
          <Input
            id="message"
            name="message"
            type="textarea"
            value={params.message}
            onChange={(e) => handleInputChange(e)}
            autoFocus
            disabled={busy}
          />
        </FormGroup>
        <FormGroup>
          <Label check>
            <Input
              onChange={(e) => handleInputChange(e)}
              checked={params.notify}
              name="notify"
              type="checkbox"
            />{' '}
            {'Notify partners.'}
          </Label>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        {busy && <div>Submitting message...</div>}
        {error && <div className="text-danger">{error.message}</div>}
        <Button
          onClick={() => submit(params)}
          disabled={busy}
          className={'btn-sm btn-primary'}
        >
          {primaryActionLabel}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
