import React from 'react';
import { Route, Switch } from 'react-router-dom';

import List from './Facilities/List';
import Detail from './Facilities/Detail';

function Facilities() {
  return (
    <Switch>
      <Route exact path="/facilities" component={List} />
      <Route path="/facilities/:id" component={Detail} />
    </Switch>
  );
}

export default Facilities;
