import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  Button,
} from 'reactstrap';
import { callApi } from '../../utils';
import { showError, showInfo } from '../../actions/notificationActions';

class EditMerchantCommissionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cap: this.props.commission.cap,
      commissionId: this.props.commission.id,
      flat: this.props.commission.flat,
      key: this.props.commission.key,
      rate: this.props.commission.rate,
      type: this.props.type || 'individual',
      loading: false,
    };
  }

  setInput = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  updateCommission = (e) => {
    e.preventDefault();

    if (this.state.loading) return;

    const prompt = `Are you sure you want to submit?`;

    if (!window.confirm(prompt)) return;

    this.setState({ loading: true });

    callApi(
      this.state.type === 'default'
        ? `/merchants/commissions/${this.state.commissionId}`
        : `/merchants/${this.props.merchantId}/commissions/${this.state.commissionId}`,
      {
        rate: this.state.rate,
        cap: this.state.cap,
        flat: this.state.flat,
      },
      'put'
    )
      .then((res) => {
        this.props.close();
        this.props.dispatch(showInfo(res.message));
        this.props.refreshCommission();
      })
      .catch((err) => {
        this.props.dispatch(showError(err));
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { key, cap, rate, flat, loading } = this.state;

    return (
      <Modal
        size="lg"
        isOpen={this.props.open}
        toggle={this.props.close}
        backdrop="static"
      >
        <ModalHeader toggle={this.props.close}>{key}</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <FormGroup>
                <Label>Rate</Label>
                <Input
                  onChange={this.setInput}
                  value={rate}
                  name="rate"
                  placeholder="Commission Rate"
                  type="number"
                  required
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>Cap</Label>
                <Input
                  onChange={this.setInput}
                  value={cap}
                  name="cap"
                  placeholder="Cap"
                  type="number"
                  required
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>Flat</Label>
                <Input
                  onChange={this.setInput}
                  value={flat}
                  name="flat"
                  placeholder="Flat"
                  type="number"
                  required
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            type="submit"
            onClick={this.updateCommission}
            disabled={loading}
          >
            {loading ? 'Submitting...' : 'Submit'}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default connect()(EditMerchantCommissionModal);
