import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from 'reactstrap';

import { closeAllModals } from '../actions/modalActions';
import { formatAmount, formatDate, formatToken } from '../utils';
import ClickToCopy from './ClickToCopy';

export default connect((state) => ({
  open: state.modals.vendResponse,
  data: state.modals.data,
  loading: state.modals.loading,
}))(
  class VendResponseModal extends Component {
    state = {
      resellLoading: false,
    };

    componentDidUpdate(prevProps) {
      if (prevProps.open !== this.props.open && this.props.open) {
        this.setState({
          fields: this.props.data.fields || [],
          params: {},
        });
      }
    }

    close = () => {
      this.props.data.close && this.props.data.close();
      this.props.dispatch(closeAllModals());
    };

    render() {
      const { data = {} } = this.props;
      console.log('props at modal is', this.props);
      let vendResponse = null;
      if (data) {
        vendResponse = data.vendResponse;
      }

      const loading = this.props.loading;
      const open = this.props.open;

      return (
        <div>
          <Modal size="lg" isOpen={open} toggle={this.close}>
            <ModalHeader toggle={this.close}>Vend Response</ModalHeader>
            <ModalBody>
              <Row>
                <Col sm="12">
                  <Row>
                    <Col sm="6">
                      {loading ? (
                        <span className="text-secondary">Loading...</span>
                      ) : vendResponse != null ? (
                        <div>
                          <div className="d-flex mb-3 justify-content-between">
                            <div>
                              <span className="text-secondary">Token</span>
                            </div>
                            <span>
                              <span>{formatToken(vendResponse.token)}</span>{' '}
                              <span>
                                <ClickToCopy
                                  value={formatToken(vendResponse.token)}
                                />
                              </span>
                            </span>
                          </div>
                          <div className="d-flex mb-3 justify-content-between">
                            <div>
                              <span className="text-secondary">Amount</span>
                            </div>
                            <div>{formatAmount(vendResponse.amt)}</div>
                          </div>
                          <div className="d-flex mb-3 justify-content-between">
                            <div>
                              <span className="text-secondary">
                                Vend Amount
                              </span>
                            </div>
                            <div>
                              {formatAmount(vendResponse.amt_electricity)}
                            </div>
                          </div>
                          <div className="d-flex mb-3 justify-content-between">
                            <div>
                              <span className="text-secondary">VAT</span>
                            </div>
                            <span>{formatAmount(vendResponse.tax)}</span>
                          </div>
                          <div className="d-flex mb-3 justify-content-between">
                            <div>
                              <span className="text-secondary">Units</span>
                            </div>
                            {vendResponse.units && (
                              <div> {vendResponse.units} kWh</div>
                            )}
                          </div>
                          <div className="d-flex mb-3 justify-content-between">
                            <div>
                              <span className="text-secondary">Debt</span>
                            </div>
                            <div>{formatAmount(vendResponse.debt_amt)}</div>
                          </div>
                          <div className="d-flex mb-3 justify-content-between">
                            <div>
                              <span className="text-secondary">
                                Transaction Ref
                              </span>
                            </div>
                            {<div>{vendResponse.trans_ref}</div>}
                          </div>
                          <div className="d-flex mb-3 justify-content-between">
                            <div>
                              <span className="text-secondary">
                                Receipt No.
                              </span>
                            </div>
                            {<div>{vendResponse.rct_num}</div>}
                          </div>
                          {/* <div className="d-flex mb-3 justify-content-between">
                                <div>
                                  <span className="text-secondary">
                                    Vend Type
                                  </span>
                                </div>
                                {<div>{vendResponse.vend_type}</div>}
                              </div> */}
                        </div>
                      ) : (
                        <span className="text-secondary">
                          No Vend Response Received.
                        </span>
                      )}
                    </Col>
                    <Col sm="6">
                      {loading ? (
                        <span className="text-secondary">Loading...</span>
                      ) : vendResponse != null ? (
                        <div>
                          <div className="d-flex mb-3 justify-content-between">
                            <div>
                              <span className="text-secondary">Time</span>
                            </div>
                            <div>{formatDate(vendResponse.created_at)}</div>
                          </div>
                          <div className="d-flex mb-3 justify-content-between">
                            <div>
                              <span className="text-secondary">SMS Sent</span>
                            </div>
                            <div>
                              {Boolean(vendResponse.sms_sent) ? 'YES' : 'NO'}
                            </div>
                          </div>
                          <div className="d-flex mb-3 justify-content-between">
                            <div>
                              <span className="text-secondary">Email Sent</span>
                            </div>
                            <div>
                              {Boolean(vendResponse.email_sent) ? 'YES' : 'NO'}
                            </div>
                          </div>
                          <div className="d-flex mb-3 justify-content-between">
                            <div>
                              <span className="text-secondary">Meter</span>
                            </div>
                            <span>{vendResponse.meter}</span>
                          </div>
                          <div className="d-flex mb-3 justify-content-between">
                            <div>
                              <span className="text-secondary">
                                Payment Reference
                              </span>
                            </div>
                            {<div>{vendResponse.payment_reference}</div>}
                          </div>
                          <div className="d-flex mb-3 justify-content-between">
                            <div>
                              <span className="text-secondary">Gateway</span>
                            </div>
                            <div>{vendResponse.evg_gateway}</div>
                          </div>
                          <div className="d-flex mb-3 justify-content-between">
                            <div>
                              <span className="text-secondary">Vend Type</span>
                            </div>
                            {<div>{vendResponse.vend_type}</div>}
                          </div>
                          <div className="d-flex mb-3 justify-content-between">
                            <div>
                              <span className="text-secondary">Order ID</span>
                            </div>
                            {<div>{vendResponse.order_id}</div>}
                          </div>
                        </div>
                      ) : (
                        <span className="text-secondary">
                          No Vend Response Received.
                        </span>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              {/* {data && (
                <Button color="primary" disabled={loading} onClick={data.view}>
                  {data.btnText || 'View Full'}
                </Button>
              )} */}
              <div>Response Message:</div>
              <div>{vendResponse && vendResponse.response_message}</div>
            </ModalFooter>
          </Modal>
        </div>
      );
    }
  }
);
