import React, { useState, useEffect, useContext } from 'react';
import { Table, Button, Card, CardBody } from 'reactstrap';

import { callApi, formatDate, request } from '../../../utils';

import { openTransactionModal } from '../../../actions/modalActions';

import Pagination from '../../Pagination';
import Loading from '../../Loading';
import { ServiceContext } from './';
import { IncidentModal } from './IncidentModal';

function Incidents({ dispatch }) {
  let [limit] = useState(30);
  let [page, setPage] = useState(1);
  let [meta, setMeta] = useState({});
  let [loading, setLoading] = useState(true);
  let [refreshing, setRefreshing] = useState(false);
  let [rows, setRows] = useState([]);
  let [reportModalVisible, setReportModalVisible] = useState(false);
  let [messageTemplates, setMessageTemplates] = useState([]);
  let [events, setEvents] = useState([]);

  const { service } = useContext(ServiceContext);

  const fetchTransactions = async (service) => {
    if (!!service) {
      return callApi(`/services/${service.id}/incidents`, {
        page,
        limit,
      }).then((res) => {
        setRows(res.data);
        setMeta(res.meta || {});
      });
    }
  };

  const fetchTemplateMessages = async () => {
    return request.get('/incident-messages');
  };

  const fetchEvents = async () => {
    return request.get('/incident-events');
  };

  useEffect(() => {
    setLoading(true);
    fetchTransactions(service)
      .then(() => {
        fetchTemplateMessages().then((res) => {
          setMessageTemplates(res.data.data);
        });
      })
      .then(() => {
        fetchEvents().then((res) => {
          setEvents(res.data.data);
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [service, page]);

  if (!service) {
    return <React.Fragment />;
  }

  const openTransaction = (id) => {
    dispatch(openTransactionModal({ id }));
  };

  const refresh = () => {
    setRefreshing(true);
    fetchTransactions().finally(() => {
      setRefreshing(false);
    });
  };

  const showReportModal = () => {
    setReportModalVisible(true);
  };

  const toggleModalVisibility = () => {
    setReportModalVisible(!reportModalVisible);
  };

  const reportIncident = async (data) => {
    const response = await request
      .post(`/services/${service.id}/incidents`, {
        ...data,
      })
      .then((res) => {
        setRows([res.data, ...rows]);
        setMeta({ ...meta, total: meta.total + 1 });
      });
    toggleModalVisibility();
    return response;
  };

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <Button disabled={refreshing || loading} onClick={refresh}>
            Refresh
          </Button>
          <Button disabled={refreshing || loading} onClick={showReportModal}>
            Report
          </Button>
          {loading ? (
            <Loading />
          ) : (
            <Table responsive hover striped>
              <thead className="text-primary">
                <tr>
                  <th>Date</th>
                  <th>Event</th>
                  <th>Message</th>
                  <th>Reporter</th>
                </tr>
              </thead>

              <tbody>
                {!!rows.length ? (
                  rows.map((t) => (
                    <tr onClick={() => openTransaction(t.order_id)} key={t.id}>
                      <td>{formatDate(t.created_at)}</td>
                      <td>{t.event}</td>
                      <td>{t.comment}</td>
                      <td>{t.reporter.name}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={12} className="text-center">
                      No records
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          )}
          <Pagination
            page={page}
            total={meta.count}
            onChange={(page) => setPage(page)}
          />
        </CardBody>
      </Card>
      <IncidentModal
        templateInputLabel={'Message Template'}
        title={'Report an Incident'}
        primaryActionLabel={'Report Incident'}
        isOpen={reportModalVisible}
        toggle={toggleModalVisibility}
        onSubmit={reportIncident}
        events={events}
        messageTemplates={messageTemplates}
      />
    </React.Fragment>
  );
}

export default Incidents;
