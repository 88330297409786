import React from 'react';
import { Route, Switch } from 'react-router-dom';
import List from './Campaigns/List';
import Detail from './Campaigns/Detail';

function Campaigns() {
  return (
    <Switch>
      <Route exact path="/campaigns" component={List} />
      <Route path="/campaigns/:id" component={Detail} />
    </Switch>
  );
}

export default Campaigns;
