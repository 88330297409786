import React, { Component } from 'react';
import { connect } from 'react-redux';
import { callApi } from '../utils';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
  Button,
} from 'reactstrap';

import { closeAllModals } from '../actions/modalActions';
import { showError, showInfo } from '../actions/notificationActions';

export default connect((state) => ({
  open: state.modals.voucherCreate,
}))(
  class CreateVoucherModal extends Component {
    state = {
      loading: false,
      phone: '',
      meter: '',
      disco: '',
      provider: '',
      error: '',
      name: '',
      promotions: [],
      promotion_id: null,
      usages: null,
    };

    componentDidMount() {
      this.getPromotions();
    }

    setInput = (e) => {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    };

    close = () => {
      this.props.dispatch(closeAllModals());
    };

    getPromotions = () => {
      callApi(`/promotions`)
        .then((res) => {
          this.setState({ promotions: res.data });
          console.log('Promotions ', res);
          //   this.props.virtualAcctResponse(res.data);
          // alert(res.message);
          // this.props.refresh(res.data);
          // this.close();
        })
        .catch((err) => {
          if ((err.status = 'unprocessable'))
            this.props.dispatch(showError(err.message));
          this.props.dispatch(showError(err));
        });
    };

    submit = (e) => {
      e.preventDefault();

      const promotion_id = window.location.pathname.split('/')[2];

      if (!promotion_id || !this.state.usages)
        return this.setState({ error: 'Please fill in all fields!' });

      this.setState({ error: '' });

      let bodyObj = {
        usages: this.state.usages,
      };

      // Create Voucher
      callApi(`/promotions/${promotion_id}/vouchers`, bodyObj, 'post')
        .then((res) => {
          this.setState({ loading: false });
          this.props.dispatch(showInfo(res.message));
          this.props.reload();
          this.close();
        })
        .catch((err) => {
          this.setState({ loading: false });
          this.props.dispatch(showError(err.message));
        });
    };

    render() {
      const { loading, name, usages } = this.state;

      const open = this.props.open;
      return (
        <div>
          <Modal size="lg" isOpen={open} toggle={this.close}>
            <ModalHeader toggle={this.close}>Create Voucher</ModalHeader>
            <ModalBody>
              <form>
                <div>
                  <FormGroup>
                    <Label className="mt-3">Code</Label>
                    <Input
                      name="code"
                      type="text"
                      onChange={this.setInput}
                      value={name}
                      placeholder="Discount code"
                      disabled={loading}
                    />

                    <Label className="mt-3">Usages</Label>
                    <Input
                      name="usages"
                      type="number"
                      onChange={this.setInput}
                      value={usages}
                      placeholder="Maximum number of customers"
                      disabled={loading}
                    />
                  </FormGroup>
                </div>
                <div />
              </form>
              {this.state.error ? (
                <span style={{ color: 'red' }}>{this.state.error}</span>
              ) : (
                ''
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="success" disabled={loading} onClick={this.submit}>
                Create
                {loading ? (
                  <span className="fas fa-spinner fa-spin"></span>
                ) : (
                  ''
                )}
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      );
    }
  }
);
