import moment from 'moment';
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import React, { Component } from 'react';
import JsonViewer from './JsonViewer';
import promotionService from '../services/promotion.service';

import { closeAllModals } from '../actions/modalActions';
import { connect } from 'react-redux';
import { showInfo } from '../actions/notificationActions';

export default connect((state) => ({
  open: state.modals.createPromotion,
}))(
  class CreatePromotionModal extends Component {
    state = {
      loading: false,
      label: '',
      code: '',
      channels: ['Web', 'Mobile'],
      channel: 'Mobile',
      startDate: '',
      endDate: '',
      error: '',
      description: '',
      type: 'RATE',
      interestType: ['RATE', 'FLAT'],
      usages: 0,
      valueTypes: ['bonus', 'cashback', 'discount'],
      valueType: 'discount',
      budget: 0,
      automatic: ['Yes', 'No'],
      auto: null,
      // promoIsOpen: ['Yes', 'No'],
      // isOpen: null,
      amount: 0,
      appliesTo: ['vend amount', 'convenience fee'],
      applyTo: 'vend amount',
      appliesWhen: ['Payment', 'Order'],
      applyWhen: 'order',
      conditions: '',
    };

    setInput = (e) => {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    };

    setConditionValues = (value) => {
      this.setState({ conditions: value });
    };

    handleSelectChange = (e) => {
      const { name, value } = e.target;
      const booleanValue = value === 'yes' ? true : false;

      this.setState({
        [name]: booleanValue,
      });
    };

    handleStartDateChange = (e) => {
      this.setState({ startDate: e.target.value }, this.validateDates);
    };

    handleEndDateChange = (e) => {
      this.setState({ endDate: e.target.value }, this.validateDates);
    };

    validateDates = () => {
      const { startDate, endDate } = this.state;

      if (startDate && endDate) {
        const start = moment(startDate);
        const end = moment(endDate);

        if (start.isAfter(end)) {
          return this.setState({
            error: 'Start date cannot be older than End date',
          });
        }
      }
    };

    close = () => {
      this.props.dispatch(closeAllModals());
    };

    submit = (e) => {
      e.preventDefault();
      if (
        !this.state.label ||
        !this.state.code ||
        !this.state.type ||
        !this.state.amount ||
        !this.state.startDate ||
        !this.state.endDate ||
        !this.state.description ||
        !this.state.applyWhen ||
        !this.state.valueType
      )
        return this.setState({
          error:
            'Please fill in the name, code, type, amount fields, and Applies When',
        });

      this.setState({ error: '' });

      let bodyObj = {
        label: this.state.label,
        code: this.state.code,
        channels: this.state.channel,
        from: this.state.startDate,
        to: this.state.endDate,
        description: this.state.description,
        type: this.state.type,
        usages: this.state.usages,
        value_type: this.state.valueType,
        budget: this.state.budget,
        auto: this.state.auto,
        // open: this.state.isOpen,
        amount: this.state.amount,
        applies_to: this.state.applyTo,
        applies_when: this.state.applyWhen,
        conditions: this.state.conditions,
      };

      this.setState({ loading: true });

      promotionService
        .createPromotion(bodyObj)
        .then((res) => {
          this.setState({ loading: false });
          this.props.dispatch(showInfo(res.message, 'Success'));
          this.close();
          this.props.dispatch(closeAllModals());
          this.props.refresh();
        })
        .catch((error) => {
          this.setState({ loading: false });
          alert(error);
        });
    };

    render() {
      const {
        label,
        loading,
        endDate,
        startDate,
        code,
        amount,
        description,
        usage,
        applyTo,
        budget,
        type,
        valueType,
        conditions,
        appliesWhen,
        applyWhen,
        channel,
      } = this.state;

      const openModal = this.props.open;
      return (
        <div>
          <Modal size="lg" isOpen={openModal} toggle={this.close}>
            <ModalHeader toggle={this.close}>Create promotion</ModalHeader>
            <ModalBody>
              <form>
                <div>
                  <Row>
                    <Col md={6} xs={12}>
                      <FormGroup>
                        <Label className="mt-3">
                          <span style={{ color: 'red' }}>*</span> Name:
                        </Label>
                        <Input
                          name="label"
                          type="text"
                          onChange={this.setInput}
                          value={label}
                          disabled={loading}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6} xs={12}>
                      <FormGroup>
                        <Label className="mt-3">
                          <span style={{ color: 'red' }}>*</span> Code:
                        </Label>
                        <Input
                          name="code"
                          type="text"
                          onChange={this.setInput}
                          value={code}
                          disabled={loading}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} xs={12}>
                      <FormGroup>
                        <Label for="type">Channel:</Label>
                        <Input
                          name="channel"
                          type="select"
                          onChange={this.setInput}
                          value={channel}
                        >
                          <option value={''}>Please select</option>
                          {this.state.channels.map((channel, index) => (
                            <option key={index} value={channel.toLowerCase()}>
                              {channel}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label className="mt-3">
                          <span style={{ color: 'red' }}>*</span> Automatic:
                        </Label>
                        <Input
                          type="select"
                          name="auto"
                          onChange={this.handleSelectChange}
                          disabled={loading}
                        >
                          <option value={''}>Please select</option>
                          {this.state.automatic.map((auto, index) => (
                            <option key={index} value={auto.toLowerCase()}>
                              {auto}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6} xs={12}>
                      <FormGroup>
                        <Label className="mt-3">
                          <span style={{ color: 'red' }}>*</span> Type:
                        </Label>
                        <Input
                          name="type"
                          type="select"
                          placeholder="eg: RATE or FLAT"
                          onChange={this.setInput}
                          value={type}
                          disabled={loading}
                        >
                          <option value={''}></option>
                          {this.state.interestType.map((type, index) => (
                            <option key={index} value={type}>
                              {type}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md={6} xs={12}>
                      <FormGroup>
                        <Label className="mt-3">Usage:</Label>
                        <Input
                          name="usage"
                          type="number"
                          onChange={this.setInput}
                          value={usage}
                          disabled={loading}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6} xs={12}>
                      <FormGroup>
                        <Label className="mt-3">
                          <span style={{ color: 'red' }}>*</span> Amount:
                        </Label>
                        <Input
                          name="amount"
                          type="number"
                          onChange={this.setInput}
                          value={amount}
                          disabled={loading}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6} xs={12}>
                      <FormGroup>
                        <Label className="mt-3">Budget:</Label>
                        <Input
                          name="budget"
                          type="number"
                          onChange={this.setInput}
                          value={budget}
                          disabled={loading}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} xs={12}>
                      <FormGroup>
                        <Label className="mt-3">
                          <span style={{ color: 'red' }}>*</span> Start Date:
                        </Label>
                        <Input
                          name="startDate"
                          type="datetime-local"
                          onChange={this.handleStartDateChange}
                          value={startDate}
                          disabled={loading}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6} xs={12}>
                      <FormGroup>
                        <Label className="mt-3">
                          <span style={{ color: 'red' }}>*</span> End Date:
                        </Label>
                        <Input
                          name="endDate"
                          type="datetime-local"
                          onChange={this.handleEndDateChange}
                          value={endDate}
                          disabled={loading}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6} xs={12}>
                      <FormGroup>
                        <Label className="mt-3">
                          <span style={{ color: 'red' }}>*</span> Applies To:
                        </Label>
                        <Input
                          name="applyTo"
                          type="select"
                          onChange={this.setInput}
                          value={applyTo}
                          disabled={loading}
                        >
                          <option value={''}></option>
                          {this.state.appliesTo.map((applyTo, index) => (
                            <option key={index} value={applyTo}>
                              {applyTo}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md={6} xs={12}>
                      <FormGroup>
                        <Label className="mt-3">
                          <span style={{ color: 'red' }}>*</span> Applies When:
                        </Label>
                        <Input
                          name="applyWhen"
                          type="select"
                          onChange={this.setInput}
                          value={applyWhen}
                          disabled={loading}
                        >
                          <option value={''}></option>
                          {appliesWhen.map((applyWhen, index) => (
                            <option
                              key={index}
                              value={applyWhen.toLocaleLowerCase()}
                            >
                              {applyWhen}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6} xs={12}>
                      <FormGroup>
                        <Label className="mt-3">Value Type:</Label>
                        <Input
                          name="valueType"
                          type="select"
                          onChange={this.setInput}
                          value={valueType}
                          disabled={loading}
                        >
                          <option value={''}></option>
                          {this.state.valueTypes.map((type, index) => (
                            <option key={index} value={type}>
                              {type}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>

                  <FormGroup>
                    <Label className="mt-3">
                      <span style={{ color: 'red' }}>*</span> Description:
                    </Label>
                    <Input
                      name="description"
                      type="textarea"
                      onChange={this.setInput}
                      value={description}
                      disabled={loading}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label for="type">Conditions</Label>
                    <JsonViewer
                      src={conditions || {}}
                      name="conditions"
                      displayDataTypes={false}
                      displayObjectSize={false}
                      shouldCollapse={({ type }) => type === 'array'} // collapse root array but not internal objects
                      enableClipboard={false}
                      iconStyle={'circle'}
                      onEdit={({ updated_src }) =>
                        this.setConditionValues(updated_src)
                      }
                      onAdd={({ updated_src }) =>
                        this.setConditionValues(updated_src)
                      }
                      onDelete={({ updated_src }) =>
                        this.setConditionValues(updated_src)
                      }
                    />
                  </FormGroup>
                </div>
                <div />
              </form>
              {this.state.error ? (
                <span style={{ color: 'red' }}>{this.state.error}</span>
              ) : (
                ''
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="success" disabled={loading} onClick={this.submit}>
                Create Promotion{' '}
                {loading ? (
                  <span className="fas fa-spinner fa-spin"></span>
                ) : (
                  ''
                )}
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      );
    }
  }
);
