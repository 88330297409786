import { Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap';

import ClickToCopy from '../ClickToCopy';
import React from 'react';
import { formatDate } from '../../utils';

function InfoTab({ campaign }) {
  if (!campaign) return <React.Fragment />;
  return (
    <Row>
      <Col sm="8">
        <Card className="card-chart">
          <CardHeader>
            <CardTitle className="mt-0">Details</CardTitle>
          </CardHeader>
          <CardBody>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Title</span>
              </div>
              <div> {campaign.title} </div>
            </div>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Type</span>
              </div>
              <div> {campaign.type.toUpperCase()} </div>
            </div>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Channel</span>
              </div>
              <span>
                <span className="cursor-pointer blue-text" onClick={() => {}}>
                  <strong>{campaign.channels.join(', ').toUpperCase()}</strong>
                </span>
                <ClickToCopy value={campaign.channel} />
              </span>
            </div>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Status</span>
              </div>
              <div>{campaign.status.toUpperCase()}</div>
            </div>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Start Date</span>
              </div>
              <div>{formatDate(campaign.start_at)}</div>
            </div>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">End Date</span>
              </div>
              <div>{formatDate(campaign.end_at)}</div>
            </div>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Status</span>
              </div>
              <div>{campaign.status.toUpperCase()}</div>
            </div>
          </CardBody>
        </Card>
      </Col>

      <Col sm="4">
        <Card className="card-chart">
          <CardHeader>
            <CardTitle className="mt-0">Meta</CardTitle>
          </CardHeader>
          <CardBody>
            {campaign.type === 'banner' && (
              <div className="d-flex mb-3 justify-content-between">
                <div>
                  <span className="text-secondary">Size</span>
                </div>
                <div> {campaign.content.size} </div>
              </div>
            )}
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">User Category</span>
              </div>
              <div>
                {' '}
                <b>
                  {campaign.content.user_categories.join(', ').toUpperCase()}
                </b>{' '}
              </div>
            </div>
            {campaign.content.discos && campaign.content.discos.length > 0 && (
              <div className="d-flex mb-3 justify-content-between">
                <div>
                  <span className="text-secondary">Selected Discos</span>
                </div>
                <div>
                  {' '}
                  <b>{campaign.content.discos.join(', ').toUpperCase()}</b>{' '}
                </div>
              </div>
            )}
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Should Repeat</span>
              </div>
              <div> {campaign.frequency.is_frequent ? 'YES' : 'NO'} </div>
            </div>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Will Repeat</span>
              </div>
              <div>
                {' '}
                {campaign.frequency.repetition !== undefined
                  ? campaign.frequency.repetition
                  : 0}{' '}
              </div>
            </div>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">URL</span>
              </div>
              <div> {campaign.content.url} </div>
            </div>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">ACTION</span>
              </div>
              <div> {campaign.content.action} </div>
            </div>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Notification Target Page</span>
              </div>
              <div> {campaign.content.target_page.toUpperCase()} </div>
            </div>
          </CardBody>
        </Card>
      </Col>

      {campaign.content.image && (
        <Col sm="12">
          <Card className="card-chart">
            <CardHeader>
              <CardTitle className="mt-0">Image</CardTitle>
            </CardHeader>
            <CardBody>
              <div>
                <img
                  src={`data:image/jpeg;base64,${campaign.content.image}`}
                  alt="campaign"
                  style={{ width: '10%' }}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      )}
    </Row>
  );
}

export default React.memo(InfoTab);
