import React from 'react';
import { connect } from 'react-redux';

import { Row, Col, Table, Button } from 'reactstrap';

import { callApi } from '../../utils';
import { showError, showInfo } from '../../actions/notificationActions';
import { showVoucherCreateModal } from '../../actions/modalActions';
import Pagination from '../Pagination';

import Loading from '../Loading';
import CreateVoucherModal from '../CreateVoucherModal';
import SearchForm from '../SearchForm';
import MessageModal from '../../MessageModal';

class Vouchers extends React.Component {
  state = {
    queryParams: {
      from: '',
      to: '',
      disco: '',
      page: 1,
      limit: '12',
      subAccount: '',
    },
    pages: 1,
    meta: { pages: 1 },
    loading: false,
    rows: [],
    query: '',
    messageModalOpen: false,
    voucherMessage: null,
    selectedVoucher: null,
  };

  setInput = (e) => {
    const { name, value } = e.target;
    this.setState({
      queryParams: { ...this.state.queryParams, [name]: value },
    });
    // setQueryParams({[name]: value})
  };

  setPage = (page) => {
    if (page) {
      this.setState(
        { queryParams: { ...this.state.queryParams, page } },
        () => {
          this.getVouchers();
        }
      );
    }
  };

  startLoad = () => this.setState({ loading: true });
  stopLoad = () => this.setState({ loading: false });

  getVouchers = (e) => {
    const id = this.props.id;

    this.startLoad();

    // { ...this.state.queryParams }
    callApi(`/promotions/${id}/vouchers`)
      .then(({ data = {}, meta = {} }) => {
        this.setState({ rows: data, meta });
        this.stopLoad();
      })
      .catch((err) => {
        this.props.dispatch(showError(err));
        this.stopLoad();
      });
  };

  componentDidMount() {
    this.getVouchers();
  }

  openVoucherModal = () => {
    this.props.dispatch(showVoucherCreateModal());
  };

  toggleMessageModal = (e) => {
    e.preventDefault();

    this.setState({
      messageModalOpen: !this.state.messageModalOpen,
    });
  };

  composeVoucherMessage(voucher) {
    const message = this.composeMessage(voucher);

    this.setState({
      voucherMessage: message,
      messageModalOpen: true,
      selectedVoucher: voucher,
    });
  }

  sendVoucherMessage = async (message) => {
    await callApi(
      `/vouchers/${this.state.selectedVoucher.id}/dispatch`,
      message,
      'post'
    )
      .then((res) => {
        this.props.dispatch(showInfo(res.message));
      })
      .catch((err) => {
        this.props.dispatch(showError(err));
      });

    this.setState({
      messageModalOpen: !this.state.messageModalOpen,
    });
  };

  composeMessage(voucher) {
    const { promotion } = this.props;
    return `${promotion.description} Enter discount code, ${voucher.code}, on BuyPower when checking-out.\n\nThanks for using BuyPower.`;
  }

  composePromotionAmount(promotion) {
    return promotion.type === 'FLAT'
      ? promotion.amount
      : Number(promotion.amount) * 100 + '%';
  }

  render() {
    const {
      rows,
      queryParams,
      loading,
      meta,
      query,
      messageModalOpen,
      voucherMessage,
    } = this.state;

    return (
      <Row>
        <Col sm="12">
          <div className="mt-10">
            <CreateVoucherModal reload={this.getVouchers} />

            {messageModalOpen ? (
              <MessageModal
                toggle={this.toggleMessageModal}
                show={messageModalOpen}
                onSubmit={this.sendVoucherMessage}
                defaults={{
                  message: voucherMessage,
                }}
              />
            ) : null}

            {loading ? (
              <Loading />
            ) : (
              <div>
                <div className="d-flex justify-content-between">
                  <div>
                    <SearchForm
                      value={query}
                      onChange={(e) => this.setState({ query: e.target.value })}
                      onSearch={() => this.getVouchers()}
                      loading={loading}
                    />
                  </div>
                  <div>
                    <Button
                      onClick={this.openVoucherModal}
                      // disabled={}
                      color="primary"
                    >
                      Create Voucher
                    </Button>
                  </div>
                </div>
                <Table responsive hover striped>
                  <thead className="text-primary">
                    <tr>
                      <th>Code</th>
                      <th>Usages</th>
                      <th>Dispatched</th>
                      <th>Created At</th>
                      <th>Created By</th>
                      <th width="1"></th>
                    </tr>
                  </thead>

                  <tbody>
                    {rows.length ? (
                      rows.map((t, i) => (
                        <tr key={t.id}>
                          <td>{t.code}</td>
                          <td>{t.usages}</td>
                          <td>{t.dispatched}</td>
                          <td>{t.created_at}</td>
                          <td>{t.creator ? t.creator.name : 'Unknown'}</td>
                          <td>
                            <Button
                              size="sm"
                              onClick={() => this.composeVoucherMessage(t)}
                            >
                              Dispatch
                            </Button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={20} className="text-center">
                          No data
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            )}

            <Pagination
              total={meta.pages || 1}
              page={queryParams.page}
              onChange={this.setPage}
            />
          </div>
          {/* </Card> */}
        </Col>
      </Row>
    );
  }
}

export default connect()(Vouchers);
