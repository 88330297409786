import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
} from 'reactstrap';
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { callApi, formatAmount, formatDate, isNotEmpty } from '../../utils';
import {
  closeAllModals,
  confirmAction,
  openUserModal,
  toggleModalLoading,
} from '../../actions/modalActions';
import { showError, showInfo } from '../../actions/notificationActions';

import ClickToCopy from '../ClickToCopy';
import ConfirmActionModal from '../ConfirmActionModal';
import classnames from 'classnames';
import { connect } from 'react-redux';

// import VendRequests from './VendRequests';
// import Payments from './Payments';
// import { Insight } from './Insight';

class Detail extends Component {
  state = {
    payment: {},
    meta: {},

    loading: true,
    toggling: false,
    activeTab: '1',
    toggle: null,
    rows: [],
    page: 1,
    pages: 1,
    limit: 10,
    enabled: false,
  };

  componentWillMount() {
    this.loadView();
  }

  startLoad = () => this.setState({ loading: true });
  stopLoad = () => this.setState({ loading: false });

  scrollToTop = () =>
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);

  loadView = (e) => {
    const id = this.props.match.params.id;

    this.startLoad();
    callApi(`/payments/${id}`)
      .then(({ data = {}, meta = {} }) => {
        this.setState({ payment: data, meta });
        this.scrollToTop();
        if (!data.order) {
          this.fetchOrder(data.order_id);
        }
      })
      .catch((err) => {
        this.props.dispatch(showError(err));
      })
      .finally(() => {
        // this.setState({ payment, enabled });
        this.stopLoad();
      });
  };

  setPage = (page) => {
    if (page) {
      this.setState({ page }, () => {
        this.loadView();
      });
    }
  };

  opentUser = (id) => {
    this.props.dispatch(openUserModal(id));
  };

  setInput = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  refund = async (params) => {
    try {
      this.props.dispatch(toggleModalLoading(true));
      const id = this.props.match.params.id;
      await callApi(
        `/payments/${id}/refund`,
        { method: 'BANK_TRANSFER', ...params },
        'post'
      );
    } catch (error) {
      this.props.dispatch(showError(error.message));
    } finally {
      this.props.dispatch(toggleModalLoading(false));
    }
  };

  confirmRefund = () => {
    this.props.dispatch(
      confirmAction({
        confirmCallback: (params) => this.refund(params),
        cancel: () => this.props.dispatch(closeAllModals()),
        confirmText: '',
        confirmBtnText: 'Refund',
        cancelBtnText: 'Cancel',
        title: 'Refund Payment',
        fields: [
          {
            label: 'Please Select a Method',
            name: 'method',
            type: 'select',
            isValid: (val) => isNotEmpty(val),
            error: 'Method is required',

            options: [
              {
                key: '',
                value: '',
                label: 'please select',
              },
              {
                key: 'BANK_TRANSFER',
                value: 'BANK_TRANSFER',
                label: 'Bank Transfer',
              },
              {
                key: 'CHARGE_BACK',
                value: 'CHARGE_BACK',
                label: 'Charge Back',
              },
            ],
          },
          {
            label: 'Comment',
            name: 'comment',
            type: 'text',
            isValid: (val) => isNotEmpty(val),
            error: 'Comment is required',
          },
        ],
      })
    );
  };

  fetchOrder = async (orderId) => {
    const res = await callApi(`orders/${orderId}`);

    this.setState({ payment: { ...this.state.payment, order: res.data } });
  };

  openOrder = (id) => {
    window.open(`/orders/${id}`);
  };

  verify = async () => {
    this.startLoad();
    const { dispatch, match } = this.props;
    const { id } = match.params;
    try {
      const res = await callApi(`/payments/${id}/confirmation`);

      dispatch(showInfo(res.message || 'Verified!'));
      this.loadView();
    } catch (error) {
      dispatch(showError(error));
    } finally {
      this.stopLoad();
    }
  };

  render() {
    const { payment, loading } = this.state;

    // const toggle = (tab) => {
    //   this.setState({ activeTab: tab });
    // };

    const { history, match, location } = this.props;

    console.log(payment);

    return (
      <div className="content">
        <ConfirmActionModal />
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardHeader className="ml-2">
                <div className="d-flex justify-content-between align-contents-center">
                  <div>
                    <CardTitle>{payment.reference || 'Loading...'}</CardTitle>
                    <p className="category">Single Payment</p>
                  </div>
                  <div>
                    <Button
                      disabled={loading || payment.status !== 'SUCCESSFUL'}
                      onClick={this.confirmRefund}
                      color="danger"
                    >
                      REFUND
                    </Button>

                    {payment.status === 'SUCCESSFUL' && (
                      <React.Fragment>
                        <div
                          style={{ width: '10px', display: 'inline-block' }}
                        />
                        <Button
                          disabled={loading}
                          onClick={this.verify}
                          color="success"
                        >
                          VERIFY
                        </Button>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </CardHeader>

              <CardBody>
                <div>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        style={{ color: 'black' }}
                        href="#"
                        className={classnames({
                          active: location.pathname === `${match.url}`,
                        })}
                        onClick={() => {
                          history.push(`${match.url}`);
                        }}
                      >
                        General
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <br />
                  <div>
                    <Switch>
                      <Route exact path={`${match.path}`}>
                        <GeneralTab
                          payment={payment}
                          customer={payment.customer || {}}
                          order={payment.order || {}}
                          loading={loading}
                          openOrder={this.openOrder}
                        />
                      </Route>
                      <Route exact path={`${match.path}/requests`}>
                        {/* <VendRequests order={order} /> */}
                      </Route>
                      <Route exact path={`${match.path}/payments`}>
                        {/* <Payments order={order} /> */}
                      </Route>
                    </Switch>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

function GeneralTab({
  payment,
  customer = {},
  order = {},
  loading,
  openOrder,
}) {
  return (
    <div>
      <Row>
        <Col sm="6">
          <Card className="card-chart">
            <CardHeader>
              <CardTitle className="mt-0">Payment</CardTitle>
            </CardHeader>
            <CardBody>
              <div className="d-flex mb-3 justify-content-between">
                <div>
                  <span className="text-secondary">Order ID</span>
                </div>
                <div> {payment.order_id} </div>
              </div>
              <div className="d-flex mb-3 justify-content-between">
                <div>
                  <span className="text-secondary">Amount Due</span>
                </div>
                {payment.amount_due && (
                  <div> {formatAmount(payment.amount_due)} </div>
                )}
              </div>
              <div className="d-flex mb-3 justify-content-between">
                <div>
                  <span className="text-secondary">Amount Paid</span>
                </div>
                {payment.amount_paid && (
                  <div> {formatAmount(payment.amount_paid)} </div>
                )}
              </div>
              <div className="d-flex mb-3 justify-content-between">
                <div>
                  <span className="text-secondary">Payment Method</span>
                </div>
                <div> {payment.method} </div>
              </div>
              <div className="d-flex mb-3 justify-content-between">
                <div>
                  <span className="text-secondary">Processor</span>
                </div>
                <div> {payment.processor} </div>
              </div>
              <div className="d-flex mb-3 justify-content-between">
                <div>
                  <span className="text-secondary">Status</span>
                </div>
                <div> {payment.status} </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col sm="6">
          <Card className="card-chart">
            <CardHeader>
              <CardTitle className="mt-0">Customer</CardTitle>
            </CardHeader>
            <CardBody>
              <div className="d-flex mb-3 justify-content-between">
                <div>
                  <span className="text-secondary">Joined</span>
                </div>
                {customer.created_at && (
                  <div> {formatDate(customer.created_at)} </div>
                )}
              </div>
              <div className="d-flex mb-3 justify-content-between">
                <div>
                  <span className="text-secondary">Email</span>
                </div>
                <div>{customer.email}</div>
              </div>
              <div className="d-flex mb-3 justify-content-between">
                <div>
                  <span className="text-secondary">Name</span>
                </div>
                <div> {customer.name} </div>
              </div>
              <div className="d-flex mb-3 justify-content-between">
                <div>
                  <span className="text-secondary">Disco Location</span>
                </div>
                <div> {customer.disco_location} </div>
              </div>
              <div className="d-flex mb-3 justify-content-between">
                <div>
                  <span className="text-secondary">Phone</span>
                </div>
                <span>
                  <span className="cursor-pointer blue-text" onClick={() => {}}>
                    <strong>{customer.phone}</strong>{' '}
                  </span>
                  <ClickToCopy value={customer.phone} />
                </span>
              </div>
              <div className="d-flex mb-3 justify-content-between">
                <div>
                  <span className="text-secondary">Wallet</span>
                </div>
                <div>{formatAmount(customer.wallet)}</div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col sm="12">
          <Card className="card-chart">
            <CardHeader>
              <Row className="d-flex px-3 justify-content-between align-items-center">
                <CardTitle className=" mt-0">Order</CardTitle>
                <Button
                  className="d-flex justify-content-between"
                  color="success"
                  size="sm"
                  onClick={() => openOrder(order.id)}
                >
                  {/* <span>Open Order</span> */}
                  <i className="now-ui-icons ui-1_send hover-blue" />
                </Button>
              </Row>
            </CardHeader>
            <CardBody>
              <Row>
                <Col sm="6">
                  {loading ? (
                    <span className="text-secondary">Loading...</span>
                  ) : order.id !== undefined ? (
                    <div>
                      <div className="d-flex mb-3 justify-content-between">
                        <div>
                          <span className="text-secondary">Meter No.</span>
                        </div>
                        <div> {order.meter_no} </div>
                      </div>
                      <div className="d-flex mb-3 justify-content-between">
                        <div>
                          <span className="text-secondary">Channel</span>
                        </div>
                        <div> {order.channel} </div>
                      </div>
                      <div className="d-flex mb-3 justify-content-between">
                        <div>
                          <span className="text-secondary">Amount</span>
                        </div>
                        <span>{formatAmount(order.amount)}</span>
                      </div>
                      <div className="d-flex mb-3 justify-content-between">
                        <div>
                          <span className="text-secondary">Disco</span>
                        </div>
                        {<div> {order.disco_code}</div>}
                      </div>
                      <div className="d-flex mb-3 justify-content-between">
                        <div>
                          <span className="text-secondary">Order ID</span>
                        </div>
                        <div>{order.id}</div>
                      </div>
                      <div className="d-flex mb-3 justify-content-between">
                        <div>
                          <span className="text-secondary">Order Ref</span>
                        </div>
                        {<div>{order.order_id}</div>}
                      </div>
                      <div className="d-flex mb-3 justify-content-between">
                        <div>
                          <span className="text-secondary">
                            Query Reference
                          </span>
                        </div>
                        {<div>{order.query_reference}</div>}
                      </div>
                      <div className="d-flex mb-3 justify-content-between">
                        <div>
                          <span className="text-secondary">Vend Type</span>
                        </div>
                        {<div>{order.vend_type}</div>}
                      </div>
                    </div>
                  ) : (
                    <span className="text-secondary">
                      No Vend Response Received.
                    </span>
                  )}
                </Col>
                <Col sm="6">
                  {loading ? (
                    <span className="text-secondary">Loading...</span>
                  ) : order.id !== undefined ? (
                    <div>
                      <div className="d-flex mb-3 justify-content-between">
                        <div>
                          <span className="text-secondary">
                            BuyPower Channel
                          </span>
                        </div>
                        <div> {order.buypower_channel} </div>
                      </div>
                      <div className="d-flex mb-3 justify-content-between">
                        <div>
                          <span className="text-secondary">
                            Convenience Fee
                          </span>
                        </div>
                        <div> {formatAmount(order.convenience_fee)} </div>
                      </div>
                      <div className="d-flex mb-3 justify-content-between">
                        <div>
                          <span className="text-secondary">Order Status</span>
                        </div>
                        <div> {order.order_status} </div>
                      </div>
                      <div className="d-flex mb-3 justify-content-between">
                        <div>
                          <span className="text-secondary">Paid At</span>
                        </div>
                        <div> {formatDate(order.paid_at)} </div>
                      </div>
                      <div className="d-flex mb-3 justify-content-between">
                        <div>
                          <span className="text-secondary">Payment Type</span>
                        </div>
                        <div> {order.payment_type} </div>
                      </div>
                      <div className="d-flex mb-3 justify-content-between">
                        <div>
                          <span className="text-secondary">
                            Transaction Date
                          </span>
                        </div>
                        <div> {formatDate(order.transaction_date)} </div>
                      </div>
                      <div className="d-flex mb-3 justify-content-between">
                        <div>
                          <span className="text-secondary">Vend Status</span>
                        </div>
                        <div> {order.vend_status} </div>
                      </div>
                      <div className="d-flex mb-3 justify-content-between">
                        <div>
                          <span className="text-secondary">Vend Amount</span>
                        </div>
                        <div> {formatAmount(order.vend_amount)} </div>
                      </div>
                    </div>
                  ) : (
                    <span className="text-secondary">
                      No Payment Response Received.
                    </span>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default connect()(Detail);
