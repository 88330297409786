import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Button,
  CardText,
} from 'reactstrap';

import { callApi } from '../../utils';
import { showError } from '../../actions/notificationActions';

import Loading from '../Loading';

class Client extends Component {
  state = {
    enabled: false,
    error: false,
    client: null,
    loading: true,
  };

  title;

  componentWillMount() {
    const { environment } = this.props;

    switch (environment) {
      case 'production':
        this.title = 'Live Client';
        break;
      case 'test':
        this.title = 'Test Client';
        break;
      default:
        this.title = 'Undefined Client';
    }

    this.loadView();
  }

  startLoad = () => this.setState({ loading: true });
  stopLoad = () => this.setState({ loading: false });

  scrollToTop = () =>
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);

  loadView = (e) => {
    const { environment } = this.props;

    const { id } = this.props;

    callApi(`/merchant/${id}/client/${environment}`)
      .then(({ data }) => {
        this.setState({ client: data, enabled: !!data });
        this.scrollToTop();
      })
      .catch((err) => {
        this.props.dispatch(showError(err));
      })
      .finally(() => {
        this.stopLoad();
      });
  };

  createClient = () => {
    const data = {};
    const { id } = this.props;
    this.startLoad();
    callApi(`/merchant/${id}/client`, data, 'POST')
      .then(({ data = {} }) => {
        this.setState({ client: data });
        this.scrollToTop();
      })
      .catch((err) => {
        this.props.dispatch(showError(err));
      })
      .finally(() => {
        this.stopLoad();
      });
  };

  saveClient = () => {};

  toggleStatus = () => {
    const { enabled } = this.state;
    this.setState({ enabled: !enabled });
  };

  render() {
    const { enabled } = this.state;

    const content = this.renderContent();

    return (
      <Card>
        <CardHeader>
          <CardTitle>
            <div className="d-flex justify-content-between">
              <div>{this.title}</div>
              <div>
                <div className="p-2">
                  <label className="switch sm">
                    <input
                      type="checkbox"
                      checked={enabled}
                      onChange={this.toggleStatus}
                    />
                    <span className="slider round" />
                  </label>
                </div>
              </div>
            </div>
          </CardTitle>
        </CardHeader>
        <CardBody>{content}</CardBody>
      </Card>
    );
  }

  renderContent() {
    const { client, loading } = this.state;

    if (loading) {
      return <Loading />;
    } else if (client) {
      return (
        <div>
          <div>
            <div className="alert alert-info">
              <span className="text-bold">Bearer Token: </span> {client.token}
            </div>
          </div>

          {/* <Button onClick={() => this.saveClient()}>Update Client</Button> */}
        </div>
      );
    } else {
      return (
        <div>
          <CardText>{this.title} not set.</CardText>
          <Button onClick={() => this.createClient()}>Create Client</Button>
        </div>
      );
    }
  }
}

export default connect()(Client);
