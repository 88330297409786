import React from 'react';
import { Route, Switch } from 'react-router-dom';

import List from './Watchlist/List';
import Detail from './Watchlist/Detail';

function Watchlist() {
  return (
    <Switch>
      <Route exact path="/watchlists" component={List} />
      <Route path="/watchlists/:id" component={Detail} />
    </Switch>
  );
}

export default Watchlist;
