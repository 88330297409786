import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Table,
  Button,
} from 'reactstrap';

import { callApi, formatDate, formatAmount } from '../../utils';
import { showError } from '../../actions/notificationActions';

import Loading from '../Loading';
import SuccessTick from '../SuccessTick';
import ErrorTick from '../ErrorTick';
import Pagination from '../Pagination';
import SearchForm from '../SearchForm';
import { openCreateCustomerModal } from '../../actions/modalActions';
import { setCustomerDetails } from '../../actions/customerAction';
import CreateCustomerModal from '../CreateCustomerModal';

class Users extends Component {
  state = {
    users: [],
    query: '',
    page: 1,
    limit: 30,
    loading: false,
  };

  componentWillMount() {
    this.loadUsers();
  }

  startLoad = () => this.setState({ loading: true });
  stopLoad = () => this.setState({ loading: false });

  scrollToTop = () =>
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);

  loadUsers = (e) => {
    // Event may be passed in from search form
    if (e) e.preventDefault();

    const { query, page, limit } = this.state;
    this.startLoad();
    callApi(`/users/all?q=${query}&page=${page}&limit=${limit}`)
      .then(({ users = [] }) => {
        this.setState({ loading: false, users });
        this.scrollToTop();
      })
      .catch((err) => {
        this.stopLoad();
        this.props.dispatch(showError(err));
      });
  };

  setPage = (page) => {
    if (page) {
      this.setState({ page }, () => {
        this.loadUsers();
      });
    }
  };

  setQuery = (e) => {
    this.setState({ query: e.target.value });
  };

  openUser = (user) => {
    // this.props.dispatch(openUserModal(id));
    this.props.history.push(`/users/${user.id}`);
    this.props.dispatch(setCustomerDetails(user));
  };

  refresh = () => {
    window.location.reload();
  };

  render() {
    const { query, users, page, loading, limit } = this.state;
    return (
      <div className="content">
        <CreateCustomerModal refresh={this.refresh} />
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardHeader className="ml-2">
                <div className="d-flex justify-content-between align-contents-center">
                  <div>
                    <CardTitle>USERS</CardTitle>
                    <p className="category">All Users - Page {page}</p>
                  </div>
                  <div>
                    <Button
                      // size="sm"
                      // disabled={}
                      color={'primary'}
                      onClick={() => {
                        this.props.dispatch(openCreateCustomerModal());
                      }}
                    >
                      Create User
                    </Button>
                  </div>
                </div>
              </CardHeader>

              <SearchForm
                value={query}
                onChange={this.setQuery}
                onSearch={this.loadUsers}
                loading={loading}
              />

              <CardBody>
                <Table responsive hover striped>
                  <thead className="text-primary">
                    <tr>
                      <th>No.</th>
                      <th>Name</th>
                      <th>Phone</th>
                      <th>Email</th>
                      <th>Joined</th>
                      <th>Social</th>
                      <th>Wallet</th>
                    </tr>
                  </thead>

                  <tbody>
                    {users.map((u, i) => (
                      <tr onClick={() => this.openUser(u)} key={u.id}>
                        <td>{loading ? 'x' : i + 1 + (page - 1) * limit}</td>
                        <td>{u.name}</td>
                        <td>{u.phone}</td>
                        <td>{u.email}</td>
                        <td>{formatDate(u.created_at)}</td>
                        <td>
                          {Number(u.social_follow) ? (
                            <ErrorTick />
                          ) : (
                            <SuccessTick />
                          )}
                        </td>
                        <td>{formatAmount(Number(u.wallet) / 100)}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Pagination page={page} onChange={this.setPage} />
                {loading && <Loading />}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect()(Users);
