import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/es/storage';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
// import { analyticsMiddleware as analytics } from '../analytics';

import reducers from './reducers';

const DEV = process.env.NODE_ENV !== 'production';

const persistConfig = {
  key: 'buypoweradmin',
  storage,
};

let middleware = [thunk];

if (DEV) {
  // Dev Mode middleware
  const logger = createLogger({ collapsed: true });
  middleware.push(logger);
}

if (!DEV) {
  // Prod Mode middleware
}

let composers = [applyMiddleware(...middleware)];

if (DEV) {
  // Redux Dev Tools Extension
  composers.push(
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : (a) => a
  ); // Enable Redux Dev Tools;
}

const initStore = (initialState) => {
  const reducer = persistCombineReducers(persistConfig, reducers);

  const store = createStore(reducer, initialState, compose(...composers));

  const persistor = persistStore(store);

  return { store, persistor };
};

export default initStore;
