import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Row,
  Table,
} from 'reactstrap';
import React, { Component } from 'react';

import Loading from './Loading';
import Pagination from './Pagination';
import SearchForm from './SearchForm';
import { callApi } from '../utils';
import { connect } from 'react-redux';
import { openSendNotificationModal } from '../actions/modalActions';
import { showError } from '../actions/notificationActions';

class SendPushNotification extends Component {
  state = {
    users: [],
    query: '',
    page: 1,
    limit: 30,
    loading: false,
    selectedUsers: [],
    recipients: ['ALL', 'SELECTED', 'USER_CATEGORY', 'DISCO'],
    recipient: '',
    userCategory: '',
    userCategories: [
      'ALL',
      'POWER_USERS',
      'REGULAR_USERS',
      'OCCASIONAL_USERS',
      'CHURNED_USERS',
      'NEW_CUSTOMERS',
      'CARD_EXPIRED',
      'CARD_ABOUT_TO_EXPIRE',
    ],
    discos: [],
    disco: '',
  };

  componentDidMount() {
    this.loadUsers();
    this.loadDiscos();
  }

  startLoad = () => this.setState({ loading: true });
  stopLoad = () => this.setState({ loading: false });

  scrollToTop = () =>
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);

  loadUsers = (e) => {
    // Event may be passed in from search form
    if (e) e.preventDefault();

    const { query, page, limit } = this.state;
    this.startLoad();
    callApi(`/users/all?q=${query}&page=${page}&limit=${limit}`)
      .then(({ users = [] }) => {
        this.setState({ loading: false, users });
        this.scrollToTop();
      })
      .catch((err) => {
        this.stopLoad();
        this.props.dispatch(showError(err));
      });
  };

  loadDiscos = () => {
    // use callApi to get discos
    // this.setState({ loading: true });
    callApi('/service-distribution', {}, 'get')
      .then((res) => {
        this.setState({ discos: res.data.map((disco) => disco.name) });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  setPage = (page) => {
    if (page) {
      this.setState({ page }, () => {
        this.loadUsers();
      });
    }
  };

  setQuery = (e) => {
    this.setState({ query: e.target.value });
  };

  refresh = () => {
    window.location.reload();
  };

  addUserToSelection = (userId) => {
    const { selectedUsers } = this.state;
    if (selectedUsers.includes(userId)) {
      this.setState({
        selectedUsers: selectedUsers.filter((u) => u !== userId),
      });
    } else {
      this.setState({
        selectedUsers: [...selectedUsers, userId],
      });
    }
  };

  setRecipients = (e) => {
    this.setState({ recipient: e.target.value });
  };

  setUserCategories = (e) => {
    this.setState({ userCategory: e.target.value });
  };

  setDisco = (e) => {
    this.setState({ disco: e.target.value });
  };

  render() {
    const { query, users, page, loading, limit } = this.state;
    console.log(this.state.recipients);
    console.log(this.state.recipient);
    return (
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardHeader className="ml-2">
                <div className="d-flex justify-content-between align-contents-center">
                  <div>
                    <CardTitle>Send Push Notification</CardTitle>
                  </div>
                  <div>
                    <Button
                      // size="sm"
                      // disabled={}
                      color={'primary'}
                      onClick={() => {
                        this.props.dispatch(
                          openSendNotificationModal({
                            recipient: this.state.recipient,
                            selectedUsers: this.state.selectedUsers,
                            userCategory: this.state.userCategory,
                            disco: this.state.disco,
                          })
                        );
                      }}
                    >
                      Send{' '}
                      {this.state.recipient === 'SELECTED' &&
                        this.state.selectedUsers.length}{' '}
                      Push Notification{' '}
                      {/* {this.state.recipients !== 'ALL'
                        ? this.state.selectedUsers.length + ' people'
                        : 'Everyone'} */}
                    </Button>
                  </div>
                </div>
              </CardHeader>

              <CardHeader className="ml-2">
                <div className="d-flex justify-content-between align-contents-center">
                  <div>
                    <SearchForm
                      value={query}
                      onChange={this.setQuery}
                      onSearch={this.loadUsers}
                      loading={loading}
                    />
                  </div>

                  <div>
                    <FormGroup>
                      <Input
                        id="status"
                        name="status"
                        type="select"
                        onChange={this.setRecipients}
                      >
                        <option value={'recipients'}>Recipients</option>
                        {this.state.recipients.map((type, index) => (
                          <option key={index} value={type.toUpperCase()}>
                            {type.toUpperCase()}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>

                    {this.state.recipient === 'DISCO' && (
                      <FormGroup>
                        <Input
                          id="status"
                          name="status"
                          type="select"
                          onChange={this.setDisco}
                        >
                          <option value={'Category'}>Select A Disco</option>
                          {this.state.discos.map((disco, index) => (
                            <option key={index} value={disco.toUpperCase()}>
                              {disco.toUpperCase()}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    )}
                    {this.state.recipient === 'USER_CATEGORY' && (
                      <FormGroup>
                        <Input
                          id="status"
                          name="status"
                          type="select"
                          onChange={this.setUserCategories}
                        >
                          <option value={'Category'}>
                            Select User Category
                          </option>
                          {this.state.userCategories.map((type, index) => (
                            <option key={index} value={type.toUpperCase()}>
                              {type.toUpperCase()}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    )}
                  </div>
                </div>
              </CardHeader>

              <CardBody>
                <Table responsive hover striped>
                  <thead className="text-primary">
                    <tr>
                      <th>No.</th>
                      <th>Name</th>
                      <th>Phone</th>
                      <th>Email</th>
                      {this.state.recipient === 'SELECTED' && <th>Action</th>}
                    </tr>
                  </thead>

                  <tbody>
                    {users.map((u, i) => (
                      <tr key={u.id}>
                        <td>{loading ? 'x' : i + 1 + (page - 1) * limit}</td>
                        <td>{u.name}</td>
                        <td>{u.phone}</td>
                        <td>{u.email}</td>
                        {this.state.recipient === 'SELECTED' && (
                          <td>
                            <Button
                              size="sm"
                              // disabled={}
                              color={'green'}
                              onClick={() => {
                                this.addUserToSelection(u.id);
                              }}
                            >
                              {this.state.selectedUsers.includes(u.id)
                                ? 'Remove'
                                : 'Add'}
                            </Button>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Pagination page={page} onChange={this.setPage} />
                {loading && <Loading />}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect()(SendPushNotification);
