import React, { useState, useEffect } from 'react';
import { Table } from 'reactstrap';
import Loading from '../Loading';
import { connect } from 'react-redux';
import { closeAllModals, previewEntity } from '../../actions/modalActions';
import { showError } from '../../actions/notificationActions';
import Pagination from '../Pagination';
import { callApi, formatAmount, formatDateTime } from '../../utils';

function Similar(props) {
  const { order, dispatch } = props;
  const [loading, setLoading] = useState(true);

  const [queryParams, setQueryParams] = useState({ limit: 30, page: 1 });
  const [response, setResponse] = useState({ data: [], meta: { pages: 1 } });
  const [currentPayment, setCurrentPayment] = useState({});

  const fetchTransactions = async () => {
    setLoading(true);
    return callApi(`/orders/${order.id}/similar`)
      .then((res) => {
        const { data = [], meta = {} } = res;
        setResponse({ data, meta });
      })
      .catch((err) => {
        dispatch(showError(err.message));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const view = (id) => {
    props.history.push(`/orders/${id}`);
  };

  useEffect(() => {
    setLoading(true);

    if (order.id) {
      fetchTransactions().finally(() => setLoading(false));
    }
  }, [queryParams.page, order.id]);

  useEffect(() => {
    if (currentPayment.order_id) {
      dispatch(
        previewEntity({
          entity: currentPayment,
          btnText: 'Open Payment',
          close: () => {
            setCurrentPayment({});
          },
          view: () => {
            dispatch(closeAllModals());
            props.history.push(`/all-payments/${currentPayment.id}`);
          },
        })
      );
    }
  }, [currentPayment.order_id]);

  const setPage = (page = 1) => {
    setQueryParams({ ...queryParams, page });
  };

  const { data, meta } = response;
  const { page } = queryParams;

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <Table responsive hover striped>
          <thead className="text-primary">
            <tr>
              <th>Order ID</th>
              <th>Customer</th>
              <th>Amount</th>
              <th>Disco</th>
              <th>Channel</th>
              <th>Order Status</th>
              <th>Date</th>
            </tr>
          </thead>

          <tbody>
            {data.map((t, i) => (
              <tr onClick={() => view(t.id)} key={t.id}>
                <td>{t.order_id}</td>
                <td>{t.name}</td>
                <td>{formatAmount(t.amount)}</td>
                <td>{t.disco_code}</td>
                <td>{t.channel}</td>
                <td>{t.order_status}</td>
                <td>{formatDateTime(t.created_at)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}{' '}
      <Pagination page={page} total={meta.pages} onChange={setPage} />
    </div>
  );
}

export default connect()(Similar);
