import React, { Component } from 'react';
import { connect } from 'react-redux';
import SearchForm from '../SearchForm';

import {
  Row,
  Col,
  Card,
  CardBody,
  Table,
  Button,
  CardFooter,
} from 'reactstrap';

import { callApi } from '../../utils';
import { showError } from '../../actions/notificationActions';
import { openCreateMerchantModal } from '../../actions/modalActions';

import Loading from '../Loading';
import Pagination from '../Pagination';

class List extends Component {
  state = {
    channels: [],
    query: '',
    page: 1,
    pages: 1,
    limit: 30,
    loading: false,
  };

  componentWillMount() {
    this.loadPage();
  }

  startLoad = () => this.setState({ loading: true });
  stopLoad = () => this.setState({ loading: false });

  scrollToTop = () =>
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);

  loadPage = (e) => {
    // Event may be passed in from search form
    if (e) e.preventDefault();

    const { query, page, limit } = this.state;

    this.startLoad();
    callApi(`/merchant`, { query, page, limit })
      .then(({ data = [], meta }) => {
        this.setState({ channels: data, pages: meta.pages });
        this.scrollToTop();
      })
      .catch((err) => {
        this.stopLoad();
        this.props.dispatch(showError(err));
      })
      .finally(() => {
        this.stopLoad();
      });
  };

  setPage = (page) => {
    if (page) {
      this.setState({ page }, () => {
        this.loadPage();
      });
    }
  };

  onRowClick(item) {
    this.props.history.push({
      pathname: `/businesses/${item.id}`,
      data: item, // your data array of objects
    });
  }

  onCreateMerchant = (e) => {
    const a = {};
    a.refresh = this.loadPage;
    this.props.dispatch(openCreateMerchantModal(a));
  };
  setQuery = (e) => {
    this.setState({ query: e.target.value });
  };

  render() {
    const { query, channels, page, loading, pages } = this.state;
    return (
      <div className="container-fluid">
        <Row className="mt-4">
          <Col xs={12} md={12}>
            <Row className="align-contents-center">
              <Col xs={12} md={6}>
                <h4>Businesses</h4>
                <p className="category">All Businesses</p>
              </Col>
              <Col className="text-md-right" xs={12} md={6}>
                <Button color="primary" onClick={this.onCreateMerchant}>
                  Add Business
                </Button>
              </Col>
            </Row>
            <Card>
              <CardBody>
                <div>
                  <SearchForm
                    value={query}
                    onChange={this.setQuery}
                    onSearch={this.loadPage}
                    loading={loading}
                  />
                </div>
                <Table responsive hover striped>
                  <thead className="text-primary">
                    <tr>
                      {/* <th>ID</th> */}
                      <th>Account Name</th>
                      <th>Phone</th>
                      <th>User</th>
                      <th>Wallet Type</th>
                      <th>Managed</th>
                      <th>Active Since</th>
                      {/* <th>Wallet</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {channels.map((u, i) => (
                      <tr
                        key={u.id}
                        style={{ cursor: 'text' }}
                        onClick={() => this.onRowClick(u)}
                      >
                        {/* <td>{u.id}</td> */}
                        <td>{u.name}</td>
                        <td>{u.phone}</td>
                        <td>{u.user ? u.user.name : ''}</td>
                        <td>{u.type}</td>
                        <td>{u.managed ? 'Yes' : 'No'}</td>
                        <td>{u.created_at}</td>
                        {/* <td><Link pathname><i className="fas fa-eye"></i></Link></td> */}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
              <CardFooter>
                <Pagination total={pages} page={page} onChange={this.setPage} />
              </CardFooter>
              {loading && <Loading />}
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect()(List);
