import React from 'react';
import { Route, Switch } from 'react-router-dom';

import List from './Gateways/List';
import Detail from './Gateways/Detail';

function Refunds() {
  return (
    <Switch>
      <Route exact path="/gateways/" component={List} />
      <Route path="/gateways/:id" component={Detail} />
    </Switch>
  );
}

export default Refunds;
