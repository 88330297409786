import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  Row,
  Col,
  Card,
  Input,
  CardHeader,
  CardTitle,
  CardBody,
  Table,
  Button,
} from 'reactstrap';

import { callApi, formatDate } from '../utils';
import { showError, showInfo } from '../actions/notificationActions';

import Loading from './Loading';
import Pagination from './Pagination';
import { openTransactionModal } from '../actions/modalActions';

class AgentCancellations extends Component {
  state = {
    supervisor: 'ALL',
    approved: 'ALL',
    cancellations: [],
    supervisors: [],
    page: 1,
    limit: 20,
    start: '',
    end: '',
    loading: false,
    approvingCancellation: false,
  };

  componentWillMount() {
    this.loadCancellations();
    this.loadSupervisors();
  }

  startLoad = () => this.setState({ loading: true });
  stopLoad = () => this.setState({ loading: false });

  scrollToTop = () =>
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);

  loadSupervisors = () => {
    this.setState({ loading: true });
    callApi(`/agentsupervisors`)
      .then((res) => {
        this.setState({ loading: false, supervisors: [] }, () =>
          this.setState({ supervisors: res.data })
        );
        this.scrollToTop();
      })
      .catch((err) => {
        this.setState({ loading: false });
        this.props.dispatch(showError(err));
      });
  };

  loadCancellations = () => {
    const { supervisor, approved, page, limit, start, end } = this.state;
    this.setState({ loading: true });
    callApi(`/agentcancellations`, {
      supervisor,
      approved,
      page,
      limit,
      start,
      end,
    })
      .then((res) => {
        this.setState({ loading: false, cancellations: [] }, () =>
          this.setState({
            cancellations: res.data,
          })
        );
        this.scrollToTop();
      })
      .catch((err) => {
        this.setState({ loading: false });
        this.props.dispatch(showError(err));
      });
  };

  setPage = (page) => {
    if (page) {
      this.setState({ page }, () => {
        this.loadCancellations();
      });
    }
  };

  delete = (id) => {
    this.setState({ loading: true });
    callApi('/agentcancellations/cancel', { id }, 'post')
      .then((res) => {
        this.loadCancellations();
        this.props.dispatch(showInfo(res));
        this.scrollToTop();
      })
      .catch((err) => {
        this.setState({ loading: false });
        this.props.dispatch(showError(err));
      });
  };

  openTransaction = (id) => {
    this.props.dispatch(openTransactionModal({ id }));
  };

  approveCancellation = (id) => {
    this.setState({ loading: true, approvingCancellation: true });
    callApi('/agentcancellations/approve', { id }, 'post')
      .then((res) => {
        this.loadCancellations();
        this.props.dispatch(showInfo(res));
        this.scrollToTop();
      })
      .catch((err) => {
        this.setState({ loading: false, approvingCancellation: false });
        this.props.dispatch(showError(err));
      });
  };

  setQuery = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const {
      approved,
      supervisor,
      page,
      loading,
      limit,
      start,
      end,
      approvingCancellation,
    } = this.state;
    const cancellations = this.state.cancellations || [];
    const supervisors = this.state.supervisors || [];
    return (
      <Col className="content">
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardHeader className="ml-2">
                <CardTitle>Agent Cancellations</CardTitle>
                <p className="category">Agent Cancellations - Page {page}</p>
              </CardHeader>

              <Row style={{ padding: '10px 20px' }}>
                <Col md={6} xs={12}>
                  <h6>Start Date</h6>
                  <Input
                    name="start"
                    placeholder="Pick start date"
                    type="date"
                    onChange={this.setQuery.bind(this)}
                    value={start}
                    style={{ maxWidth: '500px' }}
                  />
                </Col>
                <Col md={6} xs={12}>
                  <h6>End Date</h6>
                  <Input
                    name="end"
                    placeholder="Pick end date"
                    type="date"
                    onChange={this.setQuery.bind(this)}
                    value={end}
                    style={{ maxWidth: '500px' }}
                  />
                </Col>
              </Row>
              <Row style={{ padding: '10px 20px' }}>
                <Col md={6} xs={12}>
                  <h6>Status</h6>
                  <Input
                    name="approved"
                    type="select"
                    onChange={this.setQuery.bind(this)}
                    value={approved}
                    style={{ maxWidth: '500px' }}
                  >
                    <option value="ALL">ALL</option>
                    <option value="true">Approved</option>
                    <option value="false">New</option>
                  </Input>
                </Col>
                <Col md={6} xs={12}>
                  <h6>Select Supervisor</h6>
                  <Input
                    name="supervisor"
                    type="select"
                    onChange={this.setQuery.bind(this)}
                    value={supervisor}
                    style={{ maxWidth: '500px' }}
                  >
                    <option value="ALL">ALL</option>
                    {supervisors.map((s) => (
                      <option key={s.id} value={s.id}>
                        {s.name}
                      </option>
                    ))}
                  </Input>
                </Col>
              </Row>

              {loading && (
                <div className="justify-content-center">
                  <Loading />
                </div>
              )}
              <div style={{ paddingLeft: '20px', maxWidth: '500px' }}>
                <Button
                  size="lg"
                  color="primary"
                  onClick={() => this.loadCancellations()}
                >
                  {loading ? 'PLEASE WAIT...' : 'SEARCH'}
                </Button>
              </div>

              <CardBody>
                <Table responsive hover striped>
                  <thead className="text-primary">
                    <tr>
                      <th>No.</th>
                      <th>Date</th>
                      <th>Supervisor</th>
                      <th>Phone</th>
                      <th>Reference</th>
                      <th>Reason</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {cancellations.map((c, i) => (
                      <tr
                        key={c._id}
                        onClick={() => this.openTransaction(c.ref)}
                      >
                        <td>{loading ? 'x' : i + 1 + (page - 1) * limit}</td>
                        <td>{formatDate(c.createdAt)}</td>
                        <td>{c.name}</td>
                        <td>{c.phone}</td>
                        <td>{c.ref}</td>
                        <td>{c.reason}</td>

                        <td>{c.approved ? 'APPROVED' : 'NEW'}</td>
                        <td>
                          {!c.approved ? (
                            <div>
                              <Button
                                disabled={approvingCancellation}
                                size="sm"
                                color="danger"
                                onClick={() => this.approveCancellation(c._id)}
                              >
                                Approve
                              </Button>{' '}
                              <Button
                                disabled={loading}
                                size="sm"
                                color="danger"
                                onClick={() => this.delete(c._id)}
                              >
                                Delete
                              </Button>
                            </div>
                          ) : (
                            <span>Treated</span>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Pagination page={page} onChange={this.setPage} />
                {loading && <Loading />}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Col>
    );
  }
}

export default connect()(AgentCancellations);
