import React from 'react';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';
import { Route, Switch, Redirect } from 'react-router-dom';

import { Header, Footer, Sidebar } from '../components';

import routes from '../routes';
import Modals from '../components/Modals';

import {
  isSuperAdmin,
  isFinance,
  isSupportAdmin,
  isShiftSupervisor,
  _checkACL,
  getRoutes,
  logoutAdmin,
  hasACL,
  fetchACL,
} from '../utils';

let ps;

class Main extends React.Component {
  state = {
    _isShiftSupervisor: false,
    _isSuperAdmin: false,
    _isSupportAdmin: false,
    _isFinance: false,
    loading: true,
  };

  mainPanelRef = React.createRef();

  componentDidMount() {
    let finalState = {};
    this.getConfig()
      .then((config) => {
        if (!hasACL()) {
          logoutAdmin();
        }

        finalState = {
          ...finalState,
          ...config,
          loading: false,
        };
      })
      .finally(() => {
        this.setState(finalState);
      });
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
      document.body.classList.toggle('perfect-scrollbar-on');
    }
  }
  componentDidUpdate(e) {
    if (!this.state.loading) {
      if (navigator.platform.indexOf('Win') > -1) {
        ps = new PerfectScrollbar(this.mainPanelRef.current);
        document.body.classList.toggle('perfect-scrollbar-on');
      }
      if (e.history.action === 'PUSH') {
        this.mainPanelRef.current.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
      }
    }
  }

  getConfig = async () => {
    const [supervisor, finance, superAdmin, supportAdmin] = await Promise.all([
      isShiftSupervisor(),
      isFinance(),
      isSuperAdmin(),
      isSupportAdmin(),
      fetchACL(),
    ]);
    return {
      _isShiftSupervisor: supervisor,
      _isFinance: finance,
      _isSuperAdmin: superAdmin,
      _isSupportAdmin: supportAdmin,
    };
  };

  render() {
    const {
      _isSupportAdmin,
      _isSuperAdmin,
      _isShiftSupervisor,
      _isFinance,
      loading,
    } = this.state;

    if (loading) {
      return <div>Loading...</div>;
    }

    return (
      <div className="wrapper">
        <Modals />
        <Sidebar {...this.props} routes={getRoutes(routes)} />
        <div className="main-panel" ref={this.mainPanelRef}>
          <Header {...this.props} />
          <Switch>
            {routes.map((prop, key) => {
              if (prop.views) {
                prop.views.map((prop2, key2) => {
                  return (
                    <Route
                      path={prop2.path}
                      component={prop2.component}
                      key={key2}
                    />
                  );
                });
              }
              if (prop.redirect)
                return <Redirect from={prop.path} to={prop.pathTo} key={key} />;

              if (prop.checkACL && !_checkACL(prop.resource)) {
                return null;
              }

              if (prop.isRestrictedComponent && !_isSuperAdmin) {
                return null;
              }

              if (prop.isFinanceComponent && !_isFinance) {
                return null;
              }

              if (prop.isSupportComponent && !_isSupportAdmin) {
                return null;
              }

              if (
                prop.isRestrictedSupportComponent &&
                !(_isSuperAdmin || _isShiftSupervisor)
              ) {
                return null;
              }

              return (
                <Route path={prop.path} component={prop.component} key={key} />
              );
            })}
          </Switch>
          <Footer fluid />
        </div>
      </div>
    );
  }
}

export default Main;
