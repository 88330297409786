import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { closeAllModals } from '../actions/modalActions';

import VendDetails from './VendDetails';
import TokenResponse from './TokenResponse';
import QMcashDetails from './QMcashDetails';

export default connect((state) => ({
  id: state.modals.data,
  open: state.modals.qmcash,
}))(
  class TransactionModal extends Component {
    state = {};

    close = () => {
      this.props.dispatch(closeAllModals());
    };

    render() {
      const { id, open, dispatch } = this.props;
      return (
        <div>
          <Modal size="lg" isOpen={open} toggle={this.close}>
            <ModalHeader toggle={this.close}>QMCASH Details</ModalHeader>
            <ModalBody>
              <QMcashDetails id={id} dispatch={dispatch} />
              <VendDetails id={id} dispatch={dispatch} />
              <TokenResponse id={id} />
            </ModalBody>
            <ModalFooter />
          </Modal>
        </div>
      );
    }
  }
);
