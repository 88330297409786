import auth from './authReducer';
import user from './userReducer';
import notifications from './notificationReducer';
import modals from './modalsReducer';
import customer from './customerReducer';

export default {
  auth,
  user,
  modals,
  notifications,
  customer,
};
