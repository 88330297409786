import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
} from 'reactstrap';
import React, { Component } from 'react';
// import ClickToCopy from '../ClickToCopy';
// import SettlementsTab from './SettlementsTab';
import { Route, Switch } from 'react-router-dom';
import {
  closeAllModals,
  confirmAction,
  toggleModalLoading,
  openUpdateUserWalletModal,
  openUserModal,
  showSupportMsgModal,
} from '../../actions/modalActions';
import { showError, showInfo } from '../../actions/notificationActions';

import ConfirmActionModal from '../ConfirmActionModal';
import InfoTab from './InfoTab';
import SupportMsgModal from '../SupportMsgModal';
import UpdateUserWallet from '../UpdateUserWallet';
import UserCards from './UserCards';
import UserFacilityGrants from './UserFacilityGrants';
import UserTransactions from './UserTransactions';
import UserVirtualAccounts from './UserVirtualAccounts';
import UserWallet from './UserWallet';
import { callApi } from '../../utils';
import classnames from 'classnames';
import { connect } from 'react-redux';

class Detail extends Component {
  state = {
    user: null,
    meta: {},

    loading: true,
    loadingModal: false,
    supportMsgLoading: false,
    activeTab: '1',
    toggle: null,
    rows: [],
    page: 1,
    pages: 1,
    limit: 10,
  };

  componentWillMount() {
    this.loadUser();
  }

  startLoad = () => this.setState({ loading: true });
  stopLoad = () => this.setState({ loading: false });

  scrollToTop = () =>
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);

  loadUser = () => {
    this.startLoad();
    callApi('/user?id=' + this.props.match.params.id)
      .then(({ user = {}, cards = [] }) => {
        this.setState({ loading: false, user, cards });
      })
      .catch((err) => {
        this.stopLoad();
        this.props.dispatch(showError(err));
      });
  };

  editUser = () => {
    this.props.dispatch(openUserModal(this.props.match.params.id));
  };

  getSettlements = (params) => {
    const id = this.props.match.params.id;

    this.startLoad();
    callApi(`/settlement/${id}`, params)
      .then(({ data = {}, meta = {} }) => {
        this.setState({ rows: data, meta });
        this.scrollToTop();
      })
      .catch((err) => {
        this.props.dispatch(showError(err));
      })
      .finally(() => {
        this.stopLoad();
      });
  };

  setPage = (page) => {
    if (page) {
      this.setState({ page }, () => {
        this.loadView();
      });
    }
  };

  blockSupervisor = (id, newStatus, e) => {
    e.stopPropagation();
    if (!window.confirm(`${newStatus === '0' ? 'Block' : 'Unblock'} user?`))
      return;
    this.setState({ loading: true });
    callApi('/agententitys/status', { id, status: newStatus }, 'post')
      .then((res) => {
        // this.setState({ loading: false });
        this.props.dispatch(showInfo(res.message));
        this.loadView();
      })
      .catch((err) => {
        this.setState({ loading: false });
        this.props.dispatch(showError(err));
      });
  };

  opentUser = (id) => {
    this.props.dispatch(openUserModal(id));
  };

  confirmReset = () => {
    this.props.dispatch(
      confirmAction({
        confirmCallback: this.resetPassword,
        cancel: () => this.props.dispatch(closeAllModals()),
        confirmText: 'Are you sure you want to reset password for this user?',
        loading: this.state.loadingModal,
      })
    );
  };

  updateWallet = () => {
    this.props.dispatch(
      openUpdateUserWalletModal({
        id: this.props.match.params.id,
        onSuccess: this.loadUser,
      })
    );
  };

  resetPassword = () => {
    // if (!window.confirm("Reset user's Password?")) return;
    this.setState({ loadingModal: true }, this.toggleModalLoading);
    callApi('/user/reset?id=' + this.props.match.params.id)
      .then((res) => {
        this.setState({ loadingModal: false }, this.toggleModalLoading);
        this.props.dispatch(showInfo(res.message));
        this.props.dispatch(closeAllModals());
      })
      .catch((err) => {
        this.setState({ loadingModal: false }, this.toggleModalLoading);
        this.props.dispatch(showError(err));
      });
  };

  toggleModalLoading = () => {
    this.props.dispatch(toggleModalLoading(this.state.loadingModal));
  };

  setInput = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  getSupportMessage = () => {
    this.setState({
      supportMsgLoading: true,
    });

    callApi(`/customers/instruction`)
      .then(({ data }) => {
        this.props.dispatch(showSupportMsgModal(data));
        this.setState({
          supportMsgLoading: false,
        });
      })
      .catch((err) => {
        this.props.dispatch(showError(err));
        this.setState({
          supportMsgLoading: false,
        });
      });
  };

  render() {
    let { user, loading, supportMsgLoading } = this.state;

    const { history, match, location } = this.props;

    if (!user) return <React.Fragment />;

    return (
      <div className="content">
        <ConfirmActionModal />
        <UpdateUserWallet />
        <SupportMsgModal customer_id={this.props.match.params.id} />
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardHeader className="ml-2">
                <div className="d-flex justify-content-between align-contents-center">
                  <div>
                    <CardTitle>{user.name || 'Loading...'}</CardTitle>
                    <p className="category">Single User</p>
                  </div>
                  <div>
                    <Button
                      // size="sm"
                      disabled={supportMsgLoading}
                      color={'primary'}
                      onClick={this.getSupportMessage}
                    >
                      Support Message
                    </Button>

                    <Button
                      // size="sm"
                      disabled={loading}
                      color={'primary'}
                      onClick={this.editUser}
                    >
                      Edit
                    </Button>

                    <Button
                      // size="sm"
                      disabled={loading}
                      color={'primary'}
                      onClick={this.updateWallet}
                    >
                      Update Wallet
                    </Button>

                    <Button
                      // size="sm"
                      disabled={loading}
                      color={'danger'}
                      onClick={this.confirmReset}
                    >
                      Reset Password
                    </Button>
                  </div>
                </div>
              </CardHeader>

              <CardBody>
                <div>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        style={{ color: 'black' }}
                        href="#"
                        className={classnames({
                          active: location.pathname === `${match.url}`,
                        })}
                        onClick={() => {
                          history.push(`${match.url}`);
                        }}
                      >
                        General
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ color: 'black' }}
                        href="#"
                        className={classnames({
                          active: location.pathname === `${match.url}/cards`,
                        })}
                        onClick={() => {
                          history.push(`${match.url}/cards`);
                        }}
                      >
                        Cards
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ color: 'black' }}
                        href="#"
                        className={classnames({
                          active: location.pathname === `${match.url}/txns`,
                        })}
                        onClick={() => {
                          history.push(`${match.url}/txns`);
                        }}
                      >
                        Transactions
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ color: 'black' }}
                        href="#"
                        className={classnames({
                          active:
                            location.pathname === `${match.url}/v-accounts`,
                        })}
                        onClick={() => {
                          history.push(`${match.url}/v-accounts`);
                        }}
                      >
                        Virtual Accounts
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ color: 'black' }}
                        href="#"
                        className={classnames({
                          active:
                            location.pathname ===
                            `${match.url}/facility-grants`,
                        })}
                        onClick={() => {
                          history.push(`${match.url}/facility-grants`);
                        }}
                      >
                        Facility Grants
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ color: 'black' }}
                        href="#"
                        className={classnames({
                          active: location.pathname === `${match.url}/wallet`,
                        })}
                        onClick={() => {
                          history.push(`${match.url}/wallet`);
                        }}
                      >
                        Wallet
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <br />
                  {user && (
                    <Switch>
                      <Route exact path={`${match.path}`}>
                        <InfoTab user={user} />
                      </Route>
                      <Route path={`${match.path}/cards`}>
                        <UserCards user={user} />
                      </Route>
                      <Route path={`${match.path}/txns`}>
                        <UserTransactions user={user} />
                      </Route>
                      <Route path={`${match.path}/wallet`}>
                        <UserWallet user={user} />
                      </Route>
                      <Route
                        path={`${match.path}/v-accounts`}
                        render={(props) => (
                          <UserVirtualAccounts {...props} user={user} />
                        )}
                      ></Route>
                      <Route
                        path={`${match.path}/facility-grants`}
                        render={(props) => (
                          <UserFacilityGrants {...props} user={user} />
                        )}
                      ></Route>
                    </Switch>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect()(Detail);
