import React from 'react';

import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { isMobile } from '../utils';

export default ({
  size = isMobile() ? 'md' : 'md',
  onChange,
  page = 1,
  total = isMobile() ? 5 : 30,
}) => {
  if (!onChange) {
    onChange = (num) => {
      page = num;
    };
  }
  const end = total - page <= 10 ? total : page + 10;
  const start = page > 1 ? page - 1 : page;

  return (
    <div className="p-2">
      <Pagination size={size}>
        <PaginationItem
          disabled={page < 2}
          onClick={(e) => {
            e.preventDefault();
            onChange(page > 1 ? page - 1 : null);
          }}
        >
          <PaginationLink previous />
        </PaginationItem>

        {Array(end - start + 1)
          .fill('')
          .map((_, i) => (
            <PaginationItem
              key={i}
              active={page === i + start}
              onClick={(e) => {
                e.preventDefault();
                onChange(i + start);
              }}
            >
              <PaginationLink>{i + start}</PaginationLink>
            </PaginationItem>
          ))}

        <PaginationItem
          disabled={page > total - 1}
          onClick={(e) => {
            e.preventDefault();
            onChange(page < total ? page + 1 : null);
          }}
        >
          <PaginationLink next />
        </PaginationItem>
      </Pagination>
    </div>
  );
};
