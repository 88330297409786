import { Route, Switch } from 'react-router-dom';

import Detail from './Merchants/Detail';
import Merchants from './Merchants/Index';
import React from 'react';

function Refundables() {
  return (
    // <div>
    <Switch>
      <Route path="/merchants" component={Merchants} />
      {/* <Route exact path="/merchants/list" component={List} /> */}
      <Route exact path="/merchants/:id" component={Detail} />
    </Switch>
    // </div>
  );
}

export default Refundables;
