import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  FormGroup,
  Label,
  ModalFooter,
} from 'reactstrap';

import { callApi, GATEWAYS } from '../../../utils';

import JsonViewer from '../../JsonViewer';

function ResourceInfoModal(props) {
  const defaultParams = {
    resourceId: '',
    plan: '',
    gateway: '',
  };
  const [error, setError] = useState(null);
  const [querying, setQuerying] = useState(false);
  const [resourceInfo, setResourceInfo] = useState(null);
  const [params, setParams] = useState(defaultParams);

  const { service, onCreateOrder, isOpen, ...rest } = props;

  if (!isOpen) {
    return <React.Fragment />;
  }

  const fetchResourceInfo = async ({ resourceId, plan }) => {
    const resourceInfo = await callApi(
      `/services/${service.id}/resources/${resourceId}`,
      {
        plan,
      }
    ).then((res) => res.data);

    return resourceInfo;
  };

  const onResourceQuery = async () => {
    setError(null);
    setQuerying(true);

    const { resourceId, plan } = params;
    const resourceInfo = await fetchResourceInfo({
      resourceId,
      plan,
    })
      .catch(setError)
      .finally(() => {
        setQuerying(false);
      });

    setResourceInfo(resourceInfo);
  };

  const handleInputChange = (input) => {
    setParams({
      ...params,
      [input.name]: input.value,
    });
  };

  const toggle = () => {
    setParams(defaultParams);
    setResourceInfo(null);
    props.toggle();
  };

  let gateways = [];

  if (!resourceInfo) {
    gateways = GATEWAYS[service.code] || [];
  }

  return (
    <Modal {...rest} toggle={toggle} isOpen={isOpen}>
      {!resourceInfo ? (
        <React.Fragment>
          <ModalHeader tag="h5" toggle={toggle}>
            Get Resource Info
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="resourceId">Resource</Label>
              <Input
                id="resourceId"
                name="resourceId"
                type="text"
                value={params.resourceId}
                onChange={(e) => handleInputChange(e.target)}
                readOnly={querying}
                autoFocus
              ></Input>
            </FormGroup>
            <FormGroup>
              <Label for="plan">Plan</Label>
              <Input
                id="plan"
                name="plan"
                type="select"
                value={params.plan}
                onChange={(e) => handleInputChange(e.target)}
                readOnly={querying}
              >
                <option value={null}>Select a plan</option>
                <option>PREPAID</option>
                <option>POSTPAID</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="gateway">Gateway</Label>
              <Input
                id="gateway"
                name="gateway"
                type="select"
                value={params.gateway}
                onChange={(e) => handleInputChange(e.target)}
                readOnly={querying}
              >
                <option value={null}>Select a gateway</option>
                {gateways.map((s) => (
                  <option key={s.value} value={s.value}>
                    {s.name}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            {error && <div className="text-warning">{error.message}</div>}
            <Button onClick={() => onResourceQuery()} disabled={querying}>
              Query
            </Button>
          </ModalFooter>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <ModalHeader tag="div" toggle={toggle}>
            <h5 className="mb-0">{resourceInfo.meterNo}</h5>
            <div>{resourceInfo.address}</div>
          </ModalHeader>
          <ModalBody>
            <div>
              <Button
                size="sm"
                onClick={() =>
                  onCreateOrder({
                    disco: service.id,
                    meter: resourceInfo.meterNo,
                    vendType: resourceInfo.vendType,
                    amount: resourceInfo.minVendAmount,
                  })
                }
              >
                Create Order
              </Button>
            </div>
            <JsonViewer
              src={resourceInfo}
              name={'Resource Info'}
              iconStyle={'circle'}
              displayDataTypes={false}
              displayObjectSize={false}
            />
          </ModalBody>
        </React.Fragment>
      )}
    </Modal>
  );
}

export default ResourceInfoModal;
