import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from './Loading';
import { callApi } from '../utils';
import { showError, showInfo } from '../actions/notificationActions';
import { getSupervisor } from '../utils';
import {
  Row,
  Col,
  Input,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
} from 'reactstrap';

export default connect((state) => ({
  id: state.modals.data,
  open: state.modals.session,
}))(
  class SessionModal extends Component {
    state = {
      loading: false,
      users: [],
      supervisorId: '',
      ready: false,
      submitBtn: 'Set Supervisor',
      supervisorName: '',
    };

    setInput = (e) => {
      const { name, value } = e.target;
      this.setState({ [name]: value }, this.checkReady);
    };

    checkReady = () => {
      const { supervisorId } = this.state;
      if (supervisorId) {
        this.setState({ ready: true, submitBtn: 'Submit' });
      } else {
        this.setState({ ready: false, submitBtn: 'Set Supervisor' });
      }
    };

    setSupervisor = (e) => {
      e.preventDefault();
      this.setState({ loading: true });
      const { supervisorId } = this.state;
      callApi('/supervisor', { id: supervisorId }, 'post')
        .then(({ message }) => {
          this.props.dispatch(showInfo(message));
          this.setSupervisorName();
        })
        .catch((err) => {
          this.props.dispatch(showError(err));
        })
        .finally(this.setState({ loading: false }));
    };

    componentDidMount() {
      this.getAllAdminUsers();
      this.setSupervisorName();
    }

    setSupervisorName = async () => {
      const { name } = await getSupervisor();
      this.setState({ supervisorName: name });
    };

    getAllAdminUsers = () => {
      this.setState({ loading: true });
      callApi('/adminusers/all')
        .then(({ data }) => {
          this.setState({ users: data, loading: false });
        })
        .catch((err) => {
          this.setState({ loading: false });
          this.props.dispatch(showError(err));
        });
    };

    render() {
      const {
        users,
        ready,
        submitBtn,
        loading,
        supervisorId,
        supervisorName,
      } = this.state;
      return (
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <Card>
                <CardHeader className="ml-2">
                  <CardTitle>Set Supervisor</CardTitle>
                  <p className="category">Set Supervisor</p>
                </CardHeader>
                {loading ? <Loading /> : null}
                <CardBody>
                  <h3>Current Supervisor - {supervisorName || 'NONE'}</h3>
                  <form
                    className="order-form"
                    id="createOrderForm"
                    onSubmit={this.setSupervisor}
                  >
                    <Input
                      name="supervisorId"
                      type="select"
                      onChange={this.setInput.bind(this)}
                      value={supervisorId}
                      style={{ maxWidth: '500px' }}
                    >
                      <option value="">Please select</option>
                      {users.map((u, index) => (
                        <option key={index} value={u.id}>
                          {u.name.toUpperCase()}
                        </option>
                      ))}
                    </Input>

                    <button
                      type="submit"
                      disabled={!ready || loading}
                      style={{ marginTop: '20px', padding: '15px' }}
                    >
                      {submitBtn}
                    </button>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      );
    }
  }
);
