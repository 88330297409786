import React, { useState, useEffect, useContext } from 'react';
import { Table, Button, Card, CardBody } from 'reactstrap';

import { callApi, formatAmount, formatDate } from '../../../utils';

import { openTransactionModal } from '../../../actions/modalActions';

import Pagination from '../../Pagination';
import Loading from '../../Loading';
import { ServiceContext } from './';

function Transactions({ dispatch }) {
  let [limit] = useState(30);
  let [page, setPage] = useState(1);
  let [meta, setMeta] = useState({});
  let [loading, setLoading] = useState(true);
  let [refreshing, setRefreshing] = useState(false);
  let [transactions, setTransactions] = useState([]);

  const { service } = useContext(ServiceContext);

  const fetchTransactions = async (service) => {
    if (!!service) {
      return callApi(`/services/${service.id}/transactions`, {
        include: ['agent', 'merchant'],
        page,
        limit,
      }).then((res) => {
        setTransactions(res.data);
        setMeta(res.meta || {});
      });
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchTransactions(service).finally(() => {
      setLoading(false);
    });
  }, [service, page]);

  if (!service) {
    return <React.Fragment />;
  }

  const openTransaction = (id) => {
    dispatch(openTransactionModal({ id }));
  };

  const refresh = () => {
    setRefreshing(true);
    fetchTransactions().finally(() => {
      setRefreshing(false);
    });
  };

  return (
    <Card>
      <CardBody>
        <Button disabled={refreshing || loading} onClick={refresh}>
          Refresh
        </Button>
        {loading ? (
          <Loading />
        ) : (
          <Table responsive hover striped>
            <thead className="text-primary">
              <tr>
                <th>No.</th>
                <th>Reference</th>
                <th>Date</th>
                <th>Name</th>
                <th>Phone</th>
                <th>Meter</th>
                <th>Amount</th>
                <th>Type</th>
                <th>Channel</th>
                <th>Agent</th>
                <th>Business</th>
                <th>Status</th>
              </tr>
            </thead>

            <tbody>
              {!!transactions.length ? (
                transactions.map((t, i) => (
                  <tr onClick={() => openTransaction(t.order_id)} key={t.id}>
                    <td>{loading ? 'x' : i + 1 + (page - 1) * limit}</td>
                    <td>{formatDate(t.created_at)}</td>
                    <td>{t.order_id}</td>
                    <td>{t.name}</td>
                    <td>{t.phone}</td>
                    <td>{t.meter_no}</td>
                    <td>{formatAmount(t.amount)}</td>
                    <td>{t.vend_type}</td>
                    <td>{t.channel || 'N/A'}</td>
                    <td>{t.agent.name}</td>
                    <td>{t.merchant.name}</td>
                    <td>{t.order_status}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={12} className="text-center">
                    No records
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        )}
        <Pagination
          page={page}
          total={meta.count}
          onChange={(page) => setPage(page)}
        />
      </CardBody>
    </Card>
  );
}

export default Transactions;
