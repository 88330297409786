import { Button, Col, Nav, NavItem, NavLink, Row } from 'reactstrap';
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  closeAllModals,
  openGenerateModal,
  openUserModal,
} from '../../actions/modalActions';
import { showError, showInfo } from '../../actions/notificationActions';

import ConfirmActionModal from '../ConfirmActionModal';
import { Insight } from './Insight';
import Payments from './Payments';
import Response from './Response';
import Similar from './Similar';
import TransactionTimeline from './TransactionTimeline';
import { callApi } from '../../utils';
import classnames from 'classnames';
import { connect } from 'react-redux';

class Detail extends Component {
  state = {
    order: {},
    meta: {},

    loading: true,
    toggling: false,
    activeTab: '1',
    toggle: null,
    rows: [],
    page: 1,
    pages: 1,
    limit: 10,
    enabled: false,
  };

  componentDidMount() {
    this.loadView();
    this.props.dispatch(closeAllModals());
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.loadView();
    }
  }

  startLoad = () => this.setState({ loading: true });
  stopLoad = () => this.setState({ loading: false });

  scrollToTop = () =>
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);

  loadView = (e) => {
    const id = this.props.match.params.id;

    this.startLoad();
    callApi(`/orders/${id}?include=["agent"]`)
      .then(({ data = {}, meta = {} }) => {
        this.setState({ order: data, meta });
        this.scrollToTop();
      })
      .catch((err) => {
        this.props.dispatch(showError(err));
      })
      .finally(() => {
        this.stopLoad();
      });
  };

  setPage = (page) => {
    if (page) {
      this.setState({ page }, () => {
        this.loadView();
      });
    }
  };

  opentUser = (id) => {
    this.props.dispatch(openUserModal(id));
  };

  setInput = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  toggleStatus = () => {
    const { id } = this.props.match.params;
    let { enabled } = this.state;

    this.setState({ toggling: true });

    callApi(`/services/${id}/status`, {}, 'put')
      .then(({ data = {}, meta = {} }) => {
        // this.scrollToTop();
        enabled = data.active;
      })
      .catch((err) => {
        this.props.dispatch(showError(err));
      })
      .finally(() => {
        this.setState({ enabled, toggling: false });
      });
  };

  cancel = async (e) => {
    if (
      !window.confirm(
        `This will cancel the transaction and move the money to the customer's wallet, Proceed?`
      )
    )
      return;

    try {
      this.startLoad();
      const res = await callApi(`/orders/${this.state.order.id}`, {}, 'delete');
      this.props.dispatch(showInfo(res.message));
      this.loadView();
    } catch (error) {
      this.props.dispatch(showError(error.message));
    } finally {
      this.stopLoad();
    }
  };

  cancelAndReorder = async (e) => {
    if (
      !window.confirm(
        `This will cancel the transaction and move the money to the customer's wallet, Proceed?`
      )
    )
      return;
    try {
      const { order = {} } = this.state;
      this.startLoad();
      await callApi(`/orders/${order.id}`, {}, 'delete');

      this.props.dispatch(
        openGenerateModal({
          meter: order.meter_no,
          disco: order.disco_code,
          mode: 'CASH',
          vendType: order.vend_type,
          phone: order.phone,
          amount: order.vend_amount,
          id: order.id,
          api_method: 'put',
          link: `/orders/${order.id}`,
          btnText: 'Update',
          reroute: true,
          done: (params) => {
            this.props.dispatch(closeAllModals());
            this.props.dispatch(showInfo(params.message));
            this.props.history.push(`/orders/${params.id}`, {
              queryCanceledOrder: true,
            });
          },
          failed: () => {},
        })
      );

      // const { data = {}, message = '' } = await callApi(
      //   `/orders/${this.state.order.id}`,
      //   {},
      //   'put'
      // );
      // this.props.dispatch(showInfo(message));
      // this.props.history.push(`/orders/${data.id}`);

      // this.loadView();
    } catch (error) {
      this.props.dispatch(showError(error.message));
    } finally {
      this.stopLoad();
    }
  };

  treat = (flag = true) => {
    if (
      !window.confirm(
        `${flag ? 'Treat' : 'Untreat'} this transaction ${
          this.state.order.id
        } ?`
      )
    )
      return;

    this.setState({ loading: true });
    let link =
      '/transaction/treat' +
      `?id=${this.state.order.order_id}&treat=${flag ? 'true' : ''}`;
    callApi(link)
      .then((res) => {
        this.props.dispatch(
          showInfo(res.message || 'Transaction treated successfully.')
        );
        this.loadView();
      })
      .catch((err) => {
        this.props.dispatch(showError(err));
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  sendSms = async () => {
    const { dispatch } = this.props;
    try {
      this.startLoad();
      const { order } = this.state;
      const res = await callApi(`/orders/${order.id}/delivery`, {}, 'post');
      dispatch(showInfo(res.message));
    } catch (error) {
      dispatch(showError(error.message || 'An error occurred'));
    } finally {
      this.stopLoad();
    }
  };

  render() {
    const { order, loading, meta } = this.state;
    const { history, match, location } = this.props;

    return (
      <div className="container-fluid mt-3">
        <ConfirmActionModal />
        <Row>
          <Col xs={12} md={12}>
            <div>
              <div>
                <Row>
                  <Col xs={12} md={6}>
                    <h4 className="my-0 d-none d-sm-block">
                      {order.order_id || 'Loading...'}
                    </h4>
                    <h6 className="my-0 d-sm-none">
                      {order.order_id || 'Loading...'}
                    </h6>
                    <p className="category">Single Order</p>
                  </Col>
                  <Col xs={12} md={6} className="text-md-right text-xs-center">
                    {order.treated_on ? (
                      <Button
                        disabled={loading}
                        onClick={() => this.treat(false)}
                        color="danger"
                      >
                        UNTREAT
                      </Button>
                    ) : (
                      <Button
                        disabled={loading}
                        onClick={this.treat}
                        color="success"
                      >
                        TREAT
                      </Button>
                    )}
                    {order.order_status === 'SUCCESSFUL' && (
                      <Button
                        disabled={loading}
                        onClick={this.sendSms}
                        color="success"
                      >
                        SEND SMS
                      </Button>
                    )}
                    {meta.canRefund && (
                      <Button
                        disabled={!meta.canRefund || loading}
                        onClick={this.cancel}
                        color="primary"
                      >
                        CANCEL
                      </Button>
                    )}
                    {meta.canRegenerate && (
                      <Button
                        disabled={!meta.canRegenerate || loading}
                        onClick={this.cancelAndReorder}
                        color="primary"
                      >
                        CANCEL &amp; RE-ORDER
                      </Button>
                    )}
                  </Col>
                  {/* <div>
                    <label className={toggling ? 'switch' : 'switch disabled'}>
                      <input
                        type="checkbox"
                        checked={enabled}
                        onChange={this.toggleStatus}
                      />
                      <span className="slider round" />
                    </label>
                  </div> */}
                </Row>
              </div>
              <div>
                <div>
                  <Nav pills={true}>
                    <NavItem>
                      <NavLink
                        href={`${match.url}`}
                        className={classnames({
                          active: location.pathname === `${match.url}`,
                        })}
                        onClick={(e) => {
                          e.preventDefault();
                          history.push(e.target.getAttribute('href'));
                        }}
                      >
                        General
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        href={`${match.url}/payments`}
                        className={classnames({
                          active: location.pathname === `${match.url}/payments`,
                        })}
                        onClick={(e) => {
                          e.preventDefault();
                          history.push(e.target.getAttribute('href'));
                        }}
                      >
                        Payments
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href={`${match.url}/timeline`}
                        className={classnames({
                          active: location.pathname === `${match.url}/timeline`,
                        })}
                        onClick={(e) => {
                          e.preventDefault();
                          history.push(e.target.getAttribute('href'));
                        }}
                      >
                        Timeline
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href={`${match.url}/similar`}
                        className={classnames({
                          active: location.pathname === `${match.url}/similar`,
                        })}
                        onClick={(e) => {
                          e.preventDefault();
                          history.push(e.target.getAttribute('href'));
                        }}
                      >
                        Similar Orders
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href={`${match.url}/responses`}
                        className={classnames({
                          active:
                            location.pathname === `${match.url}/responses`,
                        })}
                        onClick={(e) => {
                          e.preventDefault();
                          history.push(e.target.getAttribute('href'));
                        }}
                      >
                        Responses
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <br />
                  <div>
                    <Switch>
                      <Route
                        exact
                        path={`${match.path}`}
                        render={(props) => (
                          <Insight
                            {...props}
                            order={order}
                            loading={loading}
                            reload={this.loadView}
                          />
                        )}
                      ></Route>
                      <Route
                        exact
                        path={`${match.path}/timeline`}
                        render={(props) => (
                          <TransactionTimeline {...props} order={order} />
                        )}
                      ></Route>
                      <Route
                        exact
                        path={`${match.path}/payments`}
                        render={(props) => (
                          <Payments {...props} order={order} />
                        )}
                      ></Route>
                      <Route
                        exact
                        path={`${match.path}/similar`}
                        render={(props) => <Similar {...props} order={order} />}
                      ></Route>
                      <Route
                        exact
                        path={`${match.path}/responses`}
                        render={(props) => (
                          <Response {...props} order={order} />
                        )}
                      ></Route>
                    </Switch>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect()(Detail);
