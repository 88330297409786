import React from 'react';
import { Row, Col, Card, CardHeader, CardTitle, CardBody } from 'reactstrap';
import { formatDate } from '../../utils';
import Loading from '../Loading';
import VerificationAsset from './VerificationAsset';

export function Insight(props) {
  const { verification = {}, loading } = props;

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <Row>
          <Col sm="6">
            {/* <Col> */}
            <Card className="card-chart">
              <CardHeader>
                <CardTitle className="mt-0">Facility</CardTitle>
              </CardHeader>
              <CardBody>
                <div className="d-flex mb-3 justify-content-between">
                  <div>
                    <span className="text-secondary">Meter No.</span>
                  </div>
                  <span>
                    <span>
                      <strong>{verification.facility.reference}</strong>
                    </span>
                  </span>
                </div>
                <div className="d-flex mb-3 justify-content-between">
                  <div>
                    <span className="text-secondary">Owner</span>
                  </div>
                  <div> {verification.facility.owner} </div>
                </div>
                <div className="d-flex mb-3 justify-content-between">
                  <div>
                    <span className="text-secondary">Address</span>
                  </div>
                  <div> {verification.facility.address} </div>
                </div>
                <div className="d-flex mb-3 justify-content-between">
                  <div>
                    <span className="text-secondary">Asset Provider</span>
                  </div>
                  <div> {verification.facility.asset_provider} </div>
                </div>
                <div className="d-flex mb-3 justify-content-between">
                  <div>
                    <span className="text-secondary">Service Provider</span>
                  </div>
                  <div> {verification.facility.service_provider} </div>
                </div>
                <div className="d-flex mb-3 justify-content-between">
                  <div>
                    <span className="text-secondary">Type</span>
                  </div>
                  <div> {verification.facility.type} </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          {/* <Card className="card-chart">
              <CardHeader>
                <CardTitle className="mt-0">Customer</CardTitle>
              </CardHeader>
              <CardBody>
                <div className="d-flex mb-3 justify-content-between">
                  <div>
                    <span className="text-secondary">Name</span>
                  </div>
                  {verification.customer.name && <div> {verification.customer.name} </div>}
                </div>
                <div className="d-flex mb-3 justify-content-between">
                  <div>
                    <span className="text-secondary">Phone</span>
                  </div>
                  <div> {verification.customer.phone} </div>
                </div>
                <div className="d-flex mb-3 justify-content-between">
                  <div>
                    <span className="text-secondary">Email</span>
                  </div>
                  <div> {verification.customer.email} </div>
                </div>
              </CardBody>
            </Card> */}
          {/* </Col> */}
          <Col sm="6">
            <Row>
              <Col>
                <Card className="card-chart">
                  <CardHeader>
                    <CardTitle className="mt-0">Verification Detail</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <div className="d-flex mb-3 justify-content-between">
                      <div>
                        <span className="text-secondary">Status</span>
                      </div>
                      <div> {verification.status} </div>
                    </div>
                    <div className="d-flex mb-3 justify-content-between">
                      <div>
                        <span className="text-secondary">Created At</span>
                      </div>
                      {verification.createdAt && (
                        <div> {formatDate(verification.createdAt)} </div>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card className="card-chart">
                  <CardHeader>
                    <CardTitle className="mt-0">Customer</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <div className="d-flex mb-3 justify-content-between">
                      <div>
                        <span className="text-secondary">Name</span>
                      </div>
                      {verification.customer.name && (
                        <div> {verification.customer.name} </div>
                      )}
                    </div>
                    <div className="d-flex mb-3 justify-content-between">
                      <div>
                        <span className="text-secondary">Phone</span>
                      </div>
                      <div> {verification.customer.phone} </div>
                    </div>
                    <div className="d-flex mb-3 justify-content-between">
                      <div>
                        <span className="text-secondary">Email</span>
                      </div>
                      <div> {verification.customer.email} </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      )}{' '}
      <Row>
        <Col sm="12">
          <VerificationAsset
            // {...props}
            assets={verification.assets}
            facility={verification.facility}
          />
        </Col>
      </Row>
    </div>
  );
}
