import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  Row,
  Col,
  Input,
  FormGroup,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Table,
  Badge,
} from 'reactstrap';

import { callApi, formatDate, getDuration } from '../utils';
import { showError } from '../actions/notificationActions';

import SearchForm from './SearchForm';
import Loading from './Loading';
import Pagination from './Pagination';
import { openSessionModal } from '../actions/modalActions';

const getSessionState = (data = []) => {
  if (data.some((d) => d.category === 'GOAL_EVENT')) {
    return <Badge color="success">PAID</Badge>;
  } else if (data.some((d) => d.category === 'CALL')) {
    return <Badge color="primary">CALLED</Badge>;
  } else if (data.some((d) => d.category === 'ERROR')) {
    return <Badge color="danger">ERROR</Badge>;
  } else return null;
};

class UserSessions extends Component {
  state = {
    query: '',
    sessions: [],
    allSessions: [],
    page: 1,
    limit: 30,
    loading: false,
    action: '',
    label: '',
    category: '',
    newDisco: true,
  };

  componentWillMount() {
    this.loadSessions();
  }

  startLoad = () => this.setState({ loading: true });
  stopLoad = () => this.setState({ loading: false });

  scrollToTop = () =>
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);

  loadSessions = (e) => {
    // Event may be passed in from search form
    if (e) e.preventDefault();

    const {
      query,
      page,
      limit,
      action,
      label,
      category,
      newDisco,
    } = this.state;
    this.startLoad();
    callApi(
      `/analytics/sessions?q=${query}&page=${page}&limit=${limit}&action=${action}&label=${label}&category=${category}&newDisco=${newDisco}`
    )
      .then(({ sessions = [] }) => {
        this.setState({
          loading: false,
          sessions: sessions.filter(
            (s) => +new Date(s.created_at) !== +new Date(s.updated_at)
          ),
        });
        this.scrollToTop();
      })
      .catch((err) => {
        this.stopLoad();
        this.props.dispatch(showError(err));
      });
  };

  toggleNewDisco = () => {
    this.setState(({ newDisco }) => ({ newDisco: !newDisco }));
  };

  setPage = (page) => {
    if (page) {
      this.setState({ page }, () => {
        this.loadSessions();
      });
    }
  };

  openSession = (id) => {
    this.props.dispatch(openSessionModal(id));
  };

  setQuery = (e) => {
    this.setState({ query: e.target.value });
  };

  filter = (e) => {
    this.setState({ category: e.target.value }, () => {
      this.loadSessions();
    });
  };

  render() {
    const {
      query,
      sessions,
      page,
      loading,
      limit,
      category,
      newDisco,
    } = this.state;
    return (
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardHeader className="ml-2">
                <CardTitle>User Sessions</CardTitle>
                <p className="category">User Sessions - Page {page}</p>
              </CardHeader>

              <SearchForm
                value={query}
                onChange={this.setQuery}
                onSearch={this.loadSessions}
                loading={loading}
              />

              <div style={{ padding: 20 }}>
                <FormGroup>
                  <h6 style={{ fontWeight: 500 }}>Filter</h6>
                  <Input
                    name="filter"
                    type="select"
                    onChange={this.filter.bind(this)}
                    value={category}
                    style={{ maxWidth: '500px' }}
                  >
                    <option value="ERROR">Sessions with errors</option>
                    <option value="">All user sessions</option>
                  </Input>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={newDisco}
                      onChange={this.toggleNewDisco}
                    />
                    <span className="slider round" />
                  </label>
                </FormGroup>
              </div>

              <CardBody>
                <Table responsive hover striped>
                  <thead className="text-primary">
                    <tr>
                      <th>No.</th>
                      <th>Time</th>
                      <th>Duration</th>
                      <th>Name</th>
                      <th>Phone</th>
                      <th>State</th>
                    </tr>
                  </thead>

                  <tbody>
                    {sessions.map((s, i) => (
                      <tr onClick={() => this.openSession(s._id)} key={s._id}>
                        <td>{loading ? 'x' : i + 1 + (page - 1) * limit}</td>
                        <td>{formatDate(s.created_at)}</td>
                        <td>{getDuration(s.created_at, s.updated_at)}</td>
                        <td>{s.name}</td>
                        <td>{s.phone}</td>
                        <td>{getSessionState(s.data)}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Pagination page={page} onChange={this.setPage} />
                {loading && <Loading />}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect()(UserSessions);
