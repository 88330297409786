import React, { Component } from 'react';
import { connect } from 'react-redux';
import { callApi } from '../utils';
import { AdminConfig } from '../config';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
  Button,
} from 'reactstrap';

import { closeAllModals } from '../actions/modalActions';

export default connect((state) => ({
  open: state.modals.virtualAccount,
  customerPhone: state.customer.phone,
  customerId: state.customer.id,
}))(
  class CreateVirtualAccountModal extends Component {
    state = {
      loading: false,
      phone: '',
      meter: '',
      disco: '',
      provider: '',
      error: '',
    };

    componentDidMount() {
      this.setState({ phone: this.props.customerPhone });
    }

    setInput = (e) => {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    };

    close = () => {
      this.props.dispatch(closeAllModals());
    };

    submit = (e) => {
      e.preventDefault();
      //   console.log(this.props.customerId) //1255452
      if (
        !this.state.phone ||
        !this.state.meter ||
        !this.state.disco ||
        !this.state.provider
      )
        return this.setState({ error: 'Please fill in all fields!' });
      this.setState({ error: '' });

      let customerId = this.props.customerId;
      let bodyObj = {
        meter: this.state.meter,
        disco: this.state.disco,
        provider: this.state.provider,
      };

      this.setState({ loading: true });

      // Virtual Account Creation Request
      callApi(`/customers/${customerId}/virtual-accounts`, bodyObj, 'post')
        .then((res) => {
          this.setState({ loading: false });
          //   this.props.virtualAcctResponse(res.data);
          alert(res.message);
          this.props.refresh(res.data);
          this.close();
        })
        .catch((err) => {
          this.setState({ loading: false });
          if ((err.status = 'unprocessable')) alert(err.message);
        });
    };

    render() {
      const { meter, loading } = this.state;

      const open = this.props.open;
      return (
        <div>
          <Modal size="lg" isOpen={open} toggle={this.close}>
            <ModalHeader toggle={this.close}>
              Create Virtual Account
            </ModalHeader>
            <ModalBody>
              <form>
                <div>
                  <FormGroup>
                    <Label className="mt-3">Meter:</Label>
                    <Input
                      name="meter"
                      type="number"
                      onChange={this.setInput}
                      value={meter}
                      disabled={loading}
                    />

                    <Label className="mt-3">Disco:</Label>
                    <Input
                      type="select"
                      name="disco"
                      onChange={this.setInput}
                      disabled={loading}
                    >
                      <option>Select a Disco</option>
                      {AdminConfig.disco.map((value, index) => (
                        <option key={index} value={value}>
                          {value.toUpperCase()}
                        </option>
                      ))}
                    </Input>

                    <Label className="mt-3">Provider:</Label>
                    <Input
                      type="select"
                      name="provider"
                      onChange={this.setInput}
                      disabled={loading}
                      className="mb-2"
                    >
                      <option>Select a Provider</option>
                      {AdminConfig.provider.map((value, index) => (
                        <option key={index} value={value}>
                          {value.toUpperCase()}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </div>
                <div />
              </form>
              {this.state.error ? (
                <span style={{ color: 'red' }}>{this.state.error}</span>
              ) : (
                ''
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="success" disabled={loading} onClick={this.submit}>
                Create Account{' '}
                {loading ? (
                  <span className="fas fa-spinner fa-spin"></span>
                ) : (
                  ''
                )}
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      );
    }
  }
);
