import React from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Label,
  Button,
  Table,
  FormGroup,
  Input,
  Badge,
} from 'reactstrap';

import { callApi, formatAmount, formatDateTime } from '../../utils';
import { showError } from '../../actions/notificationActions';
import Pagination from '../Pagination';

import Loading from '../Loading';
import { openTransactionModal } from '../../actions/modalActions';

class MerchantWallet extends React.Component {
  state = {
    queryParams: {
      from: '',
      to: '',
      q: '',
      disco: '',
      page: 1,
      limit: '30',
      subAccount: '',
      amount: '',
      generatedBy: '1',
      operation: '',
    },
    pages: 1,
    meta: { pages: 1 },
    loading: false,
    rows: [],
  };

  setInput = (e) => {
    const { name, value } = e.target;
    this.setState({
      queryParams: { ...this.state.queryParams, [name]: value },
    });
    // setQueryParams({[name]: value})
  };

  setPage = (page) => {
    if (page) {
      this.setState(
        { queryParams: { ...this.state.queryParams, page } },
        () => {
          this.loadTimeline();
        }
      );
    }
  };

  startLoad = () => this.setState({ loading: true });
  stopLoad = () => this.setState({ loading: false });

  loadTimeline = async () => {
    const { from, to, page, operation, q } = this.state.queryParams;
    const id = this.props.id;
    this.setState({ loading: true });

    callApi(`/merchants/${id}/wallet-transactions`, {
      from,
      to,
      q,
      operation,
      page,
    })
      .then(({ data = [], meta = {} }) => {
        this.setState({ loading: false });
        this.setState({ rows: data, meta });
      })
      .catch((err) => {
        this.setState({ loading: false });
        this.props.dispatch(showError(err));
      });
  };
  componentWillMount() {
    this.loadTimeline();
  }

  openTransaction = (id) => {
    this.props.dispatch(openTransactionModal({ id }));
  };

  render() {
    const { rows, queryParams, loading, meta } = this.state;

    const { from, to, operation, q } = queryParams;

    return (
      <Row>
        <Col md="12" xs={12}>
          <div className="mt-10">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                this.getSettlements();
              }}
              className="mb-20"
            >
              <Row className="justify-content-center mx-3">
                <div>
                  <div>Balance</div>
                  <h5 className="mt-0">
                    {formatAmount(this.props.merchant.wallet)}
                  </h5>
                </div>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Search</Label>
                    <Input
                      onChange={this.setInput}
                      value={q}
                      name="q"
                      placeholder="Search by Transaction Reference or Phone Number"
                      type="text"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row colSpan={3}>
                <Col>
                  <FormGroup>
                    <Label>From</Label>
                    <Input
                      onChange={this.setInput}
                      value={from}
                      name="from"
                      placeholder="From"
                      type="date"
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>To</Label>
                    <Input
                      onChange={this.setInput}
                      value={to}
                      name="to"
                      placeholder="To"
                      type="date"
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>Operation</Label>
                    <Input
                      onChange={this.setInput}
                      value={operation}
                      name="operation"
                      placeholder="Operation"
                      type="select"
                    >
                      <option value="">ALL</option>
                      <option value="credit">CREDIT</option>
                      <option value="debit">DEBIT</option>
                    </Input>
                  </FormGroup>
                </Col>
                {/* <Col>
                  <FormGroup>
                    <Label>Amount</Label>
                    <Input
                      onChange={this.setInput}
                      value={amount}
                      name="amount"
                      placeholder="Amount"
                      type="number"
                    />
                  </FormGroup>
                </Col> */}
              </Row>
              <Row className="justify-content-center mx-3">
                {/* <div>
                <div>Balance</div>
                <h5 className="mt-0">{formatAmount(this.props.merchant.wallet)}</h5>
                </div> */}
                <Button size="lg" color="primary" onClick={this.loadTimeline}>
                  {loading ? 'PLEASE WAIT...' : 'Query'}
                </Button>
              </Row>
            </form>

            {loading ? (
              <Loading />
            ) : (
              <Table responsive hover striped>
                <thead className="text-primary">
                  <tr>
                    <th>#</th>
                    <th>Date</th>
                    <th>Reference</th>
                    <th>Amount</th>
                    <th>Balance Before</th>
                    <th>Balance After</th>
                    <th>Type</th>
                  </tr>
                </thead>

                <tbody>
                  {rows.map((t, i) => {
                    return (
                      <tr
                        // onClick={() => this.openTransaction(t.order_id)}
                        key={t.order_id}
                      >
                        <td>
                          {(queryParams.page - 1) * queryParams.limit + (i + 1)}
                        </td>
                        <td>{formatDateTime(t.created_at)}</td>
                        <td>{t.ref}</td>
                        <td
                          className={
                            t.operation === 'credit'
                              ? 'text-success'
                              : 'text-danger'
                          }
                        >
                          <span className="font-weight-bold">
                            {formatAmount(t.amount)}
                          </span>
                        </td>
                        <td>{formatAmount(t.balance_before)}</td>
                        <td>{formatAmount(t.balance_after)}</td>
                        <td>
                          <Badge>{t.type}</Badge>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            )}
            <Pagination
              total={meta.pages || 1}
              page={queryParams.page}
              onChange={this.setPage}
            />
          </div>
          {/* </Card> */}
        </Col>
      </Row>
    );
  }
}

export default connect()(MerchantWallet);
