import React from 'react';

const SwitchRadio = ({
  checked,
  onChange,
  size,
  name,
  index,
  toggling,
  ...props
}) => {
  return (
    <div key={index} {...props}>
      <span className="mr-3">{name}</span>
      <label
        className={!toggling ? `switch ${size}` : `switch ${size} disabled`}
      >
        <input type="checkbox" checked={checked} onChange={onChange} />
        <span className="slider round" />
      </label>
    </div>
  );
};

export default SwitchRadio;
