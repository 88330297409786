import { Route, Switch } from 'react-router-dom';

import Detail from './Surveys/Detail';
import List from './Surveys//List';
import React from 'react';

function Surveys() {
  return (
    <Switch>
      <Route exact path="/surveys" component={List} />
      <Route path="/surveys/:id" component={Detail} />
    </Switch>
  );
}

export default Surveys;
