import { AUTHENTICATE, UNAUTHENTICATE } from '../actions/authActions';

const initAuthState = {
  authenticated: false,
};

export default (state = initAuthState, action) => {
  switch (action.type) {
    case AUTHENTICATE:
      return { ...state, authenticated: true };

    case UNAUTHENTICATE:
      return { ...state, authenticated: false };

    default:
      return state;
  }
};
