import React, { Component } from 'react';

import { Collapse, Button, Card, Table } from 'reactstrap';

import Loading from './Loading';
import { callApi, formatAmount, formatDateTime } from '../utils';

export default class OrderPayments extends Component {
  state = {
    loading: false,
    open: false,
    erred: false,
    response: null,
  };

  loadResponse = () => {
    const { order } = this.props;

    if (!order) {
      return;
    }

    this.setState({ loading: true });

    callApi(`/orders/${order.id}/payments`)
      .then((response) => {
        this.setState({
          loading: false,
          response,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
          erred: true,
          response: typeof err === 'object' ? err : { message: err.message },
        });
      });
  };

  toggle = () => {
    const { open } = this.state;

    this.setState({ open: !open }, () => {
      if (!open) {
        this.loadResponse();
      }
    });
  };

  render() {
    const { loading, open, response } = this.state;

    return (
      <div>
        <Button block color="secondary" onClick={this.toggle}>
          {open ? 'Close' : 'View'} Payments
        </Button>
        {loading ? (
          <Loading />
        ) : (
          open && (
            <Collapse isOpen={open}>
              <Card>
                <Table striped>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Reference</th>
                      <th>Method</th>
                      <th>Amount</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {response ? (
                      response.data.map((payment, i) => (
                        <tr key={i}>
                          <td>{formatDateTime(payment.created_at)}</td>
                          <td>{payment.reference}</td>
                          <td>{payment.method}</td>
                          <td>{formatAmount(payment.amount_paid)}</td>
                          <td>{payment.status}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={5}>No payments found</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Card>
            </Collapse>
          )
        )}
      </div>
    );
  }
}
