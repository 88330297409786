import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  Button,
} from 'reactstrap';

import { closeAllModals } from '../actions/modalActions';
import { showError } from '../actions/notificationActions';

import { callApi, formatAmount } from '../utils';

import Pagination from './Pagination';

export default connect((state) => ({
  open: state.modals.supervisorLogs,
  data: state.modals.data,
}))(
  class SupervisorLogsModal extends Component {
    state = {
      logQueries: {
        from: '',
        to: '',
        id: '',
        operation: 'credit',
        generatedBy: '',
        amount: '',
        page: '1',
      },
      logs: [],
      users: [],
      pages: 0,
      loading: false,
    };

    componentDidUpdate(prevProps) {
      if (prevProps.open !== this.props.open && this.props.open) {
        this.setState({
          logQueries: {
            from: '',
            to: '',
            id: '',
            operation: 'credit',
            amount: '',
            page: '1',
          },
          users: [],
          logs: [],
          pages: 0,
        });
        this.loadLogs(this.props.data.id);
        // this.getAllAdminUsers();
      }
    }

    setInput = (e) => {
      const { name, value } = e.target;

      this.setState((state) => {
        return { ...state, logQueries: { ...state.logQueries, [name]: value } };
      });
    };

    setPage = (page) => {
      if (page) {
        this.setState(
          { logQueries: { ...this.state.logQueries, page } },
          () => {
            this.loadLogs();
          }
        );
      }
    };

    close = () => {
      this.props.dispatch(closeAllModals());
    };

    loadLogs = () => {
      const { from, to, operation, amount, page } = this.state.logQueries;
      const id = this.props.data.id;
      this.setState({ loading: true });
      callApi(`/agentsupervisors/timeline`, {
        id,
        from,
        to,
        operation,
        amount,
        page,
      })
        .then(({ data = [], pages }) => {
          this.setState({ loading: false });
          this.setState({ logs: data, pages });
        })
        .catch((err) => {
          this.setState({ loading: false });
          this.props.dispatch(showError(err));
        });

      this.getAllAdminUsers();
    };

    getAllAdminUsers = () => {
      this.setState({ loading: true });
      callApi('/adminusers/all')
        .then(({ data }) => {
          this.setState({ users: data, loading: false });
        })
        .catch((err) => {
          this.setState({ loading: false });
          this.props.dispatch(showError(err));
        });
    };

    render() {
      const {
        from,
        to,
        operation,
        amount,
        generatedBy,
        page,
      } = this.state.logQueries;
      // const logs = this.state.logs;
      const users = this.state.users;
      const logs = this.state.logs;
      const pages = this.state.pages;
      const loading = this.state.loading;
      const open = this.props.open;
      return (
        <div>
          <Modal size="lg" isOpen={open} toggle={this.close}>
            <ModalHeader toggle={this.close}>Transactions Timeline</ModalHeader>
            <ModalBody>
              <form onSubmit={this.loadLogs}>
                <Row>
                  <Col xs={12} md={12}>
                    <Row colSpan={2} style={{ padding: '0 25px' }}>
                      <Col xs="6">
                        <FormGroup>
                          <Label>From</Label>
                          <Input
                            onChange={this.setInput}
                            value={from}
                            name="from"
                            placeholder="From"
                            type="date"
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="6">
                        <FormGroup>
                          <Label>To</Label>
                          <Input
                            onChange={this.setInput}
                            value={to}
                            name="to"
                            placeholder="To"
                            type="date"
                          />
                        </FormGroup>
                      </Col>

                      <Col>
                        <FormGroup>
                          <Label>Amount</Label>
                          <Input
                            onChange={this.setInput}
                            value={amount}
                            name="amount"
                            placeholder="Amount"
                            type="number"
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>Operation</Label>
                          <Input
                            onChange={this.setInput}
                            value={operation}
                            name="operation"
                            placeholder="Operation"
                            type="select"
                          >
                            <option value="credit">CREDIT</option>
                            <option value="debit">DEBIT</option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>Generated By</Label>
                          <Input
                            onChange={this.setInput}
                            value={generatedBy}
                            name="generatedBy"
                            placeholder=""
                            type="select"
                          >
                            <option value="">Any</option>
                            {users.map((u) => (
                              <option key={u.id} value={u.id}>
                                {u.name}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="justify-content-center">
                      <Button size="lg" color="primary" onClick={this.loadLogs}>
                        {loading ? 'PLEASE WAIT...' : 'Query'}
                      </Button>
                    </Row>

                    {logs.length > 0 ? (
                      logs.map((l) => {
                        const user =
                          users.find((u) => u.id === l.generatedBy) || {};

                        const generatedBy = user.name || 'Agent';

                        return (
                          <div className="debt-row" key={l.id}>
                            <h4>{l.operation}</h4>
                            <div style={{ display: 'flex' }}>
                              <h5>{`${new Date(l.created).toLocaleString(
                                'en-GB',
                                {
                                  year: 'numeric',
                                  month: 'long',
                                  day: 'numeric',
                                }
                              )} • ${new Date(l.created).toLocaleTimeString(
                                'en-US',
                                {
                                  hour: '2-digit',
                                  minute: '2-digit',
                                }
                              )}`}</h5>
                              <div style={{ display: 'flex', flexGrow: '1' }} />
                              <h6
                                style={{
                                  color: `${
                                    l.operation === 'credit' ? 'green' : 'red'
                                  }`,
                                }}
                              >{`${formatAmount(l.amount)}`}</h6>
                            </div>
                            <h5
                              style={{ marginTop: '14px' }}
                            >{`Generated By: ${generatedBy}`}</h5>
                          </div>
                        );
                      })
                    ) : (
                      <h4>No transactions.</h4>
                    )}
                  </Col>
                </Row>
              </form>
            </ModalBody>
            <ModalFooter>
              <Pagination total={pages} onChange={this.setPage} page={page} />
            </ModalFooter>
          </Modal>
        </div>
      );
    }
  }
);
