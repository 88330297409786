export const OPEN_TRANSACTION = 'OPEN_TRANSACTION';
export const OPEN_QMCASH = 'OPEN_QMCASH';
export const OPEN_USER = 'OPEN_USER';
export const OPEN_PROMOTION = 'OPEN_PROMOTION';
export const OPEN_CAMPAIGN = 'OPEN_CAMPAIGN';
export const OPEN_SUPERVISOR = 'OPEN_SUPERVISOR';
export const OPEN_MERCHANT = 'OPEN_MERCHANT';
export const OPEN_SUPERVISOR_LOGS = 'OPEN_SUPERVISOR_LOGS';
export const OPEN_REFUND_MODAL = 'OPEN_REFUND_MODAL';
export const OPEN_GENERATE_MODAL = 'OPEN_GENERATE_MODAL';
export const OPEN_REGENERATE_MODAL = 'OPEN_REGENERATE_MODAL';
export const OPEN_MERCHANT_MODAL = 'OPEN_MERCHANT_MODAL';
export const OPEN_SESSION_MODAL = 'OPEN_SESSION_MODAL';
export const OPEN_ACCOUNT_MODAL = 'OPEN_ACCOUNT_MODAL';
export const OPEN_GEN_ACCOUNT_MODAL = 'OPEN_GEN_ACCOUNT_MODAL';
export const OPEN_ADROIT_CHEQUES_MODAL = 'OPEN_ADROIT_CHEQUES_MODAL';
export const OPEN_API_USERS_REFUND_MODAL = 'OPEN_API_USERS_REFUND_MODAL';
export const OPEN_CREATE_MERCHANT_MODAL = 'OPEN_CREATE_MERCHANT_MODAL';
export const OPEN_BALANCE_UPDATE_MODAL = 'OPEN_BALANCE_UPDATE_MODAL';
export const OPEN_TOPUP_WALLET_MODAL = 'OPEN_TOPUP_WALLET_MODAL';
export const CONFIRM_ACTION_MODAL = 'CONFIRM_ACTION_MODAL';
export const COMPLETE_REFUND_MODAL = 'COMPLETE_REFUND_MODAL';
export const TOGGLE_CONFIRM_MODAL_LOADING = 'TOGGLE_CONFIRM_MODAL_LOADING';
export const UPDATE_REFUND_MANIFEST = 'UPDATE_REFUND_MANIFEST';
export const UPDATE_USER_WALLET_MODAL = 'UPDATE_USER_WALLET_MODAL';
export const OPEN_PREVIEW_MODAL = 'OPEN_PREVIEW_MODAL';
export const OPEN_CHANNEL_NEW_ORDER_MODAL = 'OPEN_CHANNEL_NEW_ORDER_MODAL';
export const OPEN_EDIT_COMMISSION_MODAL = 'OPEN_EDIT_COMMISSION_MODAL';
export const SHOW_VEND_RESPONSE = 'SHOW_VEND_RESPONSE';
export const SHOW_TOKEN_RESPONSE_MODAL = 'SHOW_TOKEN_RESPONSE_MODAL';
export const SHOW_CREATE_VIRTUAL_ACCOUNT_MODAL =
  'SHOW_CREATE_VIRTUAL_ACCOUNT_MODAL';
export const SHOW_CREATE_CUSTOMER_MODAL = 'SHOW_CREATE_CUSTOMER_MODAL';
export const SHOW_CREATE_CAMPAIGN_MODAL = 'SHOW_CREATE_CAMPAIGN_MODAL';
export const SHOW_CREATE_PROMOTION_MODAL = 'SHOW_CREATE_PROMOTION_MODAL';
export const SHOW_GEOCODE_ADDRESS_MODAL = 'SHOW_GEOCODE_ADDRESS_MODAL';
export const SHOW_FACILITY_UPDATE_MODAL = 'SHOW_FACILITY_UPDATE_MODAL';
export const SHOW_WATCHLIST_MODAL = 'SHOW_WATCHLIST_MODAL';
export const SHOW_CREATE_WATCHLIST_MODAL = 'SHOW_CREATE_WATCHLIST_MODAL';
export const SHOW_SUPPORT_MSG_MODAL = 'SHOW_SUPPORT_MSG_MODAL';
export const SHOW_VOUCHERS_CREATE_MODAL = 'SHOW_VOUCHERS_CREATE_MODAL';
export const SHOW_APPROVE_MODAL = 'SHOW_APPROVE_MODAL';
export const SHOW_CREATE_SUPERVISOR_MODAL = 'SHOW_CREATE_SUPERVISOR_MODAL';
export const OPEN_SEND_NOTIFICATION_MODAL = 'OPEN_SEND_NOTIFICATION_MODAL';
export const SHOW_CREATE_SURVEY_MODAL = 'SHOW_CREATE_SURVEY_MODAL';
export const SHOW_SURVEY_RESPONSE_MODAL = 'SHOW_SURVEY_RESPONSE_MODAL';
export const OPEN_EDIT_SURVEY_MODAL = 'OPEN_EDIT_SURVEY_MODAL';

export const CLOSE_ALL_MODALS = 'CLOSE_ALL_MODALS';

export const openTransactionModal = (id) => ({
  type: OPEN_TRANSACTION,
  id,
});

export const openQmcashModal = (id) => ({
  type: OPEN_QMCASH,
  id,
});

export const openAdroitChequesModal = () => ({
  type: OPEN_ADROIT_CHEQUES_MODAL,
});

export const openApiUsersRefundModal = () => ({
  type: OPEN_API_USERS_REFUND_MODAL,
});

export const openUserModal = (id) => ({
  type: OPEN_USER,
  id,
});

export const openCampaignModal = (id) => ({
  type: OPEN_CAMPAIGN,
  id,
});

export const openEditSurveyModal = (id) => ({
  type: OPEN_EDIT_SURVEY_MODAL,
  id,
});

export const openRefundModal = (data) => ({
  type: OPEN_REFUND_MODAL,
  data,
});

export const openSupervisorModal = (data) => ({
  type: OPEN_SUPERVISOR,
  data,
});

export const openCreateMerchantModal = (data) => ({
  type: OPEN_CREATE_MERCHANT_MODAL,
  data,
});

export const openBalanceUpdateModal = (data) => ({
  type: OPEN_BALANCE_UPDATE_MODAL,
  data,
});

export const openTopupWalletModal = (data) => ({
  type: OPEN_TOPUP_WALLET_MODAL,
  data,
});

export const openUpdateUserWalletModal = (data) => ({
  type: UPDATE_USER_WALLET_MODAL,
  data,
});

export const openMerchantDetailPage = (data) => ({
  type: OPEN_MERCHANT,
  data,
});

export const openSupervisorLogsModal = (data) => ({
  type: OPEN_SUPERVISOR_LOGS,
  data,
});

export const openSessionModal = (id) => ({
  type: OPEN_SESSION_MODAL,
  id,
});

export const openAccountsModal = (id) => ({
  type: OPEN_ACCOUNT_MODAL,
  id,
});

export const openGenerateModal = (data) => ({
  type: OPEN_GENERATE_MODAL,
  data,
});

export const openRegenerateModal = (data) => ({
  type: OPEN_REGENERATE_MODAL,
  data,
});

export const confirmAction = (data) => ({
  type: CONFIRM_ACTION_MODAL,
  data,
});
export const previewEntity = (data) => ({
  type: OPEN_PREVIEW_MODAL,
  data,
});

export const openChannelCreateOrder = (data) => ({
  type: OPEN_CHANNEL_NEW_ORDER_MODAL,
  data,
});

export const openEditCommissionModal = (data) => ({
  type: OPEN_EDIT_COMMISSION_MODAL,
  data,
});

export const showVendResponse = (data = {}) => ({
  type: SHOW_VEND_RESPONSE,
  data,
});

export const toggleModalLoading = (data) => ({
  type: TOGGLE_CONFIRM_MODAL_LOADING,
  data,
});

export const openRefundCompletionModal = (data) => ({
  type: COMPLETE_REFUND_MODAL,
  data,
});
export const showTokenResponseModal = (data) => ({
  type: SHOW_TOKEN_RESPONSE_MODAL,
  data,
});

export const updateManifest = (data) => ({
  type: UPDATE_REFUND_MANIFEST,
  data,
});

export const openMerchantModal = () => ({ type: OPEN_MERCHANT_MODAL });

export const openGenAccountModal = () => ({ type: OPEN_GEN_ACCOUNT_MODAL });

export const openSendNotificationModal = (data) => ({
  type: OPEN_SEND_NOTIFICATION_MODAL,
  data,
});

export const closeAllModals = () => ({ type: CLOSE_ALL_MODALS });

// Action to open the virtual account creation modal
export const openVirtualAccountModal = (data) => ({
  type: SHOW_CREATE_VIRTUAL_ACCOUNT_MODAL,
  data,
});

// Action to open the create customer modal
export const openCreateCustomerModal = () => ({
  type: SHOW_CREATE_CUSTOMER_MODAL,
});

export const openCreateCampaignModal = (data) => ({
  type: SHOW_CREATE_CAMPAIGN_MODAL,
  data,
});

export const openCreatePromotionModal = (data) => ({
  type: SHOW_CREATE_PROMOTION_MODAL,
  data,
});

export const openPromotionModal = (id) => ({
  type: OPEN_PROMOTION,
  id,
});

export const openCreateSurveyModal = () => ({
  type: SHOW_CREATE_SURVEY_MODAL,
});

export const openSurveyResponseModal = (data) => ({
  type: SHOW_SURVEY_RESPONSE_MODAL,
  data,
});

export const openGeoCodeAddressModal = (data) => ({
  type: SHOW_GEOCODE_ADDRESS_MODAL,
  data,
});

export const openFacilityUpdateModal = (data) => ({
  type: SHOW_FACILITY_UPDATE_MODAL,
  data,
});

export const onOpenWatchlistModal = (data) => ({
  type: SHOW_WATCHLIST_MODAL,
  data,
});
export const openCreateWatchlistModal = (data) => ({
  type: SHOW_CREATE_WATCHLIST_MODAL,
  data,
});

export const showSupportMsgModal = (data) => ({
  type: SHOW_SUPPORT_MSG_MODAL,
  data,
});

export const showVoucherCreateModal = () => ({
  type: SHOW_VOUCHERS_CREATE_MODAL,
});

export const showaApproveModal = () => ({
  type: SHOW_APPROVE_MODAL,
});

// Action to open the create supervisor modal
export const openCreateSupervisorModal = () => ({
  type: SHOW_CREATE_SUPERVISOR_MODAL,
});
