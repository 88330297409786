import React from 'react';

import { Row, Col, Table } from 'reactstrap';

import Pagination from '../../Pagination';

import { callApi, formatAmount, formatDate, _toDash } from '../../../utils';
import { showError } from '../../../actions/notificationActions';

import Loading from '../../Loading';
import { openTransactionModal } from '../../../actions/modalActions';
import { BusinessContext } from './';

class Settlements extends React.Component {
  state = {
    loading: true,
    transactions: [],
    page: {
      limit: 30,
      number: 1,
      total: 0,
      query: null,
    },
  };

  componentWillMount() {
    this.loadView();
  }

  startLoad = () => this.setState({ loading: true });
  stopLoad = () => this.setState({ loading: false });

  scrollToTop = () =>
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);

  loadView = (e) => {
    e && e.preventDefault();
    const { business } = this.context;
    const { page } = this.state;

    this.startLoad();

    callApi(`/channels/${business.id}/settlements`, {
      page: page.number,
      limit: page.limit,
      q: page.query,
    })
      .then(({ data = [], meta }) => {
        this.setState({
          transactions: data,
          page: {
            ...page,
            total: meta.total,
          },
        });
        this.scrollToTop();
      })
      .catch((err) => {
        this.props.dispatch(showError(err));
      })
      .finally(() => {
        this.stopLoad();
      });
  };

  setQuery = (e) => {
    this.setState({
      page: {
        ...this.state.page,
        q: e.target.value,
      },
    });
  };

  openTransaction = (id) => {
    this.props.dispatch(openTransactionModal({ id }));
  };

  setPage = (pageNumber) => {
    let { page } = this.state;

    const canLoad = page.total % ((page.number - 1) * page.limit) < page.total;

    if (canLoad) {
      this.startLoad();
      this.setState(
        {
          page: {
            ...page,
            number: pageNumber,
          },
        },
        () => {
          this.loadView();
        }
      );
    }
  };

  rowNumber(index) {
    const { loading, page } = this.state;
    return loading ? 'x' : index + 1 + (page.number - 1) * page.limit;
  }

  render() {
    const { loading, transactions, page } = this.state;
    return (
      <React.Fragment>
        <Row>
          <Col sm="12">
            {loading ? (
              <Loading />
            ) : (
              <div>
                <Row>
                  <Col sm="12">
                    <Table responsive hover striped>
                      <thead className="text-primary">
                        <tr>
                          <th>No.</th>
                          <th>From</th>
                          <th>To</th>
                          <th>Total Transactions</th>
                          <th>Amount</th>
                          <th>Status</th>
                          <th>Created At</th>
                        </tr>
                      </thead>

                      <tbody>
                        {transactions.map((t, i) => (
                          <tr key={t.id}>
                            <td>{this.rowNumber(i)}</td>
                            <td>{formatDate(t.period_start)}</td>
                            <td>{formatDate(t.period_end)}</td>
                            <td>{t.total_transactions}</td>
                            <td>{formatAmount(t.amount)}</td>
                            <td>{t.status}</td>
                            <td>{formatDate(t.created_at)}</td>
                            <td>{t.disco_code}</td>
                            <td>{_toDash(t.order_status)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <Pagination
                      total={page.total}
                      page={page.number}
                      onChange={this.setPage}
                    />
                    {loading && <Loading />}
                  </Col>
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

Settlements.contextType = BusinessContext;

export default Settlements;
