import React from 'react';
import { connect } from 'react-redux';
import { saveAs } from 'file-saver';
import { Row, Col, Label, Button, Table, FormGroup, Input } from 'reactstrap';

import { callApi, formatAmount, formatDateTime } from '../../utils';
import { showError } from '../../actions/notificationActions';
import Pagination from '../Pagination';

import Loading from '../Loading';

class SupervisorTransactions extends React.Component {
  state = {
    queryParams: {
      from: '',
      to: '',
      disco: '',
      page: 1,
      limit: '50',
      subAccount: '',
      amount: '',
      generatedBy: '1',
      operation: 'credit',
    },
    pages: 1,
    meta: { pages: 1 },
    loading: false,
    rows: [],
    users: [],
  };

  setInput = (e) => {
    const { name, value } = e.target;
    this.setState({
      queryParams: { ...this.state.queryParams, [name]: value },
    });
    // setQueryParams({[name]: value})
  };

  setPage = (page) => {
    if (page) {
      this.setState(
        { queryParams: { ...this.state.queryParams, page } },
        () => {
          this.loadTimeline();
        }
      );
    }
  };

  startLoad = () => this.setState({ loading: true });
  stopLoad = () => this.setState({ loading: false });

  loadTimeline = () => {
    const {
      from,
      to,
      operation,
      amount,
      page,
      generatedBy,
      limit,
    } = this.state.queryParams;
    const id = this.props.id;
    this.setState({ loading: true });
    callApi(`/agentsupervisors/timeline`, {
      id,
      from,
      to,
      operation,
      amount,
      page,
      generatedBy,
      limit,
    })
      .then(({ data = [], pages }) => {
        this.setState({ loading: false });
        this.setState({ rows: data, meta: { pages } });
      })
      .catch((err) => {
        this.setState({ loading: false });
        this.props.dispatch(showError(err));
      });

    // this.getAllAdminUsers();
  };
  componentWillMount() {
    // this.getSettlements();
    this.getAllAdminUsers();
  }

  requery = (order_id) => {
    this.setState({ loading: true });
    callApi(`/request/${order_id}/response`)
      .then(() => {
        this.loadTimeline();
      })
      .catch((err) => {
        this.setState({ loading: false });
        this.props.dispatch(showError(err));
      });
  };

  downloadTransactions = async () => {
    const { from, to, operation, amount, generatedBy } = this.state.queryParams;
    const { users } = this.state;
    const id = this.props.id;
    console.log(users, generatedBy);
    const res = await fetch(
      `/agentsupervisors/timeline?${new URLSearchParams({
        from,
        to,
        operation,
        amount,
        id,
        download: true,
        supervisor: users.find((u) => u.id === generatedBy).name || 'Agent',
      })}`,
      {
        method: 'GET',
      }
    );

    let fileName = 'test.xlsx';
    fileName = res.headers.get('content-disposition').split('=')[1];

    const blob = await res.blob();

    saveAs(blob, fileName);
  };

  getAllAdminUsers = () => {
    this.setState({ loading: true });
    callApi('/adminusers/all')
      .then(({ data }) => {
        this.setState({ users: data, loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
        this.props.dispatch(showError(err));
      });
  };

  render() {
    const { rows, queryParams, loading, meta, users } = this.state;

    const { from, to, operation, generatedBy, amount } = queryParams;

    console.log(this.props);

    return (
      <Row>
        <Col md="12" xs={12}>
          <div className="mt-10">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                this.getSettlements();
              }}
              className="mb-20"
            >
              <Row colSpan={2} style={{ padding: '0 25px' }}>
                <Col xs="6">
                  <FormGroup>
                    <Label>From</Label>
                    <Input
                      onChange={this.setInput}
                      value={from}
                      name="from"
                      placeholder="From"
                      type="date"
                    />
                  </FormGroup>
                </Col>
                <Col xs="6">
                  <FormGroup>
                    <Label>To</Label>
                    <Input
                      onChange={this.setInput}
                      value={to}
                      name="to"
                      placeholder="To"
                      type="date"
                    />
                  </FormGroup>
                </Col>

                <Col>
                  <FormGroup>
                    <Label>Amount</Label>
                    <Input
                      onChange={this.setInput}
                      value={amount}
                      name="amount"
                      placeholder="Amount"
                      type="number"
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>Operation</Label>
                    <Input
                      onChange={this.setInput}
                      value={operation}
                      name="operation"
                      placeholder="Operation"
                      type="select"
                    >
                      <option value="credit">CREDIT</option>
                      <option value="debit">DEBIT</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>Generated By</Label>
                    <Input
                      onChange={this.setInput}
                      value={generatedBy}
                      name="generatedBy"
                      placeholder=""
                      type="select"
                    >
                      <option value="">Any</option>
                      {users.map((u) => (
                        <option key={u.id} value={u.id}>
                          {u.name}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Button size="lg" color="primary" onClick={this.loadTimeline}>
                  {loading ? 'PLEASE WAIT...' : 'Query'}
                </Button>
              </Row>
            </form>

            {loading ? (
              <Loading />
            ) : (
              <Table responsive hover striped>
                <thead className="text-primary">
                  <tr>
                    <th>S/N</th>
                    <th>Date</th>
                    {/* <th>Domain</th> */}
                    {/* <th>Processed</th> */}
                    <th>Amount</th>
                    <th>Operation</th>
                    <th>Order ID</th>
                    <th>Status</th>
                    <th>Generated By</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {rows.map((t, i) => {
                    const user =
                      users.find((u) => u.id === t.generatedBy) || {};

                    const generatedBy = user.name || 'Agent';
                    return (
                      <tr key={t._id}>
                        <td>
                          {(queryParams.page - 1) * queryParams.limit + (i + 1)}
                        </td>
                        <td>{formatDateTime(t.created)}</td>
                        <td>{formatAmount(t.amount)}</td>
                        <td>{t.operation}</td>
                        <td>{t.ref}</td>
                        <td>{t.order_status}</td>
                        <td>{generatedBy}</td>
                        <td>
                          {['PEDNING', 'NEW', 'QUEUED'].includes(
                            t.order_status
                          ) ? (
                            <Button
                              onClick={() => this.requery(t.ref)}
                              size="md"
                              color="warning"
                            >
                              Requery
                            </Button>
                          ) : (
                            <React.Fragment></React.Fragment>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            )}
            {rows.length > 0 && (
              <div className="d-flex justify-content-end">
                <Button
                  size="sm"
                  color="link"
                  onClick={() => this.downloadTransactions()}
                >
                  Download
                </Button>
              </div>
            )}
            <Pagination
              total={meta.pages}
              page={queryParams.page}
              onChange={this.setPage}
            />
          </div>
          {/* </Card> */}
        </Col>
      </Row>
    );
  }
}

export default connect()(SupervisorTransactions);
