import { SET_CUSTOMER_DETAILS } from '../actions/customerAction';

const initUserState = {
  id: '',
  phone: '',
};

export default (state = initUserState, action) => {
  switch (action.type) {
    case SET_CUSTOMER_DETAILS:
      return action.data
        ? Object.assign({}, { phone: action.data.phone, id: action.data.id })
        : initUserState;

    default:
      return state;
  }
};
