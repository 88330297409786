import React from 'react';
import { Route, Switch } from 'react-router-dom';

import List from './Promotions/List';
import Detail from './Promotions/Detail';

// import {
//   _checkACL,
// } from '../utils';

function Refundables(prop) {
  // if (prop.isRestrictedComponent && !_checkACL('promotions')) {
  //   return;
  // }
  return (
    // <div>
    <Switch>
      <Route exact path="/promotions" component={List} />
      <Route path="/promotions/:id" component={Detail} />
    </Switch>
    // </div>
  );
}

export default Refundables;
