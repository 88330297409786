import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Row,
  Table,
} from 'reactstrap';
import React, { Component } from 'react';
import { callApi, formatDate } from '../../utils';

import CreateCampaignModal from '../CreateCampaignModal';
import Loading from '../Loading';
import Pagination from '../Pagination';
import SearchForm from '../SearchForm';
import campaignService from '../../services/campaign.service';
import { connect } from 'react-redux';
import { openCreateCampaignModal } from '../../actions/modalActions';
import { showError } from '../../actions/notificationActions';

class Campaigns extends Component {
  state = {
    campaigns: [],
    type: '',
    channel: '',
    query: '',
    page: 1,
    limit: 30,
    loading: false,
    queryTypes: ['banner', 'announcement', 'advert', 'alert'],
    queryChannels: ['web', 'mobile'],
    discos: [],
  };

  componentWillMount() {
    this.loadCampaigns();
    this.loadDiscos();
  }

  startLoad = () => this.setState({ loading: true });
  stopLoad = () => this.setState({ loading: false });

  scrollToTop = () =>
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);

  loadCampaigns = (e, filterType, filterChannel) => {
    // Event may be passed in from search form
    if (e) e.preventDefault();

    const { type, page, limit, query } = this.state;
    this.startLoad();
    campaignService
      .getCampaigns({
        type: filterType || type,
        page,
        limit,
        ...(filterChannel && { channels: [filterChannel] }),
        query,
      })
      .then((res) => {
        this.setState({ loading: false, campaigns: res.data });
        this.scrollToTop();
      })
      .catch((err) => {
        this.stopLoad();
        this.props.dispatch(showError(err.message));
      });
  };

  loadDiscos = () => {
    // use callApi to get discos
    // this.setState({ loading: true });
    callApi('/service-distribution', {}, 'get')
      .then((res) => {
        this.setState({ discos: res.data.map((disco) => disco.name) });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  setPage = (page) => {
    if (page) {
      this.setState({ page }, () => {
        this.loadCampaigns();
      });
    }
  };

  setQuery = (e) => {
    this.setState({ query: e.target.value });
  };

  setFilterByType = (e) => {
    this.loadCampaigns(null, e.target.value !== 'all' ? e.target.value : '');
  };

  setChannel = (e) => {
    this.loadCampaigns(
      null,
      null,
      e.target.value !== 'all' ? e.target.value : ''
    );
  };

  setType = (e) => {
    this.setState({ type: e.target.value });
  };

  openCampaign = (campaign) => {
    // this.props.dispatch(openCampaignModal(id));
    this.props.history.push(`/campaigns/${campaign.id}`);
    // this.props.dispatch(setCustomerDetails(campaign));
  };

  refresh = () => {
    window.location.reload();
  };

  render() {
    const { query, campaigns, page, loading, limit } = this.state;
    return (
      <div className="content">
        <CreateCampaignModal refresh={this.refresh} />
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardHeader className="ml-2">
                <div className="d-flex justify-content-between align-contents-center">
                  <div>
                    <CardTitle>CAMPAIGNS</CardTitle>
                    <p className="category">All CAMPAIGNS - Page {page}</p>
                  </div>
                  <div>
                    <Button
                      // size="sm"
                      // disabled={}
                      color={'primary'}
                      onClick={() => {
                        this.props.dispatch(
                          openCreateCampaignModal({ discos: this.state.discos })
                        );
                      }}
                    >
                      Create Campaign
                    </Button>
                  </div>
                </div>
              </CardHeader>

              <CardHeader className="ml-2">
                <div className="d-flex justify-content-between align-contents-center">
                  <div>
                    <SearchForm
                      value={query}
                      onChange={this.setQuery}
                      onSearch={this.loadCampaigns}
                      loading={loading}
                    />
                  </div>

                  <div>
                    <FormGroup>
                      <Input
                        id="status"
                        name="status"
                        type="select"
                        onChange={this.setFilterByType}
                      >
                        <option value={'all'}>Filter By Type</option>
                        {this.state.queryTypes.map((type, index) => (
                          <option key={index} value={type.toLocaleLowerCase()}>
                            {type.toUpperCase()}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <Input
                        id="status"
                        name="status"
                        type="select"
                        onChange={this.setChannel}
                      >
                        <option value={'all'}>Filter By Channel</option>
                        {this.state.queryChannels.map((type, index) => (
                          <option key={index} value={type.toLocaleLowerCase()}>
                            {type.toUpperCase()}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </div>
                </div>
              </CardHeader>

              <CardBody>
                <Table responsive hover striped>
                  <thead className="text-primary">
                    <tr>
                      <th>No.</th>
                      <th>Title</th>
                      <th>Channel</th>
                      <th>Type</th>
                      <th>status</th>
                      <th>Starts</th>
                      <th>Ends</th>
                      <th>CreatedAt</th>
                    </tr>
                  </thead>

                  <tbody>
                    {campaigns.map((u, i) => (
                      <tr onClick={() => this.openCampaign(u)} key={u.id}>
                        <td>{loading ? 'x' : i + 1 + (page - 1) * limit}</td>
                        <td>{u.title}</td>
                        <td>{u.channels.join(', ')}</td>
                        <td>{u.type}</td>
                        <td>{u.status}</td>
                        <td>{formatDate(u.start_at)}</td>
                        <td>{formatDate(u.end_at)}</td>
                        <td>{formatDate(u.created_at)}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Pagination page={page} onChange={this.setPage} />
                {loading && <Loading />}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect()(Campaigns);
