import React from 'react';
import { Route, Switch } from 'react-router-dom';

import List from './Payments/List';
import Detail from './Payments/Detail';

function Payments() {
  return (
    <Switch>
      <Route exact path="/all-payments" component={List} />
      <Route path="/all-payments/:id" component={Detail} />
    </Switch>
  );
}

export default Payments;
