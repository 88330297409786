import React, { Component } from 'react';
import { connect } from 'react-redux';
import { callApi } from '../utils';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
  Button,
} from 'reactstrap';

import { closeAllModals } from '../actions/modalActions';

export default connect((state) => ({
  open: state.modals.createWatchlist,
}))(
  class CreateWatchlistModal extends Component {
    state = {
      loading: false,
      name: '',
      description: '',
      error: '',
    };

    setInput = (e) => {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    };

    close = () => {
      this.props.dispatch(closeAllModals());
    };

    submit = (e) => {
      e.preventDefault();

      if (!this.state.name || !this.state.description) {
        return this.setState({ error: 'Please fill in all fields!' });
      }

      this.setState({ error: '' });

      let bodyObj = {
        name: this.state.name,
        description: this.state.description,
      };

      this.setState({ loading: true });

      // Request to create a Watchlist
      callApi(`/watchlists`, bodyObj, 'post')
        .then((res) => {
          this.setState({ loading: false });
          this.props.refresh();
          alert(res.message);
        })
        .catch((err) => {
          this.setState({ loading: false });
          if ((err.status = 'unprocessable')) alert(err.message);
          console.log(err);
        })
        .finally(() => {
          this.close();
        });
    };

    render() {
      const { name, description, loading } = this.state;

      const open = this.props.open;
      return (
        <div>
          <Modal size="lg" isOpen={open} toggle={this.close}>
            <ModalHeader toggle={this.close}>Create Watchlist</ModalHeader>
            <ModalBody>
              <form>
                <div>
                  <FormGroup>
                    <Label className="mt-3">Name:</Label>
                    <Input
                      name="name"
                      type="text"
                      onChange={this.setInput}
                      value={name}
                      disabled={loading}
                    />

                    <Label className="mt-3">Description:</Label>
                    <Input
                      name="description"
                      type="textarea"
                      onChange={this.setInput}
                      value={description}
                      disabled={loading}
                    />
                  </FormGroup>
                </div>
                <div />
              </form>
              {this.state.error ? (
                <span style={{ color: 'red' }}>{this.state.error}</span>
              ) : (
                ''
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="primary" disabled={loading} onClick={this.close}>
                Close
              </Button>

              <Button color="success" disabled={loading} onClick={this.submit}>
                {loading ? (
                  <div>
                    Creating
                    <span className="fas fa-spinner fa-spin ml-2"></span>
                  </div>
                ) : (
                  'Create Watchlist'
                )}
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      );
    }
  }
);
