import React from 'react';
import { dashboardShippedProductsChart } from 'variables/charts.jsx';

import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  CardFooter,
  UncontrolledDropdown,
} from 'reactstrap';

import Stats from '../../Stats/Stats';
import { Line } from 'react-chartjs-2';
import CardCategory from '../../CardElements/CardCategory';

class Insights extends React.Component {
  render() {
    return (
      <div>
        <Row>
          <Col sm="12">
            <Card className="card-chart">
              <CardHeader>
                <CardCategory>Global Sales</CardCategory>
                <CardTitle>Shipped Products</CardTitle>
                <UncontrolledDropdown>
                  <DropdownToggle
                    className="btn-round btn-simple btn-icon"
                    color="default"
                  >
                    <i className="now-ui-icons loader_gear" />
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem>Action</DropdownItem>
                    <DropdownItem>Another Action</DropdownItem>
                    <DropdownItem>Something else here</DropdownItem>
                    <DropdownItem className="text-danger">
                      Remove data
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <Line
                    data={dashboardShippedProductsChart.data}
                    options={dashboardShippedProductsChart.options}
                  />
                </div>
              </CardBody>
              <CardFooter>
                <Stats>
                  {[
                    {
                      i: 'now-ui-icons arrows-1_refresh-69',
                      t: 'Just Updated',
                    },
                  ]}
                </Stats>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Insights;
