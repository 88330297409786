import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  Button,
} from 'reactstrap';

import { closeAllModals } from '../actions/modalActions';
import { showError, showInfo } from '../actions/notificationActions';

import { callApi } from '../utils';

export default connect((state) => ({
  open: state.modals.updateBalance,
  data: state.modals.data,
}))(
  class UpdateBalanceModal extends Component {
    state = {
      data: {
        ref: '',
        amount: '',
        commission: '',
        type: 'REFUND',
        // operation: 'CREDIT',
      },
      loading: false,
    };

    componentDidUpdate(prevProps) {
      if (prevProps.open !== this.props.open && this.props.open) {
        this.setState({
          data: {
            ref: '',
            amount: '',
            commission: '',
            type: 'REFUND',
            // operation: 'CREDIT',
          },
        });
      }
    }

    setInput = (e) => {
      const { name, value } = e.target;
      this.setState((state) => {
        return { ...state, data: { ...state.data, [name]: value } };
      });
    };

    close = () => {
      this.props.dispatch(closeAllModals());
    };

    updateBalance = () => {
      const { id, onSuccess } = this.props.data;
      const { ref, amount, commission, type } = this.state.data;
      let { operation } = this.state.data;

      if (type === 'overdraft_retrieval') {
        operation = 'debit';
      }

      this.setState({ loading: true });

      callApi(
        `/merchant-wallet/${id}/balance`,
        { ref, amount, type, commission, operation },
        'post'
      )
        .then((res) => {
          this.props.dispatch(showInfo(res.message));
          this.close();
          onSuccess(res.data.balance);
        })
        .catch((err) => {
          this.props.dispatch(showError(err));
        })
        .finally((err) => {
          this.setState({ loading: false });
        });
    };

    render() {
      const { ref, amount, commission, type } = this.state.data;
      const loading = this.state.loading;
      const open = this.props.open;
      return (
        <form onSubmit={this.updateUser}>
          <Modal size="lg" isOpen={open} toggle={this.close}>
            <ModalHeader toggle={this.close}>Update Wallet Balance</ModalHeader>
            <ModalBody>
              <Row>
                <Col xs={12} md={12}>
                  <FormGroup>
                    <Label>Transaction Reference</Label>
                    <Input
                      onChange={this.setInput}
                      value={ref}
                      name="ref"
                      placeholder="Optional"
                      type="text"
                    />
                  </FormGroup>
                  {/* <FormGroup>
                    <Label>Operation</Label>
                    <Input
                      onChange={this.setInput}
                      value={operation}
                      name="operation"
                      type="select"
                    >
                      <option value="CREDIT">CREDIT</option>
                      <option value="DEBIT">DEBIT</option>
                    </Input>
                  </FormGroup> */}
                  <FormGroup>
                    <Label>Amount</Label>
                    <Input
                      onChange={this.setInput}
                      value={amount}
                      name="amount"
                      placeholder="0.00"
                      type="text"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Commission</Label>
                    <Input
                      onChange={this.setInput}
                      value={commission}
                      name="commission"
                      placeholder="0.00"
                      type="text"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Treatment</Label>
                    <Input
                      onChange={this.setInput}
                      value={type}
                      name="type"
                      type="select"
                    >
                      <option value="refund">REFUND</option>
                      <option value="topup">TOPUP</option>
                      <option value="reversal">REVERSAL</option>
                      <option value="retrieval">RETRIEVAL</option>
                      <option value="overdraft_topup">OVERDRAFT TOPUP</option>
                      <option value="overdraft_retrieval">
                        OVERDRAFT RETRIEVAL
                      </option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                disabled={loading}
                onClick={this.updateBalance}
              >
                Update
              </Button>
            </ModalFooter>
          </Modal>
        </form>
      );
    }
  }
);
