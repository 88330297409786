import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  Button,
} from 'reactstrap';

import { closeAllModals } from '../actions/modalActions';
import { showError, showInfo } from '../actions/notificationActions';

import { callApi } from '../utils';

export default connect((state) => ({
  open: state.modals.updateWalletModal,
  data: state.modals.data,
}))(
  class UpdateBalanceModal extends Component {
    state = {
      data: {
        amount: '',
        operation: 'CREDIT',
        ref: '',
      },
      loading: false,
    };

    componentDidUpdate(prevProps) {
      if (prevProps.open !== this.props.open && this.props.open) {
        this.setState({
          data: {
            amount: '',
            operation: 'CREDIT',
          },
        });
      }
    }

    setInput = (e) => {
      const { name, value } = e.target;
      this.setState((state) => {
        return { ...state, data: { ...state.data, [name]: value } };
      });
    };

    close = () => {
      this.props.dispatch(closeAllModals());
    };

    updateBalance = () => {
      const { id, onSuccess } = this.props.data;
      const { amount, operation, ref } = this.state.data;
      this.setState({ loading: true });
      callApi(
        `/customers/${id}/wallet/transactions`,
        { amount, operation, ref },
        'post'
      )
        .then((res) => {
          this.setState({ loading: false });
          this.props.dispatch(showInfo(res.message));
          this.close();
          onSuccess();
        })
        .catch((err) => {
          this.setState({ loading: false });
          this.props.dispatch(showError(err));
        });
    };

    render() {
      const { ref, amount, operation } = this.state.data;
      const loading = this.state.loading;
      const open = this.props.open;
      return (
        <form>
          <Modal size="lg" isOpen={open} toggle={this.close}>
            <ModalHeader toggle={this.close}>Update Wallet Balance</ModalHeader>
            <ModalBody>
              <Row>
                <Col xs={12} md={12}>
                  <FormGroup>
                    <Label>Reference</Label>
                    <Input
                      onChange={this.setInput}
                      value={ref}
                      name="ref"
                      placeholder="Optional"
                      type="text"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12}>
                  <FormGroup>
                    <Label>Operation</Label>
                    <Input
                      onChange={this.setInput}
                      value={operation}
                      name="operation"
                      type="select"
                    >
                      <option value="CREDIT">CREDIT</option>
                      <option value="DEBIT">DEBIT</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12}>
                  <FormGroup>
                    <Label>Amount</Label>
                    <Input
                      onChange={this.setInput}
                      value={amount}
                      name="amount"
                      placeholder="0.00"
                      type="text"
                    />
                  </FormGroup>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                disabled={loading}
                onClick={this.updateBalance}
              >
                Update
              </Button>
            </ModalFooter>
          </Modal>
        </form>
      );
    }
  }
);
