import React, { Component } from 'react';
import { connect } from 'react-redux';
import ConfirmActionModal from '../ConfirmActionModal';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Nav,
  NavLink,
  NavItem,
  Button,
} from 'reactstrap';
import classnames from 'classnames';

import { callApi } from '../../utils';
import { showError, showInfo } from '../../actions/notificationActions';

import { closeAllModals } from '../../actions/modalActions';

import { Route, Switch } from 'react-router-dom';
import { Insight } from './Insight';

class Detail extends Component {
  state = {
    verification: {},
    loading: true,
    approvalLoading: false,
    declineLoading: false,
  };

  componentDidMount() {
    this.loadGrantDetail();
    this.props.dispatch(closeAllModals());
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.loadGrantDetail();
    }
  }

  startLoad = () => this.setState({ loading: true });
  stopLoad = () => this.setState({ loading: false });

  scrollToTop = () =>
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);

  loadGrantDetail = (e) => {
    const id = this.props.match.params.id;

    this.startLoad();
    callApi(`/facility-verifications/${id}`)
      .then(({ data = {} }) => {
        this.setState({ verification: data });
        this.scrollToTop();
      })
      .catch((err) => {
        this.props.dispatch(showError(err));
      })
      .finally(() => {
        this.stopLoad();
      });
  };

  processApproval = () => {
    const { facility_id: id, user_id } = this.state.verification;
    const payload = { status: 'APPROVED', user_id };

    this.setState({ approvalLoading: true });

    callApi(`/facility-verifications/${id}/status`, payload, 'put')
      .then((res) => {
        this.setState({ approvalLoading: false });
        this.loadGrantDetail();
        this.props.dispatch(showInfo(res.message));
      })
      .catch((err) => {
        this.setState({ approvalLoading: false });
        this.props.dispatch(showError(err));
      });
  };

  processDecline = () => {
    const { facility_id: id, user_id } = this.state.verification;
    const payload = { status: 'DECLINED', user_id };

    this.setState({ declineLoading: true });

    callApi(`/facility-verifications/${id}/status`, payload, 'put')
      .then((res) => {
        this.setState({ declineLoading: false });
        this.loadGrantDetail();
        this.props.dispatch(showInfo(res.message));
      })
      .catch((err) => {
        this.setState({ declineLoading: false });
        this.props.dispatch(showError(err));
      });
  };

  render() {
    const {
      verification,
      loading,
      approvalLoading,
      declineLoading,
    } = this.state;

    const { history, match, location } = this.props;

    return (
      <div className="content">
        <ConfirmActionModal />
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardHeader className="ml-2">
                <div className="d-flex justify-content-between align-contents-center">
                  <div>
                    <CardTitle>
                      {(verification.facility &&
                        verification.facility.reference) ||
                        'Loading...'}
                    </CardTitle>
                    <p className="category">Single Verification</p>
                  </div>
                  <div>
                    {verification.status === 'APPROVED' ? (
                      <Button
                        disabled={declineLoading}
                        onClick={this.processDecline}
                        color="danger"
                      >
                        DECLINE
                        {declineLoading ? (
                          <span className="fas fa-spinner fa-spin ml-3"></span>
                        ) : (
                          ''
                        )}
                      </Button>
                    ) : verification.status === 'DECLINED' ? (
                      <Button
                        disabled={approvalLoading}
                        onClick={this.processApproval}
                        color="success"
                      >
                        APPROVE
                        {approvalLoading ? (
                          <span className="fas fa-spinner fa-spin ml-3"></span>
                        ) : (
                          ''
                        )}
                      </Button>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </CardHeader>

              <CardBody>
                <div>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        style={{ color: 'black' }}
                        href="#"
                        className={classnames({
                          active: location.pathname === `${match.url}`,
                        })}
                        onClick={() => {
                          history.push(`${match.url}`);
                        }}
                      >
                        General
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <br />
                  <div>
                    <Switch>
                      <Route
                        exact
                        path={`${match.path}`}
                        render={(props) => (
                          <Insight
                            {...props}
                            verification={verification}
                            loading={loading}
                            reload={this.loadGrantDetail}
                          />
                        )}
                      ></Route>
                    </Switch>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect()(Detail);
