import * as Yup from 'yup';

import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import React, { Component } from 'react';
import { showError, showInfo } from '../actions/notificationActions';

import Compressor from 'compressorjs';
import campaignService from '../services/campaign.service';
import { closeAllModals } from '../actions/modalActions';
import { connect } from 'react-redux';

export default connect((state) => ({
  open: state.modals.createCampaign,
  data: state.modals.data,
}))(
  class CreateCampaignModal extends Component {
    state = {
      loading: false,
      type: '',
      title: '',
      is_frequent: false,
      repetition: '',
      channels: [],
      end_at: '',
      start_at: '',
      status: '',
      url: '',
      size: '',
      error: '',
      types: ['Banner', 'Alert', 'Announcement', 'Advert'],
      campaignsFor: ['DIRECT_DEBIT', 'ALL'],
      campaignStatus: ['Published', 'Draft'],
      campaignChannels: ['Web', 'Mobile'],
      bannerSizes: ['Big', 'Small'],
      message: '',
      action: '',
      image: '',
      selected_user_categories: [],
      userCategories: [
        'ALL',
        'POWER_USER',
        'REGULAR_USER',
        'OCCASIONAL_USER',
        'NEW_CUSTOMER',
        'CARD_EXPIRED',
        'CARD_ABOUT_TO_EXPIRE',
        'INTERNAL_TEAM',
        'CHURN_1_YEAR',
        'CHURN_3_MONTHS',
        'CHURN_5_MONTHS',
      ],
      actions: ['OPEN', 'NAVIGATE'],
      discos: [],
      selected_discos: [],
      target_page: '',
      targetPages: [
        'HOME',
        'REFERRAL',
        'SETTINGS',
        'DIRECT_DEBIT',
        'CREATE_ORDER/ELECTRICITY',
        'CREATE_ORDER/VTU',
        'CREATE_ORDER/DATA',
        'CREATE_ORDER/TV',
        'WALLET/ADD_FUNDS',
      ],
      errors: [],
    };

    showMultipleTimes = (e) => {
      const { checked } = e.target;
      this.setState({ is_frequent: checked });
    };

    setImageInput = async (e) => {
      const file = e.target.files[0];

      if (file) {
        if (file.size > 2 * 1024 * 1024) {
          return this.setState({ error: 'File size exceeds 2MB!' });
        }

        try {
          new Compressor(file, {
            maxSizeMB: 0.2, // Maximum allowed size in MB (200KB)
            maxWidthOrHeight: 800,
            success: (compressedFile) => {
              const reader = new FileReader();
              reader.onload = () => {
                const base64 = reader.result.split(',')[1];
                this.setState({ image: base64 });
              };
              reader.readAsDataURL(compressedFile);
            },
            error: () => {
              this.setState({ error: 'Failed to compress image!' });
            },
          });
        } catch (error) {
          this.setState({ error: 'Failed to compress image!' });
        }
      }
    };

    componentDidUpdate(prevProps) {
      if (this.props.open && !prevProps.open) {
        this.setState({
          discos: this.props.data.discos,
        });
      }
    }

    setInput = (e) => {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    };

    handleChannelChange = (selectedOptions) => {
      this.setState({ channels: selectedOptions });
    };

    handleDiscoChange = (selectedOptions) => {
      this.setState({ selected_discos: selectedOptions });
    };

    handleUserCategoryChange = (selectedOptions) => {
      this.setState({ selected_user_categories: selectedOptions });
    };

    close = () => {
      this.props.dispatch(closeAllModals());
    };

    submit = async (e) => {
      e.preventDefault();
      const schema = Yup.object().shape({
        title: Yup.string().required('Title is required').trim(),
        type: Yup.string().required('Type is required').trim(),
        channels: Yup.array()
          .of(Yup.string().required('Channel is required').trim())
          .required('Channel is required'),
        status: Yup.string().required('Status is required').trim(),
        start_at: Yup.string().required('Start date is required').trim(),
        end_at: Yup.string().required('End date is required').trim(),
        is_frequent: Yup.bool(),
        action: Yup.string().required('Action is required').trim(),
        repetition: Yup.string().when('is_frequent', {
          is: (value) => value === true,
          then: (schema) => schema.required('Repetition is required').trim(),
        }),
        url: Yup.string().when('action', {
          is: (value) => value === 'OPEN',
          then: (schema) => schema.required('URL is required').trim(),
          otherwise: (schema) => schema.optional(),
        }),
        size: Yup.string().when('type', {
          is: (value) => value === 'banner',
          then: (schema) => schema.required('Size is required').trim(),
          otherwise: (schema) => schema.optional(),
        }),
        message: Yup.string().when('type', {
          is: (value) => value === 'announcement',
          then: (schema) => schema.required('Message is required').trim(),
          otherwise: (schema) => schema.optional(),
        }),
        image: Yup.string().when('type', {
          is: (value) => value === 'announcement',
          then: (schema) => schema.optional(),
          otherwise: (schema) => schema.required('Image is required'),
        }),
        selected_user_categories: Yup.array()
          .of(Yup.string().required('User category is required').trim())
          .required('User category is required'),
        selected_discos: Yup.array()
          .of(Yup.string().required('Disco is required').trim())
          .required('Disco is required'),
        target_page: Yup.string().required('Target page is required').trim(),
      });

      try {
        let validForm = await schema.isValid(this.state);
        if (!validForm) {
          await schema.validate(this.state, {
            abortEarly: false,
          });
        }
      } catch (error) {
        this.setState({ errors: error.errors });
        return;
      }

      let bodyObj = {
        title: this.state.title,
        type: this.state.type,
        channels: this.state.channels,
        status: this.state.status,
        start_at: this.state.start_at,
        end_at: this.state.end_at,
        is_frequent: this.state.is_frequent,
        ...(this.state.repetition !== '' && {
          repetition: this.state.repetition,
        }),
        content: {
          url: this.state.url,
          action: this.state.action,
          ...(this.state.size !== '' && {
            size: this.state.size,
          }),
          ...(this.state.message !== '' && {
            message: this.state.message,
          }),
          ...(this.state.image !== null && {
            image: this.state.image,
          }),
          user_categories: this.state.selected_user_categories,
          ...(this.state.selected_discos.length > 0 && {
            discos: this.state.selected_discos,
          }),
          ...(this.state.target_page !== '' && {
            target_page: this.state.target_page,
          }),
        },
      };

      this.setState({ loading: true });
      campaignService
        .createCampaign(bodyObj)
        .then((res) => {
          this.setState({ loading: false });

          this.props.dispatch(showInfo(res.message));
          this.close();
          this.props.dispatch(closeAllModals());
          setTimeout(() => {
            this.props.refresh();
          }, 2000);
        })
        .catch((err) => {
          this.setState({ loading: false });
          if ((err.status = 'unprocessable'))
            this.props.dispatch(showError(err.message));
        });
    };

    render() {
      const {
        title,
        loading,
        end_at,
        is_frequent,
        repetition,
        size,
        start_at,
        url,
        message,
        action,
        discos,
      } = this.state;

      const open = this.props.open;
      return (
        <div>
          <Modal size="lg" isOpen={open} toggle={this.close}>
            <ModalHeader toggle={this.close}>Create campaign</ModalHeader>
            <ModalBody>
              {this.state.errors.length > 0 ? (
                <ul>
                  {this.state.errors.map((error, index) => (
                    <li key={index} style={{ color: 'red' }}>
                      {error}
                    </li>
                  ))}
                </ul>
              ) : (
                ''
              )}
              <form>
                <div>
                  <FormGroup>
                    <Label className="mt-3">Title:</Label>
                    <Input
                      name="title"
                      type="text"
                      onChange={this.setInput}
                      value={title}
                      disabled={loading}
                    />
                  </FormGroup>
                  <Row>
                    <Col md={12} xs={12}>
                      <FormGroup>
                        <Label for="type">Type:</Label>
                        <Input
                          id="type"
                          name="type"
                          type="select"
                          onChange={this.setInput}
                        >
                          <option value={''}>Please select</option>
                          {this.state.types.map((type, index) => (
                            <option key={index} value={type.toLowerCase()}>
                              {type}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Label for="exampleSelectMulti">Channel</Label>
                  <Input
                    id="exampleSelectMulti"
                    multiple
                    name="channel"
                    type="select"
                    disabled={loading}
                    value={this.state.channels}
                    onChange={(e) => {
                      const selectedOptions = Array.from(
                        e.target.selectedOptions
                      ).map((option) => option.value);
                      this.handleChannelChange(selectedOptions);
                    }}
                  >
                    {this.state.campaignChannels.map((channel, index) => (
                      <option key={index} value={channel.toLocaleLowerCase()}>
                        {channel}
                      </option>
                    ))}
                  </Input>

                  <Row>
                    <Col md={6} xs={12}>
                      <FormGroup>
                        <Label className="mt-3">Start Date:</Label>
                        <Input
                          name="start_at"
                          type="datetime-local"
                          onChange={this.setInput}
                          value={start_at}
                          disabled={loading}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={6} xs={12}>
                      <Label className="mt-3">End Date:</Label>
                      <Input
                        name="end_at"
                        type="datetime-local"
                        onChange={this.setInput}
                        value={end_at}
                        disabled={loading}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6} xs={12}>
                      <FormGroup>
                        <Label for="type">Status</Label>
                        <Input
                          id="status"
                          name="status"
                          type="select"
                          onChange={this.setInput}
                        >
                          <option value={''}>Please select</option>
                          {this.state.campaignStatus.map((status, index) => (
                            <option
                              key={index}
                              value={status.toLocaleLowerCase()}
                            >
                              {status}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>

                    <Col md={6} xs={12}>
                      <FormGroup>
                        <Label for="target_page">
                          Notification Target Page
                        </Label>
                        <Input
                          id="target_page"
                          name="target_page"
                          type="select"
                          onChange={this.setInput}
                        >
                          <option value={''}>Please select</option>
                          {this.state.targetPages.map((page, index) => (
                            <option
                              key={index}
                              value={page.toLocaleLowerCase()}
                            >
                              {page}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={this.state.action === 'OPEN' ? 6 : 12} xs={12}>
                      <Label className="mt-3">Action:</Label>
                      <Input
                        id="action"
                        name="action"
                        type="select"
                        onChange={this.setInput}
                        value={action}
                      >
                        <option value={''}>Please select</option>
                        {this.state.actions.map((action, index) => (
                          <option key={index} value={action}>
                            {action}
                          </option>
                        ))}
                      </Input>
                    </Col>

                    {this.state.action === 'OPEN' && (
                      <Col md={6} xs={12}>
                        <Label className="mt-3">Url:</Label>
                        <Input
                          name="url"
                          type="text"
                          onChange={this.setInput}
                          value={url}
                          disabled={loading}
                        />
                      </Col>
                    )}
                  </Row>

                  <Row>
                    <Col md={6} xs={12}>
                      <Label for="userCategory" className="mt-2">
                        User Category
                      </Label>
                      <Input
                        multiple
                        id="userCategory"
                        name="selected_user_categories"
                        type="select"
                        onChange={(e) => {
                          const selectedOptions = Array.from(
                            e.target.selectedOptions
                          ).map((option) => option.value);
                          this.handleUserCategoryChange(selectedOptions);
                        }}
                        disabled={loading}
                        value={this.state.selected_user_categories}
                      >
                        {this.state.userCategories.map((category, index) => (
                          <option key={index} value={category.toLowerCase()}>
                            {category}
                          </option>
                        ))}
                      </Input>
                    </Col>

                    <Col md={6} xs={12}>
                      <Label for="discoCategory" className="mt-2">
                        Disco Category
                      </Label>
                      <Input
                        id="discoCategory"
                        multiple
                        name="selected_discos"
                        type="select"
                        disabled={loading}
                        value={this.state.selected_discos}
                        onChange={(e) => {
                          const selectedOptions = Array.from(
                            e.target.selectedOptions
                          ).map((option) => option.value);
                          this.handleDiscoChange(selectedOptions);
                        }}
                      >
                        {discos.map((disco, index) => (
                          <option key={index} value={disco.toLowerCase()}>
                            {disco}
                          </option>
                        ))}
                      </Input>
                    </Col>
                  </Row>

                  {this.state.type !== '' && this.state.type === 'banner' && (
                    <span>
                      <Label className="mt-3">Size:</Label>
                      <Input
                        name="size"
                        type="select"
                        onChange={this.setInput}
                        value={size}
                        disabled={loading}
                      >
                        <option value={''}>Please select</option>
                        {this.state.bannerSizes.map((size, index) => (
                          <option key={index} value={size.toLowerCase()}>
                            {size}
                          </option>
                        ))}
                      </Input>
                    </span>
                  )}

                  {this.state.type !== '' &&
                    this.state.type !== 'announcement' && (
                      <span>
                        <Label for="exampleFile">Image</Label>
                        <Input
                          id="exampleFile"
                          type="file"
                          name="image"
                          onChange={this.setImageInput}
                          disabled={loading}
                        />
                      </span>
                    )}

                  {this.state.type !== '' &&
                    this.state.type === 'announcement' && (
                      <span>
                        <Label className="mt-3">Message:</Label>
                        <Input
                          name="message"
                          type="text"
                          onChange={this.setInput}
                          value={message}
                          disabled={loading}
                        />
                      </span>
                    )}
                  <div
                    style={{
                      textAlign: 'left',
                      margin: '2% 0% 0% 3%',
                      //   width: '50%',
                    }}
                  >
                    <FormGroup>
                      <Input
                        type="checkbox"
                        id="checkbox2"
                        onChange={this.showMultipleTimes}
                        value={is_frequent}
                        disabled={loading}
                        name="is_frequent"
                      />{' '}
                      <Label check for="checkbox2">
                        Show Multiple Times
                      </Label>
                    </FormGroup>
                  </div>

                  {this.state.is_frequent && (
                    <span>
                      <Label className="mt-3">Repetition:</Label>
                      <Input
                        name="repetition"
                        type="number"
                        onChange={this.setInput}
                        value={repetition}
                        disabled={loading}
                      />
                    </span>
                  )}
                </div>
                <div />
              </form>
            </ModalBody>
            <ModalFooter>
              <Button color="success" disabled={loading} onClick={this.submit}>
                Create Campaign{' '}
                {loading ? (
                  <span className="fas fa-spinner fa-spin"></span>
                ) : (
                  ''
                )}
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      );
    }
  }
);
