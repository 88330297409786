import React, { useState, useEffect } from 'react';

import { Row, Col, Card, CardHeader, CardTitle, CardBody } from 'reactstrap';

import { callApi, formatAmount } from '../../utils';

import SwitchRadio from '../SwitchRadio';
import ClickToCopy from '../ClickToCopy';
import ErrorTick from '../ErrorTick';
import SuccessTick from '../SuccessTick';

function InfoTab({ user }) {
  const [watchlist, setWatchlist] = useState([]);
  const [toggling, setToggling] = useState(false);
  // const [enabled, setEnabled] = useState(false);
  const [userWatchlists, setUserWatchlists] = useState([]);

  useEffect(() => {
    getWatchlist();
    getUserWatchlists();
  }, [user]);

  const getWatchlist = () => {
    // const { query, page, limit } = this.state;
    callApi(`/watchlists`).then(({ data }) => {
      setWatchlist([...data]);
    });
  };

  const getUserWatchlists = () => {
    callApi(`/customers/${user.phone}/watchlists`).then(({ data = [] }) => {
      const list = data.map((item) => item.watchlist_id);
      setUserWatchlists(list);
    });
  };

  const removeFromWatchlist = (watchlist_id) => {
    callApi(
      `/watchlists/${watchlist_id}/users/${user.phone}`,
      { reference: user.phone },
      'delete'
    ).then(() => {
      // Update user watchlist
      getUserWatchlists();
      setToggling(true);
    });
  };

  const addToWatchlist = (watchlist_id) => {
    callApi(
      `/watchlists/${watchlist_id}/users`,
      { reference: user.phone },
      'post'
    ).then(() => {
      setToggling(true);
      getUserWatchlists();
    });
  };

  const toggleStatus = (watchlist, isAdded) => {
    if (isAdded) {
      setToggling(false);
      removeFromWatchlist(watchlist.id);
    } else {
      setToggling(false);
      addToWatchlist(watchlist.id);
    }
  };

  if (!user) return <React.Fragment />;

  return (
    <Row>
      <Col sm="6">
        <Card className="card-chart">
          <CardHeader>
            <CardTitle className="mt-0">User</CardTitle>
          </CardHeader>
          <CardBody>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Name</span>
              </div>
              <div> {user.name} </div>
            </div>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Email</span>
              </div>
              <div> {user.email} </div>
            </div>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Phone</span>
              </div>
              <span>
                <span className="cursor-pointer blue-text" onClick={() => {}}>
                  <strong>{user.phone}</strong>
                </span>
                <ClickToCopy value={user.phone} />
              </span>
            </div>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Social Follow</span>
              </div>
              <div>
                {Number(user.social_follow) ? <ErrorTick /> : <SuccessTick />}
              </div>
            </div>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Balance</span>
              </div>
              {user.wallet && (
                <div> {formatAmount(Number(user.wallet) / 100)} </div>
              )}
            </div>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Debt</span>
              </div>
              <div>
                {user.debt
                  ? formatAmount(Number(user.debt) / 100)
                  : formatAmount(0)}
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>

      <Col sm="6">
        <Card className="card-chart">
          <CardHeader>
            <CardTitle className="mt-0">Watchlist</CardTitle>
          </CardHeader>
          <CardBody>
            {watchlist.length > 0 &&
              watchlist.map((watchlist, index) => {
                const enabled = !!userWatchlists.find(
                  (item) => item === watchlist.id
                );

                return (
                  <SwitchRadio
                    key={index}
                    checked={enabled}
                    onChange={() => toggleStatus(watchlist, enabled)}
                    size="sm"
                    toggling={toggling}
                    name={watchlist.name}
                    className="d-flex justify-content-between align-items-center mb-3"
                  />
                );
              })}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default React.memo(InfoTab);
