import React, { useState, useEffect } from 'react';
import { Table, Button } from 'reactstrap';
import { callApi, formatAmount, formatDate } from '../../utils';
import Loading from '../Loading';
import { connect } from 'react-redux';
import { showVendResponse } from '../../actions/modalActions';
import { showError } from '../../actions/notificationActions';
import Pagination from '../Pagination';
import VendResponseModal from '../VendResponseModal';

function Responses(props) {
  const { order, dispatch } = props;
  const [loading, setLoading] = useState(true);
  const [flushing, setFlushing] = useState(false);

  const [queryParams, setQueryParams] = useState({ limit: 30, page: 1 });
  const [response, setResponse] = useState({ data: [], meta: { pages: 1 } });
  const [resellLoading, setResellLoading] = useState(false);

  const fetchVendResponse = async () => {
    setLoading(true);
    return callApi(`/orders/${order.id}/responses`)
      .then((res) => {
        const { data = [], meta = {} } = res;
        setResponse({ data, meta });
        console.log(data);
      })
      .catch((err) => {
        dispatch(showError(err.message));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Function to mark the vend response for resell
  const markForResell = async (id) => {
    if (!id) return;
    // 7347426

    try {
      setResellLoading(true);

      const response = await callApi(`/response/${id}/resell`, {}, 'delete');

      alert(response.message);

      await requery(id);

      setResellLoading(false);
    } catch (error) {
      console.log('Err: ', error);
    }
  };

  // Function to mark the vend response for resell
  const flush = async (response) => {
    if (!response || !response.id) return;
    // 7347426

    try {
      setFlushing(true);

      const res = await callApi(`/responses/${response.id}`, {}, 'delete');

      alert(res.message);

      await fetchVendResponse();
    } catch (error) {
      console.log('Err: ', error);
    } finally {
      setFlushing(false);
    }
  };

  const requery = async () => {
    setLoading(true);
    return callApi(`/orders/${order.id}/responses`)
      .then((res) => {
        const { data = [], meta = {} } = res;
        setResponse({ data, meta });
        console.log(data);
      })
      .catch((err) => {
        dispatch(showError(err.message));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const refresh = () => fetchVendResponse();

  useEffect(() => {
    setLoading(true);

    if (order.id) {
      fetchVendResponse().finally(() => setLoading(false));
    }
  }, [queryParams.page, order.id]);

  const setPage = (page = 1) => {
    setQueryParams({ ...queryParams, page });
  };

  const { data, meta } = response;
  const { page, limit } = queryParams;

  return (
    <div>
      <VendResponseModal />
      <div className="d-flex">
        <div style={{ width: '15px' }} />
        <Button disabled={loading} onClick={refresh}>
          Refresh
        </Button>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <Table responsive hover striped>
          <thead className="text-primary">
            <tr>
              <th>No.</th>
              <th>Reference</th>
              <th>Amount Due</th>
              <th>Amount Paid</th>
              <th>Vend Type</th>
              <th>Processor</th>
              <th>Date</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            {data.length ? (
              data.map((t, i) => (
                <tr
                  onClick={() =>
                    dispatch(showVendResponse({ vendResponse: t }))
                  }
                  key={t.id}
                >
                  <td>{loading ? 'x' : i + 1 + (page - 1) * limit}</td>
                  <td>{t.payment_reference}</td>
                  <td>{formatAmount(t.amt)}</td>
                  <td>{formatAmount(t.amt)}</td>
                  <td>{t.vend_type}</td>
                  <td>{t.util}</td>
                  <td>{formatDate(t.created_at)}</td>
                  <td>{t.resell === 0 ? 'Resellable' : 'Not Resellable'}</td>
                  <td>
                    {t.resell === 0 ? (
                      <Button
                        size="sm"
                        color="success"
                        disabled={resellLoading}
                        onClick={(e) => {
                          e.stopPropagation();
                          markForResell(t.id);
                        }}
                      >
                        Mark for Resell
                      </Button>
                    ) : (
                      <Button
                        size="sm"
                        color="primary"
                        disabled={true}
                        onClick={() => {}}
                      >
                        Marked
                      </Button>
                    )}
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        flush(t);
                      }}
                      disabled={flushing}
                      color="danger"
                      size="sm"
                    >
                      Flush
                    </Button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="9">No vend responses for this order.</td>
              </tr>
            )}
          </tbody>
        </Table>
      )}{' '}
      <Pagination page={page} total={meta.pages} onChange={setPage} />
    </div>
  );
}

export default connect()(Responses);
