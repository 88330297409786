import React, { Component } from 'react';
import { connect } from 'react-redux';
import ConfirmActionModal from '../ConfirmActionModal';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Nav,
  NavLink,
  NavItem,
} from 'reactstrap';
import classnames from 'classnames';

import { callApi, formatAmount } from '../../utils';
import { showError } from '../../actions/notificationActions';

import { openUserModal } from '../../actions/modalActions';

import ClickToCopy from '../ClickToCopy';
import Transactions from './Transactions';
import { Route, Switch } from 'react-router-dom';

class Detail extends Component {
  state = {
    provider: {},
    meta: {},

    loading: true,
    activeTab: '1',
    toggle: null,
    rows: [],
    page: 1,
    pages: 1,
    limit: 10,
    wallet: null,
  };

  componentWillMount() {
    this.loadView();
  }

  startLoad = () => this.setState({ loading: true });
  stopLoad = () => this.setState({ loading: false });

  scrollToTop = () =>
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);

  loadView = (e) => {
    const id = this.props.match.params.id;

    this.startLoad();
    callApi(`/service-providers/${id}`)
      .then(({ data = {}, meta = {} }) => {
        this.setState({ provider: data });
        this.getWallet(id);
        this.scrollToTop();
      })
      .catch((err) => {
        this.props.dispatch(showError(err));
      })
      .finally(() => {
        this.stopLoad();
      });
  };

  getWallet = (id) => {
    callApi(`/service-providers/${id}/wallet`)
      .then(({ data }) => {
        this.setState({
          wallet: data,
        });
      })
      .catch((error) => {});
  };

  // getSettlements = (params) => {
  //   const id = this.props.match.params.id;

  //   this.startLoad();
  //   callApi(`/settlement/${id}`, params)
  //     .then(({ data = {}, meta = {} }) => {
  //       this.setState({ rows: data, meta });
  //       this.scrollToTop();
  //     })
  //     .catch((err) => {
  //       this.props.dispatch(showError(err));
  //     })
  //     .finally(() => {
  //       this.stopLoad();
  //     });
  // };

  setPage = (page) => {
    if (page) {
      this.setState({ page }, () => {
        this.loadView();
      });
    }
  };

  opentUser = (id) => {
    this.props.dispatch(openUserModal(id));
  };

  setInput = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    let { provider, wallet } = this.state;

    // const toggle = (tab) => {
    //   this.setState({ activeTab: tab });
    // };

    const { history, match, location } = this.props;

    const id = match.params.id;

    return (
      <div className="content">
        <ConfirmActionModal />
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardHeader className="ml-2">
                <div className="d-flex justify-content-between">
                  <div>
                    <CardTitle>{provider.name || 'Loading...'}</CardTitle>
                    <p className="category">Service provider</p>
                  </div>
                  <div>
                    <h4>{wallet ? formatAmount(wallet.balance) : '-.--'}</h4>
                  </div>
                </div>
              </CardHeader>

              <CardBody>
                <div>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        style={{ color: 'black' }}
                        href="#"
                        className={classnames({
                          active: location.pathname === `${match.url}`,
                        })}
                        onClick={() => {
                          history.push(`${match.url}`);
                        }}
                      >
                        General
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ color: 'black' }}
                        href="#"
                        className={classnames({
                          active:
                            location.pathname === `${match.url}/transactions`,
                        })}
                        onClick={() => {
                          history.push(`${match.url}/transactions`);
                        }}
                      >
                        Transactions
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <br />
                  <div>
                    <Switch>
                      <Route exact path={`${match.path}`}>
                        <GeneralTab provider={provider} />
                      </Route>
                      <Route path={`${match.path}/transactions`}>
                        <Transactions id={id} />
                      </Route>
                    </Switch>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

function GeneralTab({ provider }) {
  return (
    <Row>
      <Col sm="6">
        <Card className="card-chart">
          <CardHeader>
            <CardTitle className="mt-0">provider</CardTitle>
          </CardHeader>
          <CardBody>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Order ID</span>
              </div>
              <div> {provider.order_id} </div>
            </div>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Name</span>
              </div>
              <div> {provider.name} </div>
            </div>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Phone</span>
              </div>
              <span>
                <span className="cursor-pointer blue-text" onClick={() => {}}>
                  <strong>{provider.phone}</strong>{' '}
                </span>
                <ClickToCopy value={provider.phone} />
              </span>
            </div>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">DisCo</span>
              </div>
              <div> {provider.disco_code} </div>
            </div>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Payment Method</span>
              </div>
              <div> {provider.payment_type} </div>
            </div>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Amount</span>
              </div>
              {provider.amount && <div> {formatAmount(provider.amount)} </div>}
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default connect()(Detail);
