export const VERTICALS = ['ELECTRICITY', 'TV', 'DATA', 'AIRTIME'];
export const ADROIT_BANKS = [
  { code: 1, name: 'ACCESS BANK' },
  { code: 2, name: 'DIAMOND BANK' },
  { code: 3, name: 'FIDELITY BANK' },
  { code: 4, name: 'FCMB BANK' },
  { code: 5, name: 'FIRST BANK OF NIGERIA' },
  { code: 6, name: 'GTB BANK' },
  { code: 7, name: 'POLARIS BANK' },
  { code: 8, name: 'UNION BANK' },
  { code: 9, name: 'UBA BANK' },
  { code: 10, name: 'ZENITH BANK' },
  { code: 11, name: 'CITI BANK' },
  { code: 12, name: 'ECO BANK' },
  { code: 13, name: 'HERITAGE BANK' },
  { code: 14, name: 'KEYSTONE BANK' },
  { code: 15, name: 'STANBIC BANK' },
  { code: 16, name: 'STANDARD CHARTERED BANK' },
  { code: 17, name: 'STERLING BANK' },
  { code: 18, name: 'UNITY BANK' },
  { code: 19, name: 'WEMA BANK' },
  { code: 20, name: 'SUNTRUST BANK' },
  { code: 21, name: 'PROVIDUS BANK' },
  { code: 22, name: 'JAIZ BANK' },
];
