import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import React, { Component } from 'react';

import { closeAllModals } from '../actions/modalActions';
import { connect } from 'react-redux';

export default connect((state) => ({
  open: state.modals.survey,
  data: state.modals.data,
}))(
  class SurveyResponseModal extends Component {
    state = {
      loading: false,
      data: {},
      numberOfResponses: 0,
    };

    componentDidUpdate(prevProps) {
      if (prevProps.open !== this.props.open && this.props.open) {
        this.setState({
          loading: false,
          data: this.props.data,
          numberOfResponses: this.props.data.responses.length,
        });
      }
    }

    setInput = (e) => {
      const { name, value } = e.target;

      this.setState((state) => {
        return { ...state, campaign: { ...state.campaign, [name]: value } };
      });
    };

    close = () => {
      this.props.dispatch(closeAllModals());
    };

    render() {
      const open = this.props.open;
      return (
        <div>
          <Modal size="lg" isOpen={open} toggle={this.close}>
            <ModalHeader toggle={this.close}>Survey Response</ModalHeader>
            <ModalBody>
              <div>
                {this.state.numberOfResponses > 0 ? (
                  <div>
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Question</th>
                          <th>Response</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.data.responses.map((response, index) => (
                          <tr key={index}>
                            <td>{response.question}</td>
                            <td>{response.response}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div>
                    <h3>No responses yet</h3>
                  </div>
                )}
              </div>
            </ModalBody>
          </Modal>
        </div>
      );
    }
  }
);
