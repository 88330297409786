import React from 'react';

export default ({ color }) => (
  <strong>
    <i
      className="now-ui-icons ui-1_check"
      style={{ color: color || 'green' }}
    />
  </strong>
);
