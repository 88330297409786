import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import { showError } from '../../actions/notificationActions';
import { callApi } from '../../utils';
import BuyPowerTimeline from '../BuyPowerTimeline';
import Loading from '../Loading';

import { CardDetails, RaveTimeline, Timeline } from '../TransactionTimeline';

function TransactionTimeline(props) {
  const { order, dispatch } = props;
  console.log('props here be', props);
  const [loading, setLoading] = useState(false);

  const initialState = {
    data: {},
    id: null,
    type: '',
    timeline: {},
    buypower: [], // BuyPower Transaction Timeline
    customer: {},
    details: {},
    loading: false,
    open: false,
    error: '',
  };

  const [allState, setAllState] = useState(initialState);
  const [error, setError] = useState('');

  const loadDetails = () => {
    setLoading({ loading: true });
    callApi('/transaction/timeline?id=' + order.order_id)
      .then((data) => {
        let type = data.type;

        if (type === 'PAYSTACK') {
          data = data.data;
          setAllState({
            data,
            id: data.reference,
            type,
            timeline: data.log,
            customer: data.customer || {},
            details: data.authorization,
            loading: false,
            error: '',
          });
        }

        if (type === 'RAVE') {
          data = data.data;
          this.setState({
            data,
            timeline: data,
            type,
            loading: false,
            error: '',
          });
        }

        if (type === 'BUYPOWER' && data.buypower.length) {
          setAllState({ ...allState, data, buypower: data.buypower });
        }
      })
      .catch((err) => {
        dispatch(showError(err.message));
        setError(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (order.order_id) {
      setLoading(true);
      loadDetails();
    }
  }, [order.order_id]);

  const { buypower, data, details, type, timeline } = allState;

  return (
    <Card>
      {loading ? (
        <Loading />
      ) : (
        <CardBody className="mx-2 my-3">
          {error ? (
            <h5>{error}</h5>
          ) : (
            <div>
              <BuyPowerTimeline
                done={loadDetails}
                data={buypower}
                meta={data.data}
              />
              {type === 'RAVE' ? (
                <div>
                  <RaveTimeline timeline={timeline} />
                </div>
              ) : type === 'PAYSTACK' ? (
                <div>
                  <CardDetails details={details} />
                  <Timeline timeline={timeline || undefined} />
                </div>
              ) : null}
            </div>
          )}{' '}
        </CardBody>
      )}
    </Card>
  );
}

export default connect()(TransactionTimeline);
