import React from 'react';
import { NavLink } from 'react-router-dom';
import { Nav } from 'reactstrap';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';

import logo from 'logo-white.svg';

import {
  isSuperAdmin,
  isFinance,
  isShiftSupervisor,
  isSupportAdmin,
} from '../../utils';

var ps;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
  }

  state = {
    _isShiftSupervisor: false,
    _isSuperAdmin: false,
    _isSupportAdmin: false,
    _isFinance: false,
  };

  sidebarRef = React.createRef();

  // verifies if routeName is the one active (in browser input)
  activeRoute(path) {
    // return this.props.location.pathname.indexOf(routeName) > -1 ? 'active' : '';
    const splits = this.props.location.pathname.split('/');
    return `/${splits[1]}` === path ? 'active' : '';
  }

  getConfig = async () => {
    const [supervisor, finance, superAdmin, supportAdmin] = await Promise.all([
      isShiftSupervisor(),
      isFinance(),
      isSuperAdmin(),
      isSupportAdmin(),
    ]);
    this.setState({
      _isShiftSupervisor: supervisor,
      _isFinance: finance,
      _isSuperAdmin: superAdmin,
      _isSupportAdmin: supportAdmin,
    });
  };

  componentDidMount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(this.sidebarRef, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    this.getConfig();
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
    }
  }

  render() {
    const {
      _isShiftSupervisor,
      _isSuperAdmin,
      _isSupportAdmin,
      _isFinance,
    } = this.state;

    // Sort routes alphabetically
    this.props.routes.sort((a, b) =>
      a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    );
    return (
      <div className="sidebar" data-color="blue">
        <div className="logo">
          <a
            href="https://iadmin.buypower.ng"
            className="simple-text logo-mini"
          >
            <div className="logo-img">
              <img src={logo} alt="react-logo" />
            </div>
          </a>
          <a
            href="https://iadmin.buypower.ng"
            className="simple-text logo-normal"
          >
            BUYPOWER
          </a>
        </div>
        <div className="sidebar-wrapper" ref={(el) => (this.sidebarRef = el)}>
          <Nav>
            {this.props.routes.map((prop, key) => {
              if (prop.redirect) return null;

              if (prop.isRestrictedComponent && !_isSuperAdmin) return null;

              if (prop.isFinanceComponent && !_isFinance) return null;

              if (
                prop.isRestrictedSupportComponent &&
                !(_isSuperAdmin || _isShiftSupervisor)
              )
                return null;

              if (prop.isSupportComponent && !_isSupportAdmin) return null;

              return (
                <li
                  className={
                    this.activeRoute(prop.path) +
                    (prop.pro ? ' active active-pro' : '')
                  }
                  key={key}
                >
                  <NavLink
                    exact
                    strict
                    to={prop.path}
                    className="nav-link"
                    activeClassName="active"
                    isActive={(a, b) => {
                      const splits = b.pathname.split('/');
                      return `/${splits[1]}` === prop.path;
                    }}
                  >
                    <i className={'now-ui-icons ' + prop.icon} />
                    <p>{prop.name}</p>
                  </NavLink>
                </li>
              );
            })}
          </Nav>
        </div>
      </div>
    );
  }
}

export default Sidebar;
