export const UPDATE_USER = 'UPDATE_USER';
export const CLEAR_USER = 'CLEAR_USER';

export const updateUser = (data) => ({
  type: UPDATE_USER,
  data,
});

export const clearUser = () => ({
  type: CLEAR_USER,
});
