import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { closeAllModals } from '../actions/modalActions';

import TransactionDetails from './TransactionDetails';
import VendDetails from './VendDetails';
import TransactionTimeline from './TransactionTimeline';
import TokenResponse from './TokenResponse';
import OrderPayments from './OrderPayments';

export default connect((state) => ({
  data: state.modals.data,
  open: state.modals.transaction,
}))(
  class TransactionModal extends Component {
    state = {};

    close = () => {
      this.props.dispatch(closeAllModals());
    };

    render() {
      const { data, open, dispatch } = this.props;

      if (!data) {
        return <Fragment />;
      }

      return (
        <div>
          <Modal size="lg" isOpen={open} toggle={this.close}>
            <ModalHeader toggle={this.close}>Transaction Details</ModalHeader>
            <ModalBody>
              <TransactionDetails order={data} dispatch={dispatch} />
              <VendDetails order={data} dispatch={dispatch} />
              <TransactionTimeline order={data} dispatch={dispatch} />
              <TokenResponse order={data} />
              <OrderPayments order={data} dispatch={dispatch} />
            </ModalBody>
          </Modal>
        </div>
      );
    }
  }
);
