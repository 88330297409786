import React from 'react';
import { Row, Col, Card, CardHeader, CardTitle, CardBody } from 'reactstrap';
import { formatAmount, formatDate } from '../../utils';
import ClickToCopy from '../ClickToCopy';
import Loading from '../Loading';
import VendRequests from './VendRequests';
import Parcels from './Parcels';

export function Insight(props) {
  const { order = {}, dispatch, loading, reload } = props;

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <Row>
          <Col md={6} xs={12}>
            <Card className="card-chart">
              <CardHeader>
                <CardTitle className="mt-0">Order</CardTitle>
              </CardHeader>
              <CardBody>
                <div className="d-flex mb-3 justify-content-between">
                  <div>
                    <span className="text-secondary">Amount</span>
                  </div>
                  {order.amount && <div> {formatAmount(order.amount)} </div>}
                </div>
                <div className="d-flex mb-3 justify-content-between">
                  <div>
                    <span className="text-secondary">Channel</span>
                  </div>
                  <div> {order.channel} </div>
                </div>
                <div className="d-flex mb-3 justify-content-between">
                  <div>
                    <span className="text-secondary">Order Status</span>
                  </div>
                  <div> {order.order_status} </div>
                </div>
                <div className="d-flex mb-3 justify-content-between">
                  <div>
                    <span className="text-secondary">Date</span>
                  </div>
                  {order.created_at && (
                    <div> {formatDate(order.created_at)} </div>
                  )}
                </div>
                <div className="d-flex mb-3 justify-content-between">
                  <div>
                    <span className="text-secondary">Agent</span>
                  </div>
                  <div> {order.agent.name} </div>
                </div>
                <div className="d-flex mb-3 justify-content-between">
                  <div>
                    <span className="text-secondary">Transmissions</span>
                  </div>
                  <div> {order.transmissions} </div>
                </div>
                <div className="d-flex mb-3 justify-content-between">
                  <div>
                    <span className="text-secondary">IP Address</span>
                  </div>
                  <div> {order.remote_client_ip} </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md={6} xs={12}>
            <Row>
              <Col>
                <Card className="card-chart">
                  <CardHeader>
                    <CardTitle className="mt-0">Customer</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <div className="d-flex mb-3 justify-content-between">
                      <div>
                        <span className="text-secondary">Name</span>
                      </div>
                      <div> {order.name} </div>
                    </div>
                    <div className="d-flex mb-3 justify-content-between">
                      <div>
                        <span className="text-secondary">Phone</span>
                      </div>
                      <span>
                        <span
                          className="cursor-pointer blue-text"
                          onClick={() => this.opentUser(order.phone)}
                        >
                          <strong>{order.phone}</strong>{' '}
                        </span>
                        <ClickToCopy value={order.phone} />
                      </span>
                    </div>
                    <div className="d-flex mb-3 justify-content-between">
                      <div>
                        <span className="text-secondary">Email Address</span>
                      </div>
                      <div> {order.email} </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card className="card-chart">
                  <CardHeader>
                    <CardTitle className="mt-0">Resource</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <div className="d-flex mb-3 justify-content-between">
                      <div>
                        <span className="text-secondary">Meter No.</span>
                      </div>
                      <span>
                        <span>
                          <strong>{order.meter_no}</strong>{' '}
                        </span>
                        <ClickToCopy value={order.meter_no} />
                      </span>
                    </div>
                    <div className="d-flex mb-3 justify-content-between">
                      <div>
                        <span className="text-secondary">Vend Type</span>
                      </div>
                      <div> {order.vend_type} </div>
                    </div>
                    <div className="d-flex mb-3 justify-content-between">
                      <div>
                        <span className="text-secondary">Vertical</span>
                      </div>
                      <div> {order.vertical_type} </div>
                    </div>
                    <div className="d-flex mb-3 justify-content-between">
                      <div>
                        <span className="text-secondary">Product</span>
                      </div>
                      <div> {order.disco_code} </div>
                    </div>
                    <div className="d-flex mb-3 justify-content-between">
                      <div>
                        <span className="text-secondary">Address</span>
                      </div>
                      <div> {order.address} </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      )}{' '}
      <Row>
        <Col sm="12">
          <Parcels
            {...props}
            order={order}
            dispatch={dispatch}
            reload={reload}
          />
        </Col>
      </Row>
      <Row>
        <Col sm="12">
          <VendRequests
            {...props}
            order={order}
            dispatch={dispatch}
            reload={reload}
          />
        </Col>
      </Row>
    </div>
  );
}
