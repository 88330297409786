import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  Button,
} from 'reactstrap';

import { closeAllModals } from '../actions/modalActions';
import { showError, showInfo } from '../actions/notificationActions';

import { callApi } from '../utils';

export default connect((state) => ({
  open: state.modals.user,
  id: state.modals.data,
}))(
  class UserModal extends Component {
    state = {
      user: {
        name: '',
        phone: '',
        email: '',
        payment_processor: '',
        id: '',
      },
      cards: [],
      loading: false,
    };

    componentDidUpdate(prevProps) {
      if (prevProps.open !== this.props.open && this.props.open) {
        this.setState({
          user: {
            name: '',
            phone: '',
            email: '',
            payment_processor: '',
            id: '',
          },
          cards: [],
        });
        this.loadUser(this.props.id);
      }
    }

    loadUser = () => {
      this.setState({ loading: true });
      callApi('/user?id=' + this.props.id)
        .then(({ user = {}, cards = [] }) => {
          this.setState({ loading: false });
          this.setState({ user, cards });
        })
        .catch((err) => {
          this.setState({ loading: false });
          this.props.dispatch(showError(err));
        });
    };

    setInput = (e) => {
      const { name, value } = e.target;

      this.setState((state) => {
        return { ...state, user: { ...state.user, [name]: value } };
      });
    };

    close = () => {
      this.props.dispatch(closeAllModals());
    };

    updateUser = (e) => {
      e.preventDefault();
      const { name, email, payment_processor, id } = this.state.user;
      this.setState({ loading: true });
      callApi('/user/update', { id, name, email, payment_processor }, 'post')
        .then(() => {
          this.setState({ loading: false });
          this.props.dispatch(showInfo('User profile updated successfully'));
          this.props.dispatch(closeAllModals());
        })
        .catch((err) => {
          this.setState({ loading: false });
          this.props.dispatch(showError(err));
        });
    };

    resetPassword = (id) => {
      if (!window.confirm("Reset user's Password?")) return;
      this.setState({ loading: true });
      callApi('/user/reset?id=' + id)
        .then((res) => {
          this.setState({ loading: false });
          this.props.dispatch(showInfo(res.message));
        })
        .catch((err) => {
          this.setState({ loading: false });
          this.props.dispatch(showError(err));
        });
    };

    render() {
      const { name, phone, email, payment_processor, id } = this.state.user;
      const loading = this.state.loading;
      const open = this.props.open;
      return (
        <div>
          <Modal size="lg" isOpen={open} toggle={this.close}>
            <ModalHeader toggle={this.close}>User Details</ModalHeader>
            <ModalBody>
              <form onSubmit={this.updateUser}>
                <Row>
                  <Col xs={12} md={12}>
                    <FormGroup>
                      <Label>Name</Label>
                      <Input
                        onChange={this.setInput}
                        value={name}
                        name="name"
                        placeholder="User's Name"
                        type="text"
                        required
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label>Email</Label>
                      <Input
                        onChange={this.setInput}
                        value={email}
                        name="email"
                        placeholder="User's Email"
                        type="text"
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label>Payment Processor</Label>
                      <Input
                        name="payment_processor"
                        type="select"
                        onChange={this.setInput}
                        value={payment_processor}
                      >
                        <option value="">CHANGE PROCESSOR</option>
                        <option value="BUYPOWER">BUYPOWER</option>
                        <option value="PAYSTACK">PAYSTACK</option>
                        <option value="RAVE">RAVE</option>
                      </Input>
                    </FormGroup>

                    {/* <Row>
                      {cards.map((card) => (
                        <Col
                          key={card.id}
                          xs={12}
                          md={6}
                          style={{ textAlign: 'center' }}
                        >
                          <UserCard
                            onClick={() => this.deactivateCard(card)}
                            last={card.last_digits}
                            type={card.type}
                            disco={card.disco_code}
                          />
                        </Col>
                      ))}
                    </Row> */}

                    <FormGroup>
                      <Label>Phone</Label>
                      <Input
                        onChange={this.setInput}
                        value={phone}
                        name="phone"
                        placeholder="User's Phone"
                        type="text"
                        disabled
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label>ID</Label>
                      <Input
                        onChange={this.setInput}
                        value={id}
                        name="id"
                        placeholder="BuyPower ID"
                        type="text"
                        disabled
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Button size="lg" color="primary" type="submit">
                    {loading ? 'PLEASE WAIT...' : 'UPDATE USER DETAILS'}
                  </Button>
                </Row>
              </form>

              {/* <Row className="justify-content-center">
                <Button onClick={() => this.resetPassword(id)} color="primary">
                  {loading ? 'PLEASE WAIT...' : 'RESET PASSWORD'}
                </Button>
              </Row> */}
            </ModalBody>
            <ModalFooter />
          </Modal>
        </div>
      );
    }
  }
);
