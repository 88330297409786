import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import { closeAllModals } from '../actions/modalActions';

export default connect((state) => ({
  open: state.modals.previewEntity,
  data: state.modals.data,
  loading: state.modals.loading,
}))(
  class PreviewModal extends Component {
    componentDidUpdate(prevProps) {
      if (prevProps.open !== this.props.open && this.props.open) {
        this.setState({
          fields: this.props.data.fields || [],
          params: {},
        });
      }
    }

    setInput = (e) => {
      const { name, value } = e.target;
      console.log(name, value);
      this.setState({ [name]: value });
    };

    setInnerInput = (e) => {
      const { name, value } = e.target;
      this.setState({ params: { ...this.state.params, [name]: value } });
    };

    close = () => {
      this.props.data.close && this.props.data.close();
      this.props.dispatch(closeAllModals());
    };

    confirm = (params) => {
      this.props.data.confirmCallback(params);
    };

    render() {
      const open = this.props.open;
      return (
        <div>
          <Modal size="lg" isOpen={open} toggle={this.close}>
            <ModalHeader toggle={this.close}>Preview</ModalHeader>
            <ModalBody>{this.props.children}</ModalBody>
          </Modal>
        </div>
      );
    }
  }
);
