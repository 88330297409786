import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Modal, ModalHeader, ModalBody, ModalFooter, Row } from 'reactstrap';
import { closeAllModals } from '../actions/modalActions';
import { callApi } from '../utils';

import JsonViewer from './JsonViewer';
import Loading from './Loading';

export default connect((state) => ({ open: state.modals.account }))(
  class TransactionModal extends Component {
    state = {
      data: {},
      day: 1,
      loading: false,
    };

    close = () => {
      this.props.dispatch(closeAllModals());
    };

    getBalance = (e) => {
      if (this.state.loading) return;

      e && e.preventDefault();
      this.setState({ loading: true });
      callApi('/accounting/balances')
        .then((data) => {
          this.setState({ data, loading: false });
        })
        .catch((err) => {
          this.setState({ data: err, loading: false });
        });
    };

    loadData = (e) => {
      e && e.preventDefault();
      this.setState({ loading: true });
      callApi('/accounting/report?day=' + this.state.day)
        .then((data) => {
          this.setState({ data, loading: false });
        })
        .catch((err) => {
          this.setState({ data: err, loading: false });
        });
    };

    setDay = (e) => {
      this.setState({ day: e.target.value });
    };

    render() {
      const { open } = this.props;
      const { data, day, loading } = this.state;
      return (
        <div>
          <Modal size="lg" isOpen={open} toggle={this.close}>
            <ModalHeader toggle={this.close}>Accounting Details</ModalHeader>
            <ModalBody style={{ padding: '3em' }}>
              <Row>
                <button
                  style={{ margin: '0 auto' }}
                  className="btn btn-default"
                  onClick={this.getBalance}
                >
                  GET BALANCES
                </button>
              </Row>
              <hr />
              <Row>
                <form onSubmit={this.loadData}>
                  <input onChange={this.setDay} value={day} />
                  <button type="submit">SEARCH</button>
                </form>
              </Row>
              {loading ? (
                <Loading />
              ) : (
                <Row>
                  <JsonViewer
                    src={data}
                    name={data.date || 'Accounting Data'}
                    iconStyle={'circle'}
                    displayDataTypes={false}
                    displayObjectSize={false}
                  />
                </Row>
              )}
            </ModalBody>
            <ModalFooter />
          </Modal>
        </div>
      );
    }
  }
);
