import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Nav,
  NavLink,
  NavItem,
  Button,
} from 'reactstrap';
import classnames from 'classnames';

import { callApi } from '../../utils';
import { showError } from '../../actions/notificationActions';

import {
  closeAllModals,
  openFacilityUpdateModal,
} from '../../actions/modalActions';

import { Route, Switch } from 'react-router-dom';
import { Insight } from './Insight';
import FacilityUpdateModal from '../FacilityUpdateModal';

class Detail extends Component {
  state = {
    facility: {},
    loading: true,
    updateLoading: false,
  };

  componentDidMount() {
    this.loadFacilityDetail();
    this.props.dispatch(closeAllModals());
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.loadFacilityDetail();
    }
  }

  startLoad = () => this.setState({ loading: true });
  stopLoad = () => this.setState({ loading: false });

  scrollToTop = () =>
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);

  loadFacilityDetail = (e) => {
    const id = this.props.match.params.id;

    this.startLoad();
    callApi(`/facilities/${id}`)
      .then(({ data = {} }) => {
        this.setState({ facility: data });
        this.scrollToTop();
      })
      .catch((err) => {
        this.props.dispatch(showError(err));
      })
      .finally(() => {
        this.stopLoad();
      });
  };

  update = (data) => {
    this.props.dispatch(openFacilityUpdateModal(data));
  };

  render() {
    const { facility, loading, updateLoading } = this.state;

    const { history, match, location } = this.props;

    return (
      <div className="content">
        <FacilityUpdateModal refresh={this.loadFacilityDetail} />
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardHeader className="ml-2">
                <div className="d-flex justify-content-between align-contents-center">
                  <div>
                    <CardTitle>
                      {(facility && facility.reference) || 'Loading...'}
                    </CardTitle>
                    <p className="category">Single facility</p>
                  </div>
                  <div>
                    <Button
                      disabled={updateLoading}
                      onClick={() => this.update(facility)}
                      color="primary"
                    >
                      Update
                      {updateLoading ? (
                        <span className="fas fa-spinner fa-spin ml-3"></span>
                      ) : (
                        ''
                      )}
                    </Button>
                  </div>
                </div>
              </CardHeader>

              <CardBody>
                <div>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        style={{ color: 'black' }}
                        href="#"
                        className={classnames({
                          active: location.pathname === `${match.url}`,
                        })}
                        onClick={() => {
                          history.push(`${match.url}`);
                        }}
                      >
                        General
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <br />
                  <div>
                    <Switch>
                      <Route
                        exact
                        path={`${match.path}`}
                        render={(props) => (
                          <Insight
                            {...props}
                            facility={facility}
                            loading={loading}
                            reload={this.loadFacilityDetail}
                          />
                        )}
                      ></Route>
                    </Switch>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect()(Detail);
