import axios from 'axios';
const environment = process.env.NODE_ENV || 'production';

export const request = axios.create({
  baseURL: environment === 'production' && '/api',
  timeout: 120000, // 1 Minute
  headers: {
    'Content-Type': 'application/json',
  },
});

export default {
  async getCampaigns({ type, channels = [], page, limit, query }) {
    if (channels && !Array.isArray(channels)) {
      throw new TypeError('channels must be an array');
    }

    const where = JSON.stringify({
      ...(type !== '' && { type }),
      ...(channels.length && { channels }),
      ...(query !== '' && { title: { $like: `%${query}%` } }),
    });

    const data = await request
      .get(`/campaigns`, {
        params: {
          ...(!!where && { where }),
          ...(!!page ? { page } : 1),
          ...(!!limit ? { limit } : 20),
        },
      })
      .then(({ data }) => {
        return data;
      });

    return data;
  },

  async createCampaign(data) {
    const response = await request.post('/campaigns', data).then(({ data }) => {
      return data;
    });
    return response;
  },

  async updateCampaign(campaignId, data) {
    delete data.id;
    delete data.created_at;
    delete data.updated_at;
    delete data.deleted_at;
    delete data.updated_by;
    delete data.created_by;
    const response = await request
      .patch(`/campaigns/${campaignId}`, data)
      .then(({ data }) => {
        return data;
      });
    return response;
  },

  async deleteCampaign(campaignId) {
    const response = await request
      .delete(`/campaigns/${campaignId}`)
      .then(({ data }) => {
        return data;
      });
    return response;
  },

  async getSingleCampaign(campaignId) {
    const response = await request
      .get(`/campaigns/${campaignId}`)
      .then(({ data }) => {
        return data;
      });
    return response;
  },
};
