import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import React, { Component } from 'react';
import { showError, showInfo } from '../actions/notificationActions';

import { callApi } from '../utils';
import campaignService from '../services/campaign.service';
import { closeAllModals } from '../actions/modalActions';
import { connect } from 'react-redux';

export default connect((state) => ({
  open: state.modals.campaign,
  id: state.modals.data,
}))(
  class CampaignModal extends Component {
    state = {
      loading: false,
      error: '',
      campaign: {
        type: '',
        title: '',
        frequency: {
          repetition: 0,
          is_frequent: false,
        },
        repetition: '',
        channels: [],
        end_at: '',
        start_at: '',
        status: '',
        content: {
          url: '',
          user_categories: [],
          size: '',
          image: '',
          message: '',
          discos: [],
          target_page: '',
          action: '',
        },
      },
      //   campaign: null,
      base64Image: null,
      campaignChannels: ['Web', 'Mobile'],
      campaignStatus: ['Published', 'Draft', 'Expired'],
      types: ['Banner', 'Alert', 'Announcement', 'Advert'],
      campaignsFor: ['DIRECT_DEBIT', 'ALL'],
      userCategories: [
        'ALL',
        'POWER_USERS',
        'REGULAR_USERS',
        'OCCASIONAL_USERS',
        'CHURNED_USERS',
        'NEW_CUSTOMERS',
        'CARD_EXPIRED',
        'CARD_ABOUT_TO_EXPIRE',
      ],
      actions: ['OPEN', 'NAVIGATE'],
      discos: [],
      targetPages: ['HOME', 'REFERRAL', 'SETTINGS', 'DIRECT_DEBIT'],
    };

    componentDidUpdate(prevProps) {
      if (prevProps.open !== this.props.open && this.props.open) {
        this.setState({
          loading: false,
          base64Image: null,
          campaignChannels: ['Web', 'Mobile'],
          campaignStatus: ['Published', 'Draft'],
          types: ['Banner', 'Alert', 'Announcement', 'Advert'],
          userCategories: [
            'ALL',
            'POWER_USER',
            'REGULAR_USER',
            'OCCASIONAL_USER',
            'NEW_CUSTOMER',
            'CARD_EXPIRED',
            'CARD_ABOUT_TO_EXPIRE',
            'INTERNAL_TEAM',
            'CHURN_1_YEAR',
            'CHURN_3_MONTHS',
            'CHURN_5_MONTHS',
          ],
          targetPages: [
            'HOME',
            'REFERRAL',
            'SETTINGS',
            'DIRECT_DEBIT',
            'CREATE_ORDER/ELECTRICITY',
            'CREATE_ORDER/VTU',
            'CREATE_ORDER/DATA',
            'CREATE_ORDER/TV',
            'WALLET/ADD_FUNDS',
          ],
          campaign: {
            type: '',
            title: '',
            frequency: {
              repetition: 0,
              is_frequent: false,
            },
            repetition: '',
            channels: [],
            end_at: '',
            start_at: '',
            status: '',
            content: {
              url: '',
              user_categories: [],
              size: '',
              image: '',
              message: '',
              discos: [],
              target_page: '',
            },
          },
        });
        this.loadCampaign(this.props.id);
        this.loadDiscos();
      }
    }

    handleChannelChange = (selectedOptions) => {
      this.setState((state) => {
        return {
          ...state,
          campaign: { ...state.campaign, channels: selectedOptions },
        };
      });
    };

    handleUserCategoriesChange = (selectedOptions) => {
      this.setState((state) => {
        return {
          ...state,
          campaign: {
            ...state.campaign,
            content: {
              ...state.campaign.content,
              user_categories: selectedOptions,
            },
          },
        };
      });
    };

    handleSelectedDiscos = (selectedOptions) => {
      this.setState((state) => {
        return {
          ...state,
          campaign: {
            ...state.campaign,
            content: { ...state.campaign.content, discos: selectedOptions },
          },
        };
      });
    };

    loadCampaign = (id) => {
      this.setState({ loading: true });
      campaignService
        .getSingleCampaign(id)
        .then((res) => {
          this.setState({
            loading: false,
            campaign: res.data,
          });
        })
        .catch((err) => {
          this.stopLoad();
          this.props.dispatch(showError(err.message));
        });
    };

    loadDiscos = () => {
      // use callApi to get discos
      // this.setState({ loading: true });
      callApi('/service-distribution', {}, 'get')
        .then((res) => {
          this.setState({ discos: res.data.map((disco) => disco.name) });
        })
        .catch((err) => {
          console.log(err);
        });
    };

    setInput = (e) => {
      const { name, value } = e.target;
      this.setState((state) => {
        return { ...state, campaign: { ...state.campaign, [name]: value } };
      });
    };

    setContentInput = (e) => {
      const { name, value } = e.target;
      this.setState((state) => {
        return {
          ...state,
          campaign: {
            ...state.campaign,
            content: { ...state.campaign.content, [name]: value },
          },
        };
      });
    };

    setImageInput = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        this.setState((state) => {
          return {
            ...state,
            campaign: {
              ...state.campaign,
              content: { ...state.campaign.content, image: reader.result },
            },
          };
        });
      };
    };

    close = () => {
      this.props.dispatch(closeAllModals());
    };

    updateCampaign = (e) => {
      e.preventDefault();
      this.setState({ loading: true });
      campaignService
        .updateCampaign(this.props.id, this.state.campaign)
        .then(() => {
          this.setState({ loading: false });
          this.props.dispatch(
            showInfo('campaign profile updated successfully')
          );
          // this.props.dispatch()
          this.props.dispatch(closeAllModals());
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch((err) => {
          this.setState({ loading: false });
          this.props.dispatch(showError(err));
        });
    };

    render() {
      const {
        title,
        loading,
        type,
        image,
        content: { url, action, target_page },
        frequency: { is_frequent, repetition },
      } = this.state.campaign;

      const open = this.props.open;
      return (
        <div>
          <Modal size="lg" isOpen={open} toggle={this.close}>
            <ModalHeader toggle={this.close}>Update campaign</ModalHeader>
            <ModalBody>
              <form>
                <div>
                  <FormGroup>
                    <Label className="mt-3">Title:</Label>
                    <Input
                      name="title"
                      type="text"
                      onChange={this.setInput}
                      value={title}
                      disabled={loading}
                    />
                  </FormGroup>

                  <Row>
                    <Col md={12} xs={12}>
                      <FormGroup>
                        <Label for="type">Type</Label>
                        <Input
                          id="type"
                          name="type"
                          type="select"
                          onChange={this.setInput}
                          value={type}
                          disabled={true}
                        >
                          <option value={''}>Please select</option>
                          {this.state.types.map((type, index) => (
                            <option key={index} value={type.toLowerCase()}>
                              {type}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Label for="exampleSelectMulti">Channel</Label>
                  <Input
                    id="exampleSelectMulti"
                    multiple
                    name="channel"
                    type="select"
                    disabled={loading}
                    value={this.state.campaign.channels}
                    onChange={(e) => {
                      const selectedOptions = Array.from(
                        e.target.selectedOptions
                      ).map((option) => option.value);
                      this.handleChannelChange(selectedOptions);
                    }}
                  >
                    {this.state.campaignChannels.map((channel, index) => (
                      <option key={index} value={channel.toLocaleLowerCase()}>
                        {channel}
                      </option>
                    ))}
                  </Input>

                  <Row>
                    <Col md={6} xs={12}>
                      <FormGroup>
                        <Label className="mt-3">Start Date:</Label>
                        <Input
                          name="start_at"
                          type="datetime-local"
                          onChange={this.setInput}
                          value={this.state.campaign.start_at}
                          disabled={loading}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6} xs={12}>
                      <FormGroup>
                        <Label className="mt-3">End Date:</Label>
                        <Input
                          name="end_at"
                          type="datetime-local"
                          onChange={this.setInput}
                          value={this.state.campaign.end_at}
                          disabled={loading}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6} xs={12}>
                      <FormGroup>
                        <Label for="type">Status</Label>
                        <Input
                          id="status"
                          name="status"
                          type="select"
                          onChange={this.setInput}
                          value={this.state.campaign.status}
                        >
                          <option value={''}>Please select</option>
                          {this.state.campaignStatus.map((status, index) => (
                            <option
                              key={index}
                              value={status.toLocaleLowerCase()}
                            >
                              {status}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>

                    <Col md={6} xs={12}>
                      <FormGroup>
                        <Label for="target_page">
                          Notification Target Page
                        </Label>
                        <Input
                          id="target_page"
                          name="target_page"
                          type="select"
                          onChange={this.setContentInput}
                          value={target_page}
                        >
                          <option value={''}>Please select</option>
                          {this.state.targetPages.map((page, index) => (
                            <option
                              key={index}
                              value={page.toLocaleLowerCase()}
                            >
                              {page}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col
                      md={
                        this.state.campaign.content.action === 'OPEN' ? 6 : 12
                      }
                      xs={12}
                    >
                      <Label for={'action'} className="mt-3">
                        Action:
                      </Label>
                      <Input
                        id="status"
                        name="action"
                        type="select"
                        onChange={this.setContentInput}
                        value={action}
                      >
                        <option value={''}>Please select</option>
                        {this.state.actions.map((action, index) => (
                          <option key={index} value={action}>
                            {action}
                          </option>
                        ))}
                      </Input>
                    </Col>

                    {this.state.campaign.content.action === 'OPEN' && (
                      <Col md={6} xs={12}>
                        <Label className="mt-3">Url:</Label>
                        <Input
                          name="url"
                          type="text"
                          onChange={this.setContentInput}
                          value={url}
                          disabled={loading}
                        />
                      </Col>
                    )}
                  </Row>

                  <Row>
                    <Col md={6} xs={12}>
                      <Label for="user_categories">User Category</Label>
                      <Input
                        id="user_categories"
                        multiple
                        name="user_categories"
                        type="select"
                        disabled={loading}
                        value={this.state.campaign.content.user_categories}
                        onChange={(e) => {
                          const selectedOptions = Array.from(
                            e.target.selectedOptions
                          ).map((option) => option.value);
                          this.handleUserCategoriesChange(selectedOptions);
                        }}
                      >
                        {this.state.userCategories.map((category, index) => (
                          <option key={index} value={category.toLowerCase()}>
                            {category}
                          </option>
                        ))}
                      </Input>
                    </Col>

                    <Col md={6} xs={12}>
                      <Label for="discoCategory" className="mt-2">
                        Disco Category
                      </Label>
                      <Input
                        id="discoCategory"
                        multiple
                        name="selectedDiscos"
                        type="select"
                        disabled={loading}
                        value={this.state.campaign.content.discos}
                        onChange={(e) => {
                          const selectedOptions = Array.from(
                            e.target.selectedOptions
                          ).map((option) => option.value);
                          this.handleSelectedDiscos(selectedOptions);
                        }}
                      >
                        {this.state.discos.map((disco, index) => (
                          <option key={index} value={disco.toLowerCase()}>
                            {disco}
                          </option>
                        ))}
                      </Input>
                    </Col>
                  </Row>

                  {this.state.campaign.type !== '' &&
                    this.state.campaign.type === 'banner' && (
                      <span>
                        <Label className="mt-3">Size:</Label>
                        <Input
                          name="size"
                          type="text"
                          onChange={this.setContentInput}
                          value={this.state.campaign.content.size}
                          disabled={loading}
                        />
                      </span>
                    )}

                  {this.state.campaign.type !== '' &&
                    this.state.campaign.type !== 'announcement' && (
                      <span>
                        <Label for="exampleFile">Image</Label>
                        <Input
                          id="exampleFile"
                          type="file"
                          name="image"
                          onChange={this.setImageInput}
                          value={image}
                          disabled={loading}
                        />
                      </span>
                    )}

                  {this.state.campaign.type !== '' &&
                    this.state.campaign.type === 'announcement' && (
                      <span>
                        <Label className="mt-3">Message:</Label>
                        <Input
                          name="message"
                          type="text"
                          onChange={this.setContentInput}
                          value={this.state.campaign.content.message}
                          disabled={loading}
                        />
                      </span>
                    )}
                  <div
                    style={{
                      textAlign: 'left',
                      margin: '2% 0% 0% 3%',
                      //   width: '50%',
                    }}
                  >
                    <FormGroup>
                      <Input
                        type="checkbox"
                        id="checkbox2"
                        onChange={this.showMultipleTimes}
                        value={is_frequent}
                        disabled={loading}
                        name="is_frequent"
                      />{' '}
                      <Label check for="checkbox2">
                        Show Multiple Times
                      </Label>
                    </FormGroup>
                  </div>

                  {this.state.is_frequent && (
                    <span>
                      <Label className="mt-3">Repetition:</Label>
                      <Input
                        name="repetition"
                        type="number"
                        onChange={this.setInput}
                        value={repetition}
                        disabled={loading}
                      />
                    </span>
                  )}
                </div>
                <div />
              </form>
              {this.state.error ? (
                <span style={{ color: 'red' }}>{this.state.error}</span>
              ) : (
                ''
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                color="success"
                disabled={this.state.loading}
                onClick={this.updateCampaign}
              >
                Update Campaign{' '}
                {loading ? (
                  <span className="fas fa-spinner fa-spin"></span>
                ) : (
                  ''
                )}
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      );
    }
  }
);
