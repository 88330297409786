import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  Button,
  // CardTitle,
  CardSubtitle,
} from 'reactstrap';

import { closeAllModals } from '../actions/modalActions';
import { showError, showInfo } from '../actions/notificationActions';

import { callApi } from '../utils';

export default connect((state) => ({
  open: state.modals.refund,
  data: state.modals.data,
}))(
  class RefundWalletModal extends Component {
    state = {
      settlement: {
        check: true,
        name: '',
        amount: '',
      },
      loading: false,
    };

    componentDidUpdate(prevProps) {
      if (prevProps.open !== this.props.open && this.props.open) {
        this.setState({
          settlement: {
            check: true,
            name: this.props.data.name,
            amount: this.props.data.amount,
          },
          loading: false,
        });
        // this.loadLogs(this.props.data.id);
      }
    }

    setInput = (e) => {
      const { name, value } = e.target;

      if (name === 'check') {
        this.setState((state) => {
          return {
            ...state,
            settlement: {
              ...state.settlement,
              check: !this.state.settlement.check,
            },
          };
        });
      } else {
        this.setState((state) => {
          return {
            ...state,
            settlement: { ...state.settlement, [name]: value },
          };
        });
      }
    };

    // setPage = (page) => {
    //   if (page) {
    //     this.setState({ logQueries: {...this.state.logQueries, page} }, () => {
    //       this.loadLogs();
    //     });
    //   }
    // };

    close = () => {
      this.props.dispatch(closeAllModals());
    };

    approveSettlement = () => {
      const s = this.props.data;
      const { check } = this.state.settlement;
      this.setState({ loading: true });
      callApi(`/agentsettlements/approve`, { id: s._id, check }, 'post')
        .then(({ message }) => {
          this.setState({ loading: false });
          this.props.dispatch(showInfo(message));
          s.refresh();
        })
        .catch(({ message }) => {
          this.setState({ loading: false });
          this.props.dispatch(showError(message));
        });
    };

    // loadLogs = () => {
    //   const { from, to, operation, amount, page } = this.state.logQueries;
    //   const id = this.props.data.id;
    //   this.setState({ loading: true });
    //   callApi(
    //     `/agentsupervisors/timeline?id=${id}&from=${from}&to=${to}&operation=${operation}&amount=${amount}&page=${page}`
    //   )
    //     .then(({ data = [], pages }) => {
    //       this.setState({ loading: false });
    //       this.setState({ logs: data, pages });
    //     })
    //     .catch((err) => {
    //       this.setState({ loading: false });
    //       this.props.dispatch(showError(err));
    //     });
    // };

    render() {
      const { check, name, amount } = this.state.settlement;
      // const { name, amount } = this.props.data;
      const loading = this.state.loading;
      const open = this.props.open;
      return (
        <div>
          <Modal size="lg" isOpen={open} toggle={this.close}>
            <ModalHeader toggle={this.close}>Refund Wallet?</ModalHeader>
            <ModalBody>
              <form onSubmit={this.loadLogs}>
                <Row>
                  <Col xs={12} md={12}>
                    <Label>Name</Label>
                    <CardSubtitle>{name}</CardSubtitle>
                    <h1> </h1>
                    <Label>Amount</Label>
                    <CardSubtitle>{amount}</CardSubtitle>
                    <h1> </h1>

                    <FormGroup>
                      <Label check>
                        <Input
                          onChange={this.setInput}
                          checked={check}
                          name="check"
                          type="checkbox"
                        />{' '}
                        {'Refund Wallet'}
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Button
                    size="lg"
                    color="primary"
                    disabled={loading}
                    onClick={this.approveSettlement}
                  >
                    {loading ? 'PLEASE WAIT...' : 'Approve'}
                  </Button>
                </Row>
              </form>
            </ModalBody>
            <ModalFooter />
          </Modal>
        </div>
      );
    }
  }
);
