import React from 'react';

import { Table } from 'reactstrap';

import { formatAmount, formatDateTime } from '../../utils';

function RequestTable(props) {
  const {
    rows,
    page,
    loading,
    limit,
    onRowClick = () => {},
    exclude = [],
  } = props;
  return (
    <Table responsive hover striped>
      <thead className="text-primary">
        <tr>
          <th>No.</th>
          <th>Date</th>
          <th>Reference</th>
          <th>Meter No</th>
          {!exclude.includes('gateway') && <th>Gateway</th>}
          <th>Amount</th>
          <th>Order ID</th>
          <th>Type</th>
          <th>Agent</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {rows.map((row, rowIndex) => (
          <tr onClick={() => onRowClick(row)} key={row.id}>
            <td>{loading ? 'x' : rowIndex + 1 + (page - 1) * limit}</td>
            <td>{formatDateTime(row.created_at)}</td>
            <td>{row.reference}</td>
            <td>{row.customer_ref}</td>
            {!exclude.includes('gateway') && <td>{row.gateway}</td>}
            <td>{formatAmount(row.amount)}</td>
            <td>{row.order.order_id}</td>
            <td>{row.plan}</td>
            <td>{row.agent.name}</td>
            <td>{row.status}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default RequestTable;
