import React from 'react';
import { Row, Col } from 'reactstrap';

import Loading from '../../Loading';
import Client from '../Client';

class Clients extends React.Component {
  render() {
    const { loading, match } = this.props;

    const { id } = match.params;

    return (
      <div>
        <p>
          Clients let the merchant connect their apps to this dashboard. They
          can make use of our API to get functionalities similar to this
          dashboard. They are limited to only two Clients. One for Testing and
          the other for Production.
        </p>
        <Row>
          <Col sm="6">
            {loading ? (
              <Loading />
            ) : (
              <Client environment="production" id={id} />
            )}
          </Col>
          <Col sm="6">
            {loading ? <Loading /> : <Client environment="test" id={id} />}
          </Col>
        </Row>
      </div>
    );
  }
}

export default Clients;
