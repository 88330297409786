import React from 'react';
import { connect } from 'react-redux';

import { Row, Col, Table } from 'reactstrap';

import { callApi, formatDate } from '../../utils';
import { showError } from '../../actions/notificationActions';
import Pagination from '../Pagination';

import Loading from '../Loading';

class PromoUsers extends React.Component {
  state = {
    queryParams: {
      from: '',
      to: '',
      disco: '',
      page: 1,
      limit: '12',
      subAccount: '',
    },
    pages: 1,
    meta: { pages: 1 },
    loading: false,
    rows: [],
  };

  setInput = (e) => {
    const { name, value } = e.target;
    this.setState({
      queryParams: { ...this.state.queryParams, [name]: value },
    });
    // setQueryParams({[name]: value})
  };

  setPage = (page) => {
    if (page) {
      this.setState(
        { queryParams: { ...this.state.queryParams, page } },
        () => {
          this.getSettlements();
        }
      );
    }
  };

  startLoad = () => this.setState({ loading: true });
  stopLoad = () => this.setState({ loading: false });

  getSettlements = (e) => {
    const id = this.props.id;

    this.startLoad();
    callApi(`/promotions/${id}/users`, { ...this.state.queryParams })
      .then(({ data = {}, meta = {} }) => {
        this.setState({ rows: data, meta });
      })
      .catch((err) => {
        this.props.dispatch(showError(err));
      })
      .finally(() => {
        this.stopLoad();
      });
  };

  componentWillMount() {
    this.getSettlements();
  }

  render() {
    const { rows, queryParams, loading, meta } = this.state;

    return (
      <Row>
        <Col sm="12">
          <div className="mt-10">
            {/* <form
              onSubmit={(e) => {
                e.preventDefault();
                this.getSettlements();
              }}
              className="mb-20 d-flex justify-content-between"
            >
              <FormGroup>
                <h6>From</h6>
                <Input
                  name="from"
                  placeholder="From"
                  required
                  type="date"
                  onChange={this.setInput}
                  value={queryParams.from}
                  style={{ maxWidth: '500px' }}
                />
              </FormGroup>
              <FormGroup>
                <h6>To</h6>
                <Input
                  name="to"
                  placeholder="To"
                  required
                  type="date"
                  onChange={this.setInput}
                  value={queryParams.to}
                  style={{ maxWidth: '500px' }}
                />
              </FormGroup>
              <FormGroup>
                <h6>Disco</h6>
                <Input
                  name="disco"
                  required
                  type="select"
                  onChange={this.setInput}
                  value={queryParams.disco}
                  style={{ maxWidth: '500px' }}
                >
                  <option value="" />
                  {DISCOS.map((d) => (
                    <option key={d.id} value={d.name}>
                      {d.name}
                    </option>
                  ))}
                </Input>
              </FormGroup>

              <div style={{ textAlign: 'center', maxWidth: '500px' }}>
                <Button color="primary">
                  {loading ? 'PLEASE WAIT...' : 'Fetch'}
                </Button>
              </div>
            </form> */}

            {loading ? (
              <Loading />
            ) : (
              <Table responsive hover striped>
                <thead className="text-primary">
                  <tr>
                    {/* <th>Date</th> */}
                    {/* <th>Domain</th> */}
                    <th>Name</th>
                    <th>Redeemed</th>
                    <th>Date Redeemed</th>
                    <th>Usages</th>
                    <th>Promo Code</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>
                  {rows.map((t, i) => (
                    <tr key={t.id}>
                      {/* <td>{formatDate(t.redeemed_at)}</td> */}
                      <td>{t.user.name}</td>
                      <td>{t.redeemed ? 'YES' : 'NO'}</td>
                      <td>{formatDate(t.redeemed_at)}</td>
                      <td>{t.usages}</td>
                      <td>{t.promotion.code}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}

            <Pagination
              total={meta.pages || 1}
              page={queryParams.page}
              onChange={this.setPage}
            />
          </div>
          {/* </Card> */}
        </Col>
      </Row>
    );
  }
}

export default connect()(PromoUsers);
