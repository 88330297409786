import React from 'react';
import { Route, Switch } from 'react-router-dom';

import List from './Services/List';
import Detail from './Services/Detail';

function Services() {
  return (
    <Switch>
      <Route exact path="/services" component={List} />
      <Route path="/services/:id" component={Detail} />
    </Switch>
  );
}

export default Services;
