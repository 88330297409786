import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  Button,
} from 'reactstrap';

import { closeAllModals, openTransactionModal } from '../actions/modalActions';
import {
  DISCOS,
  PAYMENT_MODES,
  BANKS,
  callApi,
  numToWords,
  GATEWAYS,
  SERVICE_PROVIDERS,
  TV_GATEWAYS,
  DATA_GATEWAYS,
  VERTICAL_TYPES,
  AIRTIME_GATEWAYS,
  NETWORKS,
  VEND_TYPES,
} from '../utils';
import { showError } from '../actions/notificationActions';

export default connect(({ modals }) => ({
  open: modals.generate,
  data: modals.data || {},
}))(
  class GenerateModal extends Component {
    state = {
      ...this.getStateFromProps(),
      force: false,
    };

    getStateFromProps() {
      const {
        phone,
        amount,
        disco = 'ABUJA',
        meter,
        vendType,
        bank,
        mode,
        id,
        link,
        gateway,
        type = 'ELECTRICITY',
        api_method = 'post',
        done,
        failed,
        btnText,
      } = this.props.data;
      return {
        phone,
        amount,
        disco,
        gateway,
        meter,
        vendType,
        mode,
        bank,
        id: id,
        link: link,
        loading: false,
        type,
        api_method,
        done,
        failed,
        btnText,
      };
    }

    setInput = (e) => {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    };

    setForce = () => {
      this.setState({ force: !this.state.force });
    };

    componentDidUpdate(prevProps) {
      if (prevProps.open !== this.props.open) {
        this.setState(this.getStateFromProps());
      }
    }

    close = () => {
      this.props.dispatch(closeAllModals());
    };

    submitRequest = (e) => {
      e.preventDefault();
      const {
        phone,
        meter,
        vendType,
        amount,
        disco,
        bank,
        mode,
        link,
        id,
        loading,
        gateway,
        type,
        force,
        api_method,
        done,
        failed,
      } = this.state;
      if (loading) return;

      if (mode === 'BANK_TRANSFER' && !bank) {
        return this.props.dispatch(
          showError('You must select a Bank for Bank Payment Type')
        );
      }

      const prompt = `
Are you sure you want to ${
        type === VERTICAL_TYPES.ELECTRICITY ? 'generate a Token' : 'regenerate'
      } for: 

${
  type === VERTICAL_TYPES.ELECTRICITY
    ? 'Meter No.'
    : type === VERTICAL_TYPES.TV
    ? 'IUC Number'
    : 'Recepient'
}: ${meter}
Gateway: ${gateway || 'System Gateway'}
Phone: ${phone}
Amount: ₦ ${amount}

${numToWords(amount).toUpperCase()} NAIRA
    `;

      if (!window.confirm(prompt)) return; // Prompt Agent to confirm Token Generation

      this.setState({ loading: true });

      // Custom API link can be passed to component instead of default vend API link (eg. MCASH)
      callApi(
        link || '/token/vend',
        {
          phone,
          meter,
          vendType,
          amount,
          disco,
          bank,
          mode,
          id,
          force,
          gateway,
          done,
        },
        api_method || 'post'
      )
        .then((res) => {
          const { message = '', data = {} } = res;
          this.setState({ loading: false });
          if (done) {
            done({ message, id: data.id });
          } else {
            this.props.dispatch(
              openTransactionModal({
                id: res.id || data.order_id,
                vertical: this.props.data.type,
              })
            );
          }
        })
        .catch((err) => {
          this.setState({ loading: false });
          this.props.dispatch(showError(err));
          if (failed) {
            failed();
          } else {
            if (id) {
              this.props.dispatch(
                openTransactionModal({ id, vertical: this.props.data.type })
              );
            }
          }
        });
    };

    render() {
      const {
        phone,
        meter,
        vendType,
        amount,
        disco,
        bank,
        mode,
        loading,
        gateway,
        type,
        btnText,
      } = this.state;
      const gateways =
        type === VERTICAL_TYPES.TV
          ? TV_GATEWAYS
          : type === VERTICAL_TYPES.DATA
          ? DATA_GATEWAYS
          : type === VERTICAL_TYPES.AIRTIME
          ? AIRTIME_GATEWAYS
          : GATEWAYS[disco] || [];
      return (
        <Modal
          size="lg"
          isOpen={this.props.open}
          toggle={this.close}
          backdrop="static"
        >
          <ModalHeader toggle={this.close}>
            {type !== VERTICAL_TYPES.ELECTRICITY
              ? 'Regenerate'
              : 'Generate Token'}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col xs={12} md={6}>
                <FormGroup>
                  <Label>Phone</Label>
                  <Input
                    onChange={this.setInput}
                    value={phone}
                    name="phone"
                    placeholder="User's Phone Number"
                    type="text"
                    required
                  />
                </FormGroup>

                <FormGroup>
                  <Label>
                    {type === VERTICAL_TYPES.TV
                      ? 'IUC Number'
                      : type === VERTICAL_TYPES.DATA
                      ? 'Recipient Number'
                      : 'Meter No.'}
                  </Label>
                  <Input
                    onChange={this.setInput}
                    value={meter}
                    name="meter"
                    placeholder="Enter Meter Number"
                    type="text"
                    required
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Account Type</Label>
                  <Input
                    onChange={this.setInput}
                    value={vendType}
                    name="vendType"
                    type="select"
                    required
                  >
                    <option value="">Please Select</option>
                    {VEND_TYPES.map((s) => (
                      <option key={s.value} value={s.value}>
                        {s.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>

                <FormGroup>
                  <Label>Amount</Label>
                  <Input
                    onChange={this.setInput}
                    value={amount}
                    name="amount"
                    placeholder="Amount (Less Service Charge)"
                    type="text"
                    required
                  />
                </FormGroup>
              </Col>

              <Col xs={12} md={6}>
                <FormGroup>
                  <Label>
                    {type === VERTICAL_TYPES.TV
                      ? 'Service Provider'
                      : type === VERTICAL_TYPES.ELECTRICITY
                      ? 'DisCo'
                      : 'Network'}
                  </Label>
                  <Input
                    onChange={this.setInput}
                    value={disco}
                    name="disco"
                    type="select"
                    required
                  >
                    <option disabled={true} value="">{`Select a ${
                      type !== VERTICAL_TYPES.ELECTRICITY
                        ? 'Service Provider'
                        : 'DisCo'
                    }`}</option>
                    {(type === VERTICAL_TYPES.TV
                      ? SERVICE_PROVIDERS
                      : type === VERTICAL_TYPES.ELECTRICITY
                      ? DISCOS
                      : NETWORKS
                    ).map((s) => (
                      <option key={s.code} value={s.name}>
                        {s.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>

                <FormGroup>
                  <Label>Payment Type</Label>
                  <Input
                    onChange={this.setInput}
                    value={mode}
                    name="mode"
                    type="select"
                    required
                  >
                    <option value="">Please Select</option>
                    {PAYMENT_MODES.map((s) => (
                      <option key={s.value} value={s.value}>
                        {s.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>

                <FormGroup>
                  <Label>Bank</Label>
                  <Input
                    onChange={this.setInput}
                    value={bank}
                    name="bank"
                    type="select"
                  >
                    <option value="">Select Bank (Optional)</option>
                    {BANKS.map((s) => (
                      <option key={s.value} value={s.value}>
                        {s.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>

                <FormGroup>
                  <Label>Gateway</Label>
                  <Input
                    onChange={this.setInput}
                    value={gateway}
                    name="gateway"
                    type="select"
                  >
                    <option value="">Use System Gateway</option>
                    {gateways.map((s) => (
                      <option key={s.value} value={s.value}>
                        {s.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>

                <FormGroup style={{ paddingLeft: '20px' }}>
                  <Input
                    onChange={this.setForce}
                    checked={this.state.force}
                    name="force"
                    type="checkbox"
                  ></Input>
                  <Label>Force</Label>
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              type="submit"
              onClick={this.submitRequest}
              disabled={loading}
            >
              {btnText ? btnText : loading ? 'Generating...' : 'Generate'}
            </Button>
          </ModalFooter>
        </Modal>
      );
    }
  }
);
