import React from 'react';
import { Route, Switch } from 'react-router-dom';

import List from './Users/List';
import Detail from './Users/Detail';

function Users() {
  return (
    <Switch>
      <Route exact path="/users" component={List} />
      <Route path="/users/:id" component={Detail} />
    </Switch>
  );
}

export default Users;
