import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Table,
  Button,
} from 'reactstrap';

import { callApi } from '../../utils';
import { showError } from '../../actions/notificationActions';
import { openCreateWatchlistModal } from '../../actions/modalActions';
import CreateWatchlistModal from '../CreateWatchlistModal';

import Loading from '.././Loading';
import Pagination from '.././Pagination';
import { formatDate } from '../../utils';

class Watchlist extends Component {
  state = {
    rows: [],
    query: '',
    page: 1,
    limit: 30,
    loading: false,
    pages: 1,
    currentWatchlistID: {},
  };

  componentWillMount() {
    this.load();
  }

  startLoad = () => this.setState({ loading: true });
  stopLoad = () => this.setState({ loading: false });

  scrollToTop = () =>
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);

  load = (e) => {
    // Event may be passed in from search form
    if (e) e.preventDefault();

    // const { query, page, limit } = this.state;

    this.startLoad();

    this.getWatchlists();

    // callApi(`/watchlists?q=${query}&page=${page}&limit=${limit}`)
    //   .then(({ data = [], meta = {} }) => {
    //     this.setState({ loading: false, rows: data, pages: meta.pages });
    //     this.scrollToTop();
    //   })
    //   .catch((err) => {
    //     this.stopLoad();
    //     this.props.dispatch(showError(err));
    //   });
  };

  getWatchlists = () => {
    callApi(`/watchlists`)
      .then(({ data = [], meta = {} }) => {
        this.setState({ loading: false, rows: data, pages: meta.pages });
        this.scrollToTop();
      })
      .catch((err) => {
        this.stopLoad();
        this.props.dispatch(showError(err));
      });
  };

  setPage = (page) => {
    if (page) {
      this.setState({ page }, () => {
        this.load();
      });
    }
  };

  setQuery = (e) => {
    this.setState({ query: e.target.value });
  };

  view = (id) => {
    this.props.history.push(`/watchlists/${id}`);
  };

  refresh = () => {
    window.location.reload();
  };

  deleteWatchlist = async (id) => {
    const confirmed = window.confirm('Click "Ok" to delete this watchlist');

    if (confirmed) {
      this.setState({ currentWatchlistID: id });
      callApi(`/watchlists/${id}`, null, 'delete')
        .then((res) => {
          this.scrollToTop();
          this.getWatchlists();
        })
        .catch((err) => {
          this.stopLoad();
          this.props.dispatch(showError(err));
        });
    }
  };

  render() {
    const {
      currentWatchlistID,
      rows,
      page,
      loading,
      limit,
      pages,
    } = this.state;
    return (
      <div className="content">
        <CreateWatchlistModal refresh={this.getWatchlists} />
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardHeader className="ml-2">
                <div className="d-flex justify-content-between align-contents-center">
                  <CardTitle>Watchlists</CardTitle>
                  <p className="category">Watchlists - Page {page}</p>
                  <div>
                    <Button
                      color={'primary'}
                      onClick={() => {
                        this.props.dispatch(openCreateWatchlistModal());
                      }}
                    >
                      Create Watchlist
                    </Button>
                  </div>
                </div>
              </CardHeader>

              <CardBody>
                <Table responsive hover striped>
                  <thead className="text-primary">
                    <tr>
                      <th>No.</th>
                      <th>Name</th>
                      <th>Description</th>
                      <th>Created At</th>
                      <th>Actions</th>
                    </tr>
                  </thead>

                  <tbody>
                    {rows.map((t, i) => {
                      let isWatchlist = rows[i].id === t.id;
                      let isActive = currentWatchlistID === t.id;

                      let isLoading = isWatchlist && isActive ? true : false;
                      return (
                        <tr onClick={() => this.view(t.id)} key={t.id}>
                          <td>{loading ? 'x' : i + 1 + (page - 1) * limit}</td>
                          <td>{t.name}</td>
                          <td>{t.description}</td>
                          <td>{formatDate(t.created_at)}</td>

                          <td
                            onClick={(e) => {
                              if (e) {
                                e.stopPropagation();
                              }
                            }}
                          >
                            <Button
                              size="sm"
                              color="danger"
                              onClick={() => {
                                this.deleteWatchlist(t.id);
                              }}
                              disabled={isLoading}
                            >
                              {isLoading ? (
                                <div>
                                  Deleting...
                                  <span className="fas fa-spinner fa-spin ml-2"></span>
                                </div>
                              ) : (
                                'Delete'
                              )}
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <Pagination total={pages} page={page} onChange={this.setPage} />
                {loading && <Loading />}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect()(Watchlist);
