import React from 'react';
import { Route, Switch } from 'react-router-dom';

import List from './Supervisors/List';
import Detail from './Supervisors/Detail';

function Supervisors() {
  return (
    <Switch>
      <Route exact path="/supervisors" component={List} />
      <Route path="/supervisors/:id" component={Detail} />
    </Switch>
  );
}

export default Supervisors;
