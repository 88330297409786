import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Button,
} from 'reactstrap';

import { callApi } from '../utils';

import Loading from './Loading';
import SearchForm from './SearchForm';
import JsonViewer from './JsonViewer';
import OptionButtons from './OptionButtons';

import { openGenerateModal } from '../actions/modalActions';
import { showError } from '../actions/notificationActions';

class CheckMeter extends Component {
  state = {
    loading: false,
    query: '',
    disco: 'ABUJA',
    reset: false,
    details: null,
  };

  searchMeter = (e) => {
    if (e) e.preventDefault();
    const { query, disco, reset, loading } = this.state;
    if (!query || !disco || loading) return;

    this.setState({ loading: true });

    const vertical = this.verticals[disco];

    callApi(`/check/meter`, {
      meter: query,
      disco,
      vertical,
      reset: reset ? 1 : null,
    })
      .then((details) => {
        this.setState({ loading: false, details });
      })
      .catch((err) => {
        this.props.dispatch(showError(err.message));
        this.setState({ loading: false, details: null });
      });
  };

  setQuery = (e) => {
    this.setState({ query: e.target.value });
  };

  setDisco = (disco) => {
    this.setState({ disco }, () => {
      if (this.state.query) this.searchMeter();
    });
  };

  toggleReset = () => {
    this.setState(({ reset }) => ({ reset: !reset }));
  };

  options = [
    'ABUJA',
    'KADUNA',
    'JOS',
    'PH',
    'ENUGU',
    'EKO',
    'IKEJA',
    'IBADAN',
    'KANO',
    'APLE',
    'BH',
    'BENIN',
    'YOLA',
    'KOIOS',
    'KAIDA',
    'ACCESSPOWER',
    'STARTIMES',
    'DSTV',
    'GOTV',
    'MTN',
    'AIRTEL',
    '9MOBILE',
    'GLO',
  ];

  verticals = {
    ABUJA: 'ELECTRICITY',
    KADUNA: 'ELECTRICITY',
    JOS: 'ELECTRICITY',
    PH: 'ELECTRICITY',
    ENUGU: 'ELECTRICITY',
    EKO: 'ELECTRICITY',
    IKEJA: 'ELECTRICITY',
    IBADAN: 'ELECTRICITY',
    KANO: 'ELECTRICITY',
    BH: 'ELECTRICITY',
    PHISBOND: 'ELECTRICITY',
    PROTOGY: 'ELECTRICITY',
    ACCESSPOWER: 'ELECTRICITY',
    APLE: 'ELECTRICITY',
    BENIN: 'ELECTRICITY',
    YOLA: 'ELECTRICITY',
    KOIOS: 'ELECTRICITY',
    KAIDA: 'ELECTRICITY',
    STARTIMES: 'TV',
    DSTV: 'TV',
    GOTV: 'TV',
    MTN: 'VTU',
    AIRTEL: 'VTU',
    '9MOBILE': 'VTU',
    GLO: 'VTU',
  };

  render() {
    const { query, loading, disco, reset, details } = this.state;

    const data = {
      meter: details ? details.meterNo : null,
      disco,
      mode: 'CASH',
      link: '/token/vend',
    };

    return (
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardHeader className="ml-2">
                <CardTitle>Check Meter</CardTitle>
              </CardHeader>

              <SearchForm
                value={query}
                onChange={this.setQuery}
                onSearch={this.searchMeter}
                placeholder="Search Meter Number"
                // loading={loading}
              />

              <OptionButtons
                options={this.options}
                selected={disco}
                onSelect={this.setDisco}
              />

              <div className="p-2">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={reset}
                    onChange={this.toggleReset}
                  />
                  <span className="slider round" />
                </label>
              </div>

              <CardBody style={{ minHeight: '60vh' }}>
                {loading ? (
                  <Loading />
                ) : (
                  <div className="row">
                    {details ? (
                      <div className="col-md-8">
                        <JsonViewer
                          src={details}
                          name={'Meter Details'}
                          iconStyle={'circle'}
                          displayDataTypes={false}
                          displayObjectSize={false}
                        />
                      </div>
                    ) : (
                      ''
                    )}
                    {details ? (
                      <div className="col-md-4">
                        <Button
                          onClick={() =>
                            this.props.dispatch(openGenerateModal(data))
                          }
                          size="lg"
                          color="primary"
                        >
                          Generate Token
                        </Button>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect()(CheckMeter);
