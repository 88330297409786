import React, { Component } from 'react';
import { connect } from 'react-redux';
import { callApi } from '../utils';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
  Button,
} from 'reactstrap';

import { closeAllModals } from '../actions/modalActions';
import { showError, showInfo } from '../actions/notificationActions';

export default connect((state) => ({
  open: state.modals.supportMsg,
  data: state.modals.data,
}))(
  class SupportMessageModal extends Component {
    state = {
      loading: false,
      msg: this.props.data,
      error: '',
    };

    componentDidUpdate(prevProps) {
      this.updateState(prevProps);
    }

    setInput = (e) => {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    };

    close = () => {
      this.props.dispatch(closeAllModals());
    };

    updateState = (prevProps) => {
      if (prevProps.open !== this.props.open && this.props.open) {
        this.setState({
          msg: this.props.data,
        });
      }
    };

    submit = (e) => {
      e.preventDefault();

      if (!this.state.msg)
        return this.setState({ error: 'Please fill in all fields!' });

      this.setState({ error: '' });

      this.sendInstruction(this.state.msg);
    };

    sendInstruction = (data) => {
      const customer_id = this.props.customer_id;

      this.setState({
        loading: true,
      });

      callApi(`/customers/${customer_id}/instructions`, { data }, 'post')
        .then(({ data }) => {
          this.props.dispatch(closeAllModals());
          this.props.dispatch(showInfo(data));
          this.setState({
            loading: false,
          });
        })
        .catch((err) => {
          this.props.dispatch(showError(err));
          this.props.dispatch(closeAllModals());
          this.setState({
            loading: false,
          });
        });
    };

    render() {
      const { msg, loading } = this.state;

      const open = this.props.open;

      return (
        <div>
          <Modal size="lg" isOpen={open} toggle={this.close}>
            <ModalHeader toggle={this.close}>Send Support Message</ModalHeader>
            <ModalBody>
              <form>
                <div>
                  <FormGroup>
                    <Label className="mt-3">Message:</Label>
                    <Input
                      name="msg"
                      type="textarea"
                      onChange={this.setInput}
                      value={msg}
                      disabled={loading}
                    />
                  </FormGroup>
                </div>
                <div />
              </form>
              {this.state.error ? (
                <span style={{ color: 'red' }}>{this.state.error}</span>
              ) : (
                ''
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="primary" disabled={loading} onClick={this.close}>
                No
                {loading ? (
                  <span className="fas fa-spinner fa-spin"></span>
                ) : (
                  ''
                )}
              </Button>
              <Button color="success" disabled={loading} onClick={this.submit}>
                Send
                {loading ? (
                  <span className="fas fa-spinner fa-spin"></span>
                ) : (
                  ''
                )}
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      );
    }
  }
);
