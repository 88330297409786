import React from 'react';
import { connect } from 'react-redux';

import { Row, Col, Table, Button } from 'reactstrap';
import Pagination from '../../Pagination';

import { callApi, formatAmount, formatDate } from '../../../utils';
import { showError } from '../../../actions/notificationActions';

import Loading from '../../Loading';
import SearchForm from '../../SearchForm';
import {
  openBalanceUpdateModal,
  openTopupWalletModal,
} from '../../../actions/modalActions';

class Wallet extends React.Component {
  state = {
    count: 0,
    wallet: {},
    loading: true,
    transactions: [],
    page: 1,
    q: '',
    limit: 30,
    meta: {},
  };

  componentWillMount() {
    this.loadView();
  }

  startLoad = () => this.setState({ loading: true });
  stopLoad = () => this.setState({ loading: false });

  scrollToTop = () =>
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);

  loadView = (e) => {
    e && e.preventDefault();

    const { match } = this.props;
    const { id } = match.params;

    const { page, limit, q } = this.state;

    this.startLoad();

    callApi(`/channels/${id}/wallet/transactions`, {
      page,
      limit,
      q,
    })
      .then(({ data = [], meta }) => {
        this.setState({ transactions: data, count: meta.total, meta });
        this.scrollToTop();
      })
      .catch((err) => {
        this.props.dispatch(showError(err));
      })
      .finally(() => {
        this.stopLoad();
      });

    callApi(`/merchant-wallet/${id}`)
      .then(({ data = {} }) => {
        this.setState({ wallet: data });
        this.scrollToTop();
      })
      .catch((err) => {
        this.props.dispatch(showError(err));
      })
      .finally(() => {
        this.stopLoad();
      });
  };

  setQuery = (e) => {
    this.setState({ q: e.target.value });
  };

  setPage = (page) => {
    let { count, limit } = this.state;

    const canLoad = count % ((page - 1) * limit) < count;

    if (canLoad) {
      this.startLoad();
      this.setState({ page }, () => {
        this.loadView();
      });
    }
  };

  tableInfo() {
    const { loading, page, count = 0, limit } = this.state;

    let start = 0;
    let end = 0;

    if (!loading) {
      start = page * limit - limit + 1;
      end = Math.min(start + limit - 1, count);
    }

    return `Showing ${start} to ${end} of ${count}`;
  }

  onBalanceUpdate = (e, id) => {
    this.props.dispatch(
      openBalanceUpdateModal({ id, onSuccess: this.updateWallet })
    );
  };

  onTopupWallet = (e, id) => {
    this.props.dispatch(
      openTopupWalletModal({ id, onSuccess: this.updateWallet })
    );
  };

  updateWallet = (balance) => {
    this.setState({ wallet: { ...this.state.wallet, balance } });
  };

  render() {
    const { channel } = this.props;
    const { loading, transactions, page, q, meta, wallet } = this.state;
    return (
      <div>
        <Row>
          <Col sm="12">
            <div className="d-flex justify-content-between mb-3">
              <div className="d-flex flex-nowrap align-items-center">
                <div className="mr-3">
                  <div>Balance</div>
                  <h5 className="m-0">{formatAmount(wallet.balance)}</h5>
                </div>
                <div className="mr-3">
                  <div>Commission</div>
                  <h5 className="m-0">{formatAmount(wallet.commission)}</h5>
                </div>
                <div>
                  <div>Type</div>
                  <h5 className="m-0">{wallet.type}</h5>
                </div>
              </div>
              <div className="d-flex flex-nowrap align-items-center">
                <div className="mr-2">
                  <Button
                    className="my-0"
                    onClick={(e) => this.onTopupWallet(e, channel.id)}
                  >
                    Topup Wallet
                  </Button>
                </div>
                <div className="mr-2">
                  <Button
                    className="my-0"
                    onClick={(e) => this.onBalanceUpdate(e, channel.id)}
                  >
                    Update Wallet
                  </Button>
                </div>
                <div>
                  <SearchForm
                    value={q}
                    size="sm"
                    onChange={this.setQuery}
                    onSearch={this.loadView}
                    loading={loading}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            {loading ? (
              <Loading />
            ) : (
              <div>
                <Row>
                  <Col sm="12">
                    <Table responsive hover striped>
                      <tbody>
                        {transactions.map((t, i) => (
                          <tr key={t.id}>
                            <td>{formatDate(t.created_at)}</td>
                            <td>{t.ref}</td>
                            <td>{t.type}</td>
                            <td>{t.operation}</td>
                            <td>{formatAmount(t.amount)}</td>
                            <td>{formatAmount(t.balance_before)}</td>
                            <td>{formatAmount(t.balance_after)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <Pagination
                      total={meta.pages}
                      page={page}
                      onChange={this.setPage}
                    />
                    {loading && <Loading />}
                  </Col>
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect()(Wallet);
