import React from 'react';

import { Row, Col, Card, CardHeader, CardTitle, CardBody } from 'reactstrap';

function InfoTab({ watchlist, totalRecords }) {
  return (
    <Row>
      <Col sm="6">
        <Card className="card-chart">
          <CardHeader>
            <CardTitle className="mt-0">Watchlist</CardTitle>
          </CardHeader>
          <CardBody>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Watchlist ID</span>
              </div>
              <div>{watchlist.id}</div>
            </div>

            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Description</span>
              </div>
              <div> {watchlist.description}</div>
            </div>

            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Total references</span>
              </div>
              <div>{totalRecords}</div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default InfoTab;
