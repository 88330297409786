import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
  Button,
} from 'reactstrap';

import { closeAllModals } from '../actions/modalActions';

import { callApi } from '../utils';

export default connect((state) => ({
  open: state.modals.addToWatchlist,
  data: state.modals.data,
}))(
  class WatchlistModal extends Component {
    state = {
      allWatchlist: [],
      loading: false,
    };

    startLoad = () => this.setState({ loading: true });
    stopLoad = () => this.setState({ loading: false });

    setInput = (e) => {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    };

    close = () => {
      this.props.dispatch(closeAllModals());
    };

    addToWatchlist = () => {
      this.startLoad();
      callApi(
        `/watchlists/${this.props.watchlist_id}/users`,
        { reference: this.state.reference },
        'post'
      )
        .then(() => {
          this.stopLoad();
          this.close();
          this.props.refresh();
        })
        .catch((err) => {});
    };

    render() {
      const { loading } = this.state;
      const open = this.props.open;

      return (
        <Modal size="lg" isOpen={open} toggle={this.close}>
          <ModalHeader toggle={this.close}>Add User To Watchlist</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label className="mt-3">Enter Reference:</Label>
              <Input
                name="reference"
                type="text"
                onChange={this.setInput}
                disabled={loading}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              disabled={loading}
              onClick={this.addToWatchlist}
            >
              {loading ? 'Updating' : 'Update'}
            </Button>
          </ModalFooter>
        </Modal>
      );
    }
  }
);
