import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { closeAllModals } from '../actions/modalActions';
import { callApi } from '../utils';
import JsonViewer from './JsonViewer';
import Loading from './Loading';

export default connect((state) => ({
  open: state.modals.tokenResponse,
  data: state.modals.data,
}))(
  class TokenResponseModal extends Component {
    state = {
      loading: false,
      open: false,
      response: null,
    };

    loadResponse = () => {
      this.setState({ loading: true });
      const { data = {} } = this.props;
      if (data) {
        callApi('/disco/response?id=' + data.id)
          .then((response) => {
            this.setState({ loading: false, response });
          })
          .catch((err) => {
            this.setState({
              loading: false,
              response:
                typeof err === 'object' ? err : { message: err.message },
            });
          });
      }
    };

    componentDidMount() {
      this.loadResponse();
    }

    componentDidUpdate(prevProps) {
      if (prevProps.open !== this.props.open && this.props.open) {
        this.setState({
          title: this.props.data.title,
        });
        if (this.props.data) {
          this.loadResponse();
        }
      }
    }

    close = () => {
      this.props.dispatch(closeAllModals());
    };

    render() {
      const { loading, response } = this.state;

      const open = this.props.open;
      return (
        <div>
          <Modal size="lg" isOpen={open} toggle={this.close}>
            <ModalHeader toggle={this.close}>Logs</ModalHeader>
            <ModalBody>
              {loading ? (
                <Loading />
              ) : (
                <JsonViewer
                  src={response}
                  name={'Token Response'}
                  iconStyle={'circle'}
                  displayDataTypes={false}
                  displayObjectSize={false}
                />
              )}
            </ModalBody>
            <ModalFooter></ModalFooter>
          </Modal>
        </div>
      );
    }
  }
);
