import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  Button,
} from 'reactstrap';

import { closeAllModals } from '../actions/modalActions';
import { showError, showInfo } from '../actions/notificationActions';

import { callApi } from '../utils';

export default connect((state) => ({
  open: state.modals.walletTopup,
  data: state.modals.data,
}))(
  class WalletTopupModal extends Component {
    state = {
      data: {
        reference: '',
        amount: '',
      },
      loading: false,
    };

    componentDidUpdate(prevProps) {
      if (prevProps.open !== this.props.open && this.props.open) {
        this.setState({
          data: {
            reference: '',
            amount: '',
          },
        });
      }
    }

    setInput = (e) => {
      const { name, value } = e.target;
      this.setState((state) => {
        return { ...state, data: { ...state.data, [name]: value } };
      });
    };

    close = () => {
      this.props.dispatch(closeAllModals());
    };

    walletTopup = () => {
      const { id, onSuccess } = this.props.data;
      const { reference, amount } = this.state.data;

      this.setState({ loading: true });

      callApi(`/channels/${id}/wallet-topup`, { reference, amount }, 'post')
        .then((res) => {
          this.props.dispatch(showInfo(res.message));
          this.close();
          onSuccess(res.data.balance);
        })
        .catch((err) => {
          this.props.dispatch(showError(err));
        })
        .finally((err) => {
          this.setState({ loading: false });
        });
    };

    render() {
      const { reference, amount } = this.state.data;
      const loading = this.state.loading;
      const open = this.props.open;
      return (
        <form onSubmit={this.updateUser}>
          <Modal size="lg" isOpen={open} toggle={this.close}>
            <ModalHeader toggle={this.close}>Topup Wallet Balance</ModalHeader>
            <ModalBody>
              <Row>
                <Col xs={12} md={12}>
                  <FormGroup>
                    <Label>Transaction Narration</Label>
                    <Input
                      onChange={this.setInput}
                      value={reference}
                      name="`refe`rence"
                      placeholder="Reference"
                      type="text"
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Amount</Label>
                    <Input
                      onChange={this.setInput}
                      value={amount}
                      name="amount"
                      placeholder="0.00"
                      type="text"
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                disabled={loading}
                onClick={this.walletTopup}
              >
                Update
              </Button>
            </ModalFooter>
          </Modal>
        </form>
      );
    }
  }
);
