import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import {
  Row,
  Col,
  Card,
  CardTitle,
  CardBody,
  Table,
  CardFooter,
  Input,
} from 'reactstrap';

import { callApi, formatAmount, formatDateTime } from '../../utils';
import { showError } from '../../actions/notificationActions';

import Loading from '.././Loading';
import Pagination from '.././Pagination';
import SearchForm from '.././SearchForm';
import { openTransactionModal } from '../../actions/modalActions';

class List extends Component {
  state = {
    rows: [],
    query: '',
    page: 1,
    limit: 30,
    loading: false,
    pages: 1,
    pin: false,
    meta: {
      pages: 1,
      checkpoint: null,
    },
  };

  componentDidMount() {
    this.load();
  }

  startLoad = () => this.setState({ loading: true });
  stopLoad = () => this.setState({ loading: false });

  scrollToTop = () =>
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);

  load = () => {
    const { query, page, meta, pin } = this.state;

    this.startLoad();

    callApi(`/orders`, {
      include: ['agent', 'merchant'],
      query,
      page,
      checkpoint: pin && meta && meta.checkpoint ? meta.checkpoint : null,
    })
      .then(({ data = [], meta = {} }) => {
        this.setState({ loading: false, rows: data, pages: meta.pages, meta });
        this.scrollToTop();
      })
      .catch((err) => {
        this.stopLoad();
        this.props.dispatch(showError(err));
      });
  };

  setPage = (page) => {
    if (page) {
      this.setState({ page }, () => {
        this.load();
      });
    }
  };

  setQuery = (e) => {
    this.setState({ query: e.target.value });
  };

  view = (id) => {
    // this.props.history.push(`/orders/${id}`);
    window.open(`/orders/${id}`);
  };

  togglePin = () => {
    const pin = this.state.pin;
    this.setState(
      {
        pin: !pin,
        page: 1,
      },
      () => {
        if (!this.state.pin) {
          this.load();
        }
      }
    );
  };

  openTransaction = (t) => {
    this.props.dispatch(openTransactionModal(t));
  };

  render() {
    const { query, rows, page, loading, limit, meta, pin } = this.state;
    const pinClass = pin
      ? 'btn btn-primary active'
      : 'btn btn-secondary active';

    return (
      <div className="container-fluid">
        <Row className="mt-4">
          <Col xs={12} md={12}>
            <Row>
              <Col>
                <div className="ml-2">
                  <CardTitle>Orders</CardTitle>
                  <p className="category">Orders - Page {page}</p>
                </div>
              </Col>
              <Col></Col>
            </Row>
            <Card>
              <CardBody>
                <SearchForm
                  value={query}
                  onChange={this.setQuery}
                  onSearch={(e) => {
                    e.preventDefault();
                    this.load();
                  }}
                  loading={loading}
                />
                <div className="btn-group-toggle" data-toggle="buttons">
                  <label className={pinClass}>
                    <Input
                      type="checkbox"
                      value={pin}
                      autoComplete="off"
                      onChange={(e) => this.togglePin(e.target.value)}
                    />
                    {formatDateTime(meta.checkpoint)}
                  </label>
                </div>
                <Table responsive hover striped>
                  <thead className="text-primary">
                    <tr>
                      <th>No.</th>
                      <th>Order ID</th>
                      <th>Meter No</th>
                      <th>Customer</th>
                      <th>Amount</th>
                      <th>Disco</th>
                      <th>Channel</th>
                      <th>Business</th>
                      <th>Status</th>
                      <th>Date</th>
                    </tr>
                  </thead>

                  <tbody>
                    {rows.map((t, i) => (
                      <tr onClick={() => this.openTransaction(t)} key={t.id}>
                        <td>{loading ? 'x' : i + 1 + (page - 1) * limit}</td>
                        <td>{t.order_id}</td>
                        <td>{t.meter_no}</td>
                        <td>{t.name}</td>
                        <td className="text-right">{formatAmount(t.amount)}</td>
                        <td>{t.disco_code}</td>
                        <td>{t.channel}</td>
                        <td>{t.merchant && t.merchant.name}</td>
                        <td>{t.order_status}</td>
                        <td>{moment(t.created_at).calendar()}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
              <CardFooter>
                <Pagination
                  total={meta.pages}
                  page={page}
                  onChange={this.setPage}
                />
                {loading && <Loading />}
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect()(List);
