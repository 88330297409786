import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import classnames from 'classnames';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Nav,
  NavItem,
  Row,
  NavLink,
} from 'reactstrap';
import List from './List';
import Loading from '../Loading';
import MerchantCommission from './MerchantCommission';
import Detail from './Detail';

class Merchants extends Component {
  state = {
    loading: false,
  };

  render() {
    const { loading } = this.state;
    const { match, location, history } = this.props;
    return (
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardHeader className="ml-2">
                <div className="d-flex justify-content-between align-contents-center">
                  <div>{loading && <CardTitle>Loading...</CardTitle>}</div>
                </div>
              </CardHeader>

              <CardBody>
                {!loading ? (
                  <div>
                    <Nav pills={true}>
                      <NavItem>
                        <NavLink
                          href="#"
                          className={classnames({
                            active: location.pathname === `${match.url}`,
                          })}
                          onClick={() => {
                            history.push(`${match.url}`);
                          }}
                        >
                          All Merchants
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          href="#"
                          className={classnames({
                            active:
                              location.pathname ===
                              `${match.url}/default-commissions`,
                          })}
                          onClick={() => {
                            history.push(`${match.url}/default-commissions`);
                          }}
                        >
                          Commissions
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <br />
                    <div style={{ marginTop: '25px' }}>
                      <Switch>
                        {/* <Route
                          exact
                          path={`${match.path}/list`}
                          render={(props) => <List />}
                        ></Route> */}
                        <Route
                          exact
                          path={`${match.path}/default-commissions`}
                          render={(props) => (
                            <MerchantCommission type="default" {...props} />
                          )}
                        ></Route>
                        <Route
                          exact
                          path={match.path}
                          render={(props) => <List {...props} />}
                        ></Route>
                        <Route
                          exact
                          path="/merchants/:id"
                          render={(props) => <Detail {...props} />}
                        />
                      </Switch>
                    </div>
                  </div>
                ) : (
                  <Loading />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect()(Merchants);
