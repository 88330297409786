import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Table,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap';

import { callApi, formatDate, formatAmount, _toDash } from '../../utils';
import { showError } from '../../actions/notificationActions';

import Loading from '.././Loading';
import Pagination from '.././Pagination';
import SearchForm from '.././SearchForm';

class List extends Component {
  state = {
    records: [],
    query: '',
    page: 1,
    limit: 15,
    loading: false,
    meta: { pages: 1 },
    statusDropDownOpen: false,
    selectedStatus: null,
  };

  componentWillMount() {
    this.loadTransactions();
  }

  startLoad = () => this.setState({ loading: true });
  stopLoad = () => this.setState({ loading: false });

  scrollToTop = () =>
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);

  loadTransactions = (e) => {
    // Event may be passed in from search form
    if (e) e.preventDefault();

    const { query, page, limit, selectedStatus = null } = this.state;
    this.startLoad();

    let data = { page, limit };

    if (selectedStatus) {
      data = { ...data, status: selectedStatus };
    }

    if (query) {
      data = { ...data, q: query };
    }
    callApi(`/refunds`, data)
      .then(({ data = [], meta }) => {
        this.setState({ loading: false, records: data, meta });
        this.scrollToTop();
      })
      .catch((err) => {
        this.stopLoad();
        this.props.dispatch(showError(err));
      });
  };

  setPage = (page) => {
    if (page) {
      this.setState({ page }, () => {
        this.loadTransactions();
      });
    }
  };

  setQuery = (e) => {
    this.setState({ query: e.target.value });
  };

  openTransaction = (id) => {
    this.props.history.push(`/refunds/${id}`);
  };

  setSelectedStatus = (status) => {
    this.setState(
      {
        selectedStatus: status,
      },
      () => {
        this.loadTransactions();
      }
    );
  };

  render() {
    const { query, records, page, loading, limit, meta } = this.state;
    return (
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardHeader className="ml-2">
                <CardTitle>Refunds</CardTitle>
                <p className="category">Refunds - Page {page}</p>
              </CardHeader>
              <div className="d-flex">
                <div className="mr-3">
                  <SearchForm
                    value={query}
                    onChange={this.setQuery}
                    onSearch={this.loadTransactions}
                    loading={loading}
                  />
                </div>
                <Dropdown
                  isOpen={this.state.statusDropDownOpen}
                  toggle={
                    loading
                      ? null
                      : (e) => {
                          this.setState({
                            statusDropDownOpen: !this.state.statusDropDownOpen,
                          });
                        }
                  }
                  disabled={loading}
                >
                  <DropdownToggle caret>
                    {this.state.selectedStatus
                      ? this.state.selectedStatus
                      : 'ANY'}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      tag="a"
                      active={this.state.selectedStatus === null ? true : false}
                      onClick={() => this.setSelectedStatus(null)}
                    >
                      ANY
                    </DropdownItem>
                    <DropdownItem
                      tag="a"
                      active={
                        this.state.selectedStatus === 'PROCESSED' ? true : false
                      }
                      onClick={() => this.setSelectedStatus('PROCESSED')}
                    >
                      PROCESSED
                    </DropdownItem>
                    <DropdownItem
                      tag="a"
                      active={
                        this.state.selectedStatus === 'PENDING' ? true : false
                      }
                      onClick={() => this.setSelectedStatus('PENDING')}
                    >
                      PENDING
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
              <CardBody>
                <Table responsive hover striped>
                  <thead className="text-primary">
                    <tr>
                      <th>No.</th>
                      <th>Date</th>
                      <th>Customer</th>
                      <th>Method</th>
                      <th>Order Reference</th>
                      <th>Payment Reference</th>
                      <th>Processor</th>
                      {/* <th>Name</th>
                      <th>Phone</th> */}
                      <th>Amount</th>
                      <th>Status</th>
                    </tr>
                  </thead>

                  <tbody>
                    {records.map((t, i) => (
                      <tr onClick={() => this.openTransaction(t.id)} key={t.id}>
                        <td>{loading ? 'x' : i + 1 + (page - 1) * limit}</td>
                        <td>{formatDate(t.created_at)}</td>
                        <td>{t.customer.name}</td>
                        <td>{t.method}</td>
                        <td>{t.order.order_id}</td>
                        <td>{t.payment.reference}</td>
                        <td>{t.payment.processor}</td>
                        {/* <td>{t.paymentTransaction.name}</td> */}
                        {/* <td>{t.paymentTransaction.phone}</td> */}
                        <td>{formatAmount(t.amount)}</td>
                        <td>{_toDash(t.status)}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Pagination
                  page={page}
                  total={meta.pages}
                  onChange={this.setPage}
                />
                {loading && <Loading />}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect()(List);
