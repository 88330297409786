import React from 'react';
import { Row, Col, Card, CardHeader, CardTitle, CardBody } from 'reactstrap';
import { formatAmount } from '../../utils';
import ClickToCopy from '../ClickToCopy';

export function GeneralTab({ merchant }) {
  return (
    <Row>
      <Col sm="6">
        <Card className="card-chart">
          <CardHeader>
            <CardTitle className="mt-0">Order</CardTitle>
          </CardHeader>
          <CardBody>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Merchant ID</span>
              </div>
              <div> {merchant.id} </div>
            </div>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Name</span>
              </div>
              <div> {merchant.name} </div>
            </div>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Phone</span>
              </div>
              <span>
                <span
                  className="cursor-pointer blue-text"
                  onClick={() => this.opentUser(merchant.phone)}
                >
                  <strong>{merchant.phone}</strong>{' '}
                </span>
                <ClickToCopy value={merchant.phone} />
              </span>
            </div>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">DisCo</span>
              </div>
              <div> {merchant.disco_id} </div>
            </div>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Email</span>
              </div>
              <div> {merchant.email} </div>
            </div>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Wallet</span>
              </div>
              {<div> {formatAmount(merchant.wallet)} </div>}
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}
