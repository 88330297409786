import React, { Component } from 'react';
import { connect } from 'react-redux';
import ConfirmActionModal from '../ConfirmActionModal';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Nav,
  NavLink,
  NavItem,
} from 'reactstrap';
import classnames from 'classnames';

import { callApi } from '../../utils';
import { showError } from '../../actions/notificationActions';

import ClickToCopy from '../ClickToCopy';

import { Route, Switch } from 'react-router-dom';

class Detail extends Component {
  state = {
    entity: {},
    meta: {},

    loading: true,
    activeTab: '1',
    toggle: null,
    rows: [],
    page: 1,
    pages: 1,
    limit: 10,
  };

  componentWillMount() {
    this.loadView();
  }

  startLoad = () => this.setState({ loading: true });
  stopLoad = () => this.setState({ loading: false });

  scrollToTop = () =>
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);

  loadView = (e) => {
    const id = this.props.match.params.id;

    this.startLoad();
    callApi(`/virtual-accounts/${id}`)
      .then(({ data = {}, meta = {} }) => {
        this.setState({ entity: data });
        this.scrollToTop();
      })
      .catch((err) => {
        this.props.dispatch(showError(err));
      })
      .finally(() => {
        this.stopLoad();
      });
  };

  setPage = (page) => {
    if (page) {
      this.setState({ page }, () => {
        this.loadView();
      });
    }
  };

  setInput = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    let { entity } = this.state;

    const { history, match, location } = this.props;

    return (
      <div className="content">
        <ConfirmActionModal />
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardHeader className="ml-2">
                <div className="d-flex justify-content-between align-contents-center">
                  <div>
                    <CardTitle>{entity.acc_name || 'Loading...'}</CardTitle>
                    <p className="category">Virtual Account</p>
                  </div>
                </div>
              </CardHeader>

              <CardBody>
                <div>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        style={{ color: 'black' }}
                        href="#"
                        className={classnames({
                          active: location.pathname === `${match.url}`,
                        })}
                        onClick={() => {
                          history.push(`${match.url}`);
                        }}
                      >
                        General
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <br />
                  <div>
                    <Switch>
                      <Route exact path={`${match.path}`}>
                        <GeneralTab entity={entity} />
                      </Route>
                    </Switch>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

function GeneralTab({ entity }) {
  return (
    <Row>
      <Col sm="6">
        <Card className="card-chart">
          <CardHeader>
            <CardTitle className="mt-0">Virtual Account</CardTitle>
          </CardHeader>
          <CardBody>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Account Number</span>
              </div>
              <span>
                {' '}
                <span className="cursor-pointer blue-text">
                  <strong>{entity.acc_num}</strong>
                </span>{' '}
                <ClickToCopy value={entity.acc_num} />{' '}
              </span>
            </div>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Account Name</span>
              </div>
              <div> {entity.acc_name} </div>
            </div>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Phone</span>
              </div>
              <span>
                <span className="cursor-pointer blue-text" onClick={() => {}}>
                  <strong>{entity.phone}</strong>{' '}
                </span>
                <ClickToCopy value={entity.phone} />
              </span>
            </div>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Meter</span>
              </div>
              <div> {entity.meter} </div>
            </div>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">DisCo</span>
              </div>
              <div> {entity.disco} </div>
            </div>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Bank</span>
              </div>
              <div> {entity.bank} </div>
            </div>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Email</span>
              </div>
              <div> {entity.email} </div>
            </div>
            <div className="d-flex mb-3 justify-content-between">
              <div>
                <span className="text-secondary">Provider</span>
              </div>
              <div> {entity.provider} </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default connect()(Detail);
