import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';
import React, { Component } from 'react';
import { showError, showInfo } from '../actions/notificationActions';

import Loading from './Loading';
import { callApi } from '../utils';
import { closeAllModals } from '../actions/modalActions';
import { connect } from 'react-redux';

export default connect((state) => ({
  open: state.modals.sendNotification,
  sendNotificationData: state.modals.data,
}))(
  class SendPushNotification extends Component {
    state = {
      title: '',
      message: '',
      loading: false,
      selectedUsers: [],
      recipient:
        this.props.sendNotificationData &&
        this.props.sendNotificationData.recipient,
      is_scheduled: false,
      scheduled_date: '',
      target_page: '',
      targetPages: [
        'HOME',
        'REFERRAL',
        'SETTINGS',
        'DIRECT_DEBIT',
        'CREATE_ORDER/ELECTRICITY',
        'CREATE_ORDER/VTU',
        'CREATE_ORDER/DATA',
        'CREATE_ORDER/TV',
        'WALLET/ADD_FUNDS',
      ],
    };

    setInput = (e) => {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    };

    showMultipleTimes = (e) => {
      const { checked } = e.target;
      this.setState({ is_scheduled: checked });
    };

    submitRequest = (e) => {
      e.preventDefault();
      const recipient = this.props.sendNotificationData.recipient;
      const selectedUsers = this.props.sendNotificationData.selectedUsers;
      const {
        title,
        target_page,
        message,
        loading,
        is_scheduled,
        scheduled_date,
      } = this.state;

      if (loading) return;

      const prompt = `
        You are about to send a push notification to ${
          recipient === 'SELECTED'
            ? `${selectedUsers.length} user(s)`
            : 'all users'
        }.
    `;

      if (!window.confirm(prompt)) return; // Prompt Agent to confirm wallet crediting

      this.setState({ loading: true });

      callApi(
        '/send-notifications',
        {
          title,
          message,
          send_to: this.props.sendNotificationData.recipient,
          ...(recipient === 'SELECTED' && {
            selected_users: this.props.sendNotificationData.selectedUsers,
          }),
          ...(this.props.sendNotificationData.userCategory !== '' && {
            user_category: this.props.sendNotificationData.userCategory,
          }),
          is_scheduled,
          ...(is_scheduled && { scheduled_date }),
          target_page,
          ...(this.props.sendNotificationData.disco && {
            disco: this.props.sendNotificationData.disco,
          }),
        },
        'post'
      )
        .then((data) => {
          this.setState({
            loading: false,
            title: '',
            message: '',
            selectedUsers: [],
            is_scheduled: false,
            scheduled_date: '',
            target_page: '',
          });
          this.props.dispatch(showInfo(JSON.stringify(data.message)));
          this.close();
          setTimeout(() => {
            this.refresh();
          }, 2000);
        })
        .catch((err) => {
          this.setState({
            loading: false,
            title: '',
            message: '',
            selectedUsers: [],
            is_scheduled: false,
            scheduled_date: '',
            target_page: '',
          });
          this.props.dispatch(showError(err.message));
        });
    };

    close = () => {
      this.props.dispatch(closeAllModals());
    };

    refresh = () => {
      window.location.reload();
    };

    showMultipleTimes = (e) => {
      const { checked } = e.target;
      this.setState({ is_scheduled: checked });
    };

    render() {
      const { title, message, loading, target_page } = this.state;
      const { open } = this.props;

      return (
        <div>
          <Modal size="lg" isOpen={open} toggle={this.close} backdrop="static">
            <ModalHeader toggle={this.close}>
              Send Push Notification
            </ModalHeader>
            <ModalBody>
              <form onSubmit={this.submitRequest}>
                <FormGroup>
                  <Label>Title</Label>
                  <Input
                    onChange={this.setInput}
                    value={title}
                    name="title"
                    placeholder="Title of the notification"
                    type="text"
                    required
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="targetPage">Target Page:</Label>
                  <Input
                    id="targetPage"
                    name="target_page"
                    type="select"
                    value={target_page}
                    onChange={this.setInput}
                  >
                    <option value={''}>Target Page On The Mobile App</option>
                    {this.state.targetPages.map((targetPage, index) => (
                      <option key={index} value={targetPage.toLowerCase()}>
                        {targetPage.toUpperCase()}
                      </option>
                    ))}
                  </Input>
                </FormGroup>

                <FormGroup>
                  <Label for="message">Message</Label>
                  <Input
                    onChange={this.setInput}
                    id="message"
                    value={message}
                    name="message"
                    type="textarea"
                  />
                </FormGroup>

                <div
                  style={{
                    textAlign: 'left',
                    margin: '2% 0% 0% 3%',
                    //   width: '50%',
                  }}
                >
                  <FormGroup>
                    <Input
                      type="checkbox"
                      id="checkbox2"
                      onChange={this.showMultipleTimes}
                      value={this.state.is_scheduled}
                      disabled={loading}
                      name="is_scheduled"
                    />{' '}
                    <Label check for="checkbox2">
                      Schedule Notification
                    </Label>
                  </FormGroup>
                </div>

                {this.state.is_scheduled === true && (
                  <FormGroup>
                    <Label className="mt-3">Schedule Date:</Label>
                    <Input
                      name="scheduled_date"
                      type="datetime-local"
                      onChange={this.setInput}
                      value={this.state.scheduled_date}
                      disabled={loading}
                    />
                  </FormGroup>
                )}
                <hr />

                {loading && (
                  <Row className="justify-content-center">
                    <Loading />
                  </Row>
                )}

                <Row className="justify-content-center">
                  <Button size="lg" color="primary" type="submit">
                    {loading ? 'PLEASE WAIT...' : 'CONFIRM'}
                  </Button>
                </Row>
              </form>
            </ModalBody>
          </Modal>
        </div>
      );
    }
  }
);
