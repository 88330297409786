import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Table,
} from 'reactstrap';
import { GATEWAYS, callApi, formatAmount, formatDate } from '../../utils';
import React, { useEffect, useState } from 'react';
import {
  closeAllModals,
  confirmAction,
  previewEntity,
  showTokenResponseModal,
  showVendResponse,
  toggleModalLoading,
} from '../../actions/modalActions';
import { showError, showInfo } from '../../actions/notificationActions';

import Loading from '../Loading';
import Pagination from '../Pagination';
import PreviewEntityModal from '../PreviewEntityModal';
import TokenResponseModal from '../TokenResponseModal';
import VendResponseModal from '../VendResponseModal';
import { connect } from 'react-redux';

function VendRequests(props) {
  const { order, dispatch, reload: reloadOrder } = props;
  let [loading, setLoading] = useState(false);

  const [queryParams, setQueryParams] = useState({ limit: 10, page: 1 });
  const [response, setResponse] = useState({ data: [], meta: { pages: 1 } });
  const [currentVend, setCurrentVend] = useState({});
  const [requerying, setRequerying] = useState(false);
  const [reversing, setReversing] = useState(false);

  const fetchRequests = async () => {
    const { location } = props;
    const { state = {} } = location;
    setLoading(true);
    return callApi(`/orders/${order.id}/requests`, queryParams)
      .then((res) => {
        // setTransactions(res.data);
        setLoading(false);
        console.log(res);
        // setMeta(res.meta);
        const { data = [], meta = {} } = res;
        setResponse({ data, meta });
      })
      .catch((err) => {
        dispatch(showError(err.message));
        setLoading(false);
      })
      .finally(() => {
        if (state.queryCanceledOrder) {
          confirmNewRequest();
        }
      });
  };

  useEffect(() => {
    if (order.id) {
      setLoading(true);
      fetchRequests().finally(() => {
        setLoading(false);
      });
    }
  }, [queryParams.page, order.id]);

  const openTransaction = (vend) => {
    setCurrentVend(vend);
  };

  const requery = async (id) => {
    try {
      setRequerying(true);
      const res = await callApi(`/requests/${id}/response`);
      setRequerying(false);
      // fetchRequests();
      dispatch(showVendResponse({ vendResponse: res.data }));
      fetchRequests();
    } catch (error) {
      setRequerying(false);
      dispatch(showError(error));
      fetchRequests();
    }
  };

  const reverseVendRequest = async (id) => {
    try {
      setReversing(true);
      const res = await callApi(`/requests/${id}`, {}, 'delete');
      setReversing(false);
      dispatch(showInfo(res.message));
      fetchRequests();
    } catch (error) {
      setReversing(false);
      dispatch(showError(error));
      fetchRequests();
    }
  };

  const makeNewRequest = async (params) => {
    try {
      setLoading(true);
      dispatch(toggleModalLoading(true));
      const res = await callApi(`/orders/${order.id}/requests`, params, 'post');
      dispatch(showInfo(res.message));
      setResponse({ ...response, data: [...response.data, res.data] });

      await requery(res.data.id);
    } catch (error) {
      dispatch(showError(error.message));
    } finally {
      setLoading(false);
      if (reloadOrder) {
        reloadOrder();
      }
      dispatch(toggleModalLoading(false));
    }
  };

  const confirmNewRequest = async () => {
    const gateways = GATEWAYS[order.disco_code] || [];
    dispatch(
      confirmAction({
        confirmCallback: (params) => makeNewRequest(params),
        cancel: () => dispatch(closeAllModals()),
        confirmText: '',
        confirmBtnText: 'Submit',
        cancelBtnText: 'Cancel',
        title: 'New Request',
        fields: [
          {
            label: 'Please Select a Gateway',
            name: 'gateway',
            type: 'select',
            required: true,
            error: 'Please select a gateway',
            options: [
              {
                key: '',
                value: '',
                label: 'Use Default Gateway',
              },
              ...gateways.map((g) => ({
                key: g.value,
                value: g.value,
                label: g.name,
              })),
            ],
          },

          {
            label: 'Force this request?',
            name: 'force',
            type: 'checkbox',
          },
        ],
      })
    );
  };

  const showTokenResponse = (ref) => {
    dispatch(showTokenResponseModal({ id: ref }));
  };

  const { data, meta } = response;
  const { page, limit } = queryParams;

  // const dispatch = useDispatch();

  useEffect(() => {
    if (currentVend.order_id) {
      dispatch(
        previewEntity({
          entity: currentVend,
          btnText: 'Open Vend Request',
          close: () => {
            setCurrentVend({});
          },
          view: () => {
            dispatch(closeAllModals());
            props.history.push(`/vend-requests/${currentVend.id}`);
          },
        })
      );
    }
  }, [currentVend.order_id]);

  const setPage = (page = 1) => {
    setQueryParams({ ...queryParams, page });
  };

  return (
    <Card className="card-chart">
      <CardHeader>
        <Row>
          <Col xs={12} md={6}>
            <CardTitle tag="h5">Vend Requests</CardTitle>
          </Col>
          <Col xs={12} md={6} className="text-md-right text-xs-center">
            <Button
              color="primary"
              disabled={loading}
              onClick={() => confirmNewRequest(order.id)}
            >
              Make New Request
            </Button>
            <Button disabled={loading} onClick={fetchRequests}>
              Refresh
            </Button>
          </Col>
        </Row>
      </CardHeader>
      <PreviewEntityModal>
        <VendDisplay request={currentVend} />
      </PreviewEntityModal>
      <VendResponseModal />
      <TokenResponseModal />
      <CardBody>
        {loading ? (
          <Loading />
        ) : (
          <Table responsive hover striped>
            <thead className="text-primary">
              <tr>
                <th>No.</th>
                <th>Reference</th>
                <th>Gateway</th>
                <th>Service</th>
                <th>Status</th>
                <th>Amount</th>
                <th>Response</th>
                <th>Agent</th>
                {/* <th>Meter No.</th> */}
                {/* <th>Order ID</th> */}
                <th>Date</th>
                <th>Actions</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {data.length ? (
                data.map((t, i) => (
                  <tr onClick={() => openTransaction(t)} key={t.id}>
                    <td>{loading ? 'x' : i + 1 + (page - 1) * limit}</td>
                    <td>{t.reference}</td>
                    <td>{t.gateway}</td>
                    <td>{t.service}</td>
                    <td>{t.status}</td>
                    <td>{formatAmount(t.amount)}</td>
                    <td>{t.response}</td>
                    <td>{t.agent.name}</td>
                    {/* <td>{t.customer_ref}</td> */}
                    {/* <td>{t.order_id}</td> */}
                    <td>{formatDate(t.created_at)}</td>
                    <td>
                      <Button
                        size="sm"
                        color="primary"
                        disabled={requerying}
                        onClick={(e) => {
                          e.stopPropagation();
                          requery(t.id);
                        }}
                      >
                        {requerying
                          ? 'Loading'
                          : t.status === 'SUCCESSFUL'
                          ? 'Response'
                          : 'Requery'}
                      </Button>

                      <div style={{ width: '10px' }} />
                    </td>
                    <td>
                      <Button
                        size="sm"
                        color="success"
                        disabled={requerying}
                        onClick={(e) => {
                          e.stopPropagation();
                          showTokenResponse(t.reference);
                        }}
                      >
                        {requerying ? 'Loading' : 'Logs'}
                      </Button>
                    </td>
                    <td>
                      <Button
                        size="sm"
                        color="success"
                        disabled={reversing}
                        onClick={(e) => {
                          e.stopPropagation();
                          reverseVendRequest(t.id);
                        }}
                      >
                        {reversing ? 'Loading' : 'Reverse'}
                      </Button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="11" className="text-center">
                    No requests made for this order.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        )}
        <Pagination page={page} total={meta.pages} onChange={setPage} />
      </CardBody>
    </Card>
  );
}

function VendDisplay({ request = {} }) {
  return (
    <div>
      <Row>
        <Col sm="12">
          <Card className="card-chart">
            <CardHeader>
              <CardTitle className="mt-0">Request</CardTitle>
            </CardHeader>
            <CardBody>
              <div className="d-flex mb-3 justify-content-between">
                <div>
                  <span className="text-secondary">Customer ID</span>
                </div>
                <div> {request.customer_id} </div>
              </div>
              <div className="d-flex mb-3 justify-content-between">
                <div>
                  <span className="text-secondary">Customer Ref</span>
                </div>
                <div> {request.customer_ref} </div>
              </div>
              <div className="d-flex mb-3 justify-content-between">
                <div>
                  <span className="text-secondary">Plan</span>
                </div>
                <div>{request.plan}</div>
              </div>
              <div className="d-flex mb-3 justify-content-between">
                <div>
                  <span className="text-secondary">Gateway</span>
                </div>
                <div> {request.gateway} </div>
              </div>
              <div className="d-flex mb-3 justify-content-between">
                <div>
                  <span className="text-secondary">Gateway Ref</span>
                </div>
                <div> {request.gateway_ref} </div>
              </div>
              <div className="d-flex mb-3 justify-content-between">
                <div>
                  <span className="text-secondary">Amount</span>
                </div>
                {request.amount && <div> {formatAmount(request.amount)} </div>}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default connect()(VendRequests);
