import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  Button,
} from 'reactstrap';

import { closeAllModals } from '../actions/modalActions';
import { showError, showInfo } from '../actions/notificationActions';

import { callApi } from '../utils';

export default connect((state) => ({
  open: state.modals.createMerchant,
  data: state.modals.data,
}))(
  class UserModal extends Component {
    state = {
      merchant: {
        name: '',
        phone: '',
        email: '',
        type: '',
        manage: '',
      },
      loading: false,
    };

    componentDidUpdate(prevProps) {
      if (prevProps.open !== this.props.open && this.props.open) {
        this.setState({
          merchant: {
            name: '',
            phone: '',
            email: '',
            type: '',
            manage: '',
          },
        });
      }
    }

    setInput = (e) => {
      const { name, value } = e.target;

      if (name === 'manage') {
        this.setState({
          ...this.state,
          merchant: {
            ...this.state.merchant,
            manage: !this.state.merchant.manage,
          },
        });
      } else {
        this.setState((state) => {
          return { ...state, merchant: { ...state.merchant, [name]: value } };
        });
      }
    };

    close = () => {
      this.props.dispatch(closeAllModals());
    };

    createMerchant = () => {
      const { name, email, type, manage, phone } = this.state.merchant;
      this.setState({ loading: true });
      const a = this.props.data;
      callApi('/merchant', { name, email, type, manage, phone }, 'post')
        .then((res) => {
          this.setState({ loading: false });
          this.props.dispatch(showInfo(res.message));
          this.close();
          a.refresh();
        })
        .catch((err) => {
          this.setState({ loading: false });
          this.props.dispatch(showError(err));
        });
    };

    render() {
      const { name, phone, email, type, manage } = this.state.merchant;
      const loading = this.state.loading;
      const open = this.props.open;
      return (
        <div>
          <Modal size="lg" isOpen={open} toggle={this.close}>
            <ModalHeader toggle={this.close}>User Details</ModalHeader>
            <ModalBody>
              <form onSubmit={this.updateUser}>
                <Row>
                  <Col xs={12} md={12}>
                    <FormGroup>
                      <Label>Name</Label>
                      <Input
                        onChange={this.setInput}
                        value={name}
                        name="name"
                        placeholder="Merchant's Name"
                        type="text"
                        required
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label>Email</Label>
                      <Input
                        onChange={this.setInput}
                        value={email}
                        name="email"
                        placeholder="Merchant's Email"
                        type="text"
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label>Phone</Label>
                      <Input
                        onChange={this.setInput}
                        value={phone}
                        name="phone"
                        placeholder="Merchant's phone"
                        type="phone"
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label>Type</Label>
                      <Input
                        name="type"
                        type="select"
                        onChange={this.setInput}
                        value={type}
                      >
                        <option value="">CHANGE WALLET TYPE</option>
                        <option value="POSTFUND">POSTFUND</option>
                        <option value="PREFUND">PREFUND</option>
                      </Input>
                    </FormGroup>

                    <FormGroup>
                      <Label check>
                        <Input
                          onChange={this.setInput}
                          checked={manage}
                          name="manage"
                          type="checkbox"
                        />{' '}
                        {'Manage'}
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>

                <Row className="justify-content-center">
                  <Button
                    size="lg"
                    color="primary"
                    onClick={this.createMerchant}
                  >
                    {loading ? 'PLEASE WAIT...' : 'Create'}
                  </Button>
                </Row>
              </form>
            </ModalBody>
            <ModalFooter />
          </Modal>
        </div>
      );
    }
  }
);
