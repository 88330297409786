import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import React, { Component } from 'react';
import { callApi, formatAmount, formatDate } from '../../utils';
import {
  closeAllModals,
  confirmAction,
  openUserModal,
  toggleModalLoading,
} from '../../actions/modalActions';
import { showError, showInfo } from '../../actions/notificationActions';

import ClickToCopy from '../ClickToCopy';
import ConfirmActionModal from '../ConfirmActionModal';
import ErrorTick from '../ErrorTick';
import SuccessTick from '../SuccessTick';
import classnames from 'classnames';
import { connect } from 'react-redux';

class TransactionDetails extends Component {
  state = {
    transaction: { createdBy: {} },
    meta: {},
    vendResponse: {},
    paymentResponse: {},
    loading: true,
    activeTab: '1',
    toggle: null,
    transactions: [],
    page: 1,
    limit: 10,
  };

  componentWillMount() {
    this.loadView();
  }

  startLoad = () => this.setState({ loading: true });
  stopLoad = () => this.setState({ loading: false });

  scrollToTop = () =>
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);

  loadView = (e) => {
    const { mid, id } = this.props.match.params;

    const include = ['createdBy'];

    this.startLoad();
    callApi(`/channels/${mid}/transactions/${id}`, { include })
      .then(({ data = {}, meta = {} }) => {
        this.setState({ transaction: data, meta });
        this.scrollToTop();
        this.getVendResponse(data.order_id);
        this.getPaymentResponse(data.order_id);
      })
      .catch((err) => {
        this.props.dispatch(showError(err));
      })
      .finally(() => {
        // this.stopLoad();
      });
  };

  getVendResponse = (id) => {
    callApi(`/transactions/vendresponse`, { id })
      .then(({ transaction = {} }) => {
        this.setState({ vendResponse: transaction });
      })
      .catch((err) => {
        this.props.dispatch(showError(err));
      })
      .finally(() => {
        this.stopLoad();
      });
  };

  getPaymentResponse = (id) => {
    callApi(`/transactions/payment`, { id })
      .then(({ transaction = {} }) => {
        this.setState({ paymentResponse: transaction });
      })
      .catch((err) => {
        this.props.dispatch(showError(err));
      })
      .finally(() => {
        this.stopLoad();
      });
  };

  refundOrder = (params) => {
    const { id } = this.props.match.params;
    this.props.dispatch(toggleModalLoading(true));
    callApi(`/refunds`, { order_id: id, comment: params.comment }, 'post')
      .then(({ data = {}, message = 'Done' }) => {
        this.scrollToTop();
        this.props.dispatch(toggleModalLoading(false));
        this.props.dispatch(showInfo(message));
        this.props.dispatch(closeAllModals());
        this.setState({ meta: { ...this.state.meta, canRefund: false } });
      })
      .catch((err) => {
        this.props.dispatch(showError(err));
        this.props.dispatch(toggleModalLoading(false));
      })
      .finally(() => {
        this.stopLoad();
      });
  };

  confirmRefund = () => {
    this.props.dispatch(
      confirmAction({
        confirmCallback: (params) => this.refundOrder(params),
        cancel: () => this.props.dispatch(closeAllModals()),
        confirmText: 'Are you sure you want to refund?',
        fields: [
          {
            label: 'Comment',
            name: 'comment',
            type: 'text',
          },
        ],
      })
    );
  };

  regenerateOrder = () => {
    const id = this.props.match.params.id;
    this.props.dispatch(toggleModalLoading(true));
    callApi(`/order/${id}`, {}, 'put')
      .then(({ vendResponse = {} }) => {
        this.scrollToTop();
        this.props.dispatch(toggleModalLoading(false));
        this.props.dispatch(showInfo('Transaction regenerated successfully'));
        this.props.dispatch(closeAllModals());
        this.props.history.push(`/refundables/${vendResponse.id}`);
        this.loadView();
      })
      .catch((err) => {
        this.props.dispatch(showError(err));
        this.props.dispatch(toggleModalLoading(false));
      })
      .finally(() => {
        this.stopLoad();
      });
  };

  confirmRegenerate = () => {
    this.props.dispatch(
      confirmAction({
        confirmCallback: this.regenerateOrder,
        cancel: () => this.props.dispatch(closeAllModals()),
        confirmText: 'Are you sure you want to regenerate this order?',
        loading: this.state.loadingModal,
      })
    );
  };

  setPage = (page) => {
    if (page) {
      this.setState({ page }, () => {
        this.loadView();
      });
    }
  };

  opentUser = (id) => {
    this.props.dispatch(openUserModal(id));
  };

  render() {
    let {
      transaction,
      meta,
      loading,
      activeTab,
      vendResponse,
      paymentResponse,
    } = this.state;

    const toggle = (tab) => {
      this.setState({ activeTab: tab });
    };

    return (
      <div className="content">
        <ConfirmActionModal />
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardHeader className="ml-2">
                <div className="d-flex justify-content-between align-contents-center">
                  <div>
                    <CardTitle>Transaction Details</CardTitle>
                    <p className="category">
                      {transaction.order_id || 'Loading...'}
                    </p>
                  </div>
                  <div>
                    <Button
                      onClick={this.confirmRegenerate}
                      disabled={!meta.canRegenerate}
                      color="primary"
                    >
                      Regenerate
                    </Button>
                    {/* <div style={{width: '1%'}} /> */}
                    <Button
                      onClick={this.confirmRefund}
                      disabled={!meta.canRefund}
                      color="primary"
                    >
                      Refund
                    </Button>
                  </div>
                </div>
              </CardHeader>

              <CardBody>
                <div>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => {
                          toggle('1');
                        }}
                      >
                        Details
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <br />
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <Row>
                        <Col sm="6">
                          <Card className="card-chart">
                            <CardHeader>
                              <CardTitle className="mt-0">
                                Transaction
                              </CardTitle>
                            </CardHeader>
                            <CardBody>
                              <div className="d-flex mb-3 justify-content-between">
                                <div>
                                  <span className="text-secondary">
                                    Transaction ID
                                  </span>
                                </div>
                                <div> {transaction.order_id} </div>
                              </div>
                              <div className="d-flex mb-3 justify-content-between">
                                <div>
                                  <span className="text-secondary">Name</span>
                                </div>
                                <div> {transaction.name} </div>
                              </div>
                              <div className="d-flex mb-3 justify-content-between">
                                <div>
                                  <span className="text-secondary">Phone</span>
                                </div>
                                <span>
                                  <span
                                    className="cursor-pointer blue-text"
                                    onClick={() =>
                                      this.opentUser(transaction.phone)
                                    }
                                  >
                                    <strong>{transaction.phone}</strong>{' '}
                                  </span>
                                  <ClickToCopy value={transaction.phone} />
                                </span>
                              </div>
                              <div className="d-flex mb-3 justify-content-between">
                                <div>
                                  <span className="text-secondary">DisCo</span>
                                </div>
                                <div> {transaction.disco_code} </div>
                              </div>
                              <div className="d-flex mb-3 justify-content-between">
                                <div>
                                  <span className="text-secondary">
                                    Payment Method
                                  </span>
                                </div>
                                <div> {transaction.payment_type} </div>
                              </div>
                              <div className="d-flex mb-3 justify-content-between">
                                <div>
                                  <span className="text-secondary">Amount</span>
                                </div>
                                {transaction.amount && (
                                  <div>
                                    {' '}
                                    {formatAmount(transaction.amount)}{' '}
                                  </div>
                                )}
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col sm="6">
                          <Card className="card-chart">
                            <CardHeader>
                              <CardTitle className="mt-0">
                                Other Information
                              </CardTitle>
                            </CardHeader>
                            <CardBody>
                              <div className="d-flex mb-3 justify-content-between">
                                <div>
                                  <span className="text-secondary">Time</span>
                                </div>
                                {transaction.created_at && (
                                  <div>
                                    {' '}
                                    {formatDate(transaction.created_at)}{' '}
                                  </div>
                                )}
                              </div>
                              <div className="d-flex mb-3 justify-content-between">
                                <div>
                                  <span className="text-secondary">
                                    Treated
                                  </span>
                                </div>
                                {!loading && (
                                  <div>
                                    {' '}
                                    {transaction.treated_on ? (
                                      <SuccessTick />
                                    ) : (
                                      <ErrorTick />
                                    )}{' '}
                                  </div>
                                )}
                              </div>
                              <div className="d-flex mb-3 justify-content-between">
                                <div>
                                  <span className="text-secondary">
                                    Created By
                                  </span>
                                </div>
                                <div> {transaction.createdBy.name} </div>
                              </div>
                              <div className="d-flex mb-3 justify-content-between">
                                <div>
                                  <span className="text-secondary">Status</span>
                                </div>
                                <div> {transaction.payment_status} </div>
                              </div>
                              <div className="d-flex mb-3 justify-content-between">
                                <div>
                                  <span className="text-secondary">
                                    Gateway
                                  </span>
                                </div>
                                <div> {transaction.evg_gateway} </div>
                              </div>
                              <div className="d-flex mb-3 justify-content-between">
                                <div>
                                  <span className="text-secondary">
                                    Vend Status
                                  </span>
                                </div>
                                {!loading && (
                                  <div>
                                    {vendResponse.created_at
                                      ? 'Success'
                                      : 'No vend response received'}{' '}
                                  </div>
                                )}
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="12">
                          <Card className="card-chart">
                            <CardHeader>
                              <CardTitle className="mt-0">
                                Vend Response
                              </CardTitle>
                            </CardHeader>
                            <CardBody>
                              <Row>
                                <Col sm="6">
                                  {loading ? (
                                    <span className="text-secondary">
                                      Loading...
                                    </span>
                                  ) : vendResponse.created_at !== undefined ? (
                                    <div>
                                      <div className="d-flex mb-3 justify-content-between">
                                        <div>
                                          <span className="text-secondary">
                                            Token
                                          </span>
                                        </div>
                                        <div> {vendResponse.token} </div>
                                      </div>
                                      <div className="d-flex mb-3 justify-content-between">
                                        <div>
                                          <span className="text-secondary">
                                            Vend Amount
                                          </span>
                                        </div>
                                        <div>
                                          {' '}
                                          {vendResponse.amt_electricity}{' '}
                                        </div>
                                      </div>
                                      <div className="d-flex mb-3 justify-content-between">
                                        <div>
                                          <span className="text-secondary">
                                            VAT
                                          </span>
                                        </div>
                                        <span>
                                          {formatAmount(vendResponse.tax)}
                                        </span>
                                      </div>
                                      <div className="d-flex mb-3 justify-content-between">
                                        <div>
                                          <span className="text-secondary">
                                            Units
                                          </span>
                                        </div>
                                        {vendResponse.units && (
                                          <div> {vendResponse.units} kWh</div>
                                        )}
                                      </div>
                                      <div className="d-flex mb-3 justify-content-between">
                                        <div>
                                          <span className="text-secondary">
                                            Debt
                                          </span>
                                        </div>
                                        <div>
                                          {formatAmount(vendResponse.debt_amt)}
                                        </div>
                                      </div>
                                      <div className="d-flex mb-3 justify-content-between">
                                        <div>
                                          <span className="text-secondary">
                                            Transaction Ref
                                          </span>
                                        </div>
                                        {<div>{vendResponse.trans_ref}</div>}
                                      </div>
                                      <div className="d-flex mb-3 justify-content-between">
                                        <div>
                                          <span className="text-secondary">
                                            Receipt No.
                                          </span>
                                        </div>
                                        {<div>{vendResponse.rct_num}</div>}
                                      </div>
                                      <div className="d-flex mb-3 justify-content-between">
                                        <div>
                                          <span className="text-secondary">
                                            Vend Type
                                          </span>
                                        </div>
                                        {<div>{vendResponse.vend_type}</div>}
                                      </div>
                                    </div>
                                  ) : (
                                    <span className="text-secondary">
                                      No Vend Response Received.
                                    </span>
                                  )}
                                </Col>
                                <Col sm="6">
                                  {loading ? (
                                    <span className="text-secondary">
                                      Loading...
                                    </span>
                                  ) : paymentResponse.created_at !==
                                    undefined ? (
                                    <div>
                                      <div className="d-flex mb-3 justify-content-between">
                                        <div>
                                          <span className="text-secondary">
                                            Time
                                          </span>
                                        </div>
                                        <div>
                                          {' '}
                                          {formatDate(
                                            paymentResponse.created_at
                                          )}{' '}
                                        </div>
                                      </div>
                                      <div className="d-flex mb-3 justify-content-between">
                                        <div>
                                          <span className="text-secondary">
                                            Name
                                          </span>
                                        </div>
                                        <div>{paymentResponse.name}</div>
                                      </div>
                                      <div className="d-flex mb-3 justify-content-between">
                                        <div>
                                          <span className="text-secondary">
                                            Meter
                                          </span>
                                        </div>
                                        <span>{paymentResponse.meter_no}</span>
                                      </div>
                                      <div className="d-flex mb-3 justify-content-between">
                                        <div>
                                          <span className="text-secondary">
                                            Generated By
                                          </span>
                                        </div>
                                        {
                                          <div>
                                            {paymentResponse.generatedBy}
                                          </div>
                                        }
                                      </div>
                                      <div className="d-flex mb-3 justify-content-between">
                                        <div>
                                          <span className="text-secondary">
                                            Payment Status
                                          </span>
                                        </div>
                                        <div>
                                          {paymentResponse.payment_status}
                                        </div>
                                      </div>
                                      <div className="d-flex mb-3 justify-content-between">
                                        <div>
                                          <span className="text-secondary">
                                            Vertical Type
                                          </span>
                                        </div>
                                        {
                                          <div>
                                            {paymentResponse.vertical_type}
                                          </div>
                                        }
                                      </div>
                                      <div className="d-flex mb-3 justify-content-between">
                                        <div>
                                          <span className="text-secondary">
                                            Treated On
                                          </span>
                                        </div>
                                        {
                                          <div>
                                            {formatDate(
                                              paymentResponse.treated_on
                                            )}
                                          </div>
                                        }
                                      </div>
                                      <div className="d-flex mb-3 justify-content-between">
                                        <div>
                                          <span className="text-secondary">
                                            Payment Channel
                                          </span>
                                        </div>
                                        {
                                          <div>
                                            {paymentResponse.buypower_channel}
                                          </div>
                                        }
                                      </div>
                                    </div>
                                  ) : (
                                    <span className="text-secondary">
                                      No Payment Response Received.
                                    </span>
                                  )}
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect()(TransactionDetails);
