import React, { Component } from 'react';

import { Collapse, Button, Card } from 'reactstrap';

import Loading from './Loading';
import JsonViewer from './JsonViewer';
import { callApi } from '../utils';

export default class TokenResponse extends Component {
  state = {
    loading: false,
    open: false,
    response: null,
  };

  loadResponse = () => {
    this.setState({ loading: true });
    callApi('/disco/response?id=' + this.props.order.order_id)
      .then((response) => {
        this.setState({ loading: false, response });
      })
      .catch((err) => {
        this.setState({
          loading: false,
          response: typeof err === 'object' ? err : { message: err.message },
        });
      });
  };

  toggle = () => {
    this.setState(({ open }) => {
      if (!open) {
        this.loadResponse();
      }
      return { open: !open };
    });
  };

  render() {
    const { loading, open, response } = this.state;

    return (
      <div>
        <Button block color="secondary" onClick={this.toggle}>
          {open ? 'Close' : 'View'} Token Response
        </Button>
        {loading ? (
          <Loading />
        ) : (
          open && (
            <Collapse isOpen={open}>
              <Card>
                <JsonViewer
                  src={response}
                  name={'Token Response'}
                  iconStyle={'circle'}
                  displayDataTypes={false}
                  displayObjectSize={false}
                />
              </Card>
            </Collapse>
          )
        )}
      </div>
    );
  }
}
