import React, { Component } from 'react';
import { connect } from 'react-redux';
import { callApi } from '../utils';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
  Button,
} from 'reactstrap';

import { closeAllModals } from '../actions/modalActions';

export default connect((state) => ({
  open: state.modals.facilityUpdate,
  data: state.modals.data,
}))(
  class FacilityUpdateModal extends Component {
    state = {
      loading: false,
      owner: this.props.data && this.props.data.owner,
      asset_provider: this.props.data && this.props.data.asset_provider,
      service_provider: this.props.data && this.props.data.service_provider,
      type: this.props.data && this.props.data.type,
      error: '',
    };

    componentDidUpdate(prevProps) {
      this.updateState(prevProps);
    }

    setInput = (e) => {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    };

    close = () => {
      this.props.refresh();
      this.props.dispatch(closeAllModals());
    };

    updateState = (prevProps) => {
      if (prevProps.open !== this.props.open && this.props.open) {
        this.setState({
          owner: this.props.data && this.props.data.owner,
          asset_provider: this.props.data && this.props.data.asset_provider,
          service_provider: this.props.data && this.props.data.service_provider,
          type: this.props.data && this.props.data.type,
        });
      }
    };

    submit = (e) => {
      e.preventDefault();

      if (
        !this.state.owner ||
        !this.state.asset_provider ||
        !this.state.service_provider ||
        !this.state.type
      )
        return this.setState({ error: 'Please fill in all fields!' });

      this.setState({ error: '' });

      const id = this.props.data.id;
      let bodyObj = {
        owner: this.state.owner,
        asset_provider: this.state.asset_provider,
        service_provider: this.state.service_provider,
        type: this.state.type,
      };
      console.log(bodyObj);

      this.setState({ loading: true });

      // Request to update a Facility
      callApi(`/facilities/${id}`, bodyObj, 'put')
        .then((res) => {
          this.setState({ loading: false });
          console.log('Response ', res);

          alert(res.message);
          this.close();
        })
        .catch((err) => {
          this.setState({ loading: false });
          if ((err.status = 'unprocessable')) alert(err.message);
          console.log(err);
        });
    };

    // Meter type constants
    meterType = [
      { value: 'PREPAID_METER' },
      { value: 'POSTPAID_ACCOUNT' },
      { value: 'MOBILE_NUMBER' },
      { value: 'TV_DECODER' },
    ];

    render() {
      const {
        owner,
        asset_provider,
        service_provider,
        type,
        loading,
      } = this.state;

      const open = this.props.open;

      return (
        <div>
          <Modal size="lg" isOpen={open} toggle={this.close}>
            <ModalHeader toggle={this.close}>Update Facility</ModalHeader>
            <ModalBody>
              <form>
                <div>
                  <FormGroup>
                    <Label className="mt-3">Owner:</Label>
                    <Input
                      name="owner"
                      type="text"
                      onChange={this.setInput}
                      value={owner}
                      disabled={loading}
                    />

                    <Label className="mt-3">Asset Provider:</Label>
                    <Input
                      name="asset_provider"
                      type="text"
                      onChange={this.setInput}
                      value={asset_provider}
                      disabled={loading}
                    />
                  </FormGroup>

                  <Label className="mt-3">Service Provider:</Label>
                  <Input
                    name="service_provider"
                    type="text"
                    onChange={this.setInput}
                    value={service_provider}
                    disabled={loading}
                  />

                  <Label className="mt-3">Meter Type:</Label>
                  <Input
                    onChange={this.setInput}
                    value={type}
                    name="type"
                    type="select"
                    required
                  >
                    {this.meterType.map((m) => (
                      <option key={m.value} value={m.value}>
                        {m.value}
                      </option>
                    ))}
                  </Input>
                </div>
                <div />
              </form>
              {this.state.error ? (
                <span style={{ color: 'red' }}>{this.state.error}</span>
              ) : (
                ''
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="success" disabled={loading} onClick={this.submit}>
                Update Facility
                {loading ? (
                  <span className="fas fa-spinner fa-spin"></span>
                ) : (
                  ''
                )}
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      );
    }
  }
);
