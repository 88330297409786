import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Table,
} from 'reactstrap';
import React, { useEffect, useState } from 'react';
import { callApi, formatAmount, formatDate, isNotEmpty } from '../../utils';
import {
  closeAllModals,
  confirmAction,
  previewEntity,
  toggleModalLoading,
} from '../../actions/modalActions';
import { showError, showInfo } from '../../actions/notificationActions';

import ConfirmActionModal from '../ConfirmActionModal';
import Loading from '../Loading';
import Pagination from '../Pagination';
import PreviewEntityModal from '../PreviewEntityModal';
import { connect } from 'react-redux';

function Payments(props) {
  const { order, dispatch } = props;
  const [loading, setLoading] = useState(true);

  const [queryParams, setQueryParams] = useState({ limit: 30, page: 1 });
  const [response, setResponse] = useState({ data: [], meta: { pages: 1 } });
  const [currentPayment, setCurrentPayment] = useState({});
  const [verifiying, setVerifying] = useState(false);
  const [unblocking, setUnblocking] = useState(false);
  const [refunding, setRefunding] = useState(false);

  const fetchTransactions = async () => {
    setLoading(true);
    return callApi(`/orders/${order.id}/payments?include=["requirementData"]`, {
      limit,
      page,
    })
      .then((res) => {
        const { data = [], meta = {} } = res;
        setResponse({ data, meta });
      })
      .catch((err) => {
        dispatch(showError(err.message));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const refresh = () => fetchTransactions();

  const verify = async (id) => {
    setVerifying(true);
    try {
      const res = await callApi(`/payments/${id}/confirmation`);
      setVerifying(false);
      dispatch(showInfo(res.message || 'Verified!'));
      fetchTransactions();
    } catch (error) {
      dispatch(showError(error));
      setVerifying(false);
    }
  };

  const clearPaymentRequirementAttempts = async (paymentID) => {
    setUnblocking(true);
    try {
      const res = await callApi(
        `/payments/${paymentID}/payment-requirement-data`,
        {
          state: 'EXPIRY_DATE_REQUIRED',
          attempts: 0,
        },
        'patch'
      );
      setUnblocking(false);
      dispatch(showInfo(res.message || 'Attempts Cleared!'));
      fetchTransactions();
    } catch (error) {
      dispatch(showError(error));
      setUnblocking(false);
    }
  };

  useEffect(() => {
    setLoading(true);

    if (order.id) {
      fetchTransactions().finally(() => setLoading(false));
    }
  }, [queryParams.page, order.id]);

  useEffect(() => {
    if (currentPayment.order_id) {
      dispatch(
        previewEntity({
          entity: currentPayment,
          btnText: 'Open Payment',
          close: () => {
            setCurrentPayment({});
          },
          view: () => {
            dispatch(closeAllModals());
            props.history.push(`/all-payments/${currentPayment.id}`);
          },
        })
      );
    }
  }, [currentPayment.order_id]);

  const makeNewPayment = async (id) => {
    try {
      setLoading(true);
      dispatch(toggleModalLoading(true));
      const res = await callApi(`/orders/${id}/payments`, {}, 'post');
      dispatch(showInfo(res.message || 'Payment created successfully!'));
      fetchTransactions();
    } catch (error) {
      dispatch(showError(error || 'An error occurred!'));
    } finally {
      setLoading(false);
      dispatch(toggleModalLoading(false));
      dispatch(closeAllModals());
    }
  };

  const confirmNewPayment = (id) => {
    dispatch(
      confirmAction({
        confirmCallback: () => makeNewPayment(id),
        cancel: () => dispatch(closeAllModals()),
        loading: loading,
        confirmText: 'Are you sure you want to make this payment again?',
      })
    );
  };

  const initiateRefund = async (params) => {
    try {
      dispatch(toggleModalLoading(true));
      setRefunding(true);
      const res = await callApi(
        `/payments/${params.id}/refund`,
        { method: 'BANK_TRANSFER', ...params },
        'post'
      );
      dispatch(closeAllModals());
      fetchTransactions();
      dispatch(showInfo(res.message));
    } catch (error) {
      dispatch(showError(error.message));
    } finally {
      dispatch(toggleModalLoading(false));
      setRefunding(false);
    }
  };

  const confirmRefund = (id) => {
    dispatch(
      confirmAction({
        confirmCallback: (params) => initiateRefund({ ...params, id }),
        cancel: () => dispatch(closeAllModals()),
        confirmText: '',
        confirmBtnText: 'Refund',
        cancelBtnText: 'Cancel',
        title: 'Refund Payment',
        fields: [
          {
            label: 'Please Select a Method',
            name: 'method',
            type: 'select',
            isValid: (val) => isNotEmpty(val),
            error: 'Method is required',

            options: [
              {
                key: '',
                value: '',
                label: 'please select',
              },
              {
                key: 'BANK_TRANSFER',
                value: 'BANK_TRANSFER',
                label: 'Bank Transfer',
              },
              {
                key: 'CHARGE_BACK',
                value: 'CHARGE_BACK',
                label: 'Charge Back',
              },
            ],
          },
          {
            label: 'Comment',
            name: 'comment',
            type: 'text',
            isValid: (val) => isNotEmpty(val),
            error: 'Comment is required',
          },
        ],
      })
    );
  };

  const setPage = (page = 1) => {
    setQueryParams({ ...queryParams, page });
  };

  const { data, meta } = response;
  const { page, limit } = queryParams;

  return (
    <div>
      <PreviewEntityModal>
        <PaymentDisplay payment={currentPayment} />
      </PreviewEntityModal>
      <div className="d-flex">
        <Button
          color="primary"
          disabled={loading}
          onClick={() => confirmNewPayment(order.id)}
        >
          Make new Payment
        </Button>
        <div style={{ width: '15px' }} />
        <Button disabled={loading} onClick={refresh}>
          Refresh
        </Button>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <Card>
          <CardBody>
            <Table responsive hover striped>
              <thead className="text-primary">
                <tr>
                  <th>No.</th>
                  <th>Reference</th>
                  {/* <th>Order ID</th> */}
                  <th>Amount Due</th>
                  <th>Amount Paid</th>
                  <th>Method</th>
                  <th>Processor</th>
                  <th>Date</th>
                  <th>Status</th>
                  <th>State</th>
                  <th>Attempts</th>
                  <th>Action</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {data.length ? (
                  data.map((t, i) => (
                    <tr onClick={() => setCurrentPayment(t)} key={t.id}>
                      <td>{loading ? 'x' : i + 1 + (page - 1) * limit}</td>
                      <td>{t.reference}</td>
                      {/* <td>{t.order_id}</td> */}
                      <td>{formatAmount(t.amount_due)}</td>
                      <td>{formatAmount(t.amount_paid)}</td>
                      <td>{t.method}</td>
                      <td>{t.processor}</td>
                      <td>{formatDate(t.created_at)}</td>
                      <td>{t.status}</td>
                      <td>{t.state}</td>
                      <td>
                        {t.requirementData.length > 0
                          ? t.requirementData
                              .filter((r) => r.state === 'EXPIRY_DATE_REQUIRED')
                              .reduce(
                                (acc, curr) => acc + curr.data.attempts || 0,
                                0
                              )
                          : 0}
                      </td>
                      <td>
                        <Row>
                          <Col md={6}>
                            {
                              <Button
                                size="sm"
                                color="primary"
                                disabled={verifiying}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  verify(t.id);
                                }}
                              >
                                {verifiying ? 'Verifying' : 'Verify'}
                              </Button>
                            }
                          </Col>
                          <Col md={6} padding={0}>
                            {t.requirementData.length > 0 &&
                              t.requirementData
                                .filter(
                                  (r) => r.state === 'EXPIRY_DATE_REQUIRED'
                                )
                                .reduce(
                                  (acc, curr) => acc + curr.data.attempts || 0,
                                  0
                                ) > 0 && (
                                <Button
                                  size="sm"
                                  color="secondary"
                                  disabled={unblocking}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    clearPaymentRequirementAttempts(t.id);
                                  }}
                                >
                                  {unblocking ? 'Unblocking' : 'Unblock'}
                                </Button>
                              )}
                          </Col>
                        </Row>
                      </td>
                      <td>
                        {t.status === 'SUCCESSFUL' && (
                          <Button
                            size="sm"
                            color="success"
                            disabled={refunding}
                            onClick={(e) => {
                              e.stopPropagation();
                              confirmRefund(t.id);
                            }}
                          >
                            {refunding ? 'Refunding' : 'Initiate Refund'}
                          </Button>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="9">
                      No payments have been made for this order.
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            <Pagination page={page} total={meta.pages} onChange={setPage} />
          </CardBody>
        </Card>
      )}{' '}
    </div>
  );
}

function PaymentDisplay({ payment = {} }) {
  return (
    <div>
      <ConfirmActionModal />
      <Row>
        <Col sm="12">
          <Card className="card-chart">
            <CardHeader>
              <CardTitle className="mt-0">Payment</CardTitle>
            </CardHeader>
            <CardBody>
              <div className="d-flex mb-3 justify-content-between">
                <div>
                  <span className="text-secondary">Order ID</span>
                </div>
                <div> {payment.order_id} </div>
              </div>
              <div className="d-flex mb-3 justify-content-between">
                <div>
                  <span className="text-secondary">Amount Due</span>
                </div>
                {payment.amount_due && (
                  <div> {formatAmount(payment.amount_due)} </div>
                )}
              </div>
              <div className="d-flex mb-3 justify-content-between">
                <div>
                  <span className="text-secondary">Amount Paid</span>
                </div>
                {payment.amount_paid && (
                  <div> {formatAmount(payment.amount_paid)} </div>
                )}
              </div>
              <div className="d-flex mb-3 justify-content-between">
                <div>
                  <span className="text-secondary">Payment Method</span>
                </div>
                <div> {payment.method} </div>
              </div>
              <div className="d-flex mb-3 justify-content-between">
                <div>
                  <span className="text-secondary">Processor</span>
                </div>
                <div> {payment.processor} </div>
              </div>
              <div className="d-flex mb-3 justify-content-between">
                <div>
                  <span className="text-secondary">Status</span>
                </div>
                <div> {payment.status} </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default connect()(Payments);
