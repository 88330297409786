import axios from 'axios';
const environment = process.env.NODE_ENV || 'production';

export const request = axios.create({
  baseURL: environment === 'production' && '/api',
  timeout: 120000, // 1 Minute
  headers: {
    'Content-Type': 'application/json',
  },
});

export default {
  async getSurveys({ status, offset, limit, query }) {
    const where = JSON.stringify({
      ...(status !== '' && { status }),
      ...(query !== '' && { title: { $like: `%${query}%` } }),
    });

    const data = await request
      .get(`/surveys`, {
        params: {
          ...(!!where && { where }),
          ...(offset ? { offset } : { offset: 1 }),
          ...(limit ? { limit } : { limit: 20 }),
        },
      })
      .then(({ data }) => {
        return data;
      });

    return data;
  },

  async createSurvey(data) {
    const response = await request.post('/surveys', data);
    return response;
  },

  async updateSurvey(surveyId, data) {
    delete data.id;
    delete data.created_at;
    delete data.updated_at;
    delete data.deleted_at;
    delete data.updated_by;
    delete data.created_by;
    const response = await request.patch(`/surveys/${surveyId}`, data);
    return response;
  },

  async deleteSurvey(surveyId) {
    const response = await request.delete(`/surveys/${surveyId}`);
    return response;
  },

  async getSingleSurvey(surveyId) {
    const response = await request
      .get(`/surveys/${surveyId}`)
      .then(({ data }) => {
        return data;
      });
    return response;
  },

  async getSurveyResponses(surveyId) {
    const response = await request
      .get(`/surveys/${surveyId}/responses`)
      .then(({ data }) => {
        return data;
      });
    return response;
  },
};
