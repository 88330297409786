import React from 'react';

import { Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { isMobile } from '../utils';

export default ({
  placeholder = 'Search...',
  size = 'lg',
  value = '',
  onChange,
  onSearch,
  loading = false,
}) => {
  if (!onChange)
    onChange = (e) => {
      value = e.target.value;
    };

  if (!onSearch) onSearch = () => null;

  return (
    <form onSubmit={onSearch}>
      <InputGroup
        className="no-border mb-0"
        size={size}
        style={{ maxWidth: isMobile() ? 300 : 600 }}
      >
        <Input
          autoComplete="on"
          onChange={onChange}
          value={value}
          placeholder={placeholder}
        />
        <InputGroupAddon className="cursor-pointer" onClick={onSearch}>
          <i
            className={`now-ui-icons ${
              loading ? 'loader_refresh spinner' : 'ui-1_zoom-bold'
            }`}
          />
        </InputGroupAddon>
      </InputGroup>
    </form>
  );
};
