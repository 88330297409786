import React, { Component } from 'react';

import { Row, Button } from 'reactstrap';
import { callApi, VERTICAL_TYPES } from '../utils';
import { showError, showInfo } from '../actions/notificationActions';
import { closeAllModals, openGenerateModal } from '../actions/modalActions';

const KCT_DISCOS = ['IKEJA', 'JOS'];
export default class TreatTransactionAndSendKCT extends Component {
  state = {
    loading: false,
    loadingKctEmail: false,
    loadingKctSms: false,
  };

  treat = (flag = true) => {
    if (
      !window.confirm(
        `${flag ? 'Treat' : 'Untreat'} this transaction ${
          this.props.order.order_id
        } ?`
      )
    )
      return;

    this.setState({ loading: true });
    let link =
      (this.props.link || '/transaction/treat') +
      `?id=${this.props.order.order_id}&treat=${flag ? 'true' : ''}`;
    callApi(link)
      .then((res) => {
        this.setState({ loading: false });
        this.props.dispatch(
          showInfo(res.message || 'Transaction treated successfully.')
        );
        this.props.done();
      })
      .catch((err) => {
        this.props.dispatch(showError(err));
        this.setState({ loading: false });
        this.props.done();
      });
  };

  openRegenerateModal = (t, e) => {
    this.props.dispatch(
      openGenerateModal({
        phone: t.phone,
        amount: Number(t.vend_amount || 0),
        disco: t.disco_code,
        meter: t.meter_no,
        mode: t.payment_type,
        id: t.order_id,
        type: t.vertical_type,
        link:
          t.vertical_type !== VERTICAL_TYPES.ELECTRICITY
            ? '/verticals/regenerate'
            : null,
      })
    );
    if (e) e.stopPropagation();
  };

  cancel = (e) => {
    if (
      !window.confirm(
        `This will cancel the transaction and move the money to the customer's wallet, Proceed?`
      )
    )
      return;

    this.setState({ loading: true });
    let link = `/order/${this.props.order.order_id}/status`;
    return callApi(link, { status: 'CANCELED' }, 'put')
      .then((res) => {
        this.setState({ loading: false });
        this.props.dispatch(
          showInfo(res.message || 'Transaction canceled successfully.')
        );
        this.props.done();
        return res;
      })
      .catch((err) => {
        this.props.dispatch(showError(err));
        this.setState({ loading: false });
        this.props.done();
      });
  };

  cancelAndReorder = (e) => {
    this.cancel(e)
      .then((res) => {
        let link = `/order/${res.data.order_id}`;

        return callApi(link, {}, 'put').then((res) => {
          this.setState({ loading: false });
          this.props.dispatch(
            showInfo(res.message || 'Transaction canceled successfully.')
          );
          this.props.done();
          this.openRegenerateModal(res.data, e);
        });
      })
      .catch((err) => {
        this.props.dispatch(showError(err));
        this.setState({ loading: false });
        this.props.done();
      });
  };

  holdOrder = (e) => {
    if (!window.confirm(`This will hold the order from processing, Proceed?`))
      return;

    this.setState({ loading: true });
    let link = `/order/${this.props.order.order_id}/status`;
    return callApi(link, { status: 'ON_HOLD' }, 'put')
      .then((res) => {
        this.setState({ loading: false });
        this.props.dispatch(
          showInfo(res.message || 'Transaction held successfully.')
        );
        this.props.done();
        return res;
      })
      .catch((err) => {
        this.props.dispatch(showError(err));
        this.setState({ loading: false });
        this.props.done();
      });
  };

  sendKCTEmail = (data) => {
    this.setState({ loadingKctEmail: true });
    this.sendKct(data);
  };

  sendKCTSms = (data) => {
    this.setState({ loadingKctSms: true });
    this.sendKct(data);
  };

  sendKct = async (data) => {
    try {
      const res = await callApi('/kct/send', data, 'post');
      this.props.dispatch(showInfo(res.message || 'KCT sent successfully'));
    } catch (err) {
      this.props.dispatch(showError(err.message || 'Error occurred'));
    } finally {
      if (data.channel === 'sms') this.setState({ loadingKctSms: false });
      else this.setState({ loadingKctEmail: false });
    }
  };

  openTransaction = () => {
    const id = this.props.order.id;
    window.open(`/orders/${id}`, id);
  };

  render() {
    const kct = KCT_DISCOS.includes(this.props.order.disco_code);
    const { meta = {} } = this.props;
    // const { permissions = {} } = meta;

    return this.props.order.order_id ? (
      <Row className="justify-content-center">
        {this.state.loading ? (
          <span>PLEASE WAIT.....</span>
        ) : (
          <div
            style={{
              justifyContent: 'space-evenly',
              display: 'flex',
              flexWrap: 'wrap',
            }}
          >
            <Button
              onClick={(e) => {
                e.stopPropagation();
                this.openTransaction();
                this.props.dispatch(closeAllModals());
              }}
              size="sm"
              color="primary"
            >
              View Details
            </Button>
            {kct && (
              <Button
                disabled={this.state.loadingKctSms}
                onClick={() =>
                  this.sendKCTSms({
                    channel: 'sms',
                    orderId: this.props.order.order_id,
                  })
                }
                size="sm"
                color="info"
              >
                {this.state.loadingKctSms ? 'PLEASE WAIT.....' : 'SEND KCT SMS'}
              </Button>
            )}
            <div style={{ width: '10px', display: 'inline-block' }} />
            {this.props.treated ? (
              <Button
                onClick={() => this.treat(false)}
                size="sm"
                color="primary"
              >
                UNTREAT
              </Button>
            ) : (
              <Button onClick={this.treat} size="sm" color="info">
                TREAT
              </Button>
            )}
            <div style={{ width: '10px', display: 'inline-block' }} />

            {meta.canCancel && (
              <div>
                <Button onClick={this.cancel} size="sm" color="primary">
                  CANCEL
                </Button>
                <div style={{ width: '10px', display: 'inline-block' }} />
              </div>
            )}

            {meta.canReOrder && (
              <div>
                <Button
                  onClick={this.cancelAndReorder}
                  size="sm"
                  color="primary"
                >
                  CANCEL &amp; RE-ORDER
                </Button>
                <div style={{ width: '10px', display: 'inline-block' }} />
              </div>
            )}
            {meta.canHold && (
              <div>
                <Button onClick={this.holdOrder} size="sm" color="primary">
                  HOLD ORDER
                </Button>
                <div style={{ width: '10px', display: 'inline-block' }} />
              </div>
            )}
            {kct && (
              <Button
                disabled={this.state.loadingKctEmail}
                onClick={() =>
                  this.sendKCTEmail({
                    channel: 'email',
                    orderId: this.props.order.order_id,
                  })
                }
                size="sm"
                color="info"
              >
                {this.state.loadingKctEmail
                  ? 'PLEASE WAIT.....'
                  : 'SEND KCT EMAIL'}
              </Button>
            )}
          </div>
        )}
      </Row>
    ) : null;
  }
}
