import React from 'react';
import { Route, Switch } from 'react-router-dom';

import List from './Businesses/List';
import Detail from './Businesses/Detail';
import TransactionDetails from './Businesses/TransactionDetails';

function Businesses() {
  return (
    <Switch>
      <Route exact path="/businesses" component={List} />
      <Route path="/businesses/:id" component={Detail} />
      <Route
        path="/businesses/:mid/transactions/:id"
        component={TransactionDetails}
      />
    </Switch>
  );
}

export default Businesses;
