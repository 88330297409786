import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button, Table, Badge } from 'reactstrap';
import { callApi, formatAmount, formatDateTime } from '../../utils';
import Loading from '../Loading';

class UserWallet extends React.Component {
  state = {};

  render() {
    const { id } = this.props.user;

    return (
      <React.Fragment>
        <Row>
          <Col md={6} xs={12}>
            <div className="d-flex align-items-center">
              <h5 className="m-0">
                <Balance id={id} />
              </h5>
            </div>
          </Col>
          <Col md={6} xs={12}>
            <div className="d-flex justify-content-end align-items-center">
              <Button size="sm" color="primary" className="btn-sm-block">
                Update Wallet
              </Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12} xs={12}>
            <Transactions id={id} />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

function Balance(props) {
  const { id } = props;
  const [balance, setBalance] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    callApi(`/customers/${id}/wallet`)
      .then((res) => res.data)
      .then((data) => {
        setBalance(data.balance);
      })
      .catch(() => {
        setBalance(0);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  return <span>{loading ? '-.--' : formatAmount(balance)}</span>;
}

function Transactions(props) {
  const { id } = props;
  const [page, setPage] = useState(1);
  const [transactions, setTransactions] = useState([]);
  const [checkpoint, setCheckpoint] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    callApi(`/customers/${id}/wallet/transactions?page=${page}`)
      .then((res) => res.data)
      .then((data) => {
        setTransactions(data);
        const thisCheckpoint = data.length > 0 ? data[0].created_at : null;
        if (typeof setCheckpoint === 'function') {
          setCheckpoint(thisCheckpoint);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  function loadMore() {
    if (loading) {
      return;
    }

    setLoading(true);
    callApi(
      `/customers/${id}/wallet/transactions?page=${
        page + 1
      }&checkpoint=${checkpoint}`
    )
      .then((res) => res.data)
      .then((data) => {
        setPage(page + 1);
        setTransactions([...transactions, ...data]);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <React.Fragment>
      <Table responsive hover striped>
        <thead className="text-primary">
          <tr>
            <th>#</th>
            <th>Date</th>
            <th>Reference</th>
            <th>Amount</th>
            <th>Balance Before</th>
            <th>Balance After</th>
            <th>Type</th>
          </tr>
        </thead>

        <tbody>
          {transactions.map((t, i) => {
            return (
              <tr
                // onClick={() => this.openTransaction(t.order_id)}
                key={t.order_id}
              >
                <td>{i + 1}</td>
                <td>{formatDateTime(t.created_at)}</td>
                <td>{t.ref}</td>
                <td
                  className={
                    t.operation === 'credit' ? 'text-success' : 'text-danger'
                  }
                >
                  <span className="font-weight-bold">
                    {formatAmount(t.amount)}
                  </span>
                </td>
                <td>{formatAmount(t.balance_before)}</td>
                <td>{formatAmount(t.balance_after)}</td>
                <td>
                  <Badge>{t.type}</Badge>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div className="text-center">
        {loading ? (
          <Loading />
        ) : (
          <Button onClick={loadMore} disa>
            Load More
          </Button>
        )}
      </div>
    </React.Fragment>
  );
}

export default connect()(UserWallet);
