import React, { Component } from 'react';
import { connect } from 'react-redux';
import ConfirmActionModal from '../ConfirmActionModal';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Nav,
  NavLink,
  NavItem,
  Button,
} from 'reactstrap';
import classnames from 'classnames';

import { callApi, formatAmount, formatDate } from '../../utils';
import { showError } from '../../actions/notificationActions';

import {
  openUserModal,
  showTokenResponseModal,
  showVendResponse,
} from '../../actions/modalActions';

import { Route, Switch } from 'react-router-dom';
import ClickToCopy from '../ClickToCopy';
import VendResponseModal from '../VendResponseModal';
import TokenResponseModal from '../TokenResponseModal';

class Detail extends Component {
  state = {
    request: {},
    meta: {},

    loading: true,
    toggling: false,
    activeTab: '1',
    toggle: null,
    rows: [],
    page: 1,
    pages: 1,
    limit: 10,
    enabled: false,
  };

  componentWillMount() {
    this.loadView();
  }

  startLoad = () => this.setState({ loading: true });
  stopLoad = () => this.setState({ loading: false });

  scrollToTop = () =>
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);

  loadView = (e) => {
    const id = this.props.match.params.id;

    this.startLoad();
    callApi(`/requests/${id}`)
      .then(({ data = {}, meta = {} }) => {
        this.setState({ request: data, meta });
        this.scrollToTop();
        if (!data.order) {
          this.fetchOrder(data.order_id);
        }
      })
      .catch((err) => {
        this.props.dispatch(showError(err));
      })
      .finally(() => {
        this.stopLoad();
      });
  };

  fetchOrder = async (orderId) => {
    const res = await callApi(`orders/${orderId}`);

    this.setState({ payment: { ...this.state.payment, order: res.data } });
  };

  setPage = (page) => {
    if (page) {
      this.setState({ page }, () => {
        this.loadView();
      });
    }
  };

  opentUser = (id) => {
    this.props.dispatch(openUserModal(id));
  };

  setInput = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  openOrder = (id) => {
    window.open(`/orders/${id}`);
  };

  requery = async () => {
    this.startLoad();
    const { request } = this.state;

    const res = await callApi(`/requests/${request.id}/response`);
    // console.log('vendResponse', res);
    this.stopLoad();
    this.props.dispatch(showVendResponse({ vendResponse: res.data }));
  };

  showResponse = async () => {
    const { request } = this.state;
    this.props.dispatch(showTokenResponseModal({ id: request.ref }));
  };

  render() {
    const { request, loading } = this.state;
    const { history, match, location } = this.props;

    return (
      <div className="content">
        <ConfirmActionModal />
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardHeader className="ml-2">
                <div className="d-flex justify-content-between align-contents-center">
                  <div>
                    <CardTitle>{request.reference || 'Loading...'}</CardTitle>
                    <p className="category">Single Vend Request</p>
                  </div>

                  <div>
                    {request.status === 'SUCCESSFUL' && (
                      <Button
                        color="primary"
                        disabled={loading}
                        onClick={this.requery}
                      >
                        {loading ? 'Loading' : 'Response'}
                      </Button>
                    )}
                    {request.status !== 'SUCCESSFUL' && (
                      <React.Fragment>
                        <div
                          style={{ width: '10px', display: 'inline-block' }}
                        />
                        <Button
                          color="primary"
                          disabled={loading}
                          onClick={this.requery}
                        >
                          {loading ? 'Loading' : 'Requery'}
                        </Button>
                      </React.Fragment>
                    )}
                    <React.Fragment>
                      <div style={{ width: '10px', display: 'inline-block' }} />
                      <Button
                        color="success"
                        disabled={loading}
                        onClick={this.showResponse}
                      >
                        {loading ? 'Loading' : 'Logs'}
                      </Button>
                    </React.Fragment>
                  </div>
                </div>
              </CardHeader>
              <VendResponseModal />
              <TokenResponseModal />

              <CardBody>
                <div>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        style={{ color: 'black' }}
                        href="#"
                        className={classnames({
                          active: location.pathname === `${match.url}`,
                        })}
                        onClick={() => {
                          history.push(`${match.url}`);
                        }}
                      >
                        General
                      </NavLink>
                    </NavItem>
                    {/* <NavItem>
                      <NavLink
                        style={{ color: 'black' }}
                        href="#"
                        className={classnames({
                          active:
                            location.pathname === `${match.url}/request-requests`,
                        })}
                        onClick={() => {
                          history.push(`${match.url}/request-requests`);
                        }}
                      >
                        Vend Requests
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ color: 'black' }}
                        href="#"
                        className={classnames({
                          active:
                            location.pathname === `${match.url}/request-payments`,
                        })}
                        onClick={() => {
                          history.push(`${match.url}/request-payments`);
                        }}
                      >
                        Payments
                      </NavLink>
                    </NavItem> */}
                  </Nav>
                  <br />
                  <div>
                    <Switch>
                      <Route exact path={`${match.path}`}>
                        <GeneralTab
                          request={request}
                          customer={request.customer || {}}
                          order={request.order || {}}
                          loading={loading}
                          openOrder={this.openOrder}
                        />
                      </Route>
                      <Route exact path={`${match.path}/order-requests`}>
                        {/* <VendRequests order={order} /> */}
                      </Route>
                      <Route exact path={`${match.path}/order-payments`}>
                        {/* <Payments order={order} /> */}
                      </Route>
                    </Switch>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

function GeneralTab({
  request,
  customer = {},
  loading,
  order = {},
  openOrder,
}) {
  return (
    <div>
      <Row>
        <Col sm="6">
          <Card className="card-chart">
            <CardHeader>
              <CardTitle className="mt-0">Request</CardTitle>
            </CardHeader>
            <CardBody>
              <div className="d-flex mb-3 justify-content-between">
                <div>
                  <span className="text-secondary">Customer ID</span>
                </div>
                <div> {request.customer_id} </div>
              </div>
              <div className="d-flex mb-3 justify-content-between">
                <div>
                  <span className="text-secondary">Customer Ref</span>
                </div>
                <div> {request.customer_ref} </div>
              </div>
              <div className="d-flex mb-3 justify-content-between">
                <div>
                  <span className="text-secondary">Plan</span>
                </div>
                <div>{request.plan}</div>
              </div>
              <div className="d-flex mb-3 justify-content-between">
                <div>
                  <span className="text-secondary">Gateway</span>
                </div>
                <div> {request.gateway} </div>
              </div>
              <div className="d-flex mb-3 justify-content-between">
                <div>
                  <span className="text-secondary">Gateway Ref</span>
                </div>
                <div> {request.gateway_ref} </div>
              </div>
              <div className="d-flex mb-3 justify-content-between">
                <div>
                  <span className="text-secondary">Amount</span>
                </div>
                {request.amount && <div> {formatAmount(request.amount)} </div>}
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col sm="6">
          <Card className="card-chart">
            <CardHeader>
              <CardTitle className="mt-0">Customer</CardTitle>
            </CardHeader>
            <CardBody>
              <div className="d-flex mb-3 justify-content-between">
                <div>
                  <span className="text-secondary">Joined</span>
                </div>
                {customer.created_at && (
                  <div> {formatDate(customer.created_at)} </div>
                )}
              </div>
              <div className="d-flex mb-3 justify-content-between">
                <div>
                  <span className="text-secondary">Email</span>
                </div>
                <div>{customer.email}</div>
              </div>
              <div className="d-flex mb-3 justify-content-between">
                <div>
                  <span className="text-secondary">Name</span>
                </div>
                <div> {customer.name} </div>
              </div>
              <div className="d-flex mb-3 justify-content-between">
                <div>
                  <span className="text-secondary">Status</span>
                </div>
                <div> {customer.payment_status} </div>
              </div>
              <div className="d-flex mb-3 justify-content-between">
                <div>
                  <span className="text-secondary">Phone</span>
                </div>
                <span>
                  <span className="cursor-pointer blue-text" onClick={() => {}}>
                    <strong>{customer.phone}</strong>{' '}
                  </span>
                  <ClickToCopy value={customer.phone} />
                </span>
              </div>
              <div className="d-flex mb-3 justify-content-between">
                <div>
                  <span className="text-secondary">Wallet</span>
                </div>
                <div>{formatAmount(customer.wallet)}</div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col sm="12">
          <Card className="card-chart">
            <CardHeader>
              <Row className="d-flex px-3 justify-content-between align-items-center">
                <CardTitle className=" mt-0">Order</CardTitle>
                <Button
                  className="d-flex justify-content-between"
                  color="success"
                  size="sm"
                  onClick={() => openOrder(order.id)}
                >
                  {/* <span>Open Order</span> */}
                  <i className="now-ui-icons ui-1_send hover-blue" />
                </Button>
              </Row>
            </CardHeader>
            <CardBody>
              <Row>
                <Col sm="6">
                  {loading ? (
                    <span className="text-secondary">Loading...</span>
                  ) : order.id !== undefined ? (
                    <div>
                      <div className="d-flex mb-3 justify-content-between">
                        <div>
                          <span className="text-secondary">Meter No.</span>
                        </div>
                        <div> {order.meter_no} </div>
                      </div>
                      <div className="d-flex mb-3 justify-content-between">
                        <div>
                          <span className="text-secondary">Channel</span>
                        </div>
                        <div> {order.channel} </div>
                      </div>
                      <div className="d-flex mb-3 justify-content-between">
                        <div>
                          <span className="text-secondary">Amount</span>
                        </div>
                        <span>{formatAmount(order.amount)}</span>
                      </div>
                      <div className="d-flex mb-3 justify-content-between">
                        <div>
                          <span className="text-secondary">Disco</span>
                        </div>
                        {<div> {order.disco_code}</div>}
                      </div>
                      <div className="d-flex mb-3 justify-content-between">
                        <div>
                          <span className="text-secondary">Order ID</span>
                        </div>
                        <div>{order.id}</div>
                      </div>
                      <div className="d-flex mb-3 justify-content-between">
                        <div>
                          <span className="text-secondary">Order Ref</span>
                        </div>
                        {<div>{order.order_ref}</div>}
                      </div>
                      <div className="d-flex mb-3 justify-content-between">
                        <div>
                          <span className="text-secondary">
                            Query Reference
                          </span>
                        </div>
                        {<div>{order.query_reference}</div>}
                      </div>
                      <div className="d-flex mb-3 justify-content-between">
                        <div>
                          <span className="text-secondary">Vend Type</span>
                        </div>
                        {<div>{order.vend_type}</div>}
                      </div>
                    </div>
                  ) : (
                    <span className="text-secondary">
                      No Vend Response Received.
                    </span>
                  )}
                </Col>
                <Col sm="6">
                  {loading ? (
                    <span className="text-secondary">Loading...</span>
                  ) : order.id !== undefined ? (
                    <div>
                      <div className="d-flex mb-3 justify-content-between">
                        <div>
                          <span className="text-secondary">
                            BuyPower Channel
                          </span>
                        </div>
                        <div> {order.buypower_channel} </div>
                      </div>
                      <div className="d-flex mb-3 justify-content-between">
                        <div>
                          <span className="text-secondary">
                            Convenience Fee
                          </span>
                        </div>
                        <div> {formatAmount(order.convenience_fee)} </div>
                      </div>
                      <div className="d-flex mb-3 justify-content-between">
                        <div>
                          <span className="text-secondary">Order Status</span>
                        </div>
                        <div> {order.order_status} </div>
                      </div>
                      <div className="d-flex mb-3 justify-content-between">
                        <div>
                          <span className="text-secondary">Paid At</span>
                        </div>
                        <div> {formatDate(order.paid_at)} </div>
                      </div>
                      <div className="d-flex mb-3 justify-content-between">
                        <div>
                          <span className="text-secondary">Payment Type</span>
                        </div>
                        <div> {order.payment_type} </div>
                      </div>
                      <div className="d-flex mb-3 justify-content-between">
                        <div>
                          <span className="text-secondary">
                            Transaction Date
                          </span>
                        </div>
                        <div> {formatDate(order.transaction_date)} </div>
                      </div>
                      <div className="d-flex mb-3 justify-content-between">
                        <div>
                          <span className="text-secondary">Vend Status</span>
                        </div>
                        <div> {order.vend_status} </div>
                      </div>
                      <div className="d-flex mb-3 justify-content-between">
                        <div>
                          <span className="text-secondary">Vend Amount</span>
                        </div>
                        <div> {formatAmount(order.vend_amount)} </div>
                      </div>
                    </div>
                  ) : (
                    <span className="text-secondary">
                      No Payment Response Received.
                    </span>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default connect()(Detail);
