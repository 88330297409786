import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  Modal,
  ModalHeader,
  ModalBody,
  // ModalFooter,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  Button,
} from 'reactstrap';

import Loading from './Loading';

import { closeAllModals } from '../actions/modalActions';
import { numToWords, callApi } from '../utils';
import { showError, showInfo } from '../actions/notificationActions';
import { ADROIT_BANKS } from '../constants';
const initState = {
  number: '',
  amount: '',
  loading: false,
  bankCode: null,
  customer_number: '',
};

export default connect(({ modals }) => ({ open: modals.adroit }))(
  class AdroitChequesModal extends Component {
    state = initState;

    setInput = (e) => {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    };

    close = () => {
      this.props.dispatch(closeAllModals());
      this.setState(initState);
    };

    submitRequest = (e) => {
      const { number, amount, bankCode, customer_number } = this.state;
      e.preventDefault();
      const prompt = `
      Are you sure you want to Submit cheque for transaction ${number}: 
    
      Amount: ₦ ${amount}
      
      ${numToWords(amount).toUpperCase()} NAIRA
          `;

      if (!window.confirm(prompt)) return;
      this.setState({ loading: true });
      callApi(
        '/accounting/jos/cheques',
        { number, amount, bankCode, customer_number },
        'POST'
      )
        .then((result) => {
          this.setState({ loading: false });
          this.props.dispatch(showInfo(result.message));
        })
        .catch((e) => {
          this.setState({ loading: false });
          this.props.dispatch(showError(e.message));
        });
    };

    render() {
      const { number, amount, loading, bankCode, customer_number } = this.state;

      return (
        <div>
          <Modal size="lg" isOpen={this.props.open} toggle={this.close}>
            <ModalHeader toggle={this.close}>Post Cheque</ModalHeader>
            <ModalBody>
              <form onSubmit={this.submitRequest}>
                <Row>
                  <Col xs={12} md={12}>
                    <FormGroup>
                      <Label>Cheque Number</Label>
                      <Input
                        onChange={this.setInput}
                        value={number}
                        name="number"
                        placeholder="Eg. 123"
                        type="text"
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label>Amount</Label>
                      <Input
                        onChange={this.setInput}
                        value={amount}
                        name="amount"
                        placeholder="Enter Amount"
                        type="number"
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label>Customer Number</Label>
                      <Input
                        onChange={this.setInput}
                        value={customer_number}
                        name="customer_number"
                        placeholder="Enter Customer Number"
                        type="text"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>Bank</Label>
                      <Input
                        onChange={this.setInput}
                        value={bankCode}
                        name="bankCode"
                        type="select"
                        required
                      >
                        <option value="">Select Bank</option>
                        {ADROIT_BANKS.map((a) => (
                          <option key={a.name} value={a.code}>
                            {a.name}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
                <hr />

                {loading && (
                  <Row className="justify-content-center">
                    <Loading />
                  </Row>
                )}

                <Row className="justify-content-center">
                  <Button
                    size="lg"
                    color="secondary"
                    disabled={loading}
                    type="submit"
                  >
                    {loading ? 'PLEASE WAIT...' : 'GENERATE'}
                  </Button>
                </Row>
              </form>
            </ModalBody>
          </Modal>
        </div>
      );
    }
  }
);
