import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import React, { Component } from 'react';
import { showError, showInfo } from '../actions/notificationActions';

import { closeAllModals } from '../actions/modalActions';
import { connect } from 'react-redux';
import surveyService from '../services/survey.service';

export default connect((state) => ({
  open: state.modals.editSurvey,
  id: state.modals.data,
}))(
  class EditSurveyModal extends Component {
    state = {
      loading: false,
      survey: {
        title: '',
        type: '',
        description: '',
        status: '',
        start_date: '',
        end_date: '',
        questions: [],
      },
      surveyStatuses: ['PUBLISHED', 'DRAFT', 'ARCHIVED'],
      surveyTypes: ['SINGLE_CHOICE', 'TEXT'], // Supported survey types
      newQuestion: '', // State to hold the new question temporarily
      newOptions: [], // State to hold options for a new question
      newOptionText: '', // State to hold the new option text temporarily
      editIndex: -1, // Index of the question being edited
      start_date: '',
      end_date: '',
    };

    componentDidUpdate(prevProps) {
      if (prevProps.open !== this.props.open && this.props.open) {
        this.setState({
          loading: false,
          survey: {
            title: '',
            type: '',
            description: '',
            status: '',
            questions: [],
            start_date: '',
            end_date: '',
          },
        });
        this.loadSurvey(this.props.id);
      }
    }

    loadSurvey = (id) => {
      this.setState({ loading: true });
      surveyService
        .getSingleSurvey(id)
        .then((res) => {
          this.setState({
            loading: false,
            survey: res.data,
          });
        })
        .catch((err) => {
          this.stopLoad();
          this.props.dispatch(showError(err.message.message));
        });
    };

    setInput = (e) => {
      const { name, value } = e.target;
      this.setState((state) => {
        return {
          ...state,
          [name]: value,
          survey: { ...state.survey, [name]: value },
        };
      });
    };

    addQuestionField = () => {
      const {
        newQuestion,
        survey: { questions },
        newOptions,
        editIndex,
      } = this.state;
      if (newQuestion.trim() !== '') {
        if (editIndex !== -1) {
          // If editing an existing question, update it
          const updatedQuestions = [...questions];
          updatedQuestions[editIndex] = {
            ...updatedQuestions[editIndex],
            question: this.state.newQuestion,
            options: this.state.newOptions,
          };
          const { newQuestion, newOptionText, ...payload } = this.state.survey;
          this.setState({
            survey: {
              ...payload,
              questions: updatedQuestions,
            },
            newQuestion: '', // Clear the newQuestion state after updating
            newOptions: [], // Clear the newOptions state after updating
            editIndex: -1, // Reset editIndex
          });
        } else {
          // If adding a new question
          const { newQuestion, newOptionText, ...payload } = this.state.survey;
          this.setState({
            survey: {
              ...payload,
              questions: [
                ...questions,
                {
                  question: newQuestion,
                  options: newOptions,
                },
              ],
            },
            newQuestion: '', // Clear the newQuestion state after adding it to questions array
            newOptions: [], // Clear the newOptions state after adding
          });
        }
      }
    };

    addOption = () => {
      const { newOptionText, newOptions } = this.state;
      if (newOptionText.trim() !== '') {
        this.setState({
          newOptions: [...newOptions, { text: newOptionText }],
          newOptionText: '', // Clear the newOptionText state after adding it to newOptions array
        });
      }
    };

    editQuestion = (index) => {
      const {
        survey: { questions },
      } = this.state;
      this.setState({
        newQuestion: questions[index].question, // Set newQuestion to the question being edited
        newOptions: questions[index].options, // Set newOptions to the options being edited
        editIndex: index, // Set editIndex to the index of the question being edited
      });
    };

    deleteQuestion = (index) => {
      const {
        survey: { questions },
      } = this.state;
      this.setState({
        questions: questions.filter((_, i) => i !== index),
      });
    };

    deleteOption = (index) => {
      const { newOptions } = this.state;
      this.setState({
        newOptions: newOptions.filter((_, i) => i !== index),
      });
    };

    close = () => {
      this.props.dispatch(closeAllModals());
    };

    updateSurvey = (e) => {
      e.preventDefault();
      this.setState({ loading: true });
      surveyService
        .updateSurvey(this.props.id, this.state.survey)
        .then(() => {
          this.setState({ loading: false });
          this.props.dispatch(showInfo('survey updated successfully'));
          this.props.dispatch(closeAllModals());
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch((err) => {
          this.setState({ loading: false });
          this.props.dispatch(showError(err.response.data.message));
        });
    };

    render() {
      const {
        loading,
        title,
        type,
        status,
        description,
        questions,
        start_date,
        end_date,
      } = this.state.survey;
      const open = this.props.open;
      return (
        <div>
          <Modal size="lg" isOpen={open} toggle={this.close}>
            <ModalHeader toggle={this.close}>Update survey</ModalHeader>
            <ModalBody>
              <form>
                <div>
                  <FormGroup>
                    <Label className="mt-3">Title:</Label>
                    <Input
                      name="title"
                      type="text"
                      onChange={this.setInput}
                      value={title}
                      disabled={loading}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label className="mt-3">Description:</Label>
                    <Input
                      name="description"
                      type="text"
                      onChange={this.setInput}
                      value={description}
                      disabled={loading}
                    />
                  </FormGroup>

                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label for="type">Status</Label>
                        <Input
                          id="status"
                          name="status"
                          type="select"
                          onChange={this.setInput}
                          value={status}
                        >
                          <option value={''}>Please select</option>
                          {this.state.surveyStatuses.map((status, index) => (
                            <option
                              key={index}
                              value={status.toLocaleLowerCase()}
                            >
                              {status}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>

                    <Col md="6">
                      <FormGroup>
                        <Label for="type">Type</Label>
                        <Input
                          id="type"
                          name="type"
                          type="select"
                          onChange={this.setInput}
                          value={type}
                        >
                          <option value={''}>Please select</option>
                          {this.state.surveyTypes.map((type, index) => (
                            <option key={index} value={type.toUpperCase()}>
                              {type.toUpperCase()}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label for="start_date">Start Date</Label>
                        <Input
                          id="start_date"
                          name="start_date"
                          type="datetime-local"
                          onChange={this.setInput}
                          value={start_date}
                        ></Input>
                      </FormGroup>
                    </Col>

                    <Col md="6">
                      <FormGroup>
                        <Label for="end_date">End Date</Label>
                        <Input
                          id="end_date"
                          name="end_date"
                          type="datetime-local"
                          onChange={this.setInput}
                          value={end_date}
                        ></Input>
                      </FormGroup>
                    </Col>
                  </Row>

                  {/* Existing questions */}
                  {questions.map((questionObj, index) => (
                    <div key={index}>
                      <Label className="mt-3">Question {index + 1}:</Label>
                      <Input
                        type="text"
                        value={questionObj.question}
                        disabled
                      />
                      {questionObj.options &&
                        questionObj.options.map((option, optIndex) => (
                          <div key={optIndex} className="ml-3">
                            <Label>Option {optIndex + 1}:</Label>
                            <Input type="text" value={option.text} disabled />
                            <Button
                              color="danger"
                              onClick={() => this.deleteOption(optIndex)}
                              disabled={loading}
                            >
                              Delete Option
                            </Button>
                          </div>
                        ))}
                      {/* Edit and delete buttons for each question */}
                      <Button
                        color="secondary"
                        onClick={() => this.editQuestion(index)}
                        disabled={loading}
                      >
                        Edit
                      </Button>
                      <Button
                        color="danger"
                        onClick={() => this.deleteQuestion(index)}
                        disabled={loading}
                      >
                        Delete Question
                      </Button>
                    </div>
                  ))}

                  {/* Input for new question */}
                  <Label className="mt-3">New Question:</Label>
                  <Input
                    name="newQuestion"
                    type="text"
                    onChange={this.setInput}
                    value={this.state.newQuestion}
                    disabled={loading}
                  />

                  {/* Option inputs if the survey type is single_choice */}
                  {type === 'SINGLE_CHOICE' && (
                    <div>
                      <Label className="mt-3">Options:</Label>
                      {this.state.newOptions.map((option, index) => (
                        <div key={index}>
                          <Input
                            type="text"
                            value={option.text}
                            onChange={(e) => {
                              const updatedOptions = [...this.state.newOptions];
                              updatedOptions[index].text = e.target.value;
                              this.setState({ newOptions: updatedOptions });
                            }}
                            disabled={loading}
                          />
                          <Button
                            color="danger"
                            onClick={() => this.deleteOption(index)}
                            disabled={loading}
                          >
                            Delete Option
                          </Button>
                        </div>
                      ))}
                      <Input
                        name="newOptionText"
                        type="text"
                        onChange={this.setInput}
                        value={this.state.newOptionText}
                        disabled={loading}
                      />
                      <Button
                        color="primary"
                        onClick={this.addOption}
                        disabled={loading}
                      >
                        Add Option
                      </Button>
                    </div>
                  )}

                  {/* Add or update question button */}
                  <Button
                    color="primary"
                    onClick={this.addQuestionField}
                    disabled={loading}
                  >
                    {this.state.editIndex !== -1 ? 'Update' : 'Add'} Question
                  </Button>
                </div>
                <div />
              </form>
              {this.state.error ? (
                <span style={{ color: 'red' }}>{this.state.error}</span>
              ) : (
                ''
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                color="success"
                disabled={this.state.loading}
                onClick={this.updateSurvey}
              >
                Update Survey{' '}
                {loading ? (
                  <span className="fas fa-spinner fa-spin"></span>
                ) : (
                  ''
                )}
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      );
    }
  }
);
