import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
  Button,
  Row,
} from 'reactstrap';

import { closeAllModals } from '../actions/modalActions';

export default connect((state) => ({
  open: state.modals.confirmAction,
  data: state.modals.data,
  loading: state.modals.loading,
}))(
  class ConfirmModal extends Component {
    state = {
      params: {},
      errors: {},
      fields: [],
      title: '',
    };

    componentDidUpdate(prevProps) {
      if (prevProps.open !== this.props.open && this.props.open) {
        this.setState({
          fields: this.props.data.fields || [],
          cancelBtnText: this.props.data.cancelBtnText,
          confirmBtnText: this.props.data.confirmBtnText,
          title: this.props.data.title,
          params: {},
        });
      }
    }

    setInput = (e) => {
      const { name, value } = e.target;
      console.log(name, value);
      this.setState({ [name]: value });
    };

    setInnerInput = (e) => {
      const { name, value } = e.target;
      console.log(name, value);
      const { params, errors } = this.state;
      this.setState({
        params: { ...params, [name]: value },
        errors: { ...errors, [name]: '' },
      });
    };

    close = () => {
      this.props.dispatch(closeAllModals());
    };

    /**
     * Check that form fields exist. If not, submit form.
     *
     * For each field, check if the isValid function exists.
     * When it does, call the function with the appropriate field value.
     * Proceed if no errors, otherwise, display errors
     */
    confirm = (params) => {
      let errorExists = false;
      let errors = {};
      const formFields = this.props.data.fields;
      if (formFields) {
        for (const f of formFields) {
          if (f.isValid) {
            if (!f.isValid(params[f.name])) {
              errors = { ...errors, [f.name]: f.error };
              errorExists = true;
            }
          }
        }
        if (errorExists) {
          this.setState({ errors });
          return;
        } else {
          this.props.data.confirmCallback(params);
          this.close();
        }
      } else {
        this.props.data.confirmCallback(params);
        this.close();
      }
    };

    render() {
      const data = this.props.data;

      const {
        params,
        fields,
        cancelBtnText,
        confirmBtnText,
        title,
        errors,
      } = this.state;

      const loading = this.props.loading;
      const open = this.props.open;
      return (
        <div>
          <Modal size="lg" isOpen={open} toggle={this.close}>
            <ModalHeader toggle={this.close}>
              {title ? title : 'Confirm Action'}
            </ModalHeader>
            <ModalBody>
              <form onSubmit={this.updateUser}>
                <h5>{data && data.confirmText}</h5>

                {fields.length > 0 ? (
                  <div>
                    {fields.map((f) => (
                      <FormGroup key={f.name}>
                        <Label>{f.label}</Label>
                        {f.type === 'checkbox' && (
                          <span
                            style={{
                              width: '40px',
                              height: '1px',
                              display: 'inline-block',
                            }}
                          />
                        )}
                        <Input
                          name={f.name}
                          type={f.type}
                          onChange={
                            f.type === 'checkbox'
                              ? (e) =>
                                  this.setInnerInput({
                                    target: {
                                      name: e.target.name,
                                      value: e.target.checked,
                                    },
                                  })
                              : this.setInnerInput
                          }
                          // value={params[f.name]}
                        >
                          {f.options &&
                            f.options.map((o) => (
                              <option key={o.value} value={o.value}>
                                {o.label}
                              </option>
                            ))}
                        </Input>
                        {errors[f.name] && (
                          <Row className="d-flex justify-content-end mr-2">
                            <span style={{ color: 'red' }}>
                              {errors[f.name]}
                            </span>
                          </Row>
                        )}
                      </FormGroup>
                    ))}
                  </div>
                ) : (
                  <div />
                )}
              </form>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                disabled={loading}
                onClick={data && data.cancel}
              >
                {cancelBtnText ? cancelBtnText : 'No'}
              </Button>
              <Button
                color="danger"
                disabled={loading}
                onClick={() => this.confirm(params)}
              >
                {loading
                  ? 'Please Wait...'
                  : confirmBtnText
                  ? confirmBtnText
                  : 'Yes'}
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      );
    }
  }
);
