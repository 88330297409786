import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Row, Col, Card, Button } from 'reactstrap';

import {
  callApi,
  formatDate,
  // _toDash,
  // formatAmount,
  getDuration,
} from '../utils';
import { showError } from '../actions/notificationActions';

import Loading from './Loading';
// import ErrorTick from './ErrorTick';
// import SuccessTick from './SuccessTick';
import ClickToCopy from './ClickToCopy';

import { openUserModal } from '../actions/modalActions';
import TransactionTimeline from './TransactionTimeline';

export default connect()(
  class SessionDetails extends Component {
    state = {
      lodaing: false,
      transaction: {},
    };

    componentWillMount() {
      if (this.props.id) {
        this.loadSession();
      }
    }

    loadSession = () => {
      this.setState({ loading: true });
      callApi('/analytics/session?id=' + this.props.id)
        .then(({ session = {} }) => {
          this.setState({ loading: false, session });
        })
        .catch((err) => {
          this.setState({ loading: false });
          this.props.dispatch(showError(err));
        });
    };

    openUser = (id) => {
      this.props.dispatch(openUserModal(id));
    };

    callUser = (id) => {
      callApi('/analytics/called?id=' + id)
        .then(() => {
          this.loadSession();
        })
        .catch((err) => this.props.dispatch(showError(err)));
    };

    render() {
      const { loading } = this.state;
      const s = this.state.session;
      const id = this.props.id;
      return loading ? (
        <Loading />
      ) : (
        <Card>
          <Row className="justify-content-center text-center">
            <ul className="timeline-list">
              <li>Name: {s.name}</li>
              <li>
                Phone:&nbsp;
                <span
                  className="cursor-pointer blue-text"
                  onClick={() => this.openUser(s.phone)}
                >
                  <strong>{s.phone}</strong>
                </span>
                &nbsp; <ClickToCopy value={s.phone} />
              </li>
              <li>
                Duration:{' '}
                <strong className="mark">
                  {getDuration(s.created_at, s.updated_at)}
                </strong>
              </li>
              <li>Time: {formatDate(s.created_at)}</li>
              <li>
                Browser: <strong className="mark">{s.browser}</strong>
              </li>
              {!s.data.some((d) => d.category === 'CALL') ? (
                <li>
                  <Button
                    size="sm"
                    onClick={() => this.callUser(id)}
                    color="primary"
                  >
                    MARK CALLED
                  </Button>
                </li>
              ) : null}
            </ul>
          </Row>
          <hr />
          <Row className="justify-content-center text-center">
            <Col xs={3} md={3}>
              <span className="mark small">CATEGORY</span>
            </Col>
            <Col xs={3} md={3}>
              <span className="mark small">ACTION</span>
            </Col>
            <Col xs={3} md={3}>
              <span className="mark small">LABEL</span>
            </Col>
            <Col xs={3} md={3}>
              <span className="mark small">VALUE</span>
            </Col>
          </Row>
          <hr />
          {s.data.map((r) => (
            <div key={r.time}>
              <Row className="justify-content-center text-center collapsed-col">
                <Col xs={3} md={3}>
                  {r.category}
                </Col>
                <Col xs={3} md={3}>
                  {r.action}
                </Col>
                <Col xs={3} md={3}>
                  {r.label}
                </Col>
                <Col xs={3} md={3}>
                  {r.value}
                </Col>
              </Row>
              {r.action === 'SET_ORDER_ID' ? (
                <TransactionTimeline
                  id={r.label}
                  dispatch={this.props.dispatch}
                />
              ) : null}
              <hr />
            </div>
          ))}
        </Card>
      );
    }
  }
);
