import React from 'react';

import { Row, Button } from 'reactstrap';

const OptionButtons = ({
  options = [],
  header = '',
  selected = '',
  onSelect = () => null,
}) => (
  <div className="ml-4">
    {header ? <h5>{header}</h5> : ''}
    <Row>
      {options.map((o) => (
        <Button
          onClick={() => onSelect(o)}
          outline={selected !== o}
          color={selected === o ? 'primary' : 'secondary'}
          size={'sm'}
          key={o}
        >
          {o}
        </Button>
      ))}
    </Row>
  </div>
);

export default OptionButtons;
